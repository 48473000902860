import "./GeneracionOt.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function GeneracionOt() {
  const SCREEN_CODE = "fun0114_generacion_ot";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [filter, setFilter] = useState({
    fecha: null,
    capitalyHBA: false,
    tipoRemito: null,
    letra: null,
    puntoVenta: null,
    numero: null,
    estados: "TODOS",
    concesionario: null,
    marca: null,
    mercados: null,
    marcas: null,
    modelos: null,
    colores: null,
    familias: null,
    vin: null,
    bloqueoCalidad: null,
    direccion: "TODOS",
    modelo: null,
  });
  const data = convertDateObjects(reporte?.filas);

  const filterColumnsIdGenerar = [
    1491, 2139, 2941, 1269, 1569, 1799, 3774, 4197, 13928, 2140, 4216, 18356,
    14456, 19690,
  ];
  const filterColumnsIdCerrar = [14369, 1491, 3344, 14370, 13706, 5271];
  const columnsNamesGenerar = filterColumnsIds(filterColumnsIdGenerar);
  const columnsNamesCerrar = filterColumnsIds(filterColumnsIdCerrar);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  async function loadReportData() {
    setLoading(true);
    // try {
    //   let transacciones = [];
    //   if (filter.capitalyHBA) {
    //     transacciones.push("GFC06");
    //   }

    //   const { status, data } = await sipcoAxiosService.post(
    //     "/administracion-remitos-concesionario/vin",
    //     {
    //       codPais: securityFilters?.securityValues?.country?.codPais || null,
    //       codCliente: securityFilters?.securityValues?.client?.codCliente || "",
    //       codCuenta:
    //         securityFilters?.securityValues?.account?.codCuenta || null,
    //       codSubcuenta:
    //         securityFilters?.securityValues?.subaccount?.codSubcuenta || null,
    //     }
    //   );

    //   if (status === 200) {
    //     setReporte(data);
    //   }
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setLoading(false);
    // }
  }

  return (
    <div className="generacion-ot">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      />
      <Accordion multiple activeIndex={[0, 1]}>
        <AccordionTab
          header={
            <LanguageProvider
              id={"14379"}
              alt="Generación y Cierre de Ordenes de Trabajo"
            />
          }
        >
          <TabView>
            <TabPanel
              header={<LanguageProvider id={"14357"} alt="Generar OT" />}
            >
              <div className="filter-options">
                <div className="sipco-options-line">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"1799"} alt="Grupo" />
                    </label>
                    <AutoComplete field="key" dropdown />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"1569"} alt="Operación" />
                    </label>
                    <AutoComplete field="key" dropdown />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"662"} alt="Ingrese el Vin" />
                    </label>
                    <AutoComplete field="key" dropdown />
                  </div>
                  <div className="RadioButtonFilter">
                    <Checkbox
                      checked={filter.capitalyHBA}
                      onChange={(e) => {
                        setFilter({ ...filter, capitalyHBA: e.checked });
                      }}
                      style={{
                        justifyContent: "center",
                        position: "relative",
                      }}
                    />
                    <label>
                      <LanguageProvider id={"18603"} alt="Por OT" />
                    </label>
                  </div>
                  <div className="RadioButtonFilter">
                    <Checkbox
                      checked={filter.capitalyHBA}
                      onChange={(e) => {
                        setFilter({ ...filter, capitalyHBA: e.checked });
                      }}
                      style={{
                        justifyContent: "center",
                        position: "relative",
                      }}
                    />
                    <label>
                      <LanguageProvider
                        id={"18513"}
                        alt="Listado por Operación"
                      />
                    </label>
                  </div>
                </div>
                <div className="sipco-options-line">
                  <div className="RadioButtonFilter">
                    <Checkbox
                      checked={filter.capitalyHBA}
                      onChange={(e) => {
                        setFilter({ ...filter, capitalyHBA: e.checked });
                      }}
                      style={{
                        justifyContent: "center",
                        position: "relative",
                      }}
                    />
                    <label>
                      <LanguageProvider id={"11507"} alt="Fecha Pendiente" />
                    </label>
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"467"} alt="Desde :" />
                    </label>
                    <Calendar showIcon showTime showButtonBar />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"468"} alt="Hasta :" />
                    </label>
                    <Calendar showIcon showTime showButtonBar />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"11720"} alt="Cantidad de Vines" />
                    </label>
                    <Chip label="0" />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"18358"}
                        alt="Total vines seleccionados"
                      />
                    </label>
                    <Chip label="0" />
                  </div>
                </div>
                <div className="sipco-options-line">
                  <div className="RadioButtonFilter">
                    <Checkbox
                      checked={filter.capitalyHBA}
                      onChange={(e) => {
                        setFilter({ ...filter, capitalyHBA: e.checked });
                      }}
                      style={{
                        justifyContent: "center",
                        position: "relative",
                      }}
                    />
                    <label>
                      <LanguageProvider id={"19690"} alt="Fecha Priorización" />
                    </label>
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"467"} alt="Desde :" />
                    </label>
                    <Calendar showIcon showTime showButtonBar />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"468"} alt="Hasta :" />
                    </label>
                    <Calendar showIcon showTime showButtonBar />
                  </div>
                </div>
              </div>
              <DataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={data}
                paginator
                scrollHeight="flex"
                filterDisplay="menu"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                style={{ maxWidth: "100%" }}
                loading={loading}
              >
                {columnsNamesGenerar.map((colum, index) => (
                  <Column
                    key={index}
                    field={colum.id}
                    header={colum.label}
                    sortable
                    filter
                  />
                ))}
              </DataTable>
              {/* buttons */}
              <div>
                <Button
                  label={LanguageProvider({
                    id: "14357",
                    alt: "Generar OT",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "748",
                    alt: "Seleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "749",
                    alt: "Deseleccionar todo",
                  })}
                  text
                />
              </div>
            </TabPanel>
            <TabPanel
              header={<LanguageProvider id={"14377"} alt="Cerrar OT" />}
            >
              <div className="filter-options">
                <div className="sipco-options-line">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"1799"} alt="Grupo" />
                    </label>
                    <AutoComplete field="key" dropdown />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"1569"} alt="Operación" />
                    </label>
                    <AutoComplete field="key" dropdown />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"662"} alt="Ingrese el Vin" />
                    </label>
                    <AutoComplete field="key" dropdown />
                  </div>
                </div>
                <div className="sipco-options-line">
                  <div className="RadioButtonFilter">
                    <Checkbox
                      checked={filter.capitalyHBA}
                      onChange={(e) => {
                        setFilter({ ...filter, capitalyHBA: e.checked });
                      }}
                      style={{
                        justifyContent: "center",
                        position: "relative",
                      }}
                    />
                    <label>
                      <LanguageProvider id={"11507"} alt="Fecha Pendiente" />
                    </label>
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"467"} alt="Desde :" />
                    </label>
                    <Calendar showIcon showTime showButtonBar />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"468"} alt="Hasta :" />
                    </label>
                    <Calendar showIcon showTime showButtonBar />
                  </div>
                </div>
                <div className="sipco-options-line">
                  <div className="RadioButtonFilter">
                    <Checkbox
                      checked={filter.capitalyHBA}
                      onChange={(e) => {
                        setFilter({ ...filter, capitalyHBA: e.checked });
                      }}
                      style={{
                        justifyContent: "center",
                        position: "relative",
                      }}
                    />
                    <label>
                      <LanguageProvider id={"19690"} alt="Fecha Priorización" />
                    </label>
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"467"} alt="Desde :" />
                    </label>
                    <Calendar showIcon showTime showButtonBar />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"468"} alt="Hasta :" />
                    </label>
                    <Calendar showIcon showTime showButtonBar />
                  </div>
                </div>
              </div>
              <DataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={data}
                paginator
                scrollHeight="flex"
                filterDisplay="menu"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                style={{ maxWidth: "100%" }}
                loading={loading}
              >
                {columnsNamesCerrar.map((colum, index) => (
                  <Column
                    key={index}
                    field={colum.id}
                    header={colum.label}
                    sortable
                    filter
                  />
                ))}
              </DataTable>
              {/* buttons */}
              <div>
                <Button
                  label={LanguageProvider({
                    id: "14377",
                    alt: "Cerrar OT",
                  })}
                  text
                />
              </div>
            </TabPanel>
          </TabView>
        </AccordionTab>
      </Accordion>
    </div>
  );
}
export default GeneracionOt;
