import React, { useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import useFilterColumnsIds from "../hooks/useFilterColumnsIds";

const Manual: React.FC<any> = () => {
  const [columNamesManual, setColumNamesManual] = useState();
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    capitalyHBA: true,
  });

  const filterColumnsIdManual = [1491, 1569, 5788, 8380, 2521, 1669];

  const columNames = useFilterColumnsIds(filterColumnsIdManual);

  return (
    <div>
      <DataTable
        size={"small"}
        showGridlines
        stripedRows
        value={data}
        paginator
        scrollHeight="flex"
        filterDisplay="menu"
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        style={{ maxWidth: "100%" }}
        loading={loading}
      >
        {columNames.map((colum, index) => (
          <Column
            key={index}
            field={colum.id}
            header={colum.label}
            sortable
            filter
          />
        ))}
      </DataTable>
      <div className="filter-options">
        <div className="sipco-options-line">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2785"} alt="Operación" />
            </label>
            <InputText />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"10828"} alt="Linea" />
            </label>
            <InputText />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"8380"} alt="Resultado" />
            </label>
            <AutoComplete field="key" dropdown />
          </div>
        </div>
        <div className="sipco-options-line">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2521"} alt="Proveedor" />
            </label>
            <AutoComplete dropdown />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1669"} alt="Observación" />
            </label>
            <InputText />
          </div>
          <div className={"sipco-option"}>
            <Button
              className="button"
              icon="pi pi-check"
              iconPos="right"
              severity="success"
              label={LanguageProvider({
                id: "14394",
                alt: "Registrar Operación",
              })}
            />
          </div>
        </div>
      </div>
      {/* buttons */}
      <div>
        <Button
          label={LanguageProvider({
            id: "14391",
            alt: "Realizar Operación",
          })}
          text
        />
        <Button
          label={LanguageProvider({
            id: "3155",
            alt: "Cancelar",
          })}
          text
        />
      </div>
    </div>
  );
};
export default Manual;
