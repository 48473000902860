import "./MantenerValesDeRepuestos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import useRowStyles from "../../../hooks/useRowStyles";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function MantenerValesDeRepuestos() {
  const SCREEN_CODE = "fun0081_mantener_vales_de_repuestos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();

  const dt = useRef(null);

  const columnsIds = [12231, 16800, 1491, 2139, 3625, 16801];
  const columnsNames = filterColumnsIds(columnsIds);

  const [reportData, setReportData] = useState(null);
  const [loadingReportData, setLoadingReportData] = useState(false);

  const [talleres, setTalleres] = useState([]);
  const [loadingTalleres, setLoadingTalleres] = useState(false);

  const [filter, setFilter] = useState({
    taller: null,
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const rowStyles = useRowStyles([selectedRows]);

  function filterColumnsIds(ids) {
    const result = [];
    for (const element of ids) {
      result.push({
        id: element,
        label: LanguageProvider({
          id: element,
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }
  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const filters = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        subcuenta: securityFilters.securityValues.subaccount,
        taller: filter.taller,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-vales-repuestos/vale-repuesto",
        filters
      );
      if (status === 200) {
        setReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }
  async function loadTalleres() {
    setLoadingTalleres(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "mantener-vales-repuestos/combo/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
            },
          }
        );
        if (status === 200) {
          setTalleres(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTalleres(false);
    }
  }

  useEffect(() => {
    async function handleSecurityFilterChange() {
      try {
        const talleres = await loadTalleres();
        setFilter({ taller: talleres[0] });
      } catch (error) {
        console.error(error);
      }
    }
    handleSecurityFilterChange();
  }, [securityFilters.securityValues]);

  return (
    <div className="mantener-vales-de-repuestos">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"327"} alt="Taller" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <Dropdown
                  value={filter.taller}
                  onChange={(e) => setFilter({ ...filter, taller: e.value })}
                  options={talleres}
                  loading={loadingTalleres}
                  optionLabel="descripcion"
                  placeholder="Seleccione"
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset legend={<LanguageProvider id={"6697"} alt="Listado" />}>
        <div className="fieldset-content">
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={reportData}
            paginator
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            resizableColumns
            removableSort
            loading={loadingReportData}
            filterDisplay="menu"
            selection={selectedRows}
            onSelectionChange={(e) => setSelectedRows(e.value)}
            selectionMode="single"
            rowClassName={rowStyles}
          >
            <Column field="id" header={columnsNames[0].label} sortable filter />
            <Column
              field="fechaVale.timestamp"
              header={columnsNames[1].label}
              body={(data) => {
                return new Date(data.fechaVale.timestamp).toLocaleDateString();
              }}
              sortable
              filter
            />
            <Column
              field="ordenReparacion.vin.id"
              header={columnsNames[2].label}
              sortable
              filter
            />
            <Column
              field="ordenReparacion.vin.modeloLargo"
              header={columnsNames[3].label}
              sortable
              filter
            />
            <Column
              field="observaciones"
              header={columnsNames[4].label}
              sortable
              filter
            />
            <Column
              field="ordenReparacion.id"
              header={columnsNames[5].label}
              sortable
              filter
            />
          </DataTable>
        </div>
        <div>
          <Button
            label={LanguageProvider({
              id: "25",
              alt: "Alta",
            })}
            text
          />
          <Button
            label={LanguageProvider({
              id: "27",
              alt: "Modificar",
            })}
            text
          />
          <Button
            label={LanguageProvider({
              id: "21880",
              alt: "Borrar",
            })}
            text
          />
          <Button
            label={LanguageProvider({
              id: "2343",
              alt: "Cerrar Vale",
            })}
            text
          />

          <Button
            label={LanguageProvider({
              id: "2248",
              alt: "Repuestos",
            })}
            text
          />
        </div>
      </Fieldset>
    </div>
  );
}
export default MantenerValesDeRepuestos;
