import "./EgresoTaller.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function EgresoTaller() {
  const SCREEN_CODE = "fun0054_egreso_taller";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const toast = useRef<Toast>(null);

  const [loading, setLoading] = useState(false);

  const columnsIds1 = [
    17088, 396, 2139, 2140, 4724, 849, 850, 932, 1492, 3754, 16797, 802,
  ];
  const columnsIds2 = [10540, 10541, 2490, 16243, 16575, 17007, 16541];

  const columnsNames1 = filterColumnsIds(columnsIds1);
  const columnsNames2 = filterColumnsIds(columnsIds2);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  return (
    <div className="egreso-taller">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Fieldset legend={<LanguageProvider id={"320"} alt="Egreso Taller" />}>
        <div className="fieldset-content">
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"664"} alt="Talleres" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"990"} alt="Calle" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className="divide">
                <div className={"sipco-option-divide"}>
                  <label>
                    <LanguageProvider id={"991"} alt="Columna" />
                  </label>
                  <AutoComplete dropdown />
                </div>
                <div className={"sipco-option-divide"}>
                  <label>
                    <LanguageProvider id={"992"} alt="Nivel" />
                  </label>
                  <AutoComplete dropdown />
                </div>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Vin" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
          </div>
          <DataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={reporte}
                  screenName={"RecepcionPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={listRemitos}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsNames1.map((colum, index) => (
              <Column key={index} header={colum.label} sortable filter />
            ))}
          </DataTable>
          <Divider />
          <DataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={reporte}
                  screenName={"RecepcionPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={listRemitos}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsNames2.map((colum, index) => (
              <Column key={index} header={colum.label} sortable filter />
            ))}
          </DataTable>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={"17475"}
                    alt="Los registros seleccionados se marcarán como reparados, puede desmarcar con un click"
                  />
                </label>
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"741"} alt="Ubicación de Traslado" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className="divide">
                <div className={"sipco-option-divide"}>
                  <label>
                    <LanguageProvider id={"991"} alt="Columna" />
                  </label>
                  <AutoComplete dropdown />
                </div>
                <div className={"sipco-option-divide"}>
                  <label>
                    <LanguageProvider id={"992"} alt="Nivel" />
                  </label>
                  <AutoComplete dropdown />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Divider />
        <div className="fieldset-footer">
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "34",
                alt: "Procesar o guardar cambios",
              })}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default EgresoTaller;
