import "./RemitoElectronico.scss";
import "primeicons/primeicons.css";

import {  AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function RemitoElectronico() {
  const SCREEN_CODE = "fun0101_remito_electronico";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [disableInputs, setDisableInputs] = useState(true);
  const [selectedimpreso, setSelectedImpreso] = useState({
    name: "TODOS",
    code: "TODOS",
  });

  const impreso = [
    { name: "TODOS", code: "TODOS" },
    { name: "SI", code: "0" },
    { name: "NO", code: "1" },
  ];

  const table1 = [
    851, 3344, 7759, 17058, 1492, 1719, 1731, 3750, 754, 17064, 17065, 17059,
    17060,
  ];

  const columNames = filterColumnsIds(table1);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  return (
    <div className="remito-electronico">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"7795"} alt="Filtrar Por:" />}
        >
          <div className="filter-options-column" style={{ maxWidth: "80%" }}>
            <div className="sipco-options-column">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"6176"} alt="Sucursal" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className="divide">
                <div className="sipco-option-divide">
                  <label>
                    <LanguageProvider id={"1151"} alt="Estados" />
                  </label>
                  <Dropdown
                    value={selectedimpreso}
                    onChange={(e) => setSelectedImpreso(e.value)}
                    options={impreso}
                    optionLabel="name"
                  />
                </div>
                <div className="sipco-option-divide">
                  <label>
                    <LanguageProvider id={"17046"} alt="Error Rentas" />
                  </label>
                  <Dropdown
                    value={selectedimpreso}
                    onChange={(e) => setSelectedImpreso(e.value)}
                    options={impreso}
                    optionLabel="name"
                  />
                </div>
              </div>
            </div>
            <div className="sipco-options-column">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"1492"} alt="Fecha Emisión" />
                </label>
                <Calendar showButtonBar showIcon showTime />
              </div>
              <div className="sipco-option">
                <label></label>
                <Calendar showButtonBar showIcon showTime />
              </div>
            </div>
            <div className="sipco-options-column">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"1719"} alt="Fecha Salida" />
                </label>
                <Calendar showButtonBar showIcon showTime />
              </div>
              <div className="sipco-option">
                <label></label>
                <Calendar showButtonBar showIcon showTime />
              </div>
            </div>
            <div className="sipco-options-column">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"5007"} alt="Item" />
                </label>
                <InputText />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"7759"} alt="Impreso" />
                </label>
                <Dropdown
                  value={selectedimpreso}
                  onChange={(e) => setSelectedImpreso(e.value)}
                  options={impreso}
                  optionLabel="name"
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset legend={<LanguageProvider id={"6697"} alt="Listado" />}>
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {columNames.map((colum, index) => (
            <Column
              key={index}
              field={colum.id}
              header={colum.label}
              sortable
              filter
            />
          ))}
        </DataTable>
        <div className="filter-options">
          <div className="sipco-options-line">
            <div className="sipco-option" style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={"6176"} alt="Sucursal" />
              </label>
              <Chip label="0" />
            </div>
            <div className="tags">
              <Tag
                value={<LanguageProvider id={"10737"} alt="Errores de Envío" />}
              />
              <Tag
                style={{ background: "black" }}
                value={
                  <LanguageProvider
                    id={"TODO"}
                    alt="Generado Automaticamente"
                  />
                }
              />
            </div>
          </div>
        </div>
        <Button
          label={LanguageProvider({
            id: "17358",
            alt: "Origen/Destino",
          })}
          text
        />
        <Button
          label={LanguageProvider({
            id: "17492",
            alt: "Web",
          })}
          text
        />
        <Button
          label={LanguageProvider({
            id: "21062",
            alt: "Enviar PDF",
          })}
          text
        />
        <Button
          label={LanguageProvider({
            id: "22220",
            alt: "Imprimir Layout Daños",
          })}
          text
        />
      </Fieldset>
    </div>
  );
}
export default RemitoElectronico;
