import "./GestionPreparacion.scss";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import ModalOperacionVin from "./components/ModalOperacionVin";
import PanelCustom from "../../../components/panel-custom/PanelCustom";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function GestionPreparacion() {
  const SCREEN_CODE = "fun0206_gestion_preparacion";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();

  const toast = useRef<Toast>(null);

  const [vinTxt, setVinTxt] = useState("");
  const [vinFull, setVinFull] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [lineaHojaRuta, setLineaHojaRuta] = useState(null);
  const [remito, setRemito] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  async function loadVin() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/gestion-preparacion/vin-first",
        {
          pais: securityFilters.securityValues.country,
          cuenta: securityFilters.securityValues.account,
          vin: {
            id: vinTxt,
          },
        }
      );
      if (status === 200) {
        if (data.length > 0) {
          setVinTxt(data[0].id);
          const client = await loadCliente(data[0]);
          if (client) {
            await loadVinFull(data[0], client);
          }
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: LanguageProvider({ id: 1480, alt: "O VIN não existe" }),
            life: 3000,
          });
          clearForm();
        }
      }
    } catch (error) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Erro busca Vin",
        life: 3000,
      });
      console.error(error);
    }
  }
  async function loadCliente(vin: any) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/gestion-preparacion/cliente",
        {
          pais: securityFilters.securityValues.country,
          cuenta: securityFilters.securityValues.account,
          vin: vin,
        }
      );

      if (status === 200 && data?.length > 0) {
        setCliente(data[0]);
        return data[0];
      }
    } catch (error) {
      if (vin != null) {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Erro busca Cliente",
          life: 3000,
        });
      }
      console.error(error);
    }
  }
  async function loadVinFull(vin: any, client: any) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/gestion-preparacion/vin-full",
        {
          vin: {
            ...vin,
            cliente: client,
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
          },
        }
      );

      if (status === 200) {
        if (data === null) {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: LanguageProvider({ id: 1480, alt: "O VIN não existe" }),
            life: 3000,
          });
          clearForm();
        } else {
          const stockPlaya = await loadStockPlaya(data, client);
          await loadLineaHojaRuta(data);
          await loadRemito(data, client);
          setVinFull({ ...data, stockPlaya: stockPlaya });
        }
      }
    } catch (error) {
      if (vin != null) {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Erro busca VIN Full",
          life: 3000,
        });
      }
      console.error(error);
    }
  }

  async function loadStockPlaya(vin: any, client: any) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/gestion-preparacion/stock-playa",
        {
          pais: securityFilters.securityValues.country,
          cuenta: securityFilters.securityValues.account,
          cliente: client,
          vin: vin,
        }
      );

      if (status === 200) {
        return data;
      }
    } catch (error) {
      if (vin != null) {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Erro busca Stock Playa",
          life: 3000,
        });
      }
      console.error(error);
    }
  }
  async function loadRemito(vin: any, client: any) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/gestion-preparacion/remito",
        {
          pais: securityFilters.securityValues.country,
          cuenta: securityFilters.securityValues.account,
          cliente: client,
          vin: vin,
        }
      );
      if (status === 200) {
        setRemito(data);
      }
    } catch (error) {
      if (cliente != null) {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Erro busca Remito",
          life: 3000,
        });
      }
      console.error(error);
    }
  }
  async function loadLineaHojaRuta(vin: any) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/gestion-preparacion/modal/linea-hoja-ruta",
        {
          vin: vin,
        }
      );
      if (status === 200) {
        setLineaHojaRuta(data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function validaDesbloqueioVin() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/gestion-preparacion/validar-bloqueo-preparado",
        {
          vin: vinFull,
        }
      );
      if (status === 200) {
        if (data) {
          confirmDialog({
            acceptLabel: LanguageProvider()({ id: "10043", alt: "Sim" }),
            rejectLabel: LanguageProvider()({ id: "10044", alt: "Não" }),
            message: (
              <LanguageProvider
                id={"13275"}
                alt="Deseja processar as alterações realizadas?"
              />
            ),
            header: <LanguageProvider id={"16239"} alt="Atención" />,
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "reject",
            accept: () => desbloquearVin,
          });
        } else {
          toast.current?.show({
            severity: "info",
            summary: "Aviso",
            detail: "O vin não esta bloqueado por preparado",
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function desbloquearVin() {
    try {
      const { status } = await sipcoAxiosService.post(
        "/gestion-preparacion/update-fecha-bloqueo-preparacion",
        {
          vin: vinFull,
        }
      );
      if (status === 200) {
        clearForm();
      }
    } catch (error) {
      console.error(error);
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "erro no bloqueio",
        life: 3000,
      });
    }
  }
  const forzarPreparado = () => {
    confirmDialog({
      acceptLabel: LanguageProvider()({ id: "10043", alt: "Sim" }),
      rejectLabel: LanguageProvider()({ id: "10044", alt: "Não" }),
      message: (
        <LanguageProvider
          id={"13275"}
          alt="¿Desea procesar los cambios realizados?"
        />
      ),
      header: <LanguageProvider id={"16239"} alt="Atención" />,
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => setOpenModal(true),
    });
  };

  function clearForm() {
    setVinFull(null);
    setRemito(null);
    setLineaHojaRuta(null);
    setCliente(null);
    setVinTxt("");
  }

  const displayLineaHojaRuta = lineaHojaRuta != null;

  return (
    <div className="gestion-preparacion">
      <ConfirmDialog />
      <Toast ref={toast} position="top-center" />
      <ModalOperacionVin
        country={securityFilters.securityValues.country}
        vin={vinFull}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"22550"} alt="Filtrar por" />}
        >
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN" />
                </label>
                <InputText
                  value={vinTxt}
                  onChange={(e) => {
                    setVinTxt(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      loadVin();
                    }
                  }}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"54"} alt="Cliente" />
                </label>
                <InputText value={cliente?.descripcion} />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={<LanguageProvider id="18591" alt="Gestión de preparación" />}
      >
        <div className="fieldset-content">
          <PanelCustom
            header={LanguageProvider({
              id: "1165",
              alt: "Ubicación Actual",
            })}
          >
            <div className="form">
              <div className="form-row">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"2892"} alt="Playa" />
                  </label>
                  <InputText
                    value={vinFull?.stockPlaya?.subcuenta?.descripcion}
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"990"} alt="Calle" />
                  </label>
                  <InputText value={vinFull?.stockPlaya?.calle} />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"991"} alt="Columna" />
                  </label>
                  <InputText value={vinFull?.stockPlaya?.columna} />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"992"} alt="Nivel" />
                  </label>
                  <InputText value={vinFull?.stockPlaya?.nivel} />
                </div>
              </div>
            </div>
          </PanelCustom>
          <PanelCustom
            header={LanguageProvider({
              id: "1136",
              alt: "Datos del Vehículo",
            })}
          >
            <div className="form">
              <div className="form-row">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"396"} alt="Marca" />
                  </label>
                  <InputText
                    value={
                      vinFull != null
                        ? vinFull?.marca.id +
                          " - " +
                          vinFull?.marca?.descripcion
                        : ""
                    }
                    readOnly
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"2140"} alt="Color" />
                  </label>
                  <InputText
                    value={
                      vinFull != null
                        ? vinFull?.color.id +
                          " - " +
                          vinFull?.color?.descripcion
                        : ""
                    }
                    readOnly
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"2496"} alt="Origen" />
                  </label>
                  <InputText
                    value={
                      vinFull != null
                        ? vinFull?.origen.id +
                          " - " +
                          vinFull?.origen?.descripcion
                        : ""
                    }
                    readOnly
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"367"} alt="Mercado" />
                  </label>
                  <InputText
                    value={
                      vinFull != null
                        ? vinFull?.mercado.id +
                          " - " +
                          vinFull?.mercado?.descripcion
                        : ""
                    }
                    readOnly
                  />
                </div>
              </div>
              <div className="form-row">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"2139"} alt="Modelo" />
                  </label>
                  <InputText
                    value={
                      vinFull != null
                        ? vinFull?.modelo.id +
                          " - " +
                          vinFull?.modelo?.descripcion
                        : ""
                    }
                    readOnly
                  />
                </div>
              </div>
            </div>
          </PanelCustom>
          <PanelCustom
            header={LanguageProvider({
              id: "915",
              alt: "Datos del Remito",
            })}
          >
            <div className="form">
              <div className="form-row">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"2941"} alt="Concesionario" />
                  </label>
                  <InputText value={remito?.concesionario} readOnly />
                </div>
              </div>
              <div className="form-row">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"492"} alt="Dirección de Entrega" />
                  </label>
                  <InputText value={remito?.direccionEntrega} readOnly />
                </div>
              </div>
            </div>
            {displayLineaHojaRuta && (
              <>
                <Divider />
                <label>
                  <LanguageProvider
                    id="10954"
                    alt="O Vin se encontra em uma Coleta."
                  />
                </label>
              </>
            )}
          </PanelCustom>
        </div>
        <Divider />
        <div>
          <Button
            label={LanguageProvider({
              id: "1146",
              alt: "Desbloquear",
            })}
            onClick={validaDesbloqueioVin}
            text
          />
          <Button
            label={LanguageProvider({
              id: "18592",
              alt: "Forzar preparado",
            })}
            onClick={forzarPreparado}
            text
          />
        </div>
      </Fieldset>
    </div>
  );
}
export default GestionPreparacion;
