import { LanguageProvider } from "@shared/components/language-provider";

/**
 * useFilterColumnsIds
 * 
 * Este hook filtra um array de objetos de colunas, retornando uma nova estrutura
 * que inclui o `id`, `field` e `label`. A label é gerada usando o componente 
 * `LanguageProvider`, que aceita o `id` da coluna e um texto alternativo caso haja erro.
 * 
 * @param {Array} ids - Um array de objetos de colunas, cada objeto deve ter as propriedades `header` e `field`.
 * 
 * @returns {Array} - Um array de objetos formatados, com as propriedades `id`, `field` e `label`.
 * 
 * @example
 * const columns = [
 *   { header: 13, field: "@error" },
 *   { header: 3344, field: "@remito.estadoRemito.descripcion" },
 *   { header: 2941, field: "@remito.concesionario.id" },
 *   // ...
 * ];
 * 
 * const filteredColumns = useFilterColumnsIds(columns);
 * console.log(filteredColumns);
 * // Output:
 * // [
 * //   { id: 13, field: "@error", label: <LanguageProvider id={13} alt="Error Columns Labels" /> },
 * //   { id: 3344, field: "@remito.estadoRemito.descripcion", label: <LanguageProvider id={3344} alt="Error Columns Labels" /> },
 * //   { id: 2941, field: "@remito.concesionario.id", label: <LanguageProvider id={2941} alt="Error Columns Labels" /> },
 * //   ...
 * // ]
 */

const useFilterColumnsIds = (ids) => {
  const result = [];
  for (const element of ids) {
    result.push({
      id: element.header,
      field: element.field,
      label: LanguageProvider({
        id: element.header,
        alt: "Error Columns Labels",
      }),
    });
  }
  return result;
};

export default useFilterColumnsIds;
