import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const TODOS_OBJ = {
  label: "TODOS",
  id: "TODOS",
  descripcion: "TODOS",
};

export function TransaccionesUsuarios() {
  const SCREEN_CODE = "con0033_transacciones_usuarios";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterLabelId = [4791, 466, 2621, 2622, 1];
  const filterNames = [];
  const filterColumnsId = [
    3750, 448, 4791, 14288, 4589, 1491, 396, 2139, 990, 13706, 5271,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    1, 65, 5583, 5044, 54, 1791, 4791, 466, 467, 468, 1182, 748, 749, 3750, 448,
    4791, 14288, 4589, 1491, 396, 2139, 990, 13706, 5271, 16501, 16531, 2050,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    subCuentas: [],
    transacciones: [],
    fechaInicio: new Date("2020-10-20T00:00:00"),
    fechaFinal: new Date(),
    usuario: TODOS_OBJ,
  });

  const [loading, setLoading] = useState(false);

  const [subCuenta, setSubCuenta] = useState([]);
  const [transacciones, setTransacciones] = useState([]);
  const [usuario, setUsuario] = useState([]);

  const [loadingSubCuenta, setLoadingSubCuenta] = useState(true);
  const [loadingTransacciones, setLoadingTransacciones] = useState(true);

  async function loadSubCuenta() {
    setLoadingSubCuenta(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/transacciones-usuarios/combo/subcuenta",
          {
            params: {
              codCuenta: securityFilters.securityValues.account.id,
            },
          }
        );
        if (status === 200) {
          setSubCuenta(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSubCuenta(false);
    }
    return [];
  }

  async function loadTransacciones() {
    setLoadingTransacciones(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/transacciones-usuarios/combo/transaccion",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setTransacciones(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransacciones(false);
    }
    return [];
  }

  async function searchUsuario(event: any) {
    await loadUsuario(event.query);
  }
  async function loadUsuario(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/transacciones-usuarios/combo/usuario",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const usuario = data.map((x: any) => {
            const match = x.label.match(/(.*) \((.*)\)(.*)/);
            if (match) {
              const firstName = match[1].trim(); // Nome
              const lastName = match[2].trim(); // Sobrenome
              const id = match[3].trim(); // ID

              const formattedLabel = `${lastName}, ${firstName} (${id})`;
              return { ...x, label: formattedLabel };
            }
            return x;
          });

          usuario.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const usuariosFiltrados = usuario.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setUsuario(usuariosFiltrados);
            return usuariosFiltrados;
          } else {
            setUsuario(usuario);
            return usuario;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const subCuentas = await loadSubCuenta();
          const transaccioness = await loadTransacciones();

          setFilter({
            ...filter,
            subCuentas: subCuentas,
            transacciones: transaccioness,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,

        subCuentas: filter?.subCuentas?.map((x) => x.id) || [],
        transacciones: filter?.transacciones?.map((x) => x.id) || [],
        rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
        usuario: filter?.usuario?.id || null,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/transacciones-usuarios/report",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[0].label} alt="Subcuenta" />
              </label>
              <MultiSelect
                value={filter.subCuentas}
                options={subCuenta}
                onChange={(e) => {
                  setFilter({ ...filter, subCuentas: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingSubCuenta}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[1].label}
                  alt="Transacciones"
                />
              </label>
              <MultiSelect
                value={filter.transacciones}
                options={transacciones}
                onChange={(e) => {
                  setFilter({ ...filter, transacciones: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingTransacciones}
                showClear
              />
            </div>

            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[2].label} alt="Desde" />
              </label>
              <Calendar
                value={filter.fechaInicio}
                onChange={(e) => {
                  setFilter({ ...filter, fechaInicio: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[3].label} alt="Hasta" />
              </label>
              <Calendar
                value={filter.fechaFinal}
                onChange={(e) => {
                  setFilter({ ...filter, fechaFinal: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[4].label} alt="Usuário" />
              </label>
              <AutoComplete
                value={filter.usuario}
                suggestions={usuario}
                completeMethod={searchUsuario}
                onChange={(e) => setFilter({ ...filter, usuario: e.value })}
                field="label"
                dropdown
                forceSelection
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[26].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta de Transacciones por Usuario"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          loading={loading}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default TransaccionesUsuarios;
