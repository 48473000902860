import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { inactivoColorT } from "../../../hooks/useInactivoColor";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const TODOS_OBJ = {
  label: "TODOS",
  id: 0,
  descripcion: 0,
};

export function ConsultaOperacionesPendientes() {
  const SCREEN_CODE = "con0058_consulta_operaciones_pendientes";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterLabelId = [18308, 18309, 1569, 901, 321, 19178];
  const filterNames = [];
  const filterColumnsId = [
    54, 2892, 1491, 1569, 990, 991, 396, 13514, 2139, 18305, 2140, 10034, 367,
    12251, 3410, 18202, 11507, 17131, 1492, 3344, 10871, 2941, 10868, 10904,
    18306, 18307, 15739, 19690, 5366, 14370, 16245, 54, 2892, 1491, 19179, 1569,
    5788, 16416, 8380, 990, 991, 396, 13514, 2139, 18305, 2140, 10034, 367,
    12251, 3410, 18202, 11507, 17131, 1492, 3344, 10871, 2941, 10868, 10904,
    18306, 18307, 15739, 19690, 5366, 2521, 14370,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    1, 13, 54, 65, 396, 448, 466, 467, 468, 653, 748, 749, 900, 901, 990, 991,
    1182, 1184, 1491, 1569, 1791, 2139, 15739, 2140, 2892, 3750, 4589, 4791,
    5044, 5271, 5583, 10034, 13706, 14288, 14370, 16501, 13514, 18305, 367,
    12251, 3410, 18202, 11507, 17131, 1492, 3344, 10871, 2941, 10868, 10904,
    18306, 18307, 748, 749, 321, 18308, 18309, 10531, 5366, 19178, 16245, 10774,
    16416, 5788, 19179, 8380, 2502, 2521, 19690, 13209,
  ];

  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const showError = (id: string, alt: string) => {
    toast.current.show({
      severity: "error",
      summary: "Erro",
      detail: <LanguageProvider id={id} alt={alt} />,
      life: 3000,
    });
  };

  const [filter, setFilter] = useState({
    origenSolicitud: [],
    grupoOperacion: TODOS_OBJ,
    codOperacion: [],
    vin: null,
    remitoDespacho: false,
    archivoRegistracionWeb: false,
  });

  const [loading, setLoading] = useState(false);

  const [origenSolicitud, setOrigenSolicitud] = useState([]);
  const [grupoOperacion, setGrupoOperacion] = useState([]);
  const [codOperacion, setCodOperacion] = useState([]);
  const [vin, setVin] = useState([]);

  const [loadingOrigenSolicitud, setLoadingOrigenSolicitud] = useState(true);
  const [loadingGrupoOperacion, setLoadingGrupoOperacion] = useState(true);
  const [loadingCodOperacion, setLoadingCodOperacion] = useState(false);
  const [loadingVin, setLoadingVin] = useState(true);

  async function loadOrigenSolicituds() {
    setLoadingOrigenSolicitud(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/origen-solicitud",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setOrigenSolicitud(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigenSolicitud(false);
    }
    return [];
  }
  async function searchGrupoOperacion(event: any) {
    await loadGrupoOperacion(event.query);
  }
  async function loadGrupoOperacion(query = "") {
    setLoadingGrupoOperacion(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/grupo-operaciones",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const grupoOperacion = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          grupoOperacion.unshift({
            label: "TODOS",
            id: 0,
            descripcion: 0,
          });

          if (query) {
            const grupoOperacionFiltrados = grupoOperacion.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setGrupoOperacion(grupoOperacionFiltrados);
            return grupoOperacionFiltrados;
          } else {
            setGrupoOperacion(grupoOperacion);
            return grupoOperacion;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrupoOperacion(false);
    }
    return [];
  }
  async function loadCodOperacions(grupoOperacion: any) {
    setLoadingCodOperacion(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/operaciones-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              grupoOperacion: grupoOperacion,
            },
          }
        );
        if (status === 200) {
          const codOperacion = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setCodOperacion(codOperacion);
          return codOperacion;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCodOperacion(false);
    }
    return [];
  }
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/report/vin-op-pendiente",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCliente: securityFilters?.securityValues?.client?.id || null,
            clienteDescripcion:
              securityFilters?.securityValues?.client?.descripcion || "",
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            vin: vin,
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            setLoadingVin(false);
            return showError(
              "900",
              "No existe VIN para el Cliente, Cuenta y Subcuenta seleccionado"
            );
          }

          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const origenSolicituds = await loadOrigenSolicituds();
          const codOperacions = await loadCodOperacions(0);

          setFilter({
            ...filter,
            origenSolicitud: origenSolicituds,
            codOperacion: codOperacions,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  async function handleGrupoOperacionSelection(e: MultiSelectChangeEvent) {
    try {
      const selectedGrupoOperacion = e.value;

      if (selectedGrupoOperacion) {
        const codOperacion = await loadCodOperacions(selectedGrupoOperacion.id);

        setFilter({
          ...filter,
          grupoOperacion: selectedGrupoOperacion,
          codOperacion,
        });
      } else {
        setFilter({
          ...filter,
          grupoOperacion: null,
          codOperacion: [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,

        origenSolicitud: filter?.origenSolicitud?.map((x) => x.id) || [],
        grupoOperacion: filter?.grupoOperacion?.id || 0,
        codOperacion: filter?.codOperacion?.map((x) => x.id) || [],
        vin: filter?.vin?.id || "",
        remitoDespacho: filter.remitoDespacho,
        archivoRegistracionWeb: filter.archivoRegistracionWeb,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/report/operaciones-pendientes",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          allClientsOptions: true,
          allSubaccountsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[0].id}
                  alt="Origen Solicitud"
                />
              </label>
              <MultiSelect
                value={filter.origenSolicitud}
                options={origenSolicitud}
                onChange={(e) => {
                  setFilter({ ...filter, origenSolicitud: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingOrigenSolicitud}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[1].id}
                  alt="Grupo Operación)"
                />
              </label>
              <AutoComplete
                value={filter.grupoOperacion}
                suggestions={grupoOperacion}
                completeMethod={searchGrupoOperacion}
                onChange={handleGrupoOperacionSelection}
                field="label"
                dropdown
                // forceSelection //Nao se aplica
                itemTemplate={inactivoColorT}
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[2].id} alt="Operación" />
              </label>
              <MultiSelect
                value={filter.codOperacion}
                options={codOperacion}
                onChange={(e) => {
                  setFilter({ ...filter, codOperacion: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingCodOperacion}
                showClear
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[3].id}
                  alt="Ingrese el VIN)"
                />
              </label>
              <AutoComplete
                value={filter.vin}
                suggestions={vin}
                completeMethod={searchVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value })}
                field="label"
                dropdown
                forceSelection
                maxLength={17}
              />
            </div>

            <div className="RadioButtonFilter">
              <Checkbox
                checked={filter.remitoDespacho}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    remitoDespacho: !filter.remitoDespacho,
                  });
                  setReporte([]);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={filterNames[4].label} />
              </label>
              <Checkbox
                checked={filter.archivoRegistracionWeb}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    archivoRegistracionWeb: !filter.archivoRegistracionWeb,
                  });
                  setReporte([]);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={filterNames[5].label} />
              </label>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[69].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Listado Operaciones Pendientes"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loading}
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          <Column field="23" header={columnsNames[23].label} sortable filter />
          <Column field="24" header={columnsNames[24].label} sortable filter />
          <Column field="25" header={columnsNames[25].label} sortable filter />
          <Column field="26" header={columnsNames[26].label} sortable filter />
          <Column field="27" header={columnsNames[27].label} sortable filter />
          <Column field="28" header={columnsNames[28].label} sortable filter />
          <Column field="29" header={columnsNames[29].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ConsultaOperacionesPendientes;
