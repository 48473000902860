import "./ImpresionEtiquetas.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import useFilterColumnsIds from "../../../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const columnsIds = [
  { header: 54, field: "@cliente" },
  { header: 1791, field: "@cuenta" },
  { header: 4791, field: "@stockPlaya.subcuenta" },
  { header: 901, field: "@id" },
  { header: 396, field: "@marca" },
  { header: 2139, field: "@modelo" },
  { header: 2140, field: "@color" },
  { header: 1269, field: "@stockPlaya.calle" },
  { header: 1269, field: "@stockPlaya.columna" },
];

export function ImpresionEtiquetas() {
  usePageViews();
  const SCREEN_CODE = "fun0201_impresion_etiquetas";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const dt = useRef(null);

  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [dataTableValue, setDataTableValue] = useState([]);
  const [vinForm, setVinForm] = useState("");

  const columnsNames = useFilterColumnsIds(columnsIds);

  async function loadVin() {
    if (
      typeof vinForm !== "string" ||
      vinForm.length < 8 ||
      vinForm.length > 17
    ) {
      toast.current?.show({
        severity: "warn",
        detail: (
          <LanguageProvider
            id={"653"}
            alt="Deve Acessar um mínimo de 8 e um máximo de 17 dígitos"
          />
        ),
        life: 3000,
      });
      return;
    }
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        subaccount: {
          id: securityFilters.securityValues.subaccount.id,
        },
        vin: { id: vinForm },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/impresion-etiquetas/find-impresion-etiquetas",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          return data[0];
        } else {
          toast.current?.show({
            severity: "warn",
            detail: <LanguageProvider id={"654"} alt="Não existe o VIN" />,
            life: 3000,
          });
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="impresion-etiquetas">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN" />
                </label>
                <InputText
                  value={vinForm}
                  onChange={(e) => setVinForm(e.target.value)}
                  maxLength={17}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      loadVin();
                    }
                  }}
                />
              </div>
              <div className={"sipco-option-button"}>
                <Button
                  label={LanguageProvider({
                    id: "9665",
                    alt: "Buscar",
                  })}
                  onClick={loadVin}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={
          <LanguageProvider
            id={"12840"}
            alt="Impresión Etiquetas Transferencias"
          />
        }
      >
        <div className="fieldset-content">
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={dataTableValue}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsNames.map((colum, index) => (
              <Column
                key={index}
                header={colum.label}
                field={colum.field}
                sortable
              />
            ))}
          </DataTable>
        </div>
      </Fieldset>
    </div>
  );
}
export default ImpresionEtiquetas;
