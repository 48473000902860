import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function LlegadaBuqueCamionForm(props) {
  const { SCREEN_CODE } = props;
  const toast = useRef<Toast>(null);

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const [loading, setLoading] = useState(false);

  const columnsIds1 = [1491, 10830, 396, 2139, 2140, 1736, 13489, 13490];

  const columnsNames1 = filterColumnsIds(columnsIds1);

  function filterColumnsIds(ids) {
    const result = [];
    for (const element of ids) {
      result.push({
        id: element,
        label: LanguageProvider({
          id: element,
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  const loadReportData = () => {
    console.log("crick");
  };

  return (
    <div>
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-options-line" style={{ maxWidth: "70%" }}>
              <div className="sipco-option-flex-end">
                <AutoComplete dropdown />
              </div>
              <div
                className="sipco-option-flex-end"
                style={{ maxWidth: "15%" }}
              >
                <AutoComplete dropdown />
              </div>
              <div
                className="sipco-option-flex-end"
                style={{ maxWidth: "15%" }}
              >
                <AutoComplete dropdown />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"493"} alt="Tipo Transporte" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={
          <LanguageProvider id={"1052"} alt="Chegada de Navio ou Caminhão" />
        }
      >
        <div className="fieldset-content">
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"662"} alt="Acesse o VIN" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"924"} alt="Hoja de Ruta" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
          </div>
          <DataTable
            size={"small"}
            header={
              <div className="header-data-table">
                <div className="chips">
                  <div className={"sipco-chip"}>
                    <label>
                      <LanguageProvider
                        id={"864"}
                        alt="Registros Selecionados"
                      />
                    </label>
                    <Chip label="0" />
                  </div>
                  <div className={"sipco-chip"}>
                    <label>
                      <LanguageProvider id={"TODO"} alt="Total de Registros" />
                    </label>
                    <Chip label="0" />
                  </div>
                </div>

                <div
                  className="action"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <ExportButtons
                    dt={dt}
                    data={data}
                    columns={columnsIds1}
                    screenName={"RecepcionPlaya"}
                  />
                </div>
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={listRemitos}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsNames1.map((colum, index) => (
              <Column
                key={index}
                field={colum.id}
                header={colum.label}
                sortable
                filter
              />
            ))}
          </DataTable>
        </div>
        <Divider />
        <Accordion>
          <AccordionTab
            header={
              <LanguageProvider
                id={"884"}
                alt="entrada de Dados para a Recepção"
              />
            }
          >
            <div className="filter-options-column">
              <div className="sipco-options-column">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"889"} alt="Data de Recepção" />
                  </label>
                  <Calendar showButtonBar showIcon showTime />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"2496"} alt="Origem" />
                  </label>
                  <AutoComplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1119"} alt="Terminais" />
                  </label>
                  <AutoComplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1118"} alt="Despachantes" />
                  </label>
                  <AutoComplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"16844"} alt="Fornecedor danhos" />
                  </label>
                  <AutoComplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1373"} alt="Pontos de Retiro" />
                  </label>
                  <AutoComplete dropdown />
                </div>
              </div>
              <div className="sipco-options-column">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"21565"} alt="Transportador" />
                  </label>
                  <AutoComplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"4285"} alt="Trator" />
                  </label>
                  <AutoComplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"4284"} alt="Batea / Transportín" />
                  </label>
                  <AutoComplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"4655"} alt="Motorista" />
                  </label>
                  <AutoComplete dropdown />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider
                      id={"8811"}
                      alt="Quantidade de Unidades"
                    />
                  </label>
                  <InputText readOnly />
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
        <div className="fieldset-footer">
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "34",
                alt: "Processar ou guardar Mudanças",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "187",
                alt: "Imprimir ou gerar arquivo Excel",
              })}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default LlegadaBuqueCamionForm;
