import "./AdministracionNotasFiscales.scss";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { LanguageProvider } from "@shared/components/language-provider";
import PanelCustom from "../../../components/panel-custom/PanelCustom";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import WinCheckDialog from "./components/WinCheckDialog";
import { format } from "date-fns";
import useFilterColumnsIds from "../../../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const filterColumnsId = [
  { header: 13, field: "error" },
  { header: 3344, field: "remito.estadoRemito.descripcion" },
  { header: 2941, field: "remito.concesionario.id" },
  { header: 932, field: "remito.id" },
  { header: 1491, field: "remito.vin.id" },
  { header: 1719, field: "remito.vin.fechaSalidaFabrica.date" },
  { header: 1492, field: "remito.fechaEmision.date" },
  { header: 396, field: "remito.vin.marca.descripcion" },
  { header: 2139, field: "remito.vin.modelo.descripcion" },
  { header: 2140, field: "remito.vin.color.descripcion" },
  { header: 849, field: "remito.letra" },
  { header: 850, field: "remito.idPedidoCliente" },
  { header: 4216, field: "remito.concesionario.descripcion" },
  { header: 3069, field: "remito.direccionEntrega.direccion" },
  { header: 3591, field: "remito.razonSocialImpresion" },
  { header: 8299, field: "remito.domicilioImpresion" },
  { header: 159, field: "remito.provinciaImpresion" },
  { header: 3186, field: "remito.localidadImpresion" },
  { header: 13338, field: "remito.vin.modeloLargo" },
  { header: 16264, field: "bloquea" },
  { header: 10502, field: "remito.direccionEntrega.corredor.descripcion" },
  { header: 367, field: "remito.vin.mercado.descripcion" },
  { header: 16758, field: "remito.vin.descripcionIngresoTaller" },
  { header: 11316, field: "remito.fechaLlegada.date" },
  { header: 10769, field: "remito.naturalezaFiscal.id" },
];

const TODOS_OBJ = {
  id: "TODOS",
  descripcionFull: "TODOS",
  descripcion: "TODOS",
  label: "TODOS",
};

export function AdministracionNotasFiscales() {
  const SCREEN_CODE = "fun0043_administracion_notas_fiscales";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const [valueDataTable, setValueDataTable] = useState(null);
  const [loadingDataTable, setLoadingDataTable] = useState(false);
  const toast = useRef<Toast>(null);

  const [ingreseElVin, setIngreseElVin] = useState([]);
  const [filter, setFilter] = useState({
    mercado: "",
    vin: "",
    desde: "",
    ate: "",
    puntoDeVenta: "",
    numero: "",
    bloqueoCalidad: null,
    concesionario: null,
    modifyConcesionario: null,
    marca: null,
    direccionEntrega: null,
    modifyDireccionEntrega: null,
    modelo: null,
    estado: null,
    errores: null,
    proveedor: null,
    observacionesDireccionEntrega: "",
    observaciones: "",
  });

  const [selectedRows, setSelectedRows] = useState([]);

  const columnsNames = useFilterColumnsIds(filterColumnsId);

  async function loadReport() {
    setLoadingDataTable(true);
    const check = checkSecurityFilters();
    if (!check) {
      setLoadingDataTable(false);
      return;
    }

    try {
      const { account, client, country, subaccount } =
        securityFilters.securityValues;
      const params: any = {
        pais: {
          id: country.id.toString(),
        },
        cliente: {
          id: client.id.toString(),
        },
        cuenta: {
          id: account.id.toString(),
        },
        subcuenta: {
          id: subaccount.id.toString(),
        },
      };

      if (filter.puntoDeVenta) {
        params.puntoDeVenta = filter.puntoDeVenta;
      }
      if (filter.numero) {
        params.idRemito = filter.numero;
      }
      if (filter.bloqueoCalidad && filter.bloqueoCalidad.id !== TODOS_OBJ.id) {
        params.bloqueoCalidad = filter.bloqueoCalidad;
      }
      if (filter.concesionario && filter.concesionario.id !== TODOS_OBJ.id) {
        params.concesionario = filter.concesionario;
      }
      if (filter.marca && filter.marca.id !== TODOS_OBJ.id) {
        params.marca = filter.marca;
      }
      if (
        filter.direccionEntrega &&
        filter.direccionEntrega.id !== TODOS_OBJ.id
      ) {
        params.direccionEntrega = filter.direccionEntrega;
      }
      if (filter.modelo && filter.modelo.id !== TODOS_OBJ.id) {
        params.modelo = filter.modelo;
      }
      if (filter.estado && filter.estado.id !== TODOS_OBJ.id) {
        params.estadoRemito = filter.estado;
      }
      if (filter.errores && filter.errores.id !== TODOS_OBJ.id) {
        params.error = filter.errores;
      }
      console.log(params);

      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/find-remito",
        params
      );
      if (status === 200) {
        setValueDataTable(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTable(false);
      setSelectedRows([]);
    }
  }
  function checkSecurityFilters() {
    if (
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id
    ) {
      return true;
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "País, Clientes e Cuentas Obrigatorio",
        life: 3000,
      });
      return false;
    }
  }

  const [mercado, setMercado] = useState([]);
  const [loadingMercado, setLoadingMercado] = useState(false);
  async function loadMercado() {
    setLoadingMercado(true);
    try {
      if (securityFilters?.securityValues?.country == null) return;
      const params = {
        pais: securityFilters.securityValues.country,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/mercado",
        params
      );
      if (status === 200) {
        setMercado(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercado(false);
    }
  }
  const [concesionario, setConcesionario] = useState([]);
  const [modifyConcesionario, setModigyConcesionario] = useState([]);
  const [loadingConcesionarios, setLoadingConcesionarios] = useState(false);
  const [loadingModifyConcesionarios, setLoadingModifyConcesionarios] =
    useState(false);

  async function loadConcessionaria() {
    setLoadingConcesionarios(true);
    setLoadingModifyConcesionarios(true);
    try {
      if (
        securityFilters?.securityValues?.country == null ||
        securityFilters?.securityValues?.client == null
      )
        return;
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/concesionario",
        params
      );
      if (status === 200) {
        setModigyConcesionario(data);
        data.unshift(TODOS_OBJ);
        setConcesionario(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConcesionarios(false);
      setLoadingModifyConcesionarios(false);
    }
  }
  const [estados, setEstados] = useState([]);
  const [loadingEstados, setLoadingEstados] = useState(false);
  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (securityFilters?.securityValues?.country == null) return;
      const params = {
        pais: securityFilters.securityValues.country,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/estado-remito-sin-errores",
        params
      );
      if (status === 200) {
        data.unshift(TODOS_OBJ);
        setEstados(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
  }
  const [errores, setErrores] = useState([]);
  const [loadingErrores, setLoadingErrores] = useState(false);
  async function loadErrores() {
    setLoadingErrores(true);
    try {
      if (securityFilters?.securityValues?.country == null) return;
      const params = {
        pais: securityFilters.securityValues.country,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/estado-remito-con-errores",
        params
      );
      if (status === 200) {
        data.unshift(TODOS_OBJ);
        setErrores(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingErrores(false);
    }
  }
  const [bloqueoCalidad, setBloqueoCalidad] = useState([]);
  const [loadingBloqueosCalidad, setLoadingBloqueosCalidad] = useState(false);
  async function loadBloqueoCalidad() {
    setLoadingBloqueosCalidad(true);
    try {
      if (securityFilters?.securityValues?.country == null) return;
      const params = {
        pais: securityFilters.securityValues.country,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/bloqueo-calidad",
        params
      );
      if (status === 200) {
        data.unshift(TODOS_OBJ);
        setBloqueoCalidad(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBloqueosCalidad(false);
    }
  }
  const [marcas, setMarcas] = useState([]);
  const [loadingMarcas, setLoadingMarcas] = useState(false);
  async function loadMarca() {
    setLoadingMarcas(true);
    try {
      if (
        securityFilters?.securityValues?.country == null ||
        securityFilters?.securityValues?.client == null
      )
        return;
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/marca",
        params
      );
      if (status === 200) {
        data.unshift(TODOS_OBJ);
        setMarcas(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
  }
  const [direccionesEntrega, setDireccionesEntrega] = useState([]);
  const [loadingDireccionesEntrega, setLoadingDireccionesEntrega] =
    useState(false);
  const [loadingModifyDireccionesEntrega, setLoadingModifyDireccionesEntrega] =
    useState(false);

  async function loadDireccion(concesionario: any) {
    setLoadingDireccionesEntrega(true);
    try {
      if (
        securityFilters?.securityValues?.country == null ||
        securityFilters?.securityValues?.client == null ||
        !concesionario
      )
        return;
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        concesionario,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/direccion-entrega",
        params
      );
      if (status === 200) {
        setDireccionesEntrega(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDireccionesEntrega(false);
    }
  }
  const [modelos, setModelos] = useState([]);
  const [loadingModelos, setLoadingModelos] = useState(false);
  async function loadModelo(marca: any) {
    setLoadingModelos(true);
    try {
      if (
        securityFilters?.securityValues?.country == null ||
        securityFilters?.securityValues?.client == null ||
        !marca
      )
        return;
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        marca,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/modelo",
        params
      );
      if (status === 200) {
        setModelos(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
  }
  const [proveedor, setProveedor] = useState([]);
  const [loadingProveedores, setLoadingProveedores] = useState(false);
  const [modifyEnabled, setModifyEnabled] = useState(false);

  async function loadProveedor() {
    setLoadingProveedores(true);
    try {
      if (securityFilters?.securityValues?.country == null) return;
      const params = {
        pais: securityFilters.securityValues.country,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/find-proveedor",
        params
      );
      if (status === 200) {
        setProveedor(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedores(false);
    }
  }

  async function searchIngreseElVin(event: any) {
    await loadIngreseElVin(event.query);
  }
  async function loadIngreseElVin(query = "") {
    try {
      const check = checkSecurityFilters();
      if (typeof query !== "string" || query.length < 8 || query.length > 17) {
        setIngreseElVin([]);
        return;
      }

      if (!check) {
        return;
      }

      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: { id: securityFilters.securityValues.client.id },
        cuenta: { id: securityFilters.securityValues.account.id },
        vin: { id: query },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/vin",
        params
      );
      if (status === 200) {
        if (query) {
          setIngreseElVin(
            data.filter((x: any) =>
              x.id.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setIngreseElVin(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };
  async function checkVin(params) {
    try {
      setLoadingDataTable(true);
      const paramsRequest = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        vin: params?.remito?.vin,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/validate-vin",
        paramsRequest
      );
      if (status === 200) {
        return data;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTable(false);
    }
  }
  async function selectItems(params) {
    if (params.length > 0) {
      const lastSelectedItem = params[params.length - 1];
      const isValidVin = await checkVin(lastSelectedItem);

      if (isValidVin) {
        setSelectedRows(params);
      } else {
        toast.current?.show({
          severity: "warn",
          detail: (
            <LanguageProvider
              id={"1337"}
              alt="No puede seleccionar el VIN debido a que no está presente en el Stock de Playa"
            />
          ),
          life: 3000,
        });
        console.log("VIN inválido, a seleção não será alterada.");
      }
      return;
    } else {
      setSelectedRows([]);
    }
  }
  async function selectAll() {
    try {
      setLoadingDataTable(true);
      const paramsRequest = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        itens: valueDataTable,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/validate-select-all",
        paramsRequest
      );
      if (status === 200) {
        if (data) {
          setSelectedRows(valueDataTable);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTable(false);
    }
  }
  function unSelectAll() {
    setSelectedRows(null);
  }

  async function handleConcecionarioChange(value: any) {
    try {
      const direccionesEntrega = await loadDireccion(value);
      setFilter({
        ...filter,
        concesionario: value,
        direccionEntrega: direccionesEntrega[0],
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function handleModifyConcecionarioChange(value: any) {
    try {
      setLoadingModifyConcesionarios(true);
      const direccionesEntrega = await loadDireccion(value);
      setFilter({
        ...filter,
        modifyConcesionario: value,
        modifyDireccionEntrega: direccionesEntrega[0],
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModifyConcesionarios(false);
    }
  }
  async function handleMarcaChange(value: any) {
    try {
      const modelos = await loadModelo(value);
      setFilter({
        ...filter,
        marca: value,
        modelo: modelos[0],
      });
    } catch (error) {
      console.error(error);
    }
  }

  function cambiarBarra() {
    if (selectedRows.length === 0) {
      toast.current?.show({
        severity: "warn",
        summary: "Error",
        detail: (
          <LanguageProvider
            id="753"
            alt="Deve selecionar ao menos um registro do quadro"
          />
        ),
        life: 3000,
      });
      return;
    }
    if (selectedRows.length > 1) {
      toast.current?.show({
        severity: "warn",
        summary: "Error",
        detail: (
          <LanguageProvider
            id="17105"
            alt="Há mas de um registro selecionado"
          />
        ),
        life: 3000,
      });
      return;
    }
    setModifyEnabled(true);
  }

  function validOnUpdate() {
    if (
      filter.modifyConcesionario !== null &&
      filter.modifyDireccionEntrega === null
    ) {
      toast.current?.show({
        severity: "warn",
        summary: "Error",
        detail: <LanguageProvider id="39" alt="Há dados em branco." />,
        life: 3000,
      });
      return false;
    } else {
      return true;
    }
  }

  async function updateConcesionarioBocaDeEntrega() {
    try {
      setLoadingDataTable(true);
      const payload = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        concesionario: filter.modifyConcesionario,
        direccionEntrega: filter.modifyDireccionEntrega,
        itens: selectedRows,
        observaciones: filter.observaciones,
        observacionesDireccionEntrega: filter.observacionesDireccionEntrega,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/update-remito",
        payload
      );
      if (status === 200) {
        loadReport();
        setModifyEnabled(false);
        toast.current?.show({
          severity: "success",
          detail: (
            <LanguageProvider
              id={"36"}
              alt="Sua transação se realizou com sucesso."
            />
          ),
          life: 3000,
        });
      } else {
        toast.current?.show({
          severity: "error",
          detail: "ERROR",
          // detail: (
          //   <LanguageProvider
          //     id={"36"}
          //     alt="Sua transação se realizou com sucesso."
          //   />
          // ),
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function modificar() {
    if (validOnUpdate()) {
      confirmDialog({
        header: <LanguageProvider id={"16239"} alt="Atención" />,
        message: (
          <LanguageProvider
            id={"13275"}
            alt="¿Desea procesar los cambios realizados?"
          />
        ),
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "reject",
        accept: () => updateConcesionarioBocaDeEntrega(),
        reject: null,
      });
    }
  }

  const [winCheckDialogProperties, setWinCheckDialogProperties] = useState(
    new WinCheckDialogPropsDefaultValue()
  );

  function liberarConfirmodal() {
    const validationStatus = valid("liberar");
    if (validationStatus) {
      confirmDialog({
        header: <LanguageProvider id={"16239"} alt="Atención" />,
        message: (
          <LanguageProvider
            id={"13275"}
            alt="¿Desea procesar los cambios realizados?"
          />
        ),
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "reject",
        accept: () => liberar(selectedRows),
        reject: null,
      });
    }
  }

  async function liberar(itens: any) {
    // srvRemito.liberar(filterForm.pais, filterForm.cliente, filterForm.cuenta, transportista, grid.selectedItems, observaciones);
    try {
      setLoadingDataTable(true);
      const payload = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        cuenta: securityFilters.securityValues.account,
        proveedor: filter.proveedor,
        itens: itens,
        observaciones: filter.observaciones,
      };
      const { status } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/liberar-remito",
        payload
      );
      if (status === 200) {
        loadReport();
        toast.current?.show({
          severity: "success",
          detail: (
            <LanguageProvider
              id={"36"}
              alt="Sua transação se realizou com sucesso."
            />
          ),
          life: 3000,
        });
      } else {
        toast.current?.show({
          severity: "error",
          detail: "ERROR",
          // detail: (
          //   <LanguageProvider
          //     id={"36"}
          //     alt="Sua transação se realizou com sucesso."
          //   />
          // ),
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  function retenerConfirmodal() {
    const validationStatus = valid("retener");
    if (validationStatus) {
      confirmDialog({
        header: <LanguageProvider id={"16239"} alt="Atención" />,
        message: (
          <LanguageProvider
            id={"13275"}
            alt="¿Desea procesar los cambios realizados?"
          />
        ),
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "reject",
        accept: () => retener(selectedRows),
        reject: null,
      });
    }
  }

  async function retener(itens: any) {
    // 		srvRemito.retener(filterForm.pais, filterForm.cliente, filterForm.cuenta, selected, observaciones);
    try {
      setLoadingDataTable(true);
      const payload = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        cuenta: securityFilters.securityValues.account,
        itens: itens,
        observaciones: filter.observaciones,
      };
      const { status } = await sipcoAxiosService.post(
        "/administracion-notas-fiscales/retener-remito",
        payload
      );
      if (status === 200) {
        loadReport();
        toast.current?.show({
          severity: "success",
          detail: (
            <LanguageProvider
              id={"36"}
              alt="Sua transação se realizou com sucesso."
            />
          ),
          life: 3000,
        });
      } else {
        toast.current?.show({
          severity: "error",
          detail: "ERROR",
          // detail: (
          //   <LanguageProvider
          //     id={"36"}
          //     alt="Sua transação se realizou com sucesso."
          //   />
          // ),
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  function valid(validationType: string) {
    if (filter.proveedor == null) {
      toast.current?.show({
        severity: "warn",
        detail: (
          <LanguageProvider
            id={"871"}
            alt="Deve selecionar um Provedor Transportista"
          />
        ),
        life: 3000,
      });
      return;
    }

    let listVinesBloqueados = [];

    selectedRows.forEach((remitoDto) => {
      // if (
      //   remitoDto.remito?.vin?.fechaBloqueoCalidad?.date !== null &&
      //   remitoDto.remito?.vin?.fechaBloqueoCalidad?.date !== undefined
      // ) {
      if (remitoDto.remito.vin.id !== null) {
        //linha para abrir modal mesmo sem erro
        // Cria um objeto equivalente ao BooleanStringItem.
        listVinesBloqueados.push(remitoDto);
      }
    });

    if (listVinesBloqueados.length > 0) {
      setWinCheckDialogProperties({
        listVinesBloqueados: listVinesBloqueados,
        visible: true,
        validationType: validationType,
      });
    } else {
      return true;
    }
  }
  function returnWinCheck(updatedList) {
    try {
      setSelectedRows(updatedList);
      confirmDialog({
        header: <LanguageProvider id={"16239"} alt="Atención" />,
        message: (
          <LanguageProvider
            id={"13275"}
            alt="¿Desea procesar los cambios realizados?"
          />
        ),
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "reject",
        accept: () =>
          winCheckDialogProperties.validationType === "liberar"
            ? liberar(updatedList)
            : retener(updatedList),
        reject: null,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setWinCheckDialogProperties(new WinCheckDialogPropsDefaultValue());
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        let filterModel = { ...filter };
        const [
          mercados,
          conecesionarios,
          estados,
          errores,
          bloqueosCalidad,
          marcas,
          provedores,
        ] = await Promise.all([
          loadMercado(),
          loadConcessionaria(),
          loadEstados(),
          loadErrores(),
          loadBloqueoCalidad(),
          loadMarca(),
          loadProveedor(),
        ]);
        filterModel = {
          ...filterModel,
          marca: marcas[0],
          bloqueoCalidad: bloqueosCalidad[0],
          mercado: mercados[0],
          concesionario: conecesionarios[0],
          estado: estados[0],
          errores: errores[0],
          proveedor: provedores[0],
        };
        if (concesionario.length > 0) {
          const [direccionesEntrega] = await Promise.all([
            loadDireccion(conecesionarios[0]),
          ]);
          filterModel = {
            ...filterModel,
            direccionEntrega: direccionesEntrega[0],
          };
        }
        if (marcas.length > 0) {
          const [modelos] = await Promise.all([loadModelo(marcas[0])]);
          filterModel = {
            ...filterModel,
            modelo: modelos[0],
          };
        }
        setFilter(filterModel);
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues]);

  return (
    <div className="administracion-notas-fiscales">
      <Toast ref={toast} position="bottom-left" />
      <ConfirmDialog />
      <WinCheckDialog
        visible={winCheckDialogProperties.visible}
        list={winCheckDialogProperties.listVinesBloqueados}
        onClose={() => {
          setWinCheckDialogProperties(new WinCheckDialogPropsDefaultValue());
        }}
        onConfirm={returnWinCheck}
      />
      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"16656"} alt="Tipo de venta" />}
        >
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"367"} alt="Mercado" />
                </label>
                <Dropdown
                  value={filter?.mercado}
                  options={mercado}
                  onChange={(e) => setFilter({ ...filter, mercado: e.value })}
                  optionLabel="descripcion"
                  loading={loadingMercado}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                </label>
                <AutoComplete
                  value={filter?.vin}
                  suggestions={ingreseElVin}
                  completeMethod={searchIngreseElVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="id"
                  dropdown
                  maxLength={17}
                  forceSelection
                />
              </div>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab
          header={
            <LanguageProvider id={"10703"} alt="Protocolo de Notas Fiscales" />
          }
        >
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2621"} alt="Desde" />
                </label>
                <InputText
                  value={filter?.desde}
                  onChange={(e) =>
                    setFilter({ ...filter, desde: e.target.value })
                  }
                  keyfilter="int"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2622"} alt="Hasta" />
                </label>
                <InputText
                  value={filter?.ate}
                  onChange={(e) =>
                    setFilter({ ...filter, ate: e.target.value })
                  }
                  keyfilter="int"
                />
              </div>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab
          header={<LanguageProvider id={"7795"} alt="Filtrar Por:" />}
        >
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13358"} alt="Punto Venta" />
                </label>
                <InputText
                  value={filter?.puntoDeVenta}
                  onChange={(e) =>
                    setFilter({ ...filter, puntoDeVenta: e.target.value })
                  }
                  keyfilter="int"
                  maxLength={4}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"851"} alt="Número" />
                </label>
                <InputText
                  value={filter?.numero}
                  onChange={(e) =>
                    setFilter({ ...filter, numero: e.target.value })
                  }
                  keyfilter="int"
                  maxLength={8}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1595"} alt="Bloqueo Calidad" />
                </label>
                <Dropdown
                  value={filter?.bloqueoCalidad}
                  options={bloqueoCalidad}
                  onChange={(e) =>
                    setFilter({ ...filter, bloqueoCalidad: e.value })
                  }
                  optionLabel="descripcionFull"
                  loading={loadingBloqueosCalidad}
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2941"} alt="Concessionária" />
                </label>
                <Dropdown
                  value={filter?.concesionario}
                  options={concesionario}
                  onChange={(e) => handleConcecionarioChange(e.value)}
                  optionLabel="descripcion"
                  loading={loadingConcesionarios}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"396"} alt="Marca" />
                </label>
                <Dropdown
                  value={filter?.marca}
                  options={marcas}
                  onChange={(e) => handleMarcaChange(e.value)}
                  optionLabel="descripcionFull"
                  loading={loadingMarcas}
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"24014"} alt="Dirección" />
                </label>
                <Dropdown
                  value={filter?.direccionEntrega}
                  options={direccionesEntrega}
                  onChange={(e) =>
                    setFilter({ ...filter, direccionEntrega: e.value })
                  }
                  optionLabel="direccion"
                  loading={loadingDireccionesEntrega}
                  filter
                  disabled={
                    filter?.concesionario?.id === "TODOS" ||
                    filter?.concesionario === null
                  }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2139"} alt="Modelo" />
                </label>
                <Dropdown
                  value={filter?.modelo}
                  options={modelos}
                  onChange={(e) => setFilter({ ...filter, modelo: e.value })}
                  optionLabel="descripcion"
                  loading={loadingModelos}
                  filter
                  disabled={
                    filter?.marca?.id === "TODOS" || filter?.marca === null
                  }
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1151"} alt="Estados" />
                </label>
                <Dropdown
                  value={filter?.estado}
                  options={estados}
                  onChange={(e) => setFilter({ ...filter, estado: e.value })}
                  optionLabel="descripcion"
                  loading={loadingEstados}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11315"} alt="Errores" />
                </label>
                <Dropdown
                  value={filter?.errores}
                  options={errores}
                  onChange={(e) => setFilter({ ...filter, errores: e.value })}
                  optionLabel="descripcion"
                  loading={loadingErrores}
                  filter
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <PanelCustom
        header={
          <LanguageProvider
            id={"10709"}
            alt="Administración de Notas Fiscales"
          />
        }
      >
        <div className="fieldset-content">
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            value={valueDataTable}
            paginator
            scrollable
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loadingDataTable}
            selectionMode="multiple"
            selection={selectedRows}
            onSelectionChange={(e) => selectItems(e.value)}
          >
            {columnsNames.map(
              (column, index) => {
                if (
                  column.field === "remito.vin.fechaSalidaFabrica.date" ||
                  column.field === "remito.fechaEmision.date" ||
                  column.field === "remito.fechaLlegada.date"
                ) {
                  return (
                    <Column
                      key={index}
                      body={(e) => dateColumnTemplate(e, column.field)}
                      field={column.field}
                      header={column.label}
                      sortable
                      filter
                    />
                  );
                } else {
                  return (
                    <Column
                      key={index}
                      field={column.field}
                      header={column.label}
                      sortable
                      filter
                    />
                  );
                }
              }
              // <Column
              // key={index}
              // field={colum.field}
              // header={colum.label}
              // sortable
              // />
            )}
          </DataTable>
          <div className="tags">
            <Tag
              value={<LanguageProvider id={"4009"} alt="VIN en taller" />}
              style={{ background: "#ff0000" }}
            />
            <Tag
              value={<LanguageProvider id={"9428"} alt="VIN Indisponible" />}
              style={{ background: "#0000db" }}
            />
            <Tag
              value={
                <LanguageProvider
                  id={"9854"}
                  alt="Vehiculos Pendiente Taller"
                />
              }
              style={{ background: "#808000" }}
            />
            <Tag
              value={
                <LanguageProvider
                  id={"13534"}
                  alt="Operaciones bloqueantes y no bloqueantes"
                />
              }
              style={{ background: "#800040" }}
            />
          </div>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"754"} alt="Transportista" />
                </label>
                <Dropdown
                  value={filter?.proveedor}
                  options={proveedor}
                  onChange={(e) => setFilter({ ...filter, proveedor: e.value })}
                  optionLabel="descripcionFull"
                  loading={loadingProveedores}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"864"} alt="Registros Seleccionados" />
                </label>
                <InputText disabled value={selectedRows ? "1" : "0"} />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3625"} alt="Observaciones" />
                </label>
                <InputTextarea
                  value={filter.observaciones}
                  onChange={(e) =>
                    setFilter({ ...filter, observaciones: e.target.value })
                  }
                  autoResize
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={"2587"}
                    alt="Parametro para fecha Nulo"
                  />
                </label>
                <InputText disabled />
              </div>
            </div>
            {modifyEnabled && (
              <div className="form-row">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"2941"} alt="Concessionária" />
                  </label>
                  <Dropdown
                    value={filter?.modifyConcesionario}
                    options={modifyConcesionario}
                    onChange={(e) => handleModifyConcecionarioChange(e.value)}
                    optionLabel="descripcion"
                    loading={loadingModifyConcesionarios}
                    filter
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"3069"} alt="Dirección" />
                  </label>
                  <Dropdown
                    value={filter?.modifyDireccionEntrega}
                    options={direccionesEntrega}
                    onChange={(e) =>
                      setFilter({ ...filter, modifyDireccionEntrega: e.value })
                    }
                    optionLabel="direccion"
                    loading={loadingModifyDireccionesEntrega}
                    filter
                    disabled={filter?.modifyConcesionario === null}
                  />
                </div>

                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"3625"} alt="Observaciones" />
                  </label>
                  <InputTextarea
                    value={filter.observacionesDireccionEntrega}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        observacionesDireccionEntrega: e.target.value,
                      })
                    }
                    autoResize
                  />
                </div>
              </div>
            )}
          </div>

          <div className="footer-buttons">
            {modifyEnabled ? (
              <div>
                <Button
                  label={LanguageProvider({ id: "9491", alt: "Aceitar" })}
                  text
                  onClick={modificar}
                />
                <Button
                  label={LanguageProvider({
                    id: "3155",
                    alt: "Recusar",
                  })}
                  onClick={(e) => {
                    setModifyEnabled(false);
                  }}
                  text
                />
              </div>
            ) : (
              <div>
                <Button
                  label={LanguageProvider({
                    id: "187",
                    alt: "Imprimir o generar archivo Excel",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "11318",
                    alt: "Imprimir solo Notas Fiscales con Errores",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "748",
                    alt: "Seleccionar todo",
                  })}
                  onClick={selectAll}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "749",
                    alt: "Deseleccionar todo",
                  })}
                  onClick={unSelectAll}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "1522",
                    alt: "Retener el/los remitos",
                  })}
                  onClick={retenerConfirmodal}
                  text
                  disabled={selectedRows.length === 0}
                />
                <Button
                  label={LanguageProvider({
                    id: "1523",
                    alt: "Liberar el/los remitos",
                  })}
                  onClick={liberarConfirmodal}
                  text
                  disabled={selectedRows.length === 0}
                />

                <Button
                  label={LanguageProvider({ id: "27", alt: "Modificar" })}
                  text
                  onClick={cambiarBarra}
                />
                <Button
                  label={LanguageProvider({
                    id: "26",
                    alt: "Baja",
                  })}
                  text
                />
              </div>
            )}
          </div>
        </div>
      </PanelCustom>
    </div>
  );
}
export default AdministracionNotasFiscales;

class WinCheckDialogPropsDefaultValue {
  listVinesBloqueados: any[];
  visible: boolean;
  validationType: string;

  constructor() {
    this.listVinesBloqueados = [];
    this.visible = false;
    this.validationType = "liberar";
  }
}
