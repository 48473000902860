import "./CargaManualVin.scss";
import "primeicons/primeicons.css";

import React, { useEffect, useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import { useInactivoColor } from "../../../hooks/useInactivoColor";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function CargaManualVin() {
  const SCREEN_CODE = "fun0068_carga_manual_vin";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();

  const [procesarDialog, setProcesarDialog] = useState(false);
  const [confirmProcessDialog, setConfirmProcessDialog] = useState(false);
  const [loadingProcesar, setLoadingProcesar] = useState(false);
  const [fieldsEnabled, setFieldsEnabled] = useState(false);

  const [filter, setFilter] = useState({
    vin: "",
  });

  const [vinAnterior, setVinAnterior] = useState({});

  const [model, setModel] = useState({
    id: null,
    marca: null,
    modelo: null,
    modeloLargo: "",
    idModeloLargo: null,
    color: null,
    denominacionComercial: null,
    numeroMotor: "",
    patente: "",
    mercado: null,
    destino: null,
    destinoFrancia: null,
    fechaSalidaFabrica: { date: null },
    codigoPlataforma: "",
  });
  const [loadingModel, setLoadingModel] = useState(false);
  const inactivoColor = useInactivoColor();

  const [marcas, setMarcas] = useState([]);
  const [loadingMarcas, setLoadingMarcas] = useState(false);
  async function loadMarcas() {
    setLoadingMarcas(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        model?.id !== null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/marca",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.value,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.value,
            },
          }
        );
        if (status === 200) {
          setMarcas(data);
          const selectedMarca = data.find((m) => m.id === model?.marca?.id);
          setModel({ ...model, marca: selectedMarca });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
  }

  const [modelos, setModelos] = useState([]);
  const [loadingModelos, setLoadingModelos] = useState(false);
  async function loadModelos() {
    setLoadingModelos(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        model?.id != null &&
        model?.marca != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/modelo",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.value,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.value,
            },
            marca: {
              id: model?.marca.id,
            },
          }
        );
        if (status === 200) {
          setModelos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
  }

  const [cores, setCores] = useState([]);
  const [loadingCores, setLoadingCores] = useState(false);
  async function loadCores() {
    setLoadingCores(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        model?.id != null &&
        model?.marca != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/color",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.value,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.value,
            },
            marca: {
              id: model?.marca.id,
            },
          }
        );
        if (status === 200) {
          setCores(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCores(false);
    }
  }

  const [denoComerciais, setDenoComerciais] = useState([]);
  const [loadingDenoComerciais, setLoadingDenoComerciais] = useState(false);
  async function loadDenoComerciais() {
    setLoadingDenoComerciais(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        model?.id != null &&
        model?.marca != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/denominacion-comercial",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.value,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.value,
            },
            marca: {
              id: model?.marca.id,
            },
          }
        );
        if (status === 200) {
          setDenoComerciais(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDenoComerciais(false);
    }
  }

  const [mercados, setMercados] = useState([]);
  const [loadingMercados, setLoadingMercados] = useState(false);
  async function loadMercados() {
    setLoadingMercados(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        model?.id !== null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/mercado",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.value,
            },
          }
        );
        if (status === 200) {
          setMercados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
  }

  const [destinos, setDestinos] = useState([]);
  const [loadingDestinos, setLoadingDestinos] = useState(false);
  async function loadDestinos() {
    setLoadingDestinos(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        model?.id !== null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/destino",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.value,
            },
          }
        );
        if (status === 200) {
          setDestinos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinos(false);
    }
  }

  const [destinosFrancias, setDestinosFrancias] = useState([]);
  const [loadingDestinosFrancias, setLoadingDestinosFrancias] = useState(false);
  async function loadDestinosFrancias() {
    setLoadingDestinosFrancias(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        model?.id != null &&
        model?.marca != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/marca-destino",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.value,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.value,
            },
            marca: {
              id: model?.marca.id,
            },
            destino: {
              id: model?.destino?.id,
            },
          }
        );
        if (status === 200) {
          setDestinosFrancias(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinosFrancias(false);
    }
  }

  const showError = (id: string, alt: string) => {
    toast.current.show({
      severity: "error",
      summary: "Erro",
      detail: <LanguageProvider id={id} alt={alt} />,
      life: 3000,
    });
  };

  const handleClearFields = () => {
    setModel({
      id: null,
      marca: null,
      modelo: null,
      modeloLargo: "",
      idModeloLargo: null,
      color: null,
      denominacionComercial: null,
      numeroMotor: "",
      patente: "",
      mercado: null,
      destino: null,
      destinoFrancia: null,
      fechaSalidaFabrica: { date: null },
      codigoPlataforma: "",
    });
  };

  const handleProcesar = (isConfirmed) => {
    setProcesarDialog(false);
    if (isConfirmed) {
      aProcesar();
    }
  };

  const handleConfirmationClose = () => {
    handleClearFields();
    setConfirmProcessDialog(false);
  };

  const handleKeyDown = (event) => {
    setLoadingModel(true);
    try {
      if (event.key === "Enter") {
        if (filter.vin.length >= 8 && filter.vin.length <= 17) {
          handleClearFields();
          loadVinActivo();
        } else {
          return showError(
            "653",
            "Debe ingresar un minimo de 8 y un maximo de 17 dígitos"
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModel(false);
    }
  };

  async function loadVinActivo() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/modificacion-datos-vin/vin-activo",
        {
          pais: { id: securityFilters.securityValues.country.value },
          cliente: { id: securityFilters.securityValues.client.value },
          cuenta: { id: securityFilters.securityValues.account.value },
          vin: filter.vin,
        }
      );

      if (status === 200) {
        let vin = data[0];
        setVinAnterior(vin);

        if (vin.id.length === 0) {
          setFieldsEnabled(false);
          return showError("1480", "El VIN no existe");
        }
        const vinSelected = vin.id;
        setFilter({ ...filter, vin: vin.id });
        setModel({ ...model, id: vinSelected });

        if (
          (Array.isArray(marcas || destinos) && marcas.length) ||
          destinos.length > 0
        ) {
          const selectedMarca = marcas.find((x) => x.id === vin.marca.id);
          const selectedDestino = destinos.find((x) => x.id === vin.destino.id);

          if (selectedMarca || selectedDestino) {
            vin.marca = selectedMarca;
            vin.modelo = modelos.find((x) => x.id === vin.modelo.id);
            vin.color = cores.find((x) => x.id === vin.color.id);
            vin.denominacionComercial = denoComerciais.find(
              (x) => x.id === vin.denominacionComercial
            );
            vin.destinoFrancia = destinosFrancias.find(
              (x) => x.id === vin.destinoFrancia
            );
          }
          vin.mercado = mercados.find((x) => x.id === vin.mercado.id);
          vin.destino = selectedDestino;
        }

        setModel(vin);
        setFieldsEnabled(true);
      }
    } catch (error) {
      console.error(error);
      setFieldsEnabled(false);
      showError("500", "Ocorreu um erro ao carregar o VIN ativo");
    }
  }

  async function aProcesar() {
    setLoadingProcesar(true);
    try {
      const filters = {
        vinAnterior: vinAnterior,
        vinNovo: model,
      };

      const { status } = await sipcoAxiosService.post(
        "/modificacion-datos-vin/update-vin",
        filters
      );
      if (status === 200) {
        console.log("Update Vin");
        setConfirmProcessDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcesar(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client &&
        securityFilters.securityValues.account &&
        model.id !== null
      ) {
        await loadMarcas();
        await loadDestinos();
        await loadMercados();
      }
    }

    initialize();
  }, [securityFilters.securityValues, model.id]);

  useEffect(() => {
    async function handleMarcaChange() {
      if (model?.marca !== null) {
        await Promise.all([loadModelos(), loadCores(), loadDenoComerciais()]);
      }
    }
    handleMarcaChange();
  }, [model.marca]);

  useEffect(() => {
    async function handleDestinoChange() {
      if (model?.destino != null) {
        await Promise.all([loadDestinosFrancias()]);
      }
    }
    handleDestinoChange();
  }, [model.destino]);

  return (
    <div className="cargamanual-vin">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>

      <Fieldset
        legend={<LanguageProvider id={"1465"} alt="Carga Manual del VIN" />}
      >
        <div style={{ marginBottom: "10px", gap: "10px", display: "flex" }}>
          <div className={"sipco-option"}>
            <label style={{ marginRight: "10px" }}>
              <LanguageProvider id={"901"} alt="Ingrese el VIN" />
            </label>
            <InputText
              value={filter.vin}
              onChange={(e) => {
                setFilter({ ...filter, vin: e.target.value });
              }}
              onKeyDown={handleKeyDown}
            />
          </div>

          <div className={"sipco-option"} style={{ alignItems: "center" }}>
            <label style={{ marginRight: "10px" }}>
              <LanguageProvider
                id={"10196"}
                alt="Copiar el Vin a Otro Cliente"
              />
            </label>
            <Checkbox checked={true} />
          </div>
        </div>

        <Fieldset>
          <div className="form">
            <div className="column">
              {/* Primeira Parte */}
              <div className="form-row ">
                <div className="sipco-option">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"367"} alt="Mercado" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"2496"} alt="Origen" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"396"} alt="Marca" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"2139"} alt="Modelo" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"2140"} alt="Color" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4102"} alt="Destino" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16421"} alt="Destino de origen" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16422"} alt="Género" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"18202"}
                        alt="Denominación Comercial"
                      />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"22209"} alt="Cód. Plataforma" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="column">
              {/* Segunda Parte */}
              <div className="form-row">
                <div className="sipco-option">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16423"} alt="Silueta" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16424"} alt="Acabados" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16425"} alt="Motorización" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16426"} alt="Cajas" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16427"} alt="Base concepción" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"16307"}
                        alt="Cliente concesionario"
                      />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16429"} alt="Año de fabricación" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"13502"} alt="Modificación" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"12103"} alt="Fecha Fabricación" />
                    </label>
                    <div className="line">
                      <Calendar value={new Date()} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="column">
              {/* Terceira Parte */}
              <div className="form-row">
                <div className="sipco-option">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16430"} alt="Tapizado" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16431"} alt="Opcional" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4724"} alt="Patente" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"13335"} alt="Motor" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"13338"} alt="Modelo Largo" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"10784"}
                        alt="Orden de Fabricación"
                      />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16429"} alt="Año de fabricación" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"13489"} alt="Actualizó Usuario" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"13490"}
                        alt="Actualizó con Fecha"
                      />
                    </label>
                    <div className="line">
                      <Calendar value={new Date()} />
                    </div>
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"3505"} alt="NCM" />
                    </label>
                    <div className="line">
                      <AutoComplete dropdown />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Panel
            footerTemplate={(props) => {
              return (
                <div className={props.className}>
                  {/* butons */}
                  <div>
                    <Button
                      // className="buttons"
                      label={LanguageProvider({ id: "16283", alt: "Procesar" })}
                      text
                    />
                    <Button
                      label={LanguageProvider({ id: "Borrar", alt: "Borrar" })}
                      text
                    />
                    <Button
                      label={LanguageProvider({
                        id: "2366",
                        alt: "Importar Archivo",
                      })}
                      text
                    />
                  </div>
                </div>
              );
            }}
          ></Panel>
        </Fieldset>
      </Fieldset>
    </div>
  );
}
export default CargaManualVin;
