import { Accordion, AccordionTab } from "primereact/accordion";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { format } from "date-fns";
import useFilterColumnsIds from "../../../../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const columnsIds1 = [
  { header: 1491, field: "vin.id" },
  { header: 396, field: "marca.descripcion" },
  { header: 10746, field: "modelo.descripcion" },
  { header: 2140, field: "color.descripcion" },
  { header: 16542, field: "puntoDeRetiro" },
  { header: 754, field: "transportista" },
  { header: 1058, field: "despachanteRetorno" },
  { header: 5270, field: "terminal" },
  { header: 1256, field: "categoria.descripcion" },
  { header: 13335, field: "motor" },
  { header: 10979, field: "fechaNacionalizacion.date" },
];

const TODOS_OBJ = {
  id: "TODOS",
  descripcionFull: "TODOS",
  descripcion: "TODOS",
  label: "TODOS",
};

export function LiberacionAduanaBrForm(props) {
  const { SCREEN_CODE } = props;
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const dt = useRef(null);
  const [filterDtoCache, setFilterDtoCache] = useState({});
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [loadingColuna, setLoadingColuna] = useState(false);
  const [loadingNivel, setLoadingNivel] = useState(false);
  const [loadingRua, setLoadingRua] = useState(false);
  const [dataTableValues, setDataTableValues] = useState([]);
  const columnsNames = useFilterColumnsIds(columnsIds1);
  const [calle, setCalle] = useState("");
  const [calleSuggestions, setCalleSuggestions] = useState([]);
  const [columna, setColumna] = useState("");
  const [columnaSuggestions, setColumnaSuggestions] = useState([]);
  const [nivel, setNivel] = useState("");
  const [nivelSuggestions, setNivelSuggestions] = useState([]);
  const [vin, setVin] = useState("");
  const [vinSuggestions, setVinSuggestions] = useState([]);
  const [marcas, setMarcas] = useState(TODOS_OBJ);
  const [marcasSuggestions, setMarcasSuggestions] = useState([]);
  const [proveedor, setProveedor] = useState(TODOS_OBJ);
  const [proveedorSuggestions, setProveedorSuggestions] = useState([]);
  const [modelos, setModelos] = useState("");
  const [modelosSuggestions, setModelosSuggestions] = useState([]);
  const [loadingModelos, setLoadingModelos] = useState(false);
  const [transportePlaya, setTransportePlaya] = useState(TODOS_OBJ);
  const [transportePlayaSuggestions, setTransportePlayaSuggestions] = useState(
    []
  );
  const [importadoFecha, setImportadoFecha] = useState("TODOS");
  const [importadoFechaSuggestions, setImportadoFechaSuggestions] = useState(
    []
  );
  const [loadingImportadoFecha, setLoadingImportadoFecha] = useState(false);
  const [categoria, setCategoria] = useState("");
  const [categoriaSuggestions, setCategoriaSuggestions] = useState([]);
  const [fechaLiberacion, setFechaLiberacion] = useState(new Date());
  const [informaAlTransportista, setInformaAlTransportista] = useState(false);
  const [transportista, setTransportista] = useState("");
  const [tipoProveedor, setTipoProveedor] = useState<any>("");
  const [tipoProveedorSuggestions, setTipoProveedorSuggestions] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  async function loadRua(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/liberacion-aduana/calle",
        params
      );
      if (status === 200) {
        if (query) {
          setCalleSuggestions(
            data.filter((x: any) =>
              x.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setCalleSuggestions(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchRua(event: any) {
    await loadRua(event.query);
  }

  async function loadColuna(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
        calle: calle,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/liberacion-aduana/columna",
        params
      );
      if (status === 200) {
        if (query) {
          setColumnaSuggestions(
            data.filter((x: any) =>
              x.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setColumnaSuggestions(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchColuna(event: any) {
    await loadColuna(event.query);
  }

  async function loadNivel(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
        calle: calle,
        columna: columna,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/liberacion-aduana/nivel",
        params
      );
      if (status === 200) {
        if (query) {
          setNivelSuggestions(
            data.filter((x: any) =>
              x.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setNivelSuggestions(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchNivel(event: any) {
    await loadNivel(event.query);
  }

  async function searchIngreseElVin(event: any) {
    await loadIngreseElVin(event.query);
  }
  async function loadIngreseElVin(query = "") {
    try {
      const check = validateSecurityFilters();
      if (typeof query !== "string" || query.length < 8 || query.length > 17) {
        setVinSuggestions([]);
        return;
      }

      if (!check) {
        return;
      }

      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: { id: securityFilters.securityValues.client.id },
        cuenta: { id: securityFilters.securityValues.account.id },
        vin: { id: query },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/liberacion-aduana/vin",
        params
      );
      if (status === 200) {
        if (query) {
          setVinSuggestions(
            data.filter((x: any) =>
              x.id.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setVinSuggestions(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadMarcas(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/liberacion-aduana/marca",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          const dataLabel = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          dataLabel.unshift(TODOS_OBJ);
          if (query) {
            setMarcasSuggestions(
              dataLabel.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setMarcasSuggestions(dataLabel);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchMarcas(event: any) {
    await loadMarcas(event.query);
  }

  async function loadModelos(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
        },
        marca: marcas,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/liberacion-aduana/modelo",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          const dataLabel = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          dataLabel.unshift(TODOS_OBJ);
          if (query) {
            setModelosSuggestions(
              dataLabel.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setModelosSuggestions(dataLabel);
            return dataLabel;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
  }
  async function searchModelos(event: any) {
    await loadModelos(event.query);
  }

  async function loadProveedor(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
        //@author Lucas Silvestre - Duett Softwares - 30/10/2024
        //tipoProveedor é definido no projeto antigo recebendo o mesmo valor que o pais no arquivo LiberacionAduanaBrFilterForm
        //import com.gefco.model.TipoDeProveedor;
        //package com.gefco.model
        // {
        // 	import mx.collections.ArrayCollection;
        //     [Bindable]
        //     [RemoteClass(alias="com.gefco.model.TipoDeProveedor")]
        // 	public class TipoDeProveedor extends IdLabelEntity {
        // 		public var pais:Pais;
        // 	}
        // }
        tipoProveedor: {
          id: securityFilters.securityValues.country.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/liberacion-aduana/proveedor",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          data.unshift(TODOS_OBJ);
          if (query) {
            setProveedorSuggestions(
              data.filter((x: any) =>
                x.descripcion.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setProveedorSuggestions(data);
            return data;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
  }
  async function searchProveedor(event: any) {
    await loadProveedor(event.query);
  }

  async function loadTransportePlaya(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/liberacion-aduana/transporte-playa",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          const dataLabel = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          dataLabel.unshift(TODOS_OBJ);
          if (query) {
            setTransportePlayaSuggestions(
              dataLabel.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setTransportePlayaSuggestions(dataLabel);
            return dataLabel;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
  }
  async function searchTransportePlaya(event: any) {
    await loadTransportePlaya(event.query);
  }

  async function loadImportadoFecha(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        transporte:
          transportePlaya.id === "TODOS" ? { id: "0" } : transportePlaya,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/liberacion-aduana/importado-fecha",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          data.unshift("TODOS");
          if (query) {
            setImportadoFechaSuggestions(
              data.filter((x: any) =>
                x.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setImportadoFechaSuggestions(data);
            return data;
          }
        }
        setImportadoFechaSuggestions(["N/A"]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingImportadoFecha(false);
    }
  }

  async function searchImportadoFecha(event: any) {
    await loadImportadoFecha(event.query);
  }

  async function loadCategoria(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
        },
        marca: marcas,
        modelo: modelos,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/liberacion-aduana/categoria",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          if (query) {
            setCategoriaSuggestions(
              data.filter((x: any) =>
                x.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setCategoriaSuggestions(data);
            return data;
          }
        }
        setCategoriaSuggestions(["N/A"]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function searchCategoria(event: any) {
    await loadCategoria(event.query);
  }

  async function loadSubAccounts(query) {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/subcuentas-options",
        {
          params: {
            codCuenta: securityFilters.securityValues.account.id,
            descripcion: "",
          },
        }
      );
      if (status === 200) {
        const subAccounts = data.map((item: any) => ({
          value: item.codSubcuenta,
          description: `${item.descripcion} (${item.codSubcuenta})`,
        }));

        if (query) {
          setTipoProveedorSuggestions(
            subAccounts.filter((x: any) =>
              x.description.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setTipoProveedorSuggestions(subAccounts);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchSubaccount(event: any) {
    await loadSubAccounts(event.query);
  }

  function validateSecurityFilters(): boolean {
    return !!(
      securityFilters.securityValues.country?.id &&
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id &&
      securityFilters.securityValues.subaccount?.id
    );
  }

  async function loadReport() {
    try {
      setLoading(true);
      const params: any = {
        filterDto: {
          pais: {
            id: securityFilters.securityValues.country.id,
          },
          cuenta: {
            id: securityFilters.securityValues.account.id,
          },
          cliente: {
            id: securityFilters.securityValues.client.id,
          },
          subcuenta: {
            id: securityFilters.securityValues.subaccount.id,
          },
          ubicacion: {
            calle: calle,
            columna: columna,
            nivel: nivel,
          },
        },
      };

      if (vin !== "" && vin !== null && vin !== undefined) {
        params.filterDto.vin = vin;
      }
      if (
        proveedor.id !== "TODOS" &&
        proveedor.id !== null &&
        proveedor.id !== undefined
      ) {
        params.filterDto.despachante = proveedor;
      }
      if (
        marcas.id !== "TODOS" &&
        marcas.id !== null &&
        marcas.id !== undefined
      ) {
        params.filterDto.marca = marcas;
      }
      if (
        modelos !== "" &&
        modelos !== null &&
        modelos !== undefined &&
        modelos !== "TODOS"
      ) {
        params.filterDto.modelo = { id: modelos };
      }
      if (
        categoria !== "" &&
        categoria !== null &&
        categoria !== undefined &&
        categoria !== "TODOS"
      ) {
        params.filterDto.categoria = { id: categoria };
      }
      if (
        transportePlaya.id !== "TODOS" &&
        transportePlaya.id !== null &&
        transportePlaya.id !== undefined
      ) {
        params.filterDto.transportista = transportePlaya;
      }
      if (
        importadoFecha !== "TODOS" &&
        importadoFecha !== null &&
        importadoFecha !== undefined
      ) {
        params.filterDto.fecha = { id: importadoFecha };
      }

      const { status, data } = await sipcoAxiosService.post(
        "/liberacion-aduana/find-importado",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          setDataTableValues(data);
          setFilterDtoCache(params.filterDto);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function save() {
    if (!validar()) {
      return;
    }
    try {
      setLoading(true);
      const params: any = {
        filterDto: filterDtoCache,
        fechaLiberacion: { date: fechaLiberacion },
        listaDto: selectedRows,
      };
      if (
        transportista !== "" &&
        transportista !== null &&
        transportista !== undefined
      ) {
        params.transportista = transportista;
      }
      if (
        tipoProveedor !== "" &&
        tipoProveedor !== null &&
        tipoProveedor !== undefined
      ) {
        params.tipoProveedor = tipoProveedor;
      }
      const { status, data } = await sipcoAxiosService.post(
        "/liberacion-aduana/liberar-aduana",
        params
      );
      if (status === 200) {
        if (data === "liberado con exito") {
          toast.current?.show({
            severity: "success",
            detail: (
              <LanguageProvider
                id={"36"}
                alt="Sua transação se realizou com sucesso."
              />
            ),
            life: 3000,
          });
          loadReport();
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function validar() {
    if (informaAlTransportista) {
      if (
        securityFilters.securityValues.subaccount.id === tipoProveedor.value
      ) {
        toast.current?.show({
          severity: "warn",
          detail: (
            <LanguageProvider
              id={"752"}
              alt="Os Pátios Origem e Destino não podem ser as mesmas"
            />
          ),
          life: 3000,
        });
        return false;
      }
      if (selectedRows.length === 0) {
        toast.current?.show({
          severity: "warn",
          detail: (
            <LanguageProvider
              id={"753"}
              alt="Deve selecionar ao menos um registro do quadro"
            />
          ),
          life: 3000,
        });
        return false;
      }
      return true;
    }
  }

  const processar = () => {
    confirmDialog({
      message: (
        <LanguageProvider
          id={"13275"}
          alt="Deseja processar as alterações realizadas?"
        />
      ),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => save(),
      reject: null,
    });
  };

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };

  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  useEffect(() => {
    async function loadInitialRua() {
      try {
        setLoadingRua(true);
        const rua = await loadRua();
        setCalle(rua[0]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingRua(false);
      }
    }
    if (validateSecurityFilters()) {
      loadInitialRua();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues]);

  useEffect(() => {
    async function loadInitialColuna() {
      try {
        setLoadingColuna(true);
        const coluna = await loadColuna();
        setColumna(coluna[0]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingColuna(false);
      }
    }
    if (validateSecurityFilters()) {
      loadInitialColuna();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calle]);

  useEffect(() => {
    async function loadInitialNivel() {
      try {
        setLoadingNivel(true);
        const nivel = await loadNivel();
        setNivel(nivel[0]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingNivel(false);
      }
    }
    if (validateSecurityFilters()) {
      loadInitialNivel();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columna]);

  useEffect(() => {
    if (validateSecurityFilters()) {
      if (
        marcas?.id !== null &&
        marcas?.id !== undefined &&
        marcas?.id !== TODOS_OBJ.id
      ) {
        // loadInitialModelos();
        setModelos(TODOS_OBJ.label);
      } else {
        setModelos(null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marcas]);

  useEffect(() => {
    setTipoProveedor("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues.account]);

  return (
    <div className="liberacion-aduana">
      <Toast ref={toast} position="bottom-left" />
      <ConfirmDialog />
      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"921"} alt="Ubicaciones" />}
        >
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"990"} alt="Calle" />
                </label>
                {loadingRua ? (
                  <Skeleton width="100%" height="2.5rem" />
                ) : (
                  <AutoComplete
                    forceSelection
                    value={calle}
                    suggestions={calleSuggestions}
                    completeMethod={searchRua}
                    onChange={(e) => setCalle(e.value)}
                    dropdown
                  />
                )}
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"991"} alt="Columna" />
                </label>
                {loadingColuna ? (
                  <Skeleton width="100%" height="2.5rem" />
                ) : (
                  <AutoComplete
                    forceSelection
                    value={columna}
                    suggestions={columnaSuggestions}
                    completeMethod={searchColuna}
                    onChange={(e) => setColumna(e.value)}
                    disabled={
                      typeof columna === "undefined" || columna.length <= 1
                    }
                    dropdown
                  />
                )}
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"992"} alt="Nivel" />
                </label>
                {loadingNivel ? (
                  <Skeleton width="100%" height="2.5rem" />
                ) : (
                  <AutoComplete
                    forceSelection
                    value={nivel}
                    suggestions={nivelSuggestions}
                    completeMethod={searchNivel}
                    onChange={(e) => setNivel(e.value)}
                    disabled={typeof nivel === "undefined" || nivel.length <= 1}
                    dropdown
                  />
                )}
              </div>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                </label>
                <AutoComplete
                  forceSelection
                  value={vin}
                  suggestions={vinSuggestions}
                  completeMethod={searchIngreseElVin}
                  onChange={(e) => setVin(e.value)}
                  field="id"
                  dropdown
                  maxLength={17}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1058"} alt="Despachante" />
                </label>
                <AutoComplete
                  forceSelection
                  value={proveedor}
                  suggestions={proveedorSuggestions}
                  completeMethod={searchProveedor}
                  onChange={(e) => setProveedor(e.value)}
                  field="descripcion"
                  dropdown
                />
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"489"} alt="Marcas" />
                </label>
                <AutoComplete
                  forceSelection
                  value={marcas}
                  suggestions={marcasSuggestions}
                  completeMethod={searchMarcas}
                  onChange={(e) => setMarcas(e.value)}
                  field="label"
                  dropdown
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2139"} alt="Modelo" />
                </label>
                {loadingModelos ? (
                  <Skeleton width="100%" height="2.5rem" />
                ) : (
                  <AutoComplete
                    forceSelection
                    value={modelos}
                    suggestions={modelosSuggestions}
                    completeMethod={searchModelos}
                    onChange={(e) => setModelos(e.value)}
                    field="label"
                    disabled={modelos === "" || modelos === null}
                    dropdown
                  />
                )}
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"376"} alt="Transporte" />
                </label>
                <AutoComplete
                  forceSelection
                  value={transportePlaya}
                  suggestions={transportePlayaSuggestions}
                  completeMethod={searchTransportePlaya}
                  onChange={(e) => setTransportePlaya(e.value)}
                  field="label"
                  dropdown
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2131"} alt="Fecha" />
                </label>
                {loadingImportadoFecha ? (
                  <Skeleton width="100%" height="2.5rem" />
                ) : (
                  <AutoComplete
                    forceSelection
                    value={importadoFecha}
                    suggestions={importadoFechaSuggestions}
                    completeMethod={searchImportadoFecha}
                    onChange={(e) => setImportadoFecha(e.value)}
                    // field="label"
                    // disabled={
                    //   transportePlaya?.id === "TODOS" ||
                    //   transportePlaya === null
                    // }
                    dropdown
                  />
                )}
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Categoria" />
                </label>
                <AutoComplete
                  forceSelection
                  value={categoria}
                  suggestions={categoriaSuggestions}
                  completeMethod={searchCategoria}
                  onChange={(e) => setCategoria(e.value)}
                  field="descripcionFull"
                  dropdown
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset legend={<LanguageProvider id={"16226"} alt="Listado" />}>
        <div className="fieldset-content">
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={dataTableValues}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
            removableSort
            selectionMode="multiple"
            selection={selectedRows}
            onSelectionChange={(e) => setSelectedRows(e.value)}
          >
            {columnsNames.map((column, index) => {
              if (column.field === "fechaNacionalizacion.date") {
                return (
                  <Column
                    key={index}
                    body={(e) => dateColumnTemplate(e, column.field)}
                    field={column.field}
                    header={column.label}
                    sortable
                  />
                );
              } else {
                return (
                  <Column
                    key={index}
                    field={column.field}
                    header={column.label}
                    sortable
                  />
                );
              }
            })}
          </DataTable>
          <div className="chips">
            <div className={"sipco-chip"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Total de Registros" />
              </label>
              <Chip label={dataTableValues.length.toString()} />
            </div>
            <div className={"sipco-chip"}>
              <label>
                <LanguageProvider id={"864"} alt="Registros Seleccionados" />
              </label>
              <Chip label={selectedRows.length.toString()} />
            </div>
          </div>
        </div>
        <Divider />
        <div className="fieldset-footer">
          <Accordion>
            <AccordionTab
              header={
                <LanguageProvider
                  id={"883"}
                  alt="entrada de Dados para a Transferência"
                />
              }
            >
              <div className="filter-options">
                <div className="sipco-options-line">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4036"} alt="Fecha de Liberación" />
                    </label>
                    <Calendar
                      value={fechaLiberacion}
                      onChange={(e) => setFechaLiberacion(e.value)}
                      showButtonBar
                      showIcon
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className={"RadioButtonFilter"}>
                    <label>
                      <LanguageProvider
                        id={"1059"}
                        alt="Informa al Transportista?"
                      />
                    </label>
                    <Checkbox
                      checked={informaAlTransportista}
                      onChange={(e) => setInformaAlTransportista(e.checked)}
                    />
                  </div>
                  {informaAlTransportista && (
                    <>
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider
                            id={"1063"}
                            alt="Selecione Transportista"
                          />
                        </label>
                        <AutoComplete
                          forceSelection
                          value={transportista}
                          suggestions={proveedorSuggestions}
                          completeMethod={searchProveedor}
                          onChange={(e) => setTransportista(e.value)}
                          field="descripcion"
                          dropdown
                        />
                      </div>
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"740"} alt="Pátio Destino" />
                        </label>
                        <AutoComplete
                          forceSelection
                          value={tipoProveedor}
                          suggestions={tipoProveedorSuggestions}
                          completeMethod={searchSubaccount}
                          onChange={(e) => setTipoProveedor(e.value)}
                          field="description"
                          dropdown
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
        {dataTableValues.length > 0 && (
          <div className="footer-buttons">
            <Button
              label={LanguageProvider({
                id: "748",
                alt: "Seleccionar todo",
              })}
              onClick={() => {
                setSelectedRows(dataTableValues);
              }}
              text
            />
            <Button
              label={LanguageProvider({
                id: "749",
                alt: "Deseleccionar todo",
              })}
              onClick={() => {
                setSelectedRows([]);
              }}
              text
            />
            <Button
              label={LanguageProvider({
                id: "14594",
                alt: "Guardar as mudanças",
              })}
              onClick={processar}
              text
            />
          </div>
        )}
      </Fieldset>
    </div>
  );
}
export default LiberacionAduanaBrForm;
