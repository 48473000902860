import React from "react";
import { Skeleton } from "primereact/skeleton";
import { LanguageProvider } from "@shared/components/language-provider";


{
  /* <Skeleton width="100%" height="3rem"/> */
}
const SkeletonBodyForm: React.FC = () => {
  return (
    <div>
      <div className="sipco-options-line">
        <div className={"sipco-option"}>
          <label>
            <LanguageProvider id={"396"} alt="Marca" />
          </label>
          <Skeleton width="100%" height="3rem" />
        </div>
        <div className={"sipco-option"}>
          <label>
            <LanguageProvider id={"990"} alt="Calle" />
          </label>
          <Skeleton width="100%" height="3rem" />
        </div>
      </div>
      <div className="sipco-options-line">
        <div className={"sipco-option"}>
          <label>
            <LanguageProvider id={"2139"} alt="Modelo" />
          </label>
          <Skeleton width="100%" height="3rem" />
        </div>
        <div className={"sipco-option"}>
          <label>
            <LanguageProvider id={"991"} alt="Columna" />
          </label>
          <Skeleton width="100%" height="3rem" />
        </div>
      </div>
      <div className="sipco-options-line">
        <div className={"sipco-option"}>
          <label>
            <LanguageProvider id={"2140"} alt="Color" />
          </label>
          <Skeleton width="100%" height="3rem" />
        </div>
        <div className={"sipco-option"}>
          <label>
            <LanguageProvider id={"992"} alt="Nivel" />
          </label>
          <Skeleton width="100%" height="3rem" />
        </div>
      </div>
    </div>
  );
};

export default SkeletonBodyForm;
