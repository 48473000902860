import "./DespachoMercadoExportacion.scss";
import React from "react";

import usePageViews from "../../../hooks/usePageViews";
import useAuth from "@shared/AuthContext";
import DespachoMercadoExportacionBrForm from "./components/DespachoMercadoExportacionBrForm";
import DespachoMercadoExportacionForm from "./components/DespachoMercadoExportacionForm";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function DespachoMercadoExportacion() {
  const SCREEN_CODE = "fun0063_despacho_mercado_exportacion";
  ScreenCodeValue(SCREEN_CODE);
  const { user } = useAuth();
  usePageViews();

  function CountryForm() {
    if (user.pais.codPais === 2) {
      return <DespachoMercadoExportacionBrForm SCREEN_CODE={SCREEN_CODE} />;
    } else {
      return  <DespachoMercadoExportacionForm SCREEN_CODE={SCREEN_CODE} />;
    }
  }

  return (
    <div className="despacho-mercado-exportacion">
      <CountryForm />
    </div>
  );
}
export default DespachoMercadoExportacion;
