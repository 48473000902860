import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { Tree } from "primereact/tree";

interface TreeErrorDialogProps {
  visible: boolean;
  errorMessage: string;
  treeData: any[];
  onClose: () => void;
}

const TreeErrorDialog: React.FC<TreeErrorDialogProps> = ({
  visible,
  errorMessage,
  treeData,
  onClose,
}) => {
  return (
    <Dialog
      visible={visible}
      style={{ width: "500px", height: "300px" }}
      onHide={onClose}
      modal
      closable={false}
      header="Custom Modal Title"
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {/* Error Message */}
        <div
          style={{
            marginBottom: "1rem",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {errorMessage}
        </div>

        {/* Tree Structure */}
        <div style={{ flex: 1, overflow: "auto" }}>
          <Tree value={treeData} />
        </div>

        {/* Accept Button */}
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <Button label="Accept" icon="pi pi-check" onClick={onClose} />
        </div>
      </div>
    </Dialog>
  );
};

export default TreeErrorDialog;
