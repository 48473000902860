import "../components/WinCheckDialog.scss";

import React, { useEffect } from "react";

import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";

interface WinCheckDialogProps {
  visible: boolean;
  list: any[];
  onClose: () => void;
  onConfirm: (updatedList: any[]) => void;
}

const WinCheckDialog: React.FC<WinCheckDialogProps> = ({
  visible,
  list,
  onClose,
  onConfirm,
}) => {
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

  // Atualizar `selectedRows` quando a lista mudar ou a modal for aberta
  useEffect(() => {
    if (visible) {
      setSelectedRows(list);
    }
  }, [list, visible]);

  const handleConfirm = () => {
    const newSelectedRows = selectedRows.filter((row) =>
      list.some(
        (updatedItem) => updatedItem.remito.vin.id === row.remito.vin.id
      )
    );
    setSelectedRows([]);
    onConfirm(newSelectedRows);
  };

  const handleClose = () => {
    setSelectedRows([]);
    onClose();
  };

  console.log("WinCheckDialog =>", selectedRows);

  return (
    <Dialog
      header={<LanguageProvider id="11128" alt="Vines Bloqueados Atualmente" />}
      visible={visible}
      style={{ width: "50vw" }}
      onHide={handleClose} // Chama `handleClose` para limpar o estado
    >
      <Card>
        <p className="m-0">
          <LanguageProvider
            id="16769"
            alt="Existe vines bloqueados por calidade. Marque os remitos a liberar"
          />
        </p>
      </Card>
      <DataTable
        className="WinCheckDialog"
        value={list}
        tableStyle={{ minWidth: "50rem" }}
        showGridlines
        stripedRows
        scrollHeight="flex"
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        selectionMode="checkbox"
        selection={selectedRows}
        onSelectionChange={(e) => setSelectedRows(e.value)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} />
        <Column field="remito.vin.id" header="label" />
      </DataTable>
      <div className="p-dialog-footer">
        <button onClick={handleConfirm} className="p-button p-button-primary">
          Confirmar
        </button>
        <button onClick={handleClose} className="p-button p-button-secondary">
          Fechar
        </button>
      </div>
    </Dialog>
  );
};

export default WinCheckDialog;
