import "./RecepcionPlaya.scss";

import React from "react";
import RecepcionPlayaBrForm from "./components/RecepcionPlayaBrForm";
import RecepcionPlayaForm from "./components/RecepcionPlayaForm";
import useAuth from "@shared/AuthContext";
import usePageViews from "../../../hooks/usePageViews";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function RecepcionPlaya() {
  const SCREEN_CODE = "fun0042_recepcion_de_playa";
  ScreenCodeValue(SCREEN_CODE);
  const { user } = useAuth();
  usePageViews();

  function CountryForm() {
    if (user.pais.codPais === 2) {
      return <RecepcionPlayaBrForm SCREEN_CODE={SCREEN_CODE} />;
    } else {
      return <RecepcionPlayaForm SCREEN_CODE={SCREEN_CODE} />;
    }
  }

  return (
    <div className="recepcion-playa">
      <CountryForm />
    </div>
  );
}
export default RecepcionPlaya;
