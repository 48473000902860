import "./DevolucionMercadoLocal.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function DevolucionMercadoLocal() {
  const SCREEN_CODE = "fun0057_devolucion_mercado_local";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [ingreseElVin, setIngreseElVin] = useState([]);
  const [mercados, setMercados] = useState([]);
  const [remitos, setRemitos] = useState([]);
  const [estados, setEstados] = useState([]);
  const [concesionario, setConcesionario] = useState([]);
  const [marca, setMarca] = useState([]);
  const [bloqueoCalidad, setBloqueoCalidad] = useState([]);
  const [direccion, setDireccion] = useState([]);
  const [modelo, setModelo] = useState([]);

  const [filter, setFilter] = useState({
    fecha: null,
    capitalyHBA: false,
    tipoRemito: null,
    letra: null,
    puntoVenta: null,
    numero: null,
    estados: "TODOS",
    concesionario: null,
    marca: null,
    mercados: null,
    marcas: null,
    modelos: null,
    colores: null,
    familias: null,
    vin: null,
    bloqueoCalidad: null,
    direccion: "TODOS",
    modelo: null,
  });
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];
  const filterColumnsId = [
    849, 850, 24084, 1491, 23892, 2139, 2140, 2941, 4216,
  ];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  async function loadReportData() {
    // setLoading(true);
    // try {
    //   let transacciones = [];
    //   if (filter.capitalyHBA) {
    //     transacciones.push("GFC06");
    //   }
    //   const { status, data } = await sipcoAxiosService.post(
    //     "/administracion-remitos-concesionario/vin",
    //     {
    //       codPais: securityFilters?.securityValues?.country?.codPais || null,
    //       codCliente: securityFilters?.securityValues?.client?.codCliente || "",
    //       codCuenta:
    //         securityFilters?.securityValues?.account?.codCuenta || null,
    //       codSubcuenta:
    //         securityFilters?.securityValues?.subaccount?.codSubcuenta || null,
    //     }
    //   );
    //   if (status === 200) {
    //     setReporte(data);
    //   }
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setLoading(false);
    // }
  }

  return (
    <div className="devolucion-mercado-local">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN " />
                </label>
                <AutoComplete dropdown />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"924"} alt="Hoja de Ruta " />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Devolucion Nacional</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({ id: "16283", alt: "Procesar" })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "20947",
                    alt: "Modificar Daños",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={"Código Remito"} sortable filter />
          <Column field="3" header={"Cliente"} sortable filter />
          <Column field="4" header={columnsNames[3].label} sortable filter />
          <Column field="5" header={"Marca"} sortable filter />
          <Column field="6" header={columnsNames[5].label} sortable filter />
          <Column field="7" header={columnsNames[6].label} sortable filter />
          <Column field="8" header={columnsNames[7].label} sortable filter />
          <Column field="9" header={columnsNames[8].label} sortable filter />
        </DataTable>
        <div className="filter-options">
          <Tag
            style={{ background: "orange" }}
            value={
              <LanguageProvider
                id={"3674"}
                alt="Vehículo con daños antes de reingresarlo revise y deje los que están vigentes y cargue los nuevos"
              />
            }
          />
        </div>
        <div className="filter-options">
          <div className={"sipco-options-line"}>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"TODO"} alt="Ubicacón" />
              </label>
              <Dropdown value={1} optionLabel="descripcion" filter />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"TODO"} alt="Columna" />
              </label>
              <Dropdown value={1} optionLabel="descripcion" filter />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"TODO"} alt="Nivel" />
              </label>
              <Dropdown value={1} optionLabel="descripcion" filter />
            </div>
          </div>

          <div className={"sipco-options-line"}>
            <div className="sipco-option" style={{ width: "100%" }}>
              <label>
                <LanguageProvider id={"TODO"} alt="Nota" />
              </label>
              <InputText style={{ paddingRight: "500px" }} />
            </div>
          </div>
        </div>
        <Accordion>
          <AccordionTab
            header={<LanguageProvider id={"17355"} alt="Hoja Ruta" />}
          >
            <div className="form" style={{ display: "flex", gap: "20px" }}>
              <div
                className="colunm"
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className="sipco-option"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>
                    <LanguageProvider id={"754"} alt="Transportista" />
                  </label>
                  <InputText />
                </div>

                <div
                  className="sipco-option"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>
                    <LanguageProvider id={"4655"} alt="Chofer" />
                  </label>
                  <InputText />
                </div>
              </div>

              <div
                className="colunm"
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className="sipco-option"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <label>
                    <LanguageProvider id={"4285"} alt="Tractor" />
                  </label>
                  <InputText />
                </div>

                <div
                  className="sipco-option"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>
                    <LanguageProvider id={"8811"} alt="Cantidad de Unidades" />
                  </label>
                  <InputText />
                </div>
              </div>

              <div
                className="colunm"
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className="sipco-option"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>
                    <LanguageProvider id={"4284"} alt="Batea / Transportín" />
                  </label>
                  <InputText />
                </div>

                <div
                  className="sipco-option"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <label>
                    <LanguageProvider id={"16875"} alt="Compra flete" />
                  </label>
                  <Checkbox checked value={true} />

                  <label>
                    <LanguageProvider id={"16876"} alt="Venta flete" />
                  </label>
                  <Checkbox checked value={true} />
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </Panel>
    </div>
  );
}
