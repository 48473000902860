import "./DespachoMercadoNacional.scss";
import React from "react";
import usePageViews from "../../../hooks/usePageViews";
import useAuth from "@shared/AuthContext";
import DespachoMercadoNacionalForm from "./components/DespachoMercadoNacionalForm";
import DespachoMercadoNacionalBrForm from "./components/DespachoMercadoNacionalBrForm";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function DespachoMercadoNacional() {
  const SCREEN_CODE = "fun0052_despacho_mercado_nacional";
  ScreenCodeValue(SCREEN_CODE);
  const { user } = useAuth();
  usePageViews();

  function CountryForm() {
    if (user.pais.codPais === 2) {
      return <DespachoMercadoNacionalBrForm SCREEN_CODE={SCREEN_CODE} />;
    } else {
      return <DespachoMercadoNacionalForm SCREEN_CODE={SCREEN_CODE} />;
    }
  }

  return (
    <div className="despacho-mercado-nacional">
      <CountryForm />
    </div>
  );
}
export default DespachoMercadoNacional;
