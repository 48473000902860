import { LanguageProvider } from '@shared/components/language-provider';
import { useMemo } from 'react';


const useFilterColumnsIds = (ids) => {
  const result = useMemo(() => {
    return ids.map((id) => ({
      id,
      label: LanguageProvider({
        id,
        alt: 'Error Columns Labels',
      }),
    }));
  }, [ids]);

  return result;
};

export default useFilterColumnsIds;
