import React from "react";
import { Skeleton } from "primereact/skeleton";
import { LanguageProvider } from "@shared/components/language-provider";
import { Card } from "primereact/card";

const SkeletonCard: React.FC = () => {
  return (
    <Card>
          <div className="fieldset-content">
            <div className="filter-options">
              <div className={"sipco-option-text-large"}>
                <label>
                  <LanguageProvider id={"23888"} alt="Marca" />
                </label>                
                <Skeleton width="100%" height="3rem"/>
              </div>
              <div className={"sipco-option-text-large"}>
                <label>
                  <LanguageProvider id={"2139"} alt="Modelo" />
                </label>
                <Skeleton width="100%" height="3rem"/>
              </div>
              <div className={"sipco-option-text-large"}>
                <label>
                  <LanguageProvider id={"2140"} alt="Color" />
                </label>
                <Skeleton width="100%" height="3rem"/>
              </div>
              <div className={"sipco-option-text-large"}>
                <label>
                  <LanguageProvider id={"10785"} alt="LCDV" />
                </label>
                <Skeleton width="100%" height="3rem"/>
              </div>
              <div className={"sipco-option-text-large"}>
                <label>
                  <LanguageProvider id={"11821"} alt="País" />
                </label>
                <Skeleton width="100%" height="3rem"/>
              </div>
            </div>
          </div>
        </Card>
  );
};

export default SkeletonCard;
