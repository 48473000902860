import "./BloqueoUbicaciones.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import PanelCustom from "../../../components/panel-custom/PanelCustom";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SkeletonBody from "./components/SkeletonBody";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import useFilterColumnsIds from "../../../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const columnsIds = [
  { header: 990, field: "calle" },
  { header: 991, field: "columna" },
  { header: 992, field: "nivel" },
  { header: 72, field: "activo" },
  { header: 13706, field: "usuarioUltimaModificacion.id" },
  { header: 5271, field: "fechaUltimaModificacion.date" },
];
export function BloqueoUbicaciones() {
  const SCREEN_CODE = "fun0112_bloqueo_ubicaciones";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [dataTableValue, setDataTableValue] = useState(null);
  const toast = useRef<Toast>(null);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialCalles, setInitalCalles] = useState([]);
  const [initialColumnas, setInitialColumnas] = useState([]);
  const [calleIni, setCalleIni] = useState("");
  const [calleFin, setCalleFin] = useState("");
  const [columnaIni, setColumnaIni] = useState("");
  const [columnaFin, setColumnaFin] = useState("");
  const [calleIniSuggestions, setCalleIniSuggestions] = useState([]);
  const [calleFinSuggestions, setCalleFinSuggestions] = useState([]);
  const [columnaIniSuggestions, setColumnaIniSuggestions] = useState([]);
  const [columnaFinSuggestions, setColumnaFinSuggestions] = useState([]);
  const [modal, setModal] = useState(false);
  const [mensaje, setMensajes] = useState("");

  const columnsNames = useFilterColumnsIds(columnsIds);

  async function loadCalles() {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        subcuenta: { id: securityFilters.securityValues.subaccount.id },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/bloqueo-ubicaciones/calles-libres",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          data.sort((a, b) =>
            a.toString().toLowerCase() < b.toString().toLowerCase() ? -1 : 1
          );
          setInitalCalles(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadColumnas() {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        subcuenta: { id: securityFilters.securityValues.subaccount.id },
      };
      const { status, data } = await sipcoAxiosService.post(
        "bloqueo-ubicaciones/columas-libres",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          data.sort((a, b) =>
            a.toString().toLowerCase() < b.toString().toLowerCase() ? -1 : 1
          );
          setInitialColumnas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function validateSecurityFilters(): boolean {
    return !!(
      securityFilters.securityValues.country?.id &&
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id &&
      securityFilters.securityValues.subaccount?.id
    );
  }

  async function loadCalleIni(event) {
    const { query } = event;
    if (query) {
      const dataFiltered = initialCalles
        .filter((x: any) =>
          x.toString().toLowerCase().includes(query.toLowerCase())
        )
        .filter(
          (value, index, self) => index === self.findIndex((t) => t === value)
        );
      setCalleIniSuggestions(dataFiltered);
    } else {
      const uniqueData = initialCalles.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      setCalleIniSuggestions(uniqueData);
    }
  }

  async function loadCalleFin(event) {
    const { query } = event;
    if (query) {
      const dataFiltered = initialCalles
        .filter((x: any) =>
          x.toString().toLowerCase().includes(query.toLowerCase())
        )
        .filter(
          (value, index, self) => index === self.findIndex((t) => t === value)
        );
      setCalleFinSuggestions(dataFiltered);
    } else {
      const uniqueData = initialCalles.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      setCalleFinSuggestions(uniqueData);
    }
  }

  async function loadColumnaIni(event) {
    const { query } = event;
    if (query) {
      const dataFiltered = initialColumnas
        .filter((x: any) =>
          x.toString().toLowerCase().includes(query.toLowerCase())
        )
        .filter(
          (value, index, self) => index === self.findIndex((t) => t === value)
        );
      setColumnaIniSuggestions(dataFiltered);
    } else {
      const uniqueData = initialColumnas.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      setColumnaIniSuggestions(uniqueData);
    }
  }

  async function loadColumnaFin(event) {
    const { query } = event;
    if (query) {
      const dataFiltered = initialColumnas
        .filter((x: any) =>
          x.toString().toLowerCase().includes(query.toLowerCase())
        )
        .filter(
          (value, index, self) => index === self.findIndex((t) => t === value)
        );
      setColumnaFinSuggestions(dataFiltered);
    } else {
      const uniqueData = initialColumnas.filter(
        (value, index, self) => index === self.findIndex((t) => t === value)
      );
      setColumnaFinSuggestions(uniqueData);
    }
  }

  async function loadReport() {
    try {
      setLoading(true);
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        subcuenta: { id: securityFilters.securityValues.subaccount.id },
        calleIni: calleIni,
        calleFin: calleFin,
        columnaIni: columnaIni,
        columnaFin: columnaFin,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/bloqueo-ubicaciones/ubicaciones-libres",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          setDataTableValue(data);
          return data;
        } else {
          setDataTableValue([]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function desbloquearUbicaciones() {
    try {
      setLoading(true);
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        subcuenta: { id: securityFilters.securityValues.subaccount.id },
        calleIni: calleIni,
        calleFin: calleFin,
        columnaIni: columnaIni,
        columnaFin: columnaFin,
        estado: 1, // hard code do projeto antigo
      };
      const { status, data } = await sipcoAxiosService.post(
        "/bloqueo-ubicaciones/desbloquear-ubicaciones",
        params
      );
      if (status === 200) {
        if (data) {
          toast.current?.show({
            severity: "success",
            summary: (
              <LanguageProvider
                id="36"
                alt="Sua transação se realizou com sucesso."
              />
            ),
            life: 3000,
          });
          loadReport();
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "error",
          detail: "error",
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  async function bloquearUbicaciones() {
    try {
      setLoading(true);
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        subcuenta: { id: securityFilters.securityValues.subaccount.id },
        calleIni: calleIni,
        calleFin: calleFin,
        columnaIni: columnaIni,
        columnaFin: columnaFin,
        estado: 0, // hard code do projeto antigo
      };
      const { status, data } = await sipcoAxiosService.post(
        "bloqueo-ubicaciones/bloquear-ubicaciones",
        params
      );
      if (status === 200) {
        if (data) {
          toast.current?.show({
            severity: "success",
            summary: (
              <LanguageProvider
                id="36"
                alt="Sua transação se realizou com sucesso."
              />
            ),
            life: 3000,
          });
          loadReport();
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "error",
          detail: "error",
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const confirmBloquear = () => {
    confirmDialog({
      message: (
        <LanguageProvider
          id={"13275"}
          alt="Deseja processar as alterações realizadas?"
        />
      ),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => bloquearUbicaciones(),
      reject: null,
    });
  };

  const confirmDesbloquear = () => {
    confirmDialog({
      message: (
        <LanguageProvider
          id={"13275"}
          alt="Deseja processar as alterações realizadas?"
        />
      ),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => desbloquearUbicaciones(),
      reject: null,
    });
  };

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };

  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  useEffect(() => {
    setDataTableValue([]);
    async function loadInitial() {
      try {
        setLoadingFilters(true);
        const calles = await loadCalles();
        const columnas = await loadColumnas();
        setCalleIni(calles[0]);
        setCalleFin(calles[calles.length - 1]);
        setColumnaIni(columnas[0]);
        setColumnaFin(columnas[columnas.length - 1]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingFilters(false);
      }
    }
    if (validateSecurityFilters()) {
      loadInitial();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues]);

  return (
    <div className="bloqueo-ubicaciones">
      <div>
        <ConfirmDialog />
      </div>
      <Toast ref={toast} position="bottom-left" />
      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"1269"} alt="Ubicación" />}>
          {loadingFilters ? (
            <SkeletonBody />
          ) : (
            <div className="filter-options">
              <div className="sipco-options-line">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"11554"} alt="Calle Inicio" />
                  </label>
                  <AutoComplete
                    value={calleIni}
                    suggestions={calleIniSuggestions}
                    completeMethod={loadCalleIni}
                    onChange={(e) => setCalleIni(e.value)}
                    dropdown
                    forceSelection
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"11557"} alt="Calle Fin" />
                  </label>
                  <AutoComplete
                    value={calleFin}
                    suggestions={calleFinSuggestions}
                    completeMethod={loadCalleFin}
                    onChange={(e) => setCalleFin(e.value)}
                    dropdown
                    forceSelection
                  />
                </div>
              </div>
              <div className="sipco-options-line">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"11555"} alt="Columna Inicio" />
                  </label>
                  <AutoComplete
                    value={columnaIni}
                    suggestions={columnaIniSuggestions}
                    completeMethod={loadColumnaIni}
                    onChange={(e) => setColumnaIni(e.value)}
                    dropdown
                    forceSelection
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"11558"} alt="Columna Fin" />
                  </label>
                  <AutoComplete
                    value={columnaFin}
                    suggestions={columnaFinSuggestions}
                    completeMethod={loadColumnaFin}
                    onChange={(e) => setColumnaFin(e.value)}
                    dropdown
                    forceSelection
                  />
                </div>
              </div>
            </div>
          )}
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={<LanguageProvider id="12476" alt="Bloqueo de ubicaciones" />}
      >
        <div className="fieldset-content">
          <DataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={dataTableValue}
                  columns={reporte}
                  screenName={"RecepcionPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={dataTableValue}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsNames.map((column, index) => {
              if (column.field === "fechaUltimaModificacion.date") {
                return (
                  <Column
                    key={index}
                    body={(e) => dateColumnTemplate(e, column.field)}
                    field={column.field}
                    header={column.label}
                    sortable
                    filter
                  />
                );
              } else {
                return (
                  <Column
                    key={index}
                    field={column.field}
                    header={column.label}
                    sortable
                  />
                );
              }
            })}
          </DataTable>
        </div>

        <Divider />
        <div>
          <Button
            label={LanguageProvider({
              id: "12477",
              alt: "Bloquear Ubicaciones",
            })}
            onClick={confirmBloquear}
            text
          />
          <Button
            label={LanguageProvider({
              id: "12478",
              alt: "Desbloquear Ubicaciones",
            })}
            onClick={confirmDesbloquear}
            text
          />
        </div>
      </Fieldset>
    </div>
  );
}
export default BloqueoUbicaciones;
