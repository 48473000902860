import React from "react";
import { Skeleton } from "primereact/skeleton";
import { LanguageProvider } from "@shared/components/language-provider";

const SkeletonFilterOptions: React.FC = () => {
  return (
    <div>
      <div className="filter-options-column">
        <div className="sipco-options-column">
          {/* Concesionario */}
          <div className="sipco-option">
            <label>
              <LanguageProvider id={"2941"} alt="Concesionario" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          {/* Tipo Remito */}
          <div className="sipco-option">
            <label>
              <LanguageProvider id={"1617"} alt="Tipo remito" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          {/* Cód.Pedido Cliente */}
          <div className="sipco-option-text">
            <label>
              <LanguageProvider id={"16923"} alt="Cód.Pedido Cliente" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>
        </div>
        <div className="sipco-options-column">
          {/* Boca de Entrega */}
          <div className="sipco-option">
            <label>
              <LanguageProvider id={"4216"} alt="Boca de Entrega" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          {/* Leyenda */}
          <div className="sipco-option">
            <label>
              <LanguageProvider id={"16757"} alt="Leyenda" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          {/* Nro. CAI */}
          <div className="sipco-option-text">
            <label>
              <LanguageProvider id={"7888"} alt="Nro. CAI" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>
        </div>
      </div>
      <div className="filter-options-column">
        <div className="sipco-options-column">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1492"} alt="Fecha Emisión" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"4422"} alt="Llegado" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"14683"} alt="Fecha Anulación" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"21503"} alt="Liberación" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option-text"}>
            <label>
              <LanguageProvider id={"3591"} alt="Razón Social" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option-text"}>
            <label>
              <LanguageProvider id={"8299"} alt="Domicilio" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option-text"}>
            <label>
              <LanguageProvider id={"16928"} alt="Modelo a imprimir" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>
        </div>

        <div className="sipco-options-column">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"382"} alt="Despacho" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"13710"} alt="Reingreso" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"16925"} alt="Fec. Ant. Retorno" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"16926"} alt="Fecha carga retorno" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option-text"}>
            <label>
              <LanguageProvider id={"11178"} alt="CUIT" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option-text"}>
            <label>
              <LanguageProvider id={"159"} alt="Provincia" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option-text"}>
            <label>
              <LanguageProvider id={"3186"} alt="Localidad" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>
        </div>

        <div className="sipco-options-column">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"295"} alt="Retorno" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"16924"} alt="Vence CAI" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"9712"} alt="Fecha de Control" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className="sipco-option">
            <label>
              <LanguageProvider id="16927" alt="Actualizó fecha" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className="sipco-option-text">
            <label>
              <LanguageProvider id="22216" alt="IVA" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className="sipco-option-text">
            <label>
              <LanguageProvider id="11176" alt="Código Postal" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>

          <div className={"sipco-option-text"}>
            <label>
              <LanguageProvider id={"9387"} alt="Estado del remito" />
            </label>
            <Skeleton width="100%" height="3rem" />
          </div>
        </div>
      </div>
      <div className="filter-options">
  <div className="sipco-options-line" style={{ alignItems: "flex-end" }}>
    <div className={"sipco-option"}>
      <label>
        <LanguageProvider id={"10855"} alt="Paga Flete" />
      </label>
      <Skeleton width="100%" height="3rem" />
    </div>

    <div className={"sipco-option"}>
      <label>
        <LanguageProvider id={"10769"} alt="Naturaleza Fiscal" />
      </label>
      <Skeleton width="100%" height="3rem" />
    </div>

    <div className={"sipco-option-text-small"}>
      <label>
        <LanguageProvider id={"32"} alt="Valor" />
      </label>
      <Skeleton width="100%" height="3rem" />
    </div>

    <div className={"sipco-option"}>
      <label>
        <LanguageProvider id={"4974"} alt="Comentarios" />
      </label>
      <Skeleton width="100%" height="6rem" />
    </div>

    <div className={"RadioButtonFilter"}>
      <label>
        <LanguageProvider id={"22446"} alt="Ficticio" />
      </label>
      <Skeleton width="2rem" height="2rem" />
    </div>
  </div>
</div>

    </div>
  );
};

export default SkeletonFilterOptions;
