import "./TransferenciaUbicacion.scss";

import React, { useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

import { ConfirmDialog } from "primereact/confirmdialog";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import TransferenciaColecta from "./tabs/TransferenciaColecta";
import TransferenciaMasiva from "./tabs/TransferenciaMasiva";
import TransferenciaVin from "./tabs/TransferenciaVin";
import usePageViews from "../../../hooks/usePageViews";

export function TransferenciaUbicacion() {
  const SCREEN_CODE = "fun0040_transferencia_ubicacion";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const dt = useRef(null);
  const toast = useRef<Toast>(null);
  const [modal, setModal] = useState(false);
  const [mensaje, setMensajes] = useState("");

  return (
    <div className="transferencia-ubicacion">
      <Toast ref={toast} position="bottom-left" />
      <ConfirmDialog />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      />
      <Fieldset
        legend={
          <LanguageProvider id={"315"} alt="Transferencia de Ubicación" />
        }
      >
        <div>
          <TabView activeIndex={1}>
            <TabPanel
              header={
                <LanguageProvider id={"16826"} alt="Transferencia por vin" />
              }
            >
              <TransferenciaVin
                toast={toast}
                setModal={setModal}
                setMensajes={setMensajes}
              />
            </TabPanel>
            <TabPanel
              header={
                <LanguageProvider
                  id={"10958"}
                  alt="Transferencia por Colecta"
                />
              }
            >
              <TransferenciaColecta
                toast={toast}
                setModal={setModal}
                setMensajes={setMensajes}
              />
            </TabPanel>
            <TabPanel
              header={
                <LanguageProvider id={"19364"} alt="Transferencia Masiva" />
              }
            >
              <TransferenciaMasiva />
            </TabPanel>
          </TabView>
        </div>
      </Fieldset>
    </div>
  );
}
export default TransferenciaUbicacion;
