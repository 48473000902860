import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";

const DestranadoMasivoDanos: React.FC<{ vinData: any }> = ({ vinData }) => {
  const filterColumnsId = [1491, 8385, 3625];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  return (
    <div className="vin-view">
      <Fieldset
        legend={
          <label>
            Datos - Cantidad de Registros a Procesar.: 0 - Registros con
            errores.: 0
          </label>
        }
      >
        <div>
          <label style={{ marginRight: "10px" }}>
            <LanguageProvider id={1491} alt="VIN:" />
          </label>
          <AutoComplete dropdown />
        </div>
        <Divider />
        <DataTable
          size="small"
          showGridlines
          stripedRows
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          selectionMode="single"
        >
          <Column field="1" header={columnsNames[0].label} sortable filter />
        </DataTable>
        <div>
          <Button label={LanguageProvider({ id: "23824", alt: "Alta" })} text />
        </div>
      </Fieldset>
    </div>
  );
};

export default DestranadoMasivoDanos;
