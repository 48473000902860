import "./IngresoTallerManual.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function IngresoTallerManual() {
  const SCREEN_CODE = "fun0076_ingreso_taller_manual";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const toast = useRef<Toast>(null);

  const [loading, setLoading] = useState(false);

  const columnsIds1 = [1491, 396, 2139, 2140, 16957, 13490];

  const columnsNames = filterColumnsIds(columnsIds1);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  return (
    <div className="ingreso-taller-manual">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Ingrese el Vin" />
              </label>
              <AutoComplete dropdown />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={<LanguageProvider id={"2138"} alt="Ingreso Taller Manual" />}
      >
        <div className="fieldset-content">
          <DataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={reporte}
                  screenName={"RecepcionPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={listRemitos}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsNames.map((colum, index) => (
              <Column key={index} header={colum.label} sortable filter />
            ))}
          </DataTable>
        </div>
        <Divider />
        <div className="fieldset-footer">
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "25",
                alt: "Alta",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "748",
                alt: "Seleccionar todo",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "749",
                alt: "Deseleccionar todo",
              })}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default IngresoTallerManual;
