import "./monitor-interfaces.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

/**
 * Esta tela veio de um pedido do suporte. Por isso não possui codigo
 * nem filtros de segurança.
 * */

export function MonitorInterfaces() {
  const sipcoAxiosService = useSipcoAxiosService();
  const [monitoringData, setMonitoringData] = useState([]);
  const [resultMonitoringData, setResultMonitoringData] = useState([]);
  const [gruposInterfazMonitoringData, setGruposInterfazMonitoringData] =
    useState([]);
  const [listServersBygrupSelected, setListServersBygrupSelected] = useState(
    []
  );
  const [gruposServersMonitoringData, setGruposServersMonitoringData] =
    useState([]);
  const [selectedInterfaceGroup, setSelectedInterfaceGroup] = useState("");
  const [selectedServer, setSelectedServer] = useState("");
  const [rowSelected, setRowSelected] = useState("");
  const [checked, setChecked] = useState(true);
  const [active, setActive] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [modal, setModal] = useState(false);
  const [time, setTime] = useState(30);
  const [inputTimeValue, setInputTimeValue] = useState(time);
  const [timeDefined, setTimeDefined] = useState(time);
  const toast = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function fetchMonitoringData() {
    try {
      const { data, status } = await sipcoAxiosService.get(
        "/monitoreo/mon-interface"
      );

      if (status === 200) {
        if (selectedInterfaceGroup === "" && selectedServer === "") {
          setResultMonitoringData(data);
        }
        if (!!selectedInterfaceGroup) {
          selectedGrupoInterfazMonitoringData(selectedInterfaceGroup);
        }
        if (!!selectedServer) {
          selectedServerFilterServer(selectedServer);
        }

        setMonitoringData(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchMonitoringData();
  }, [refresh]);

  useEffect(() => {
    if (!active) return;
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          fetchMonitoringData();
          return inputTimeValue;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [active, fetchMonitoringData]);

  const accept = () => {
    forzarCorrida();
    toast.current.show({
      severity: "info",
      summary: "Confirmed",
      detail: "You have accepted",
      life: 3000,
    });
  };

  const reject = () => {
    setRowSelected("");
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "Cancelado.",
      life: 3000,
    });
  };

  function filterGruposInterfazMonitoringData() {
    let grupoInterface = "";
    let auxMonitoringDat = monitoringData.filter(({ grupoInterfaces }) => {
      if (grupoInterface !== grupoInterfaces) {
        grupoInterface = grupoInterfaces;
        return true;
      }
      return false;
    });

    setGruposInterfazMonitoringData([
      { grupoInterfaces: "Todos" },
      ...auxMonitoringDat,
    ]);
  }

  function selectedGrupoInterfazMonitoringData(selectedGrupoInterfaz: string) {
    setSelectedServer("");
    setSelectedInterfaceGroup(selectedGrupoInterfaz);
    if (selectedGrupoInterfaz !== undefined) {
      if (selectedGrupoInterfaz === "Todos") {
        setListServersBygrupSelected(monitoringData);
        setResultMonitoringData(monitoringData);
        return;
      }
      let specificMonitoringData = monitoringData?.filter(
        ({ grupoInterfaces }) => {
          if (grupoInterfaces === selectedGrupoInterfaz) {
            return true;
          }
        }
      );

      setListServersBygrupSelected(specificMonitoringData);
      setResultMonitoringData(specificMonitoringData);
    }
  }

  function filterServersmonitoringData() {
    let listUnicServers = [];
    const servidorSet = new Set();

    const data =
      listServersBygrupSelected.length > 0
        ? listServersBygrupSelected
        : resultMonitoringData;

    data.forEach((v) => {
      if (!servidorSet.has(v.servidorCorrida)) {
        servidorSet.add(v.servidorCorrida);
        listUnicServers.push(v);
      }
    });

    setGruposServersMonitoringData([
      { servidorCorrida: "Todos" },
      ...listUnicServers,
    ]);
  }

  function selectedServerFilterServer(selectedServer: string) {
    if (selectedServer !== undefined) {
      setSelectedServer(selectedServer);
      if (selectedServer === "Todos") {
        selectedInterfaceGroup
          ? setResultMonitoringData(listServersBygrupSelected)
          : setResultMonitoringData(monitoringData);
        return;
      }

      let specificMonitoringData = (
        listServersBygrupSelected.length > 0
          ? listServersBygrupSelected
          : resultMonitoringData
      )?.filter(({ servidorCorrida }) => {
        if (servidorCorrida === selectedServer) {
          return true;
        }
      });
      setResultMonitoringData(specificMonitoringData);
      return specificMonitoringData;
    }
  }

  async function forzarCorrida() {
    setModal(false);

    try {
      const { status } = await sipcoAxiosService.post(
        "/monitoreo/mon-interface-updateCorrigir",
        null,
        {
          params: { codInterfaz: rowSelected },
        }
      );

      if (status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Carrera forzada con éxito!",
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  function getDifferenceInSeconds(fechaUltimoPing, fechaActual) {
    const [date, time] = fechaUltimoPing.split(" ");
    const [day, month, year] = date.split("/");
    const [hour, minute, second] = time.split(":");
    const formattedfechaUltimoPing = new Date(
      `${year}-${month}-${day}T${hour}:${minute}:${second}`
    );

    const formattedfechaActual = new Date(fechaActual);

    const timeDiff = Math.abs(
      formattedfechaActual.getTime() - formattedfechaUltimoPing.getTime()
    );

    const diffInSeconds = Math.floor(timeDiff / 1000);

    return diffInSeconds;
  }

  function colorBasedOnTime(rowData) {
    const tolerancia = 1;

    const differenceInSeconds = getDifferenceInSeconds(
      rowData.fechaUltimoPing.trim(),
      rowData.fechaActual.trim()
    );

    const tiempoEstimadoCorrida =
      rowData.tiempoEstimadoCorrida.trim() === ""
        ? 5
        : rowData.tiempoEstimadoCorrida.trim();

    if (rowData.fechaFin.trim() === "") {
      //5
      if (
        rowData.fechaInicioProceso.trim() !== "" ||
        rowData.rf.trim() !== "0"
      ) {
        //6 18
        if (rowData.fechaFinProceso.trim() === "") {
          //7
          if (differenceInSeconds > (tolerancia / 2) * 60) {
            //Diffs
            console.log(rowData.fechaUltimoPing, rowData.fechaActual);
            return { "bg-green-100 text-green-900": true };
          }
        }

        if (rowData.pintar.trim() !== "1") {
          if (differenceInSeconds > tiempoEstimadoCorrida * 60) {
            if (rowData.codInterfaz.trim() === "MANDAMAIL1DIA") {
              const diffInMinutes = Math.floor(differenceInSeconds / 60);
              if (diffInMinutes > (tolerancia / 2) * 300) {
                console.log(rowData.fechaUltimoPing, rowData.fechaActual);
                return { "bg-green-100 text-green-900": true };
              }
            } else {
              return { "bg-red-100 text-red-900": true };
            }
          }
        }
      } else {
        ////fechaFin não foi preenchido, fechaInicioProceso não foi preenchido e rf = 0
        if (differenceInSeconds > tiempoEstimadoCorrida * 60) {
          return { "bg-yellow-100 text-yellow-900": true };
        }
      }
    } else {
      //fechaFin foi preenchido
      if (rowData.levantamentoPrevisto === "1") {
        return { "bg-yellow-100 text-yellow-900": true };
      }
    }
  }

  function handleKeyDown(e): void {
    if (e.key === "Enter") {
      setTime(e.target.value);
    }
  }

  let groupSubgroup: any = ["grupoInterfaces", "aplicacion"];

  return (
    <div className="monitor-interfaces">
      <Toast ref={toast} />

      <Filter
        onSearch={() => {
          setRefresh(!refresh);
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="horizontal-strip">
            <div className="organizeHorizontally">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Grupo Interfaz" />
                </label>
                <AutoComplete
                  completeMethod={() => filterGruposInterfazMonitoringData()}
                  placeholder={selectedInterfaceGroup || "Todos"}
                  value={selectedInterfaceGroup}
                  suggestions={gruposInterfazMonitoringData}
                  onChange={(e) => {
                    setSelectedInterfaceGroup(e.value?.grupoInterfaces);
                    selectedGrupoInterfazMonitoringData(
                      e.value?.grupoInterfaces
                    );
                  }}
                  field="grupoInterfaces"
                  dropdown
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Servidor" />
                </label>
                <AutoComplete
                  placeholder={selectedServer || "Todos"}
                  value={selectedServer}
                  suggestions={gruposServersMonitoringData}
                  completeMethod={() => filterServersmonitoringData()}
                  onChange={(e) => {
                    selectedServerFilterServer(e?.value?.servidorCorrida);
                  }}
                  field="servidorCorrida"
                  dropdown
                  forceSelection
                />
              </div>

              <div className={"sipco-option"}>
                <Checkbox
                  checked={checked}
                  onClick={() => {
                    setChecked(!checked);
                  }}
                ></Checkbox>
                <label>
                  <LanguageProvider
                    id={"TODO"}
                    alt="Incluir interfaces bajas"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="tiempo-atualization">
            <AutoComplete
              value={inputTimeValue}
              type="number"
              onChange={(e) => {
                setInputTimeValue(e.target.value);
              }}
              onKeyDown={handleKeyDown}
            />

            <label style={{ width: "207px" }}>
              <LanguageProvider id={"TODO"} alt="Tiempo actualización (s): " />{" "}
              {time}
            </label>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={"TODO"}
                  alt={active ? "Activo" : "inactivo"}
                />
              </label>
              <Checkbox
                checked={active}
                onClick={() => {
                  setActive(!active);
                  setTime(timeDefined);
                }}
              ></Checkbox>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Monitor Interfaces</span>
            </div>
          );
        }}
      >
        <div className="card">
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            paginator
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            resizableColumns
            removableSort
            filterDisplay="menu"
            value={
              checked
                ? resultMonitoringData
                : resultMonitoringData.filter((value) => value.fechaFin === "")
            }
            rowClassName={colorBasedOnTime}
            rowGroupMode="rowspan"
            groupRowsBy={groupSubgroup}
            sortMode="single"
            sortField="grupoInterfaces"
            sortOrder={1}
            tableStyle={{ minWidth: "50rem" }}
            selectionMode="single"
            onSelectionChange={(e) => {
              setRowSelected(e.value.codInterfaz);
              setModal(!modal);
            }}
          >
            <Column
              field="grupoInterfaces"
              header={<LanguageProvider id={"TODO"} alt="Grupo Interfaz" />}
              bodyStyle={{ backgroundColor: "white" }}
            />
            <Column
              field="aplicacion"
              header={<LanguageProvider id={"TODO"} alt="Aplicación" />}
              bodyStyle={{ backgroundColor: "white" }}
            />
            <Column
              field="proceso"
              header={<LanguageProvider id={"TODO"} alt="Proceso" />}
              style={{ minWidth: "150px" }}
            />
            <Column
              field="fechaInicio"
              header={<LanguageProvider id={"TODO"} alt="Fecha de inicio" />}
              style={{ minWidth: "200px" }}
            />
            <Column
              field="fechaFin"
              header={<LanguageProvider id={"TODO"} alt="Fecha de fim" />}
              style={{ minWidth: "100px" }}
            />
            <Column
              field="fechaInicioProceso"
              header={
                <LanguageProvider
                  id={"TODO"}
                  alt="Fecha de inicio de proceso"
                />
              }
              style={{ minWidth: "100px" }}
            />
            <Column
              field="fechaFinProceso"
              header={
                <LanguageProvider id={"TODO"} alt="Fecha de fin de proceso" />
              }
              style={{ minWidth: "100px" }}
            />
            <Column
              field="fechaUltimoPing"
              header={<LanguageProvider id={"TODO"} alt="Ultimo ping" />}
              style={{ minWidth: "100px" }}
            />
            <Column
              field="proximaCorrida"
              header={<LanguageProvider id={"TODO"} alt="Próxima Corrida" />}
              style={{ minWidth: "100px" }}
            />
            <Column
              field="forzarProximaCorrida"
              header={<LanguageProvider id={"TODO"} alt="Forzar la Corrida" />}
              style={{ minWidth: "100px" }}
            />
            <Column
              field="usuarioLogin"
              header={<LanguageProvider id={"TODO"} alt="Usuario Login" />}
              style={{ minWidth: "100px" }}
            />
            <Column
              field="tiempoEstimadoCorrida"
              header={<LanguageProvider id={"TODO"} alt="Tiempo Estimado" />}
              style={{ minWidth: "100px" }}
            />
            <Column
              field="servidorCorrida"
              header={<LanguageProvider id={"TODO"} alt="Servidor" />}
              style={{ minWidth: "100px" }}
            />
            <Column
              field="prioridad"
              header={<LanguageProvider id={"TODO"} alt="Prioridade" />}
              style={{ minWidth: "100px" }}
            />
          </DataTable>
        </div>
      </Panel>
      <ConfirmDialog
        style={{ height: "14rem", width: "30rem" }}
        visible={modal}
        onHide={() => setModal(false)}
        message="Deseas forzar la corrida?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
    </div>
  );
}
