import "./IngressoTaller.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function ConIngresoTaller() {
  const SCREEN_CODE = "con0043_ingreso_taller";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [327, 467, 468, 1151];
  const filterNames = [];
  const filterColumnsId = [
    5701, 3344, 1491, 2139, 319, 3445, 16492, 16493, 320, 3444, 3443, 3446,
    3447,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 5044, 54, 1791, 4791, 327, 467, 468, 1151, 1002, 1182, 5701, 3344,
    1491, 2139, 319, 3445, 16492, 16493, 320, 3443, 3444, 3447, 3446, 16531,
  ];

  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div className="ingresso-taller">
      <Filter
        onSearch={() => console.log("TODO")}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>Taller</label>
                <Dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>Desde</label>
                <Calendar value={new Date()} showIcon showTime />
              </div>
              <div className={"sipco-option"}>
                <label>Hasta</label>
                <Calendar value={new Date()} showIcon showTime />
              </div>
              <div className={"sipco-option"}>
                <label>Estados</label>
                <Dropdown />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        header="Tiempos de Entrega"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Tiempos de Entrega</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Tiempos de Entrega"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          {/*<Column field="" header={columnsNames[13].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[14].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[15].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[16].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[17].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[18].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[19].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[20].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[21].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[22].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[23].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[24].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[25].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[26].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[27].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[28].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[29].label} sortable filter/>*/}
        </DataTable>
      </Panel>
    </div>
  );
}
export default ConIngresoTaller;
