import "./RegistracionOperacionPorOt.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import Lectora from "./components/Lectora";
import Manual from "./components/Manual";
import Masiva from "./components/Masiva";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";
import { Fieldset } from "primereact/fieldset";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function RegistracionOperacionPorOt() {
  // const sipcoAxiosService = useSipcoAxiosService();
  // const { values: securityFilters } = useFilterContext();
  const SCREEN_CODE = "fun0202_registracion_operacion_por_ot";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [filter, setFilter] = useState({
    fecha: null,
    capitalyHBA: false,
    tipoRemito: null,
    letra: null,
    puntoVenta: null,
    numero: null,
    estados: "TODOS",
    concesionario: null,
    marca: null,
    mercados: null,
    marcas: null,
    modelos: null,
    colores: null,
    familias: null,
    vin: null,
    bloqueoCalidad: null,
    direccion: "TODOS",
    modelo: null,
  });
  const data = convertDateObjects(reporte?.filas);

  async function loadReportData() {
    setLoading(true);
    // try {
    //   let transacciones = [];
    //   if (filter.capitalyHBA) {
    //     transacciones.push("GFC06");
    //   }

    //   const { status, data } = await sipcoAxiosService.post(
    //     "/administracion-remitos-concesionario/vin",
    //     {
    //       codPais: securityFilters?.securityValues?.country?.codPais || null,
    //       codCliente: securityFilters?.securityValues?.client?.codCliente || "",
    //       codCuenta:
    //         securityFilters?.securityValues?.account?.codCuenta || null,
    //       codSubcuenta:
    //         securityFilters?.securityValues?.subaccount?.codSubcuenta || null,
    //     }
    //   );

    //   if (status === 200) {
    //     setReporte(data);
    //   }
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setLoading(false);
    // }
  }

  return (
    <div className="registration-operacion-por-ot">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      />
      <Fieldset legend={<LanguageProvider
              id={"18312"}
              alt="Registración de operaciones por OT"
            />}>        
          <TabView>
            <TabPanel header={<LanguageProvider id={"TODO"} alt="Lectora" />}>
              <Lectora />
            </TabPanel>
            <TabPanel header={<LanguageProvider id={"6850"} alt="Manual" />}>
              <Manual />
            </TabPanel>
            <TabPanel header={<LanguageProvider id={"TODO"} alt="Masiva" />}>
              <Masiva />
            </TabPanel>
          </TabView>        
      </Fieldset>
    </div>
  );
}
export default RegistracionOperacionPorOt;
