import "./DocumentacionTransportes.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Tag } from "primereact/tag";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { set } from "date-fns";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function DocumentacionTransportes() {
  const SCREEN_CODE = "con0047_documentacion_de_transportes";

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [2496, 2521, 4655];
  const filterNames = [];
  const filterColumnsId = [
    4022, 1144, 17044, 17045, 31, 1492, 3716, 13706, 5271, 10948, 4655, 10643,
    3627, 15739, 6197,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    5271, 13706, 3716, 1492, 31, 17045, 17044, 1144, 4022, 42, 2502, 65, 5044,
    4655, 7058, 5007, 2496, 376, 2521, 4847, 3605, 12146, 421, 427, 10948,
    10643, 3627, 15739, 6197, 421, 4724, 396, 72, 16782, 16783, 14610, 13858,
    10584, 11178, 6780, 2521, 7137,
  ];

  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [origen, setOrigen] = useState([]);
  const [loadingOrigen, setLoadingOrigen] = useState(false);

  const [proveedor, setProveedor] = useState([]);
  const [loadingProveedor, setLoadingProveedor] = useState(false);

  const [chofer, setChofer] = useState([]);
  const [loadingChofer, setLoadingChofer] = useState(false);

  const [empleado, setEmpleados] = useState([]);
  const [loadingEmpleados, setLoadingEmpleados] = useState(false);

  const [transporte, setTransporte] = useState([]);
  const [loadingTransporte, setLoadingTransporte] = useState(false);

  const [loading, setLoading] = useState(false);

  /*
  CHOFER se CHOFER
  EMPLEADO se RRHH
  TRANSPORTE ser TRANSPORTE
*/
  const [filter, setFilter] = useState({
    proveedor: null,
    origen: null,
    chofer: {
      nombre: "TODOS",
      apellido: " ",
      id: " ",
    },
    transporte: null,
    empleado: null,
    provedorOrigen: null,
  });

  async function loadOrigenes() {
    setLoadingOrigen(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/documentacion-transporte/combo/origen",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setOrigen(data);
          return data ?? [];
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigen(false);
    }
  }
  async function loadProveedores() {
    setLoadingProveedor(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/documentacion-transporte/combo/proveedor",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: null, descripcion: "TODOS" });
          setProveedor(data);
          return data ?? [];
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedor(false);
    }
  }
  async function loadTransporte(proveedor: any) {
    setLoadingTransporte(true);
    try {
      if (securityFilters.securityValues.country && proveedor != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/documentacion-transporte/combo/transporte",
          {
            proveedor: proveedor || null,
          }
        );
        if (status === 200) {
          data.unshift({ id: "TODOS", descripcion: "TODOS" });
          setTransporte(data);
          return data ?? [];
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransporte(false);
    }
  }
  async function loadChofer(origen: any) {
    setLoadingChofer(true);
    try {
      if (origen != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/documentacion-transporte/combo/chofer",
          {
            tipoOrigen: origen,
          }
        );
        if (status === 200) {
          data.unshift({ nombre: "TODOS", apellido: "", id: "" });
          setChofer(data);
          return data ?? [];
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChofer(false);
    }
  }

  const [provedoresOrigen, setProvedoresOrigen] = useState([]);
  const [loadingProvedoresOrigen, setLoadingProvedoresOrigen] = useState(false);
  async function loadProvedorOrigen() {
    setLoadingProvedoresOrigen(true);
    try {
      if (origen != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/documentacion-transporte/combo/provedor-origen",
          {}
        );
        if (status === 200) {
          data.unshift({ descripcion: "TODOS", id: "" });
          setProvedoresOrigen(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProvedoresOrigen(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const origenes = await loadOrigenes();
        const proveedores = await loadProveedores();
        setFilter({
          ...filter,
          origen: origenes[origenes.length - 1],
          proveedor: proveedores[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues.country]);

  async function loadReportData() {
    try {
      setLoading(true);

      const { status, data } = await sipcoAxiosService.post(
        "/documentacion-transporte/report",
        {
          pais: {
            id: securityFilters.securityValues.country.id,
          },
          tipoOrigen: filter.origen,
          proveedor: filter.proveedor,
          origen: filter.origen, // TODO : revisar de onde vem esse valor de origen
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  async function handleOrigenChange(value: any) {
    try {
      if (value.id === "CH" || value.id === "RH") {
        const choferes = await loadChofer(value);
        setFilter({
          ...filter,
          origen: value,
          chofer: choferes[0],
        });
      } else if (value.id === "T") {
        const transportes = await loadTransporte(filter.proveedor);
        setFilter({
          ...filter,
          origen: value,
          transporte: transportes[0],
        });
      } else if (value.id === "P") {
        const provedoresOrigen = await loadProvedorOrigen();
        setFilter({
          ...filter,
          origen: value,
          provedorOrigen: provedoresOrigen[0],
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function handleProvedorChange(value: any) {
    try {
      const transportes = await loadTransporte(value);
      setFilter({ ...filter, proveedor: value, transporte: transportes[0] });
    } catch (error) {
      console.error(error);
    }
  }

  console.log(filter);

  return (
    <div className="documentacion-transportes">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: false,
          client: false,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>Origen</label>
                <Dropdown
                  loading={loadingOrigen}
                  options={origen}
                  value={filter.origen}
                  optionLabel="descripcionFull"
                  filter
                  onChange={(e) => handleOrigenChange(e.value)}
                />
              </div>
              <div className={"sipco-option"}>
                <label>Proveedor</label>
                <Dropdown
                  loading={loadingProveedor}
                  options={proveedor}
                  filter
                  value={filter.proveedor}
                  optionLabel="descripcion"
                  onChange={(e) => handleProvedorChange(e.value)}
                />
              </div>
              {filter?.origen?.id === "P" && (
                <div className={"sipco-option"}>
                  <label>Provedor Origen</label>
                  <Dropdown
                    loading={loadingProvedoresOrigen}
                    options={provedoresOrigen}
                    value={filter.provedorOrigen}
                    filter
                    optionLabel="descripcion"
                    onChange={(e) =>
                      setFilter({ ...filter, provedorOrigen: e.value })
                    }
                  />
                </div>
              )}
              {filter?.origen &&
                (filter?.origen?.id === "CH" ||
                  filter?.origen?.id === "RH") && (
                  <div className={"sipco-option"}>
                    <label>Chofer</label>
                    <Dropdown
                      loading={loadingChofer}
                      options={chofer}
                      value={filter.chofer}
                      filter
                      optionLabel="nombre"
                      itemTemplate={(option) =>
                        `${option.nombre} ${option.apellido} (${option.id})`
                      }
                      onChange={(e) =>
                        setFilter({ ...filter, chofer: e.value })
                      }
                    />
                  </div>
                )}
              {/* {filter.origen && filter.origen.id === "RH" && (
                <div className={"sipco-option"}>
                  <label>Empleado</label>
                  <Dropdown
                    loading={loadingChofer}
                    options={chofer}
                    value={filter.chofer}
                    filter
                    itemTemplate={(option) =>
                      `${option.nombre} ${option.apellido} (${option.id})`
                    }
                    onChange={(e) => setFilter({ ...filter, chofer: e.value })}
                  />
                </div>
              )} */}
              {filter?.origen?.id === "T" && (
                <div className={"sipco-option"}>
                  <label>Transporte</label>
                  <Dropdown
                    loading={loadingTransporte}
                    options={transporte}
                    value={filter.transporte}
                    filter
                    optionLabel="descripcion"
                    onChange={(e) =>
                      setFilter({ ...filter, transporte: e.value })
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Documentacion de Transportes
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Documentacion de Transportes"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
        style={{ margin: "0" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
          }}
        >
          <Tag
            style={{ backgroundColor: "#fbbf24", color: "black" }}
            value={
              <LanguageProvider id={"10584"} alt="Documentación vencida" />
            }
          />
        </div>

        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          header={"Choferes"}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={"Administrador"} sortable filter />
          <Column field="" header={"Chofer"} sortable filter />
          <Column field="" header={"Tipo Documento"} sortable filter />
          <Column field="" header={"Nro. Documento"} sortable filter />
          <Column field="" header={"Fecha Alta"} sortable filter />
          <Column field="" header={"Fecha Baja"} sortable filter />
        </DataTable>
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          header={"Documentacion"}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="" header={columnsNames[1].label} sortable filter />
          <Column field="" header={columnsNames[2].label} sortable filter />
          <Column field="" header={columnsNames[3].label} sortable filter />
          <Column field="" header={columnsNames[4].label} sortable filter />
          <Column field="" header={columnsNames[5].label} sortable filter />
          <Column field="" header={columnsNames[6].label} sortable filter />
          <Column field="" header={columnsNames[7].label} sortable filter />
          <Column field="" header={columnsNames[8].label} sortable filter />
          <Column field="" header={columnsNames[9].label} sortable filter />
          <Column field="" header={columnsNames[10].label} sortable filter />
          <Column field="" header={columnsNames[11].label} sortable filter />
          <Column field="" header={columnsNames[12].label} sortable filter />
          <Column field="" header={columnsNames[13].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default DocumentacionTransportes;
