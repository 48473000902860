import "./ReimpresionRemidos.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function ReimpresionRemidos() {
  const SCREEN_CODE = "fun0045_reimpresion_remitos";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const dt = useRef(null);
  const [pedidosConfirmadosJd, setPedidosConfirmadosJd] = useState(null);

  const data = convertDateObjects(pedidosConfirmadosJd?.filas);
  const columns = [];
  const filterLabelId = [2940, 4170];
  const filterNames = [];
  const filterColumnsId = [
    924, 3344, 1491, 6781, 13358, 3184, 4170, 25029, 25030, 25031, 25032, 25033,
    25034, 25035, 25036, 25037, 25038, 25039, 25040, 25041, 25042, 25045, 25043,
    25044,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    39, 54, 65, 159, 164, 396, 448, 504, 6781, 740, 743, 924, 1131, 1342, 1491,
    13358, 2139, 2621, 2622, 2941, 3184, 3344, 3625, 4170, 4285, 4655, 4724,
    5044, 10136, 10502, 10902, 11787, 14561, 15862, 16453, 16454, 16455, 16456,
    16457, 16458, 16459, 16460, 367, 1850, 1849, 5592, 18985, 22927, 22928,
    23020, 23097, 24859, 3206, 4145, 2940, 4170, 25029, 25030, 25031, 25032,
    25033, 25034, 25035, 25036, 25037, 25038, 25039, 25040, 25041, 25042, 25043,
    25044, 25045,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  return (
    <div className="pedidos-confirmados-jd">
      <Filter
        onSearch={() => {
          console.log("TODO");
        }}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>Desea reimprimir un lote de remitos</label>
                <InputText />
                <label>Letra</label>
                <InputText />

                <label>Pto.Venta</label>
                <InputText />
                <label>Número</label>
                <InputText />
                <label>Fecha Remito</label>
                <Calendar showIcon value={new Date()} />
                <label>Tipo de Remito</label>
                <MultiSelect />
              </div>

              <div className={"sipco-option"}>
                <label>Nro. Lote</label>
                <InputText />
                <label></label>
                <InputText />

                <label></label>
                <InputText />
                <label></label>
                <InputText />
                <label></label>
                <Calendar showIcon value={new Date()} />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Reimpresión de Remito
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Reimpresión de Remito"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <>
              <Divider />
              <div className="fieldset-footer">
                {/* buttons */}
                <div>
                  <Button
                    label={LanguageProvider({
                      id: "748",
                      alt: "Seleccionar todo",
                    })}
                    text
                  />
                  <Button
                    label={LanguageProvider({
                      id: "749",
                      alt: "Deseleccionar todo",
                    })}
                    text
                  />
                  <Button
                    label={LanguageProvider({
                      id: "11975",
                      alt: "Impresión del listado de remitos",
                    })}
                    text
                  />
                </div>
              </div>
            </>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={"Leyenda"} sortable filter />
          <Column field="1" header={"Leyenda"} sortable filter />
          <Column field="2" header={"Estado"} sortable filter />
          <Column field="3" header={"Concesionario"} sortable filter />
          <Column field="4" header={"Código"} sortable filter />
          <Column field="5" header={"VIN"} sortable filter />
          <Column field="6" header={"Fecha Salida"} sortable filter />
          <Column field="7" header={"Fecha Emisión"} sortable filter />
          <Column field="8" header={"Modelo"} sortable filter />
          <Column field="9" header={"Color"} sortable filter />
          <Column field="11" header={"Letra"} sortable filter />
          <Column field="12" header={"Pedido Cliente"} sortable filter />
          <Column field="13" header={"Pto.Venta"} sortable filter />
          <Column field="14" header={"Concesionario"} sortable filter />
          <Column field="15" header={"Boca de Entrega"} sortable filter />
          <Column field="16" header={"Boca de Entrega"} sortable filter />
          <Column field="17" header={"Razón Social"} sortable filter />
          <Column field="18" header={"Domicilio"} sortable filter />
          <Column field="19" header={"Provincia"} sortable filter />
          <Column field="20" header={"Localidad"} sortable filter />
          <Column field="21" header={"Modelo Largo"} sortable filter />
          <Column field="22" header={"Mercado"} sortable filter />
          <Column field="23" header={"Desc.Pend.Ingresso"} sortable filter />
          <Column field="24" header={"Transferência"} sortable filter />
          <Column field="25" header={"Lote "} sortable filter />
        </DataTable>

        <div className={"sipco-option"}>
          <div className="form-row">
            <label style={{ marginLeft: "10px", marginRight: "10px" }}>
              Total de Registros
            </label>
            <InputText placeholder="0" />
            <label style={{ marginLeft: "10px", marginRight: "10px" }}>
              Registros Seleccionados
            </label>
            <InputText placeholder="0" />
          </div>
        </div>
      </Panel>
    </div>
  );
}
export default ReimpresionRemidos;
