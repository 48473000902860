import { Navigate, Outlet, useLocation } from "react-router-dom";
import React, { createContext, useContext, useEffect, useState } from "react";

import { ProgressSpinner } from "primereact/progressspinner";
import useAuth from "@shared/AuthContext";

export interface FilterContextProps {
  values: FilterData;
  setValues: React.Dispatch<React.SetStateAction<FilterData>>;
}
export interface FilterData {
  securityValues: FilterSecurityOptionsValues;
  subAccountDetails?: any;
  loadingSecurityValues: boolean;
}
export interface FilterSecurityOptionsValues {
  country: any;
  client: any;
  account: any;
  subaccount: any;
}

const FilterContext = createContext<FilterContextProps>(null);

const ProtectedRoutes = () => {
  const { user } = useAuth();
  const location = useLocation();

  const emptyFilterValues: FilterData = {
    securityValues: {
      country: null,
      client: null,
      account: null,
      subaccount: null,
    },
    loadingSecurityValues: false,
  };

  const [values, setValues] = useState<FilterData>(emptyFilterValues);

  useEffect(() => {
    if (values.loadingSecurityValues) {
      document.body.classList.add("loading");
    } else {
      document.body.classList.remove("loading");
    }
    return () => document.body.classList.remove("loading");
  }, [values.loadingSecurityValues]);

  if (user) {
    return (
      <FilterContext.Provider value={{ values, setValues }}>
        {values.loadingSecurityValues && (
          <div
            className="loading-security-filters-overlay"
            onClick={(e) => e.stopPropagation()}
          >
            <ProgressSpinner color="#0c2856" />
          </div>
        )}
        <Outlet />
      </FilterContext.Provider>
    );
  }
  return <Navigate to="auth/login" replace state={{ from: location }} />;
};

export default ProtectedRoutes;

export function useFilterContext() {
  return useContext(FilterContext);
}
