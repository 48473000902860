import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Filter } from "@shared/components/filter/filter";
import { InputTextarea } from "primereact/inputtextarea";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { format } from "date-fns";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const COLORS_STATE = {
  COLOR_BLOQUEO_PREPARACION: "#ff0000",
  COLOR_OPERACION_PENDIENTE_BLOQUEA: "#800040",
};

const columnsIdsColetaHeader = [
  { header: 10793, field: "id" },
  { header: 5686, field: "fechaHojaRuta" },
  { header: 4285, field: "tractor.descripcion" },
  { header: 10902, field: "batea.descripcion" },
  { header: 4655, field: "chofer.nombre" },
  { header: 10893, field: "administradorTractor.descripcion" },
  { header: 3754, field: "estado" },
  { header: 10767, field: "calle" },
  { header: 10767, field: "columna" },
];
const columnsIdsColetaDetails = [
  { header: 17088, field: "vin.id" },
  { header: 1316, field: "remito.puntoDeVenta" },
  { header: 326, field: "remito.id" },
  { header: 740, field: "subcuenta" },
  { header: 10903, field: "subcuentaOrigen.descripcion" },
  { header: 2785, field: "tipoRegistro.id" },
  { header: 2941, field: "concesionarioP.descripcion" },
  { header: 10904, field: "direccionEntregaP.descripcion" },
  { header: 3754, field: "estado.descripcion" },
  { header: 10905, field: "jumelage" },
];
const columnsIdsCtrcHeader = [
  { header: 1316, field: "serie" },
  { header: 10794, field: "id" }, // Nro. CTRC
  { header: 1492, field: "fechaEmision" },
  { header: 10883, field: "cfop" },
  { header: 10884, field: "ciudadOrigen.descripcion" },
  { header: 10885, field: "ciudadDestino.descripcion" },
  { header: 10886, field: "concesionarioRe.descripcion" },
  { header: 10887, field: "direccionEntregaRe.direccion" },
  { header: 10888, field: "concesionarioDe.descripcion" },
  { header: 10889, field: "direccionEntregaDe.descripcion" },
  { header: 10890, field: "concesionarioCo.descripcion" },
  { header: 10891, field: "direccionEntregaCo.direccion" },
  { header: 10892, field: "fleteaCargo" },
  { header: 10893, field: "proveedor.descripcion" },
  { header: 9215, field: "tipoImpuesto" },
  { header: 2898, field: "peso" },
  { header: 10894, field: "importeSeguro" },
  { header: 10895, field: "porcentajeAlicuota" },
  { header: 10896, field: "importeImpuesto" },
  { header: 10897, field: "importeBaseCalculo" },
  { header: 2724, field: "importeTotal" },
  { header: 3754, field: "estado" },
  { header: 9306, field: "observaciones" },
  { header: 11047, field: "fechaDespachoEst" },
  { header: 10898, field: "fechaDespacho" },
  { header: 10899, field: "funcion" },
  { header: 10900, field: "@notaPedidoFlete.id" }, //chave não encontrada
];
const columnsIdsCtrcDetails = [
  { header: 1316, field: "serie" },
  { header: 10794, field: "id" }, //Nro. CTRC
  { header: 17088, field: "vin.id" },
  { header: 1316, field: "remito.puntoDeVenta" },
  { header: 32, field: "valor" },
  { header: 10776, field: "naturalezaFiscal.id" },
  { header: 10793, field: "hojaRuta.id" },
  { header: 10909, field: "centroCosto.descripcion" },
  { header: 10910, field: "ordenInterna" },
  { header: 10772, field: "cuentaGasto.descripcion" },
  { header: 10899, field: "funcion" },
  { header: 396, field: "vin.marca.descripcion" },
  { header: 396, field: "vin.marca.id" },
  { header: 3754, field: "cabeceraCtrc.estado" },
];

export function DespachoMercadoNacionalBrForm(props) {
  const { SCREEN_CODE } = props;
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const toast = useRef<Toast>(null);

  const [loadingHeader, setLoadingHeader] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);

  const [modal, setModal] = useState(false);
  const [mensaje, setMensajes] = useState("");
  const [isColetaOrCtrc, setIsColetaOrCtrc] = useState("CTRC");
  const [dataTableHeader, setDataTableHeader] = useState(null);
  const [dataTableDetails, setDataTableDetails] = useState(null);
  const [selectedRowHeader, setSelectedRowHeader] = useState();
  const [selectedRowDetails, setSelectedRowDetails] = useState([]);
  const [selectedsChips, setSelectedsChips] = useState("");

  const [dataDespachoReal, setDataDespachoReal] = useState(new Date());

  const [filter, setFilter] = useState({
    ctrc: null,
    coleta: null,
    checkedTeoricDate: false,
    teoricDate: null,
  });

  function filterColumnsIds(ids) {
    const result = [];
    for (const element of ids) {
      result.push({
        id: element.header,
        field: element.field,
        label: LanguageProvider({
          id: element.header,
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  function checkSecurityFilters() {
    if (
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id
    ) {
      return true;
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "País, Clientes, Cuentas e subCuentas Obrigatorio",
        life: 3000,
      });
      return false;
    }
  }

  const [CTRCs, setCTRCs] = useState([]);
  const [loadingCTRCs, setLoadingCTRCs] = useState(false);
  async function loadCTRCs() {
    setLoadingCTRCs(true);
    try {
      if (
        securityFilters.securityValues?.country &&
        securityFilters.securityValues?.client &&
        securityFilters.securityValues?.account &&
        securityFilters.securityValues?.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/despacho-mercado-nacional/ctrc",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              descripcionCliente:
                securityFilters.securityValues.client.descripcion,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: "TODOS" });
          setCTRCs(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCTRCs(false);
    }
  }
  const [coletas, setColetas] = useState([]);
  const [loadingColetas, setLoadingColetas] = useState(false);
  async function loadColetas() {
    setLoadingColetas(true);
    try {
      if (
        securityFilters.securityValues?.country &&
        securityFilters.securityValues?.client &&
        securityFilters.securityValues?.account &&
        securityFilters.securityValues?.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/despacho-mercado-nacional/coleta",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              descripcionCliente:
                securityFilters.securityValues.client.descripcion,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: "TODOS" });
          setColetas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColetas(false);
    }
  }

  async function loadDataTableCtrcHeader(ctrc = null) {
    setLoadingHeader(true);
    const check = checkSecurityFilters();

    if (!check) {
      setLoadingHeader(false);
      return;
    }
    try {
      let requestParams = {
        codPais: securityFilters.securityValues.country.id,
        codCliente: securityFilters.securityValues.client.id,
        codCuenta: securityFilters.securityValues.account.id,
        codSubcuenta: securityFilters.securityValues.subaccount.id,
        cabeceraCtrc: null,
        fechaDespacho: null,
      };
      if (filter.ctrc.id.toUpperCase() !== "TODOS") {
        requestParams.cabeceraCtrc = { id: filter.ctrc.id };
      }
      if (filter.checkedTeoricDate) {
        requestParams.fechaDespacho = {
          date: filter.teoricDate.toISOString(),
        };
      }
      const { status, data } = await sipcoAxiosService.post(
        "/despacho-mercado-nacional/cabecera-ctrc",
        requestParams
      );
      if (status === 200) {
        setDataTableHeader(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHeader(false);
    }
  }
  async function loadDataTableColetaHeader() {
    setLoadingHeader(true);
    const check = checkSecurityFilters();

    if (!check) {
      setLoadingHeader(false);
      return;
    }
    try {
      let requestParams = {
        codPais: securityFilters.securityValues.country.id,
        codCliente: securityFilters.securityValues.client.id,
        descripcionCliente: securityFilters.securityValues.client.descripcion,
        codCuenta: securityFilters.securityValues.account.id,
        codSubcuenta: securityFilters.securityValues.subaccount.id,
        codHojaRuta: null,
      };
      if (filter.coleta.id.toUpperCase() !== "TODOS") {
        requestParams.codHojaRuta = filter.coleta.id;
      }
      const { status, data } = await sipcoAxiosService.post(
        "/despacho-mercado-nacional/hoja-ruta",
        requestParams
      );
      if (status === 200) {
        setDataTableHeader(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHeader(false);
    }
  }
  async function loadDataTableCtrcDetails(params) {
    setLoadingDetails(true);
    try {
      const check = checkSecurityFilters();

      if (!check) {
        return;
      }
      if (params) {
        const { status, data } = await sipcoAxiosService.post(
          "/despacho-mercado-nacional/detalle-ctrc",
          {
            codPais: securityFilters.securityValues.country.id,
            codCuenta: securityFilters.securityValues.account.id,
            codCliente: securityFilters.securityValues.client.id,
            descripcionCliente:
              securityFilters.securityValues.client.descripcion,
            codSubcuenta: securityFilters.securityValues.subaccount.id,
            cabeceraCtrc: params,
          }
        );
        if (status === 200) {
          console.log("responsedetais", data);
          setDataTableDetails(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDetails(false);
    }
  }
  async function loadDataTableColetaDetails(params) {
    setLoadingDetails(true);
    try {
      const check = checkSecurityFilters();

      if (!check) {
        return;
      }
      if (params) {
        const { status, data } = await sipcoAxiosService.post(
          "/despacho-mercado-nacional/linea-hr",
          {
            codPais: securityFilters.securityValues.country.id,
            codCuenta: securityFilters.securityValues.account.id,
            codCliente: securityFilters.securityValues.client.id,
            descripcionCliente:
              securityFilters.securityValues.client.descripcion,
            codSubcuenta: securityFilters.securityValues.subaccount.id,
            codHojaRuta: params.id,
          }
        );
        if (status === 200) {
          console.log("responsedetais", data);
          setDataTableDetails(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDetails(false);
    }
  }
  async function loadReportData() {
    setSelectedRowHeader(null);
    setSelectedRowDetails([]);
    if (isColetaOrCtrc === "CTRC") {
      loadDataTableCtrcHeader();
    } else {
      loadDataTableColetaHeader();
    }
  }
  const dateColumnTemplate = (data, key) => {
    const filter = format(new Date(data[key].date), "dd/MM/yyyy HH:mm:ss");
    return <td>{filter}</td>;
  };
  function getDataTableDetails(params) {
    setSelectedRowHeader(params);
    setSelectedRowDetails([]);
    if (params) {
      if (isColetaOrCtrc === "CTRC") {
        loadDataTableCtrcDetails(params);
      } else {
        loadDataTableColetaDetails(params);
      }
    }
  }
  const checkDetalleItemSelected = (paramsArray) => {
    let hasPendingOperations = false;

    // Percorre o array de parâmetros
    paramsArray.forEach((params) => {
      if (params.vin.tieneOperacionPendientesBloquea) {
        hasPendingOperations = true;
      }
    });

    // Se houver operações pendentes bloqueadoras em algum dos itens, exibe a mensagem de erro
    if (hasPendingOperations) {
      toast.current.show({
        summary: "Error",
        severity: "error",
        life: 3000,
        detail: (
          <LanguageProvider
            id={"15287"}
            alt="Apresenta operações pendentes configuradas como Bloqueadores"
          />
        ),
      });
      setSelectedRowDetails(null);
    } else {
      const caunt = paramsArray.length;
      setSelectedsChips(caunt.toString());
    }
  };
  function selectItems(params) {
    setSelectedRowDetails(params);
    if (params.length > 0) {
      checkDetalleItemSelected(params);
    }
  }
  /**
   * Determina a cor do texto para uma linha da tabela com base em condições específicas do objeto rowData.
   *
   * @param rowData - O objeto de dados da linha atual da tabela.
   * @returns A cor a ser aplicada ao texto, dependendo das condições do rowData.
   */
  const getRowColor = (rowData) => {
    let color = "inherit"; // Cor padrão

    // Verifica se a data de bloqueio de preparação está presente e define a cor correspondente
    if (
      rowData.vin &&
      rowData.vin.fechaBloqueoPreparacion &&
      rowData.vin.fechaBloqueoPreparacion.date
    ) {
      color = COLORS_STATE.COLOR_BLOQUEO_PREPARACION;
    }
    // Verifica se há operações pendentes que bloqueiam e define a cor correspondente
    else if (rowData.vin && rowData.vin.tieneOperacionPendientesBloquea) {
      color = COLORS_STATE.COLOR_OPERACION_PENDIENTE_BLOQUEA;
    }

    return color;
  };
  /**
   * Acessa um valor aninhado em um objeto com base em um caminho fornecido.
   *
   * @param obj - O objeto do qual o valor será extraído.
   * @param path - O caminho para o valor aninhado, especificado como uma string com chaves separadas por ponto (e.g., 'vin.id').
   * @returns O valor localizado no caminho especificado ou undefined se o caminho não existir.
   */
  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((value, key) => value?.[key], obj);
  };
  const checkJumelage = (item, path) => {
    const jumelage = Number(
      path.split(".").reduce((value, key) => value?.[key], item)
    );

    switch (jumelage) {
      case 0:
        return (
          <LanguageProvider id={"11143"} alt="Vin não utilizado em Jumelage" />
        );
      case 1:
        return (
          <LanguageProvider id={"11142"} alt="Vin utilizado em Jumelage" />
        );
      case 2:
        return (
          <LanguageProvider id={"11144"} alt="Vin eliminado do Jumelage" />
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    async function initialize() {
      try {
        if (isColetaOrCtrc === "CTRC") {
          const CTRCs = await loadCTRCs();
          setFilter({
            ...filter,
            ctrc: CTRCs[0],
          });
        } else {
          const coletas = await loadColetas();
          setFilter({
            ...filter,
            coleta: coletas[0],
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues, isColetaOrCtrc]);

  const columnsNamesHeaderCtrc = filterColumnsIds(columnsIdsCtrcHeader);
  const columnsNamesDetailsCtrc = filterColumnsIds(columnsIdsCtrcDetails);
  const columnsNamesHeaderColeta = filterColumnsIds(columnsIdsColetaHeader);
  const columnsNamesDetailsColeta = filterColumnsIds(columnsIdsColetaDetails);
  let columnsNamesHeader = columnsNamesHeaderCtrc;
  let columnsNamesDetails = columnsNamesDetailsCtrc;
  if (isColetaOrCtrc !== "CTRC") {
    columnsNamesHeader = columnsNamesHeaderColeta;
    columnsNamesDetails = columnsNamesDetailsColeta;
  }

  return (
    <div className="despacho-mercado-nacional">
      <Toast ref={toast} position="bottom-left" />
      <div className="card flex justify-content-center">
        <Dialog
          visible={modal}
          modal
          header={
            <span className="font-bold white-space-nowrap">Mensajes</span>
          }
          footer={
            <Button
              label="Ok"
              icon="pi pi-check"
              onClick={() => setModal(false)}
              autoFocus
            />
          }
          style={{ width: "50rem" }}
          onHide={() => {
            if (!modal) return;
            setModal(false);
          }}
        >
          <p className="m-0" style={{ padding: "1rem" }}>
            {mensaje}
          </p>
        </Dialog>
      </div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              {isColetaOrCtrc === "CTRC" ? (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"10794"} alt="Nro. CTRC" />
                  </label>
                  <Dropdown
                    value={filter.ctrc}
                    options={CTRCs}
                    onChange={(e) => setFilter({ ...filter, ctrc: e.value })}
                    loading={loadingCTRCs}
                    optionLabel="id"
                    filter
                  />
                </div>
              ) : (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"10758"} alt="Nro. Coleta" />
                  </label>
                  <Dropdown
                    value={filter.coleta}
                    options={coletas}
                    onChange={(e) => setFilter({ ...filter, coleta: e.value })}
                    loading={loadingColetas}
                    optionLabel="id"
                    filter
                  />
                </div>
              )}
              <div className={"RadioButtonFilter"}>
                <label>
                  <LanguageProvider
                    id={"5618"}
                    alt="Data de Despacho Teórica"
                  />
                </label>
                <Checkbox
                  checked={filter.checkedTeoricDate}
                  onChange={(e) => {
                    if (e.checked) {
                      setFilter({
                        ...filter,
                        checkedTeoricDate: e.checked,
                        teoricDate: new Date(),
                      });
                    } else {
                      setFilter({
                        ...filter,
                        checkedTeoricDate: e.checked,
                        teoricDate: null,
                      });
                    }
                  }}
                  disabled={isColetaOrCtrc !== "CTRC"}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="191" alt="Data:" />
                </label>
                <Calendar
                  value={filter.teoricDate}
                  onChange={(e) =>
                    setFilter({ ...filter, teoricDate: e.value })
                  }
                  showButtonBar
                  showIcon
                  disabled={!filter.checkedTeoricDate}
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className={"RadioButtonFilter"}>
                <label>
                  <LanguageProvider id={"10880"} alt="Despacho por Coleta" />
                </label>
                <RadioButton
                  value="COLETA"
                  onChange={(e) => setIsColetaOrCtrc(e.value)}
                  checked={isColetaOrCtrc === "COLETA"}
                />
              </div>
              <div className={"RadioButtonFilter"}>
                <label>
                  <LanguageProvider id={"10879"} alt="Despacho  por CTRC" />
                </label>
                <RadioButton
                  value="CTRC"
                  onChange={(e) => setIsColetaOrCtrc(e.value)}
                  checked={isColetaOrCtrc === "CTRC"}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        header={<LanguageProvider id={"287"} alt={"Despacho Mercado Local"} />}
      >
        {/* dataTable Header */}
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          selectionMode="single"
          selection={selectedRowHeader}
          value={dataTableHeader}
          onSelectionChange={(e) => getDataTableDetails(e.value)}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingHeader}
          filterDisplay="menu"
        >
          {columnsNamesHeader.map((column, index) => {
            if (
              column.field === "fechaEmision" ||
              column.field === "fechaDespachoEst" ||
              column.field === "fechaDespacho" ||
              column.field === "fechaHojaRuta"
            ) {
              return (
                <Column
                  key={index}
                  body={(e) => dateColumnTemplate(e, column.field)}
                  field={column.field}
                  header={column.label}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <Column
                  key={index}
                  field={column.field}
                  header={column.label}
                  sortable
                  filter
                />
              );
            }
          })}
        </DataTable>
        <Divider />
        <div className="form">
          <div className="form-row">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"5619"} alt="Data de Despacho Real" />
              </label>
              <Calendar
                value={dataDespachoReal}
                onChange={(e) => setDataDespachoReal(e.value)}
                showIcon
                showButtonBar
                dateFormat="dd/mm/yy"
                showTime
              />
            </div>
            <div className={"RadioButtonFilter"}>
              <label>
                <LanguageProvider
                  id={"11328"}
                  alt="Genera Prestación Compra/Venta"
                />
              </label>
              <Checkbox checked={false} />
            </div>
          </div>
          <div className="form-row">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"9306"} alt="Observações" />
              </label>
              <InputTextarea />
            </div>
          </div>
        </div>
        <Divider />
        {/* dataTable Details */}
        <DataTable
          selectionMode="multiple"
          selection={selectedRowDetails}
          onSelectionChange={(e) => selectItems(e.value)}
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={dataTableDetails}
          // value={details}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loadingDetails}
        >
          {columnsNamesDetails.map((colum, index) => {
            if (colum.field === "jumelage") {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  header={colum.label}
                  sortable
                  body={(rowData) => (
                    <span style={{ color: getRowColor(rowData) }}>
                      {checkJumelage(rowData, colum.field)}
                    </span>
                  )}
                />
              );
            } else {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  header={colum.label}
                  sortable
                  body={(rowData) => (
                    <span style={{ color: getRowColor(rowData) }}>
                      {getNestedValue(rowData, colum.field)}
                    </span>
                  )}
                />
              );
            }
          })}
        </DataTable>
        <Divider />
        <div className="form">
          <div className="form-row">
            <div className="chips">
              <div className={"sipco-chip"}>
                <label>
                  <LanguageProvider id={"10270"} alt="Total Selecionados" />
                </label>
                <Chip label={selectedsChips} />
              </div>
            </div>
            <div className="tags">
              <Tag
                value={
                  <LanguageProvider
                    id={"18601"}
                    alt={"Bloqueio por preparação"}
                  />
                }
                style={{ background: "#ff0000" }}
              />
              <Tag
                value={
                  <LanguageProvider
                    id={"22636"}
                    alt={"Operación pendiente bloqueante"}
                  />
                }
                style={{ background: "#800040" }}
              />
            </div>
          </div>
        </div>
        <Divider />
        {isColetaOrCtrc === "CTRC" ? (
          <div>
            <Button
              label={LanguageProvider({
                id: "34",
                alt: "Processar ou guardar Mudanças",
              })}
              text
            />
          </div>
        ) : (
          <div>
            <Button
              label={LanguageProvider({
                id: "34",
                alt: "Processar ou guardar Mudanças",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "748",
                alt: "Selecionar tudo",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "749",
                alt: "Deselecionar tudo",
              })}
              text
            />
          </div>
        )}
      </Panel>
    </div>
  );
}
export default DespachoMercadoNacionalBrForm;
