import React from "react";
import { Skeleton } from "primereact/skeleton";
import { LanguageProvider } from "@shared/components/language-provider";
import { Card } from "primereact/card";

const SkeletonBody: React.FC = () => {
  return (
    <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11554"} alt="Calle Inicio" />
                </label>
                <Skeleton width="100%" height="3rem"/>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11557"} alt="Calle Fin" />
                </label>
                <Skeleton width="100%" height="3rem"/>
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11555"} alt="Columna Inicio" />
                </label>
                <Skeleton width="100%" height="3rem"/>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11558"} alt="Columna Fin" />
                </label>
                <Skeleton width="100%" height="3rem"/>
              </div>
            </div>
          </div>
  );
};

export default SkeletonBody;
