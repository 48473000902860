import "./LiberacionDespachos.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function LiberacionDespachos() {
  const SCREEN_CODE = "con0044_liberacion_despachos";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const dt = useRef(null);

  const filterLabelId = [167, 468, 10202];
  const filterNames = [];
  const filterColumnsId = [3451];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }
  const mensagens = [];
  const ids = [65, 743, 5044, 54, 448, 467, 468, 10202, 3451, 39, 1131];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    incluyeRemitosTranlado: false,
  });

  const [reportData, setReportData] = useState(null);
  const [loadingReportData, setLoadingReportData] = useState(false);
  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        rangeFecha: [filter?.fechaDesde, filter?.fechaHasta],
        bool: filter?.incluyeRemitosTranlado,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/liberacion-despachos/report",
        filters
      );
      if (status === 200) {
        setReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  const formattedReportData = convertDateObjects(reportData?.filas);

  return (
    <div className="liberacion-despachos">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <Calendar
                  value={filter.fechaDesde}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaDesde: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <Calendar
                  value={filter.fechaHasta}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaHasta: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
              <div className={"RadioButtonFilter"}>
                <label>
                  <LanguageProvider
                    id={"10202"}
                    alt="Incluye los Remitos de traslado"
                  />
                </label>
                <Checkbox
                  checked={filter.incluyeRemitosTranlado}
                  onChange={(e) =>
                    setFilter({ ...filter, incluyeRemitosTranlado: e.checked })
                  }
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="3451" alt="Liberados/Despachados" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={formattedReportData}
                  columns={columnsNames}
                  screenName={"Liberados/Despachados"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={formattedReportData}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReportData}
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default LiberacionDespachos;
