import React, {useEffect, useState} from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import useFilterColumnsIds from "../hooks/useFilterColumnsIds";

const DanosDelVin: React.FC<{ data: any }> = ({ data }) => {
  const [columNamesManual, setColumNamesManual] = useState();
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setReporte(data);
  }, [data]);
  const ColumnsId1 = [
    10540, 10541, 2490, 16243, 16575, 16505, 3344, 18096, 18125, 18097, 18099,
    18098,
  ];

  const columNames1 = useFilterColumnsIds(ColumnsId1);

  return (
    <Card>
      <DataTable
        size={"small"}
        showGridlines
        stripedRows
        value={reporte}
        paginator
        scrollHeight="flex"
        filterDisplay="menu"
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        style={{ maxWidth: "100%" }}
        loading={loading}
      >
        {columNames1.map((colum, index) => (
          <Column
            key={index}
            field={colum.id}
            header={colum.label}
            sortable
            filter
          />
        ))}
      </DataTable>
    </Card>
  );
};
export default DanosDelVin;
