import "./LlegadaBuqueCamion.scss";
import React from "react";

import usePageViews from "../../../hooks/usePageViews";
import useAuth from "@shared/AuthContext";
import LlegadaBuqueCamionBrForm from "./components/LlegadaBuqueCamionBrForm";
import LlegadaBuqueCamionForm from "./components/LlegadaBuqueCamionForm";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function LlegadaBuqueCamion() {
  const SCREEN_CODE = "fun0058_llegada_buque_camion";
  ScreenCodeValue(SCREEN_CODE);
  const { user } = useAuth();
  usePageViews();



  function CountryForm() {
    if (user.pais.codPais === 2) {
      return <LlegadaBuqueCamionBrForm SCREEN_CODE={SCREEN_CODE} />;
    } else {
      return <LlegadaBuqueCamionForm SCREEN_CODE={SCREEN_CODE} />;
    }
  }

  return (
    <div className="llegada-buque-camion">
      <CountryForm />
    </div>
  );
}
export default LlegadaBuqueCamion;
