import "./DespachoTerceros.scss";
import "primeicons/primeicons.css";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function DespachoTerceros() {
  const SCREEN_CODE = "fun0100_despacho_terceros";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [disableInputs, setDisableInputs] = useState(true);
  const [selectedimpreso, setSelectedImpreso] = useState({
    name: "TODOS",
    code: "TODOS",
  });

  const impreso = [
    { name: "TODOS", code: "TODOS" },
    { name: "SI", code: "0" },
    { name: "NO", code: "1" },
  ];

  const table1 = [
    849, 850, 932, 9387, 1284, 1491, 396, 2139, 2140, 2496, 13335, 16923, 2941,
    4216, 3591, 8299, 159, 3186, 440,
  ];

  const columNames = filterColumnsIds(table1);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  return (
    <div className="despacho-terceros">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          client: true,
          country: true,
          account: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Fieldset legend={<LanguageProvider id={"6697"} alt="Listado" />}>
        <div className="filter-options">
          <div className="sipco-options-line">
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"662"} alt="Ingrese el VIN" />
              </label>
              <AutoComplete dropdown />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"4567"} alt="Nuevo Cliente" />
              </label>
              <AutoComplete dropdown />
            </div>
          </div>
        </div>
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {columNames.map((colum, index) => (
            <Column
              key={index}
              field={colum.id}
              header={colum.label}
              sortable
              filter
            />
          ))}
        </DataTable>
        <div className="filter-options-column" style={{ maxWidth: "75%" }}>
          <div className="sipco-options-column">
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"1342"} alt="Fecha Despacho" />
              </label>
              <Calendar showButtonBar showIcon showTime />
            </div>
          </div>
          <div className="sipco-options-column">
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"1332"} alt="Instalador Radios" />
              </label>
              <AutoComplete dropdown />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"16844"} alt="Proveedor daños" />
              </label>
              <AutoComplete dropdown />
            </div>
          </div>
          <div className="sipco-options-column">
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"16947"} alt="Prov. Logo" />
              </label>
              <AutoComplete dropdown />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"16948"} alt="Prov. Sticker" />
              </label>
              <AutoComplete dropdown />
            </div>
          </div>
        </div>
        <div className="filter-options">
          <div className="sipco-options-line">
            <div className="RadioButtonFilter">
              <label>
                <LanguageProvider id={"25074"} alt="Pasaje a Guarda" />
              </label>
              <Checkbox checked={false} />
            </div>
          </div>
        </div>
        <div className="buttons">
          {/* buttons */}
          <Button
            label={LanguageProvider({
              id: "34",
              alt: "Procesar o guardar cambios",
            })}
            text
          />
        </div>
      </Fieldset>
    </div>
  );
}
export default DespachoTerceros;
