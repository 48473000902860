import "./TiemposCentroPerdidos.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";

export function TiemposCentroPerdidos() {
  const SCREEN_CODE = "con0096_transit_time_pedidos_playa";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [13859, 1491, 22137, 382, 16842, 266, 10904];
  const filterNames = [];
  const filterColumnsId = [
    22137, 10871, 2941, 10868, 10904, 23369, 23370, 11372, 4976, 1669, 1491,
    11158, 3774, 10898, 16925, 10577, 17263, 22209, 18727, 17203, 2830, 22406,
    13706, 13706,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];

  const ids = [
    0, 901, 65, 54, 1791, 13859, 22137, 382, 16842, 266, 530, 748, 749, 5583,
    5044, 11372, 22137, 3774, 1491, 10871, 2941, 10868, 10904, 11158, 10898,
    10577, 17203, 4976, 22209, 18727, 1669, 653, 2830, 22406, 23369, 23370,
    15624, 3184, 3329, 17263, 13706, 16925,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }
  console.log(mensagens);
  return (
    <div className="tiempos-centro-perdidos">
      <Filter
        onSearch={() => console.log("TODO")}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>Fecha</label>
                <Calendar value={new Date()} showIcon showTime />

                <div className="form-row">
                  <Checkbox checked />
                  <label>Taller</label>
                </div>

                <Calendar value={new Date()} showIcon />
              </div>
              <div className={"sipco-option"}>
                <label>VIN</label>
                <AutoComplete dropdown />
                <label>Concesionarios</label>
                <AutoComplete dropdown />
              </div>

              <div className={"sipco-option"}>
                <label>Nro. Asignación</label>
                <InputText />
                <label>Dir. Entrega</label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <div className="form-row">
                  <Checkbox checked />
                  <label>Despacho</label>
                </div>

                <Calendar value={new Date()} showIcon />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        header="Tiempos de Centro Perdidos"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Tiempos de Centro Perdidos
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Tiempos de Centro Perdidos"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          {/*<Column field="" header={columnsNames[7].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[8].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[9].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[10].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[11].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[12].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[13].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[14].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[15].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[16].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[17].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[18].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[19].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[20].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[21].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[22].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[23].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[24].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[25].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[26].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[27].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[28].label} sortable filter/>*/}
          {/*<Column field="" header={columnsNames[29].label} sortable filter/>*/}
        </DataTable>
      </Panel>
    </div>
  );
}
export default TiemposCentroPerdidos;
