import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function LiberacionAduanaForm(props) {
  const { SCREEN_CODE } = props;
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const toast = useRef<Toast>(null);

  const [loading, setLoading] = useState(false);

  const columnsIds1 = [
    1491, 396, 10746, 2140, 16542, 754, 1058, 5270, 1256, 13335,
  ];

  const columnsNames = filterColumnsIds(columnsIds1);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  return (
    <div className="liberacion-aduana">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"921"} alt="Ubicaciones" />}
        >
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"990"} alt="Calle" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"991"} alt="Columna" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"992"} alt="Nivel" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1058"} alt="Despachante" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"489"} alt="Marcas" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"408"} alt="Modelos" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"376"} alt="Transporte" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2131"} alt="Fecha" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Categoria" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset legend={<LanguageProvider id={"16226"} alt="Listado" />}>
        <div className="fieldset-content">
          <DataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={reporte}
                  screenName={"RecepcionPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={listRemitos}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsNames.map((colum, index) => (
              <Column key={index} header={colum.label} sortable filter />
            ))}
          </DataTable>
          <div className="chips">
            <div className={"sipco-chip"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Total de Registros" />
              </label>
              <Chip label="0" />
            </div>
            <div className={"sipco-chip"}>
              <label>
                <LanguageProvider id={"864"} alt="Registros Seleccionados" />
              </label>
              <Chip label="0" />
            </div>
          </div>
          <Divider />
          <Accordion>
            <AccordionTab
              header={
                <LanguageProvider
                  id={"883"}
                  alt="Ingreso de Datos para la Transferencia"
                />
              }
            >
              <div className="filter-options">
                <div className="sipco-options-line">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4036"} alt="Fecha de Liberación" />
                    </label>
                    <Calendar showButtonBar showIcon showTime />
                  </div>
                  <div className="divide">
                    <div className={"sipco-option-divide"}>
                      <label>
                        <LanguageProvider
                          id={"1105"}
                          alt="Unidades por batea"
                        />
                      </label>
                      <InputText />
                    </div>
                    <div className={"sipco-option-divide"}>
                      <InputText />
                    </div>
                  </div>
                </div>
                <div className="sipco-options-line">
                  <div className={"RadioButtonFilter"}>
                    <label>
                      <LanguageProvider
                        id={"1059"}
                        alt="Informa al Transportista?"
                      />
                    </label>
                    <Checkbox checked={false} />
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>

        <Divider />
        <div className="fieldset-footer">
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "42",
                alt: "Excel",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "34",
                alt: "Procesar o guardar cambios",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "749",
                alt: "Deseleccionar todo",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "748",
                alt: "Seleccionar todo",
              })}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default LiberacionAduanaForm;
