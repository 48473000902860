import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const TODOS_OBJ = {
  label: "TODOS",
  id: "TODOS",
  descripcion: "TODOS",
};

export function ConsultaOfertasServicio() {
  const SCREEN_CODE = "con0066_consulta_ofertas_de_servicio";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterLabelId = [3344, 54, 16563, 6256, 2621, 2622];
  const filterNames = [];
  const filterColumnsId = [
    16563, 54, 54, 6256, 5683, 16817, 9990, 16197, 14791, 16818, 3206, 1669,
    17088, 4724, 16566, 5496, 5490, 7235, 16567, 16590, 10909, 11237, 10683,
    16076, 396, 2139, 2521, 16568, 16591, 924, 14083, 1545, 13706, 5271,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    924, 14083, 1545, 13706, 5271, 16563, 54, 6256, 5683, 16817, 9990, 16197,
    14791, 16818, 3206, 1669, 17088, 4724, 16566, 5496, 5490, 7235, 16567,
    16590, 10909, 11237, 10683, 16076, 396, 2139, 2521, 16568, 16591, 3344,
    16563, 6256, 2621, 2622, 448, 743, 65, 5583, 5044, 54, 1791, 2521, 467, 468,
    1182, 31, 11659, 16814,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    estado: TODOS_OBJ,
    estadoDescripcion: TODOS_OBJ,
    codClientes: TODOS_OBJ,
    nroOferta: null,
    fechaCotización: false,
    fechaInicio: new Date(),
    fechaFinal: new Date(),
  });

  const [loading, setLoading] = useState(false);

  const [estado, setEstado] = useState([]);
  const [codClientes, setCodClientes] = useState([]);
  const [nroOferta, setNroOferta] = useState([]);

  async function searchEstado(event: any) {
    await loadEstado(event.query);
  }
  async function loadEstado(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/ofertas-servicios/combo/estado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const estado = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          estado.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const estadoFiltrados = estado.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setEstado(estadoFiltrados);
            return estadoFiltrados;
          } else {
            setEstado(estado);
            return estado;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function searchCliente(event: any) {
    await loadCliente(event.query);
  }
  async function loadCliente(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/ofertas-servicios/combo/clientes",
          {
            params: {},
          }
        );

        if (status === 200) {
          const cliente = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          cliente.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const clienteFiltrados = cliente.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setCodClientes(clienteFiltrados);
            return clienteFiltrados;
          } else {
            setCodClientes(cliente);
            return cliente;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,

        estado: filter?.estado?.id || null,
        estadoDescripcion: filter?.estadoDescripcion?.descripcion || null,
        codClientes: filter?.codClientes?.id || null,
        nroOferta: filter?.nroOferta || null,
        bool: filter.fechaCotización ? true : false,
        rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ofertas-servicios/report",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[0].id} alt="Estado" />
              </label>
              <AutoComplete
                value={filter.estado}
                suggestions={estado}
                completeMethod={searchEstado}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    estado: e.value,
                    estadoDescripcion: e.value,
                  })
                }
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[1].id} alt="Cliente" />
              </label>
              <AutoComplete
                value={filter.codClientes}
                suggestions={codClientes}
                completeMethod={searchCliente}
                onChange={(e) => setFilter({ ...filter, codClientes: e.value })}
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[2].id}
                  alt="Nro. Oferta Servicio"
                />
              </label>
              <AutoComplete
                value={filter.nroOferta}
                suggestions={nroOferta}
                onChange={(e) => setFilter({ ...filter, nroOferta: e.value })}
                field="label"
              />
            </div>

            <div className="RadioButtonFilter">
              <Checkbox
                checked={filter.fechaCotización}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    fechaCotización: !filter.fechaCotización,
                  });
                  setReporte([]);
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={filterNames[3].label} />
              </label>
            </div>

            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[4].id} alt="Desde" />
              </label>
              <Calendar
                value={filter.fechaInicio}
                onChange={(e) => {
                  setFilter({ ...filter, fechaInicio: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
                disabled={!filter.fechaCotización}
              />
            </div>
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[5].id} alt="Hasta" />
              </label>
              <Calendar
                value={filter.fechaFinal}
                onChange={(e) => {
                  setFilter({ ...filter, fechaFinal: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
                disabled={!filter.fechaCotización}
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[51].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta Ofertas de Servicios"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loading}
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          <Column field="23" header={columnsNames[23].label} sortable filter />
          <Column field="24" header={columnsNames[24].label} sortable filter />
          <Column field="25" header={columnsNames[25].label} sortable filter />
          <Column field="26" header={columnsNames[26].label} sortable filter />
          <Column field="27" header={columnsNames[27].label} sortable filter />
          <Column field="28" header={columnsNames[28].label} sortable filter />
          <Column field="29" header={columnsNames[29].label} sortable filter />
          <Column field="30" header={columnsNames[30].label} sortable filter />
          <Column field="31" header={columnsNames[31].label} sortable filter />
          <Column field="32" header={columnsNames[32].label} sortable filter />
          <Column field="33" header={columnsNames[33].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ConsultaOfertasServicio;
