import "./filter-test.scss";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Filter } from "@shared/components/filter/filter";
import { Panel } from "primereact/panel";
import React from "react";

export function FilterTest() {
  const demoData = [
    {
      hojaRuta: "92836234",
      fechaSalida: "21-03-2024 10:00:20",
      transportista: "GEFCO",
      chofer: "Pedro",
      tractor: "AJP-1923",
      bateria: "02",
      playa: "PALOMAR",
    },
    {
      hojaRuta: "92836235",
      fechaSalida: "21-03-2024 10:05:15",
      transportista: "DHL",
      chofer: "João",
      tractor: "BXY-1234",
      bateria: "03",
      playa: "AEROPUERTO",
    },
    {
      hojaRuta: "92836236",
      fechaSalida: "21-03-2024 10:10:30",
      transportista: "UPS",
      chofer: "Maria",
      tractor: "CZK-5678",
      bateria: "04",
      playa: "PUERTO",
    },
    {
      hojaRuta: "92836237",
      fechaSalida: "21-03-2024 10:15:40",
      transportista: "FedEx",
      chofer: "Luís",
      tractor: "FGH-9012",
      bateria: "05",
      playa: "CENTRO",
    },
    {
      hojaRuta: "92836238",
      fechaSalida: "21-03-2024 10:20:55",
      transportista: "DB Schenker",
      chofer: "Ana",
      tractor: "JKL-3456",
      bateria: "06",
      playa: "RESIDENCIAL",
    },
    {
      hojaRuta: "92836239",
      fechaSalida: "21-03-2024 10:25:10",
      transportista: "Kuehne + Nagel",
      chofer: "Eduardo",
      tractor: "MNO-7890",
      bateria: "07",
      playa: "PARQUE",
    },
    {
      hojaRuta: "92836240",
      fechaSalida: "21-03-2024 10:30:20",
      transportista: "XPO Logistics",
      chofer: "Carla",
      tractor: "PQR-1234",
      bateria: "08",
      playa: "ZONA INDUSTRIAL",
    },
    {
      hojaRuta: "92836241",
      fechaSalida: "21-03-2024 10:35:35",
      transportista: "CEVA Logistics",
      chofer: "Ricardo",
      tractor: "STU-5678",
      bateria: "09",
      playa: "ALMACÉN",
    },
    {
      hojaRuta: "92836242",
      fechaSalida: "21-03-2024 10:40:45",
      transportista: "GEODIS",
      chofer: "Mariana",
      tractor: "VWX-9012",
      bateria: "10",
      playa: "TERMINAL",
    },
    {
      hojaRuta: "92836243",
      fechaSalida: "21-03-2024 10:45:50",
      transportista: "Ryder",
      chofer: "Roberto",
      tractor: "YZA-3456",
      bateria: "11",
      playa: "ESTACIÓN",
    },
    {
      hojaRuta: "92836244",
      fechaSalida: "21-03-2024 10:50:10",
      transportista: "Panalpina",
      chofer: "Silvana",
      tractor: "BCD-7890",
      bateria: "12",
      playa: "BASE",
    },
    {
      hojaRuta: "92836245",
      fechaSalida: "21-03-2024 10:55:25",
      transportista: "Agility",
      chofer: "Diego",
      tractor: "EFG-1234",
      bateria: "13",
      playa: "FÁBRICA",
    },
    {
      hojaRuta: "92836246",
      fechaSalida: "21-03-2024 11:00:30",
      transportista: "Bolloré Logistics",
      chofer: "Laura",
      tractor: "HIJ-5678",
      bateria: "14",
      playa: "PLANTA",
    },
    {
      hojaRuta: "92836247",
      fechaSalida: "21-03-2024 11:05:40",
      transportista: "Expeditors",
      chofer: "Hugo",
      tractor: "KLM-9012",
      bateria: "15",
      playa: "DEPÓSITO",
    },
    {
      hojaRuta: "92836248",
      fechaSalida: "21-03-2024 11:10:55",
      transportista: "C.H. Robinson",
      chofer: "Fernanda",
      tractor: "NOP-3456",
      bateria: "16",
      playa: "CENTRO DE DISTRIBUCIÓN",
    },
    {
      hojaRuta: "92836249",
      fechaSalida: "21-03-2024 11:15:10",
      transportista: "Sinotrans",
      chofer: "Rafael",
      tractor: "QRS-7890",
      bateria: "17",
      playa: "PUNTO DE RECOGIDA",
    },
    {
      hojaRuta: "92836250",
      fechaSalida: "21-03-2024 11:20:20",
      transportista: "GEFCO",
      chofer: "Paula",
      tractor: "TUV-1234",
      bateria: "18",
      playa: "ÁREA LOGÍSTICA",
    },
  ];
  return (
    <div className="filter-test">
      <Filter
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
        }}
      />

      <div className="report-panel">
        <Panel header={" "} style={{ margin: "1rem 0" }}>
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            value={demoData}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
          >
            <Column
              field="hojaRuta"
              sortable
              filter
              header="Nro. Hoje de Ruta A"
            />
            <Column field="fechaSalida" sortable filter header="Fecha Salida" />
            <Column
              field="transportista"
              sortable
              filter
              header="Transportista"
            />
            <Column field="chofer" sortable filter header="Chofer" />
            <Column field="tractor" sortable filter header="Tractor" />
            <Column
              field="bateria"
              sortable
              filter
              header="Batea/Transportín"
            />
            <Column field="playa" sortable filter header="Playas" />
          </DataTable>
        </Panel>
      </div>
    </div>
  );
}
