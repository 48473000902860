import "./DisponibilidadImportacion.scss";
import React from "react";

import usePageViews from "../../../hooks/usePageViews";
import DisponibilidadImportacionBrForm from "./components/DisponibilidadImportacionBrForm";
import DisponibilidadImportacionForm from "./components/DisponibilidadImportacionForm";
import useAuth from "@shared/AuthContext";
import { ScreenCodeValue } from "@shared/ScrennCode";


export function DisponibilidadImportacion() {
  const SCREEN_CODE = "fun0061_disponibilidad_importacion"; 
  ScreenCodeValue(SCREEN_CODE);
  const { user } = useAuth(); 
  usePageViews();

  function CountryForm() {
    if (user.pais.codPais === 2) {
      return <DisponibilidadImportacionBrForm SCREEN_CODE={SCREEN_CODE} />;
    } else {
      return <DisponibilidadImportacionForm SCREEN_CODE={SCREEN_CODE} />;
    }
  }
  

  return (
    <div className="disponibilidad-importacion">
     <CountryForm />
    </div>
  );
}
export default DisponibilidadImportacion;
