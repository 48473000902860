import "./ValeRepuestos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import useRowStyles from "../../../hooks/useRowStyles";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ValeRepuestos() {
  const SCREEN_CODE = "con0037_vale_repuestos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const filterLabelId = [327, 16800, 1151];
  const filterNames = [];
  const filterColumnsId = [
    4245, 5715, 4891, 5720, 1491, 2892, 10774, 2496, 396,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    31, 54, 65, 187, 327, 467, 468, 753, 1002, 1151, 1182, 1253, 1491, 1791,
    2139, 2185, 3754, 4791, 5044, 5271, 5583, 5788, 10829, 12231, 13706, 16476,
    16800, 16801, 2139, 2502, 18229, 2406,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }
  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reportData, setReportData] = useState(null);

  const [loadingChildReportData, setLoadingChildReportData] = useState(false);
  const [childReportData, setChildReportData] = useState(null);

  const [loadingTalleres, setLoadingTalleres] = useState(false);
  const [talleres, setTalleres] = useState([]);
  const [loadingEstados, setLoadingEstados] = useState(false);
  const [estados, setEstados] = useState([]);

  const [selectedReportData, setSelectedReportData] = useState(null);
  const rowStyles = useRowStyles([selectedReportData]);

  const [filter, setFilter] = useState({
    taller: null,
    estado: null,
    fechaInicio: new Date(),
    fechaFinal: new Date(),
    aplicarFiltroFecha: true,
  });

  async function loadTaller() {
    setLoadingTalleres(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/vale-repuestos/combo/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
            },
          }
        );
        if (status === 200) {
          setTalleres(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTalleres(false);
    }
  }
  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/vale-repuestos/combo/estado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcion: "Todos" });
          setEstados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
  }
  async function loadReportData() {
    setLoadingReportData(true);
    try {
      let filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        codTaller: filter?.taller?.id || null,
        estado: null,
        rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
      };

      if (filter.estado != null && filter.estado.id != 0) {
        filters.estado = filter.estado.id;
      }
      const { status, data } = await sipcoAxiosService.post(
        "vale-repuestos/report",
        filters
      );
      if (status === 200) {
        setReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }
  async function loadReportDataDetails() {
    setLoadingChildReportData(true);
    try {
      let filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        codTaller: filter?.taller?.id || null,
        vale: selectedReportData[0] || null,
      };
      const { status, data } = await sipcoAxiosService.post(
        "vale-repuestos/report-detalles",
        filters
      );
      if (status === 200) {
        setChildReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChildReportData(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const talleres = await loadTaller();
        const estados = await loadEstados();
        setFilter({
          ...filter,
          taller: talleres[0],
          estado: estados[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  useEffect(() => {
    if (selectedReportData) {
      loadReportDataDetails();
    }
  }, [selectedReportData]);

  const formattedReportData = convertDateObjects(reportData?.filas);
  const formattedChieldReportData = convertDateObjects(childReportData?.filas);

  return (
    <div className="vale-repuestos">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id="743" alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <Dropdown
                  value={filter.taller}
                  options={talleres}
                  onChange={(e) => {
                    setFilter({ ...filter, taller: e.value });
                  }}
                  filter
                  optionLabel="descripcion"
                  loading={loadingTalleres}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={"3233"}
                    alt="Aplicar Filtro por Fecha"
                  />
                </label>
                <Checkbox
                  checked={filter.aplicarFiltroFecha}
                  onChange={(e) => {
                    if (e.checked) {
                      setFilter({
                        ...filter,
                        aplicarFiltroFecha: e.checked,
                        fechaInicio: new Date(),
                        fechaFinal: new Date(),
                      });
                    } else {
                      setFilter({
                        ...filter,
                        aplicarFiltroFecha: e.checked,
                        fechaInicio: null,
                        fechaFinal: null,
                      });
                    }
                  }}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <Calendar
                  value={filter.fechaInicio}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaInicio: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                  disabled={!filter.aplicarFiltroFecha}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <Calendar
                  value={filter.fechaFinal}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaFinal: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                  disabled={!filter.aplicarFiltroFecha}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1151"} alt="Estados" />
                </label>
                <Dropdown
                  value={filter.estado}
                  options={estados}
                  onChange={(e) => {
                    setFilter({ ...filter, estado: e.value });
                  }}
                  filter
                  optionLabel="descripcion"
                  loading={loadingEstados}
                  showClear
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[31].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={formattedReportData}
                  columns={columnsNames}
                  screenName={"Vale Repuestos"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={formattedReportData}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="single"
          onSelectionChange={(e) => setSelectedReportData(e.value)}
          selection={selectedReportData}
        >
          <Column
            field="0"
            header={<LanguageProvider id="12231" alt="Nro. de Vale" />}
            sortable
            filter
          />
          <Column
            field="1"
            header={<LanguageProvider id="20804" alt="Estado" />}
            sortable
            filter
          />
          <Column
            field="2"
            header={<LanguageProvider id="16800" alt="Fecha vale" />}
            sortable
            filter
          />
          <Column
            field="3"
            header={<LanguageProvider id="16801" alt="Código orden" />}
            sortable
            filter
          />
          <Column
            field="4"
            header={<LanguageProvider id="1491" alt="VIN" />}
            sortable
            filter
          />
          <Column
            field="5"
            header={<LanguageProvider id="2139" alt="Modelo" />}
            sortable
            filter
          />
          <Column
            field="6"
            header={
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            }
            sortable
            filter
          />
          <Column
            field="7"
            header={<LanguageProvider id="5271" alt="Fec. Últ. Modificación" />}
            sortable
            filter
          />
        </DataTable>
      </Panel>

      <Panel>
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={formattedChieldReportData}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          loading={loadingChildReportData}
        >
          <Column
            field="0"
            header={<LanguageProvider id="10828" alt="Linea" />}
            sortable
            filter
          />
          <Column
            field="1"
            header={
              <LanguageProvider id="16476" alt="Código Cliente Repuestos" />
            }
            sortable
            filter
          />
          <Column
            field="2"
            header={<LanguageProvider id="10829" alt="Código Pieza" />}
            sortable
            filter
          />
          <Column
            field="3"
            header={<LanguageProvider id="12419" alt="Descripcion" />}
            sortable
            filter
          />
          <Column
            field="4"
            header={<LanguageProvider id="2185" alt="Grupo Taller" />}
            sortable
            filter
          />
          <Column
            field="5"
            header={<LanguageProvider id="10746" alt="Modelo" />}
            sortable
            filter
          />
          <Column
            field="6"
            header={<LanguageProvider id="1253" alt="Cantidad" />}
            sortable
            filter
          />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ValeRepuestos;
