import React, { useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import useFilterColumnsIds from "../hooks/useFilterColumnsIds";

const Lectora: React.FC<any> = () => {
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    capitalyHBA: true,
  });

  const filterColumnsIdLectora = [14369, 1491, 1569, 5788, 8380];

  const columNames = useFilterColumnsIds(filterColumnsIdLectora);

  return (
    <div>
      <div className="filter-options">
        <div className="sipco-options-line">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"14369"} alt="Número OT" />
            </label>
            <AutoComplete field="key" dropdown />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2781"} alt="Fecha de Realización" />
            </label>
            <Calendar showButtonBar showIcon showTime />
          </div>
        </div>
        <div className="sipco-options-line">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1491"} alt="VIN" />
            </label>
            <Chip label="0" />
          </div>
        </div>
        <div className="sipco-options-line">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1569"} alt="Operación" />
            </label>
            <InputText />
          </div>
          <div className={"sipco-option-extra-text"}>
            <InputText />
          </div>
        </div>
        <div className="sipco-options-line">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2521"} alt="Proveedor" />
            </label>
            <InputText />
          </div>
          <div className={"sipco-option-extra-text"}>
            <InputText />
          </div>
        </div>
        <div className="sipco-options-line">
          <div className={"sipco-option-text"}>
            <label>
              <LanguageProvider id={"10828"} alt="Linea" />
            </label>
            <InputText />
          </div>
        </div>
        <div className="sipco-options-line">
          <div className={"sipco-option-text"}>
            <label>
              <LanguageProvider id={"8380"} alt="Resultado" />
            </label>
            <InputText />
          </div>
        </div>
      </div>
      <DataTable
        size={"small"}
        showGridlines
        stripedRows
        value={data}
        paginator
        scrollHeight="flex"
        filterDisplay="menu"
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        style={{ maxWidth: "100%" }}
        loading={loading}
      >
        {columNames.map((colum, index) => (
          <Column
            key={index}
            field={colum.id}
            header={colum.label}
            sortable
            filter
          />
        ))}
      </DataTable>
    </div>
  );
};
export default Lectora;
