import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function DisponibilidadImportacionBrForm(props) {
  const { SCREEN_CODE } = props;
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const toast = useRef<Toast>(null);

  const [loading, setLoading] = useState(false);

  const columnsIds1 = ["", 1491, 396, 2139, 2140, 13335];

  const columnsNames = filterColumnsIds(columnsIds1);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "",
        }),
      });
    }
    return result;
  }

  return (
    <div className="disponibilidad-importacion">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"449"} alt="Pátios" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"990"} alt="Rua" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className="divide">
                <div className={"sipco-option-divide"}>
                  <AutoComplete dropdown />
                </div>
                <div className={"sipco-option-divide"}>
                  <AutoComplete dropdown />
                </div>
              </div>
            </div>

            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el Vin" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"489"} alt="Marcas" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"408"} alt="Modos" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={
          <LanguageProvider id={"1085"} alt="Disponibilidad de Importación" />
        }
      >
        <div className="fieldset-content">
          <DataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={reporte}
                  screenName={"RecepcionPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={listRemitos}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsNames.map((colum, index) => (
              <Column key={index} header={colum.label} sortable filter />
            ))}
          </DataTable>
          <div className="chips">
            <div className={"sipco-chip"}>
              <label>
                <LanguageProvider id={"864"} alt="Registros Seleccionados" />
              </label>
              <Chip label="0" />
            </div>
            <div className={"sipco-chip"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Total de Registros" />
              </label>
              <Chip label="0" />
            </div>
          </div>
          <Divider />
          <div className="filter-options-column">
            <div className="sipco-options-column">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1089"} alt="Ingreso a Playa destino" />
                </label>
                <Calendar showButtonBar showIcon showTime />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1332"} alt="Instalador Radios" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1416"} alt="Predelivery" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
            <div className="sipco-options-column">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1090"} alt="Egreso de Playa Origen" />
                </label>
                <Calendar showButtonBar showIcon showTime />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16844"} alt="Proveedor daños" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1415"} alt="Grabó Cristales" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
          </div>
        </div>

        <Divider />
        <div className="fieldset-footer">
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "748",
                alt: "Seleccionar todo",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "749",
                alt: "Deseleccionar todo",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "34",
                alt: "Procesar o guardar cambios",
              })}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default DisponibilidadImportacionBrForm;
