import "./ModificacionMasivaMotor.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";

export function ModificacionMasivaMotor() {
  const SCREEN_CODE = "fun0084_modificacion_masiva_motor";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [ingreseElVin, setIngreseElVin] = useState([]);
  const [mercados, setMercados] = useState([]);
  const [remitos, setRemitos] = useState([]);
  const [estados, setEstados] = useState([]);
  const [concesionario, setConcesionario] = useState([]);
  const [marca, setMarca] = useState([]);
  const [bloqueoCalidad, setBloqueoCalidad] = useState([]);
  const [direccion, setDireccion] = useState([]);
  const [modelo, setModelo] = useState([]);


  const [filter, setFilter] = useState({
    fecha: null,
    capitalyHBA: false,
    tipoRemito: null,
    letra: null,
    puntoVenta: null,
    numero: null,
    estados: "TODOS",
    concesionario: null,
    marca: null,
    mercados: null,
    marcas: null,
    modelos: null,
    colores: null,
    familias: null,
    vin: null,
    bloqueoCalidad: null,
    direccion: "TODOS",
    modelo: null,
  });
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];
  const filterColumnsId = [
    16949, 2518, 11833, 2519, 16483, 16480, 8627, 3756, 16968, 3756, 16481,
    16482, 3756, 13706, 23848,
  ];
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  async function loadReportData() {
    // setLoading(true);
    // try {
    //   let transacciones = [];
    //   if (filter.capitalyHBA) {
    //     transacciones.push("GFC06");
    //   }
    //   const { status, data } = await sipcoAxiosService.post(
    //     "/administracion-remitos-concesionario/vin",
    //     {
    //       codPais: securityFilters?.securityValues?.country?.codPais || null,
    //       codCliente: securityFilters?.securityValues?.client?.codCliente || "",
    //       codCuenta:
    //         securityFilters?.securityValues?.account?.codCuenta || null,
    //       codSubcuenta:
    //         securityFilters?.securityValues?.subaccount?.codSubcuenta || null,
    //     }
    //   );
    //   if (status === 200) {
    //     setReporte(data);
    //   }
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setLoading(false);
    // }
  }

  return (
    <div className="modificacion-masiva-motor">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >

        <AccordionTab header={<LanguageProvider id={"TODO"} alt="Filtrar Por" />}>
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Ingrese el VIN" />
                </label>
                <AutoComplete dropdown />
                <label>
                  <LanguageProvider id={"TODO"} alt="Marcas" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Modelos" />
                </label>
                <AutoComplete dropdown />
                <label>
                  <LanguageProvider id={"TODO"} alt="Buques" />
                </label>
                <AutoComplete dropdown />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Fechas de Llegada" />
                </label>
                <AutoComplete dropdown />
                <label>
                  <LanguageProvider id={"TODO"} alt="Origen" />
                </label>
                <AutoComplete dropdown />
              </div>
            </div>
          </div>
        </AccordionTab>



      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Modificacion Massica Motor</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({ id: "748", alt: "Seleccionar todo" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "749", alt: "Deseleccionar todo" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "34", alt: "Procesar o guardar cambios" })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          <Column field="1" header={"VIN"} sortable filter />
          <Column field="2" header={"Motor"} sortable filter />
          <Column field="3" header={"Marca"} sortable filter />
          <Column field="4" header={"Modelos"} sortable filter />


        </DataTable>

        <div className="filter-options">
          <div className={"sipco-options-line"}>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Total de Registros" />
              </label>
              
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Registros Seleccionados" />
              </label>
              
            </div>
          </div>


          <div className={"sipco-options-line"}>


            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Acción" />
              </label>
              <AutoComplete dropdown />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Parte" />
              </label>
              <AutoComplete dropdown />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Valor" />
              </label>
              <InputText />
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
}
