import "./CargaDanos.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function CargaDanos() {
  const SCREEN_CODE = "fun0059_carga_danos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [filter, setFilter] = useState({
    fecha: null,
    capitalyHBA: false,
    tipoRemito: null,
    letra: null,
    puntoVenta: null,
    numero: null,
    estados: "TODOS",
    concesionario: null,
    marca: null,
    mercados: null,
    marcas: null,
    modelos: null,
    colores: null,
    familias: null,
    vin: null,
    bloqueoCalidad: null,
    direccion: "TODOS",
    modelo: null,
  });
  const data = convertDateObjects(reporte?.filas);

  const filterColumnsId = [
    20216, 10540, 10541, 2490, 5701, 16243, 16575, 17007, 16544, 17009, 8385,
    3344, 6197, 20854, 11314, 11314, 18096, 18125, 18097, 18099, 18098, 21675,
    7280, 32,
  ];
  const columnsNames = filterColumnsIds(filterColumnsId);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  async function loadReportData() {
    setLoading(true);
    // try {
    //   let transacciones = [];
    //   if (filter.capitalyHBA) {
    //     transacciones.push("GFC06");
    //   }

    //   const { status, data } = await sipcoAxiosService.post(
    //     "/administracion-remitos-concesionario/vin",
    //     {
    //       codPais: securityFilters?.securityValues?.country?.codPais || null,
    //       codCliente: securityFilters?.securityValues?.client?.codCliente || "",
    //       codCuenta:
    //         securityFilters?.securityValues?.account?.codCuenta || null,
    //       codSubcuenta:
    //         securityFilters?.securityValues?.subaccount?.codSubcuenta || null,
    //     }
    //   );

    //   if (status === 200) {
    //     setReporte(data);
    //   }
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setLoading(false);
    // }
  }

  return (
    <div className="carga-danos">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Ingrese el Vin" />
              </label>
              <AutoComplete
                value={filter.vin}
                //suggestions={ingreseElVin}
                //completeMethod={searchIngreseElVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value })}
                field="id"
                dropdown
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Carga de Daños</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"CargaDanos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              <div className="filter-options">
                <div className={"filter-options-left"}>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"11314"} alt="CheckPoint" />
                    </label>
                    <AutoComplete
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option-line"}>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"11097"} alt="Responsable" />
                      </label>
                      <AutoComplete
                        // value={filter.letra}
                        // //suggestions={playas}
                        // //completeMethod={searchPlayas}
                        // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                        field="label"
                        dropdown
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"3774"} alt="Nro. Remito" />
                      </label>
                      <InputText
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      />
                    </div>
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"21910"}
                        alt="Parte dañada (Elemento)"
                      />
                    </label>
                    <AutoComplete
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"21675"} alt="Cuadrante (LD)" />
                    </label>
                    <AutoComplete
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"17007"} alt="Gravedad del Daño" />
                    </label>
                    <AutoComplete
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16577"} alt="Fecha del daño" />
                    </label>
                    <AutoComplete
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option-line"}>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"16577"} alt="Fecha del daño" />
                      </label>
                      <Calendar showButtonBar showTime showIcon />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"1974"} alt="Importe" />
                      </label>
                      <InputText />
                    </div>
                  </div>
                  <div className={"sipco-option-line"}>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"3344"} alt="Estado" />
                      </label>
                      <AutoComplete
                        // value={filter.letra}
                        // //suggestions={playas}
                        // //completeMethod={searchPlayas}
                        // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                        field="label"
                        dropdown
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"11070"} alt="Cod. Inspector" />
                      </label>
                      <InputText />
                    </div>
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"18125"} alt="Comentario Dossier" />
                    </label>
                    <AutoComplete
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                      dropdown
                    />
                  </div>
                </div>
                <div className={"filter-options-right"}>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"8335"} alt="Imputaciones" />
                    </label>
                    <AutoComplete
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"14288"} alt="Transacción" />
                    </label>
                    <AutoComplete
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"21911"}
                        alt="Descripción daño (CD)"
                      />
                    </label>
                    <AutoComplete
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option-line"}>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"21912"} alt="Medida (T)" />
                      </label>
                      <AutoComplete
                        // value={filter.letra}
                        // //suggestions={playas}
                        // //completeMethod={searchPlayas}
                        // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                        field="label"
                        dropdown
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"32"} alt="Valor" />
                      </label>
                      <InputText
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      />
                    </div>
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"3625"} alt="Observaciones" />
                    </label>
                    <InputText />
                  </div>
                  <div className={"sipco-option-line"}>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"18097"} alt="Fecha SAGAI" />
                      </label>
                      <Calendar showButtonBar showTime showIcon />
                    </div>
                    <div className={"sipco-option"}>
                      {/* <label>
                        <LanguageProvider id={"18098"} alt="Resultado Analisis" />
                      </label> */}
                      <Checkbox checked={filter.capitalyHBA}></Checkbox>
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider
                          id={"18098"}
                          alt="Resultado Analisis"
                        />
                      </label>

                      <AutoComplete
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      />
                    </div>
                  </div>
                  <div className={"sipco-option-line"}>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"18099"} alt="Nro. Dossier" />
                      </label>
                      <InputText
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"18096"} alt="Nro. Check List" />
                      </label>
                      <InputText
                      // value={filter.letra}
                      // //suggestions={playas}
                      // //completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* buttons */}
              <div>
                <Button
                  label={LanguageProvider({
                    id: "25",
                    alt: "Alta",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "26",
                    alt: "Baja",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "2761",
                    alt: "Reparado",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "9627",
                    alt: "Destrabar daños",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "20874",
                    alt: "Reactivar daño",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "27", alt: "Modificar" })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {columnsNames.map((colum, index) => (
            <Column
              key={index}
              field={colum.id}
              header={colum.label}
              sortable
              filter
            />
          ))}
        </DataTable>
      </Panel>
    </div>
  );
}
export default CargaDanos;
