import "./Remitos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function Remitos() {
  const SCREEN_CODE = "con0021_remitos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();

  const dt = useRef(null);

  const filterColumnsId = [
    2131, 54, 266, 1491, 9428, 9620, 14731, 14722, 2375, 13534, 14735, 932,
    2892, 3344, 4216, 8299, 10774, 2496, 396, 2139, 2140, 1492, 1285, 10540,
    16243, 16505, 292, 295, 16926, 1617, 16757, 13338, 16288, 2892, 1617, 159,
    164, 3186, 10502, 13594, 1144, 1284, 16868, 16869, 5720, 8642, 2015, 818,
    1847, 10495, 2015, 1847, 754, 4589, 17759, 18099,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    letraDesde: "R",
    letraHasta: "R",
    numeroDesde: 0,
    numerHasta: 99999999,
    puntoVentaDesde: 0,
    puntoVentaHasta: 9999,
    fechaEmisionDesde: new Date(),
    fechaEmisionHasta: new Date(),
    liberacion: false,
    liberacionDesde: new Date(),
    liberacionHasta: new Date(),
    despacho: false,
    despachoDesde: new Date(),
    despachoHasta: new Date(),
    retorno: false,
    retornoDesde: new Date(),
    retornoHasta: new Date(),
    incluirOperacionesPendientes: false,
    tipoRemito: [],
    mercados: [],
    estados: [],
    listarVeiculosComDanos: null,
    concesionarios: [],
    dirEntrega: [],
  });

  const [tiposRemito, setTiposRemito] = useState([]);
  const [loadingTiposRemito, setLoadingTiposRemito] = useState(false);
  const [mercados, setMercados] = useState([]);
  const [loadingMercados, setLoadingMercados] = useState(false);
  const [estados, setEstados] = useState([]);
  const [loadingEstadosVeiculos, setLoadingEstadosVeiculos] = useState(false);
  const [concesionarions, setConcesionarions] = useState([]);
  const [loadingConcesionarions, setLoadingConcesionarions] = useState(false);
  const [direccionesEntrega, setDireccionesEntrega] = useState([]);
  const [loadingDireccionesEntrega, setLoadingDireccionesEntrega] =
    useState(false);
  const [listarVeiculosComDanosOptions, setListarVeiculosComDanosOptions] =
    useState([]);
  const [
    loadingListarVeiculosComDanosOptions,
    setLoadingListarVeiculosComDanosOptions,
  ] = useState(false);

  const [loadingReport, setLoadingReport] = useState(false);
  const [reporte, setReporte] = useState(null);

  async function loadTiposRemito() {
    setLoadingTiposRemito(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/remitos/combo/remitos-sin-anulacion",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          setTiposRemito(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTiposRemito(false);
    }
    return [];
  }
  async function loadMercado() {
    setLoadingMercados(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/remitos/combo/mercado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setMercados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
    return [];
  }
  async function loadEstadoRemito() {
    setLoadingEstadosVeiculos(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/remitos/combo/estado-remito",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          setEstados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadosVeiculos(false);
    }
    return [];
  }
  async function loadConcesionarios() {
    setLoadingConcesionarions(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/remitos/combo/concesionario",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );

        if (status === 200) {
          setConcesionarions(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConcesionarions(false);
    }
    return [];
  }
  async function loadDirEntrega() {
    setLoadingDireccionesEntrega(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client.id &&
        filter?.concesionarios?.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/remitos/combo/concesionario",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codConcesionario: filter?.concesionarios[0].id,
            },
          }
        );

        if (status === 200) {
          setDireccionesEntrega(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDireccionesEntrega(false);
    }
    return [];
  }
  async function loadListarVeiculosComDanosOptions() {
    setLoadingListarVeiculosComDanosOptions(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/remitos/combo/si-no-ambos",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          setListarVeiculosComDanosOptions(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingListarVeiculosComDanosOptions(false);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const tiposRemito = await loadTiposRemito();
          const mercado = await loadMercado();
          const estadoRemito = await loadEstadoRemito();
          let concesionarios = [];
          if (securityFilters.securityValues?.client?.descripcion !== "TODOS") {
            concesionarios = await loadConcesionarios();
          }
          const listarVeiculosComDanosOptions =
            await loadListarVeiculosComDanosOptions();

          setFilter({
            ...filter,
            tipoRemito: tiposRemito,
            mercados: mercado,
            estados: estadoRemito,
            concesionarios: concesionarios,
            listarVeiculosComDanos: listarVeiculosComDanosOptions[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  useEffect(() => {
    async function handleConcesionarionSelecion() {
      if (filter.concesionarios?.length === 1) {
        try {
          const dirEntrega = await loadDirEntrega();
          setFilter({
            ...filter,
            dirEntrega: dirEntrega,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleConcesionarionSelecion();
  }, [filter.concesionarios]);

  async function loadReportData() {
    try {
      setLoadingReport(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        concesionarios: filter?.concesionarios?.map((x) => x.id) || [],
        estadosRemitos: filter?.estados?.map((x) => x.id) || [],
        mercados: filter?.mercados?.map((x) => x.id) || [],
        remitos: filter?.tipoRemito?.map((x) => x.id) || [],
        dirEntrega: filter?.dirEntrega?.map((x) => x.id) || [],
        rangeFecha: [
          filter?.fechaEmisionDesde || null,
          filter?.fechaEmisionHasta || null,
        ],
        liberacionBoolean: filter?.liberacion || false,
        fechaLiberacion: [
          filter?.liberacionDesde || null,
          filter?.liberacionHasta || null,
        ],
        despachoBoolean: filter?.despacho || false,
        fechaDespacho: [
          filter.despachoDesde || null,
          filter.despachoHasta || null,
        ],
        cargaRetornoBoolean: filter?.retorno || false,
        fechaCargaRetorno: [
          filter?.retornoDesde || null,
          filter?.retornoHasta || null,
        ],
        listarVehiculosDanos: filter?.listarVeiculosComDanos?.id || [],
        letras: [filter?.letraDesde || null, filter?.letraHasta || null],
        ptoVenta: [
          filter?.puntoVentaDesde || 0,
          filter?.puntoVentaHasta || null,
        ],
        numeros: [filter?.numeroDesde || 0, filter?.numerHasta || null],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/remitos/report",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReport(false);
    }
  }

  const clientIsTodos =
    securityFilters.securityValues?.client?.descripcion === "TODOS";
  const data = convertDateObjects(reporte?.filas);

  return (
    <div className="remitos">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"849"} alt="Letra" />
                </label>
                <div className="form-row">
                  <InputText
                    value={filter.letraDesde}
                    onChange={(e) => {
                      setFilter({ ...filter, letraDesde: e.target.value });
                    }}
                  />
                  <InputText
                    value={filter.letraHasta}
                    onChange={(e) => {
                      setFilter({ ...filter, letraHasta: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"25188"} alt="Liberacion" />
                </label>
                <Checkbox
                  checked={filter.liberacion}
                  onChange={() => {
                    setFilter({ ...filter, liberacion: !filter.liberacion });
                  }}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Fecha Liberacion" />
                </label>
                <div className="form-row">
                  <Calendar
                    value={filter.liberacionDesde}
                    onChange={(e) => {
                      setFilter({ ...filter, liberacionDesde: e.value });
                    }}
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showIcon
                    disabled={!filter.liberacion}
                  />
                  <Calendar
                    value={filter.liberacionHasta}
                    onChange={(e) => {
                      setFilter({ ...filter, liberacionHasta: e.value });
                    }}
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showIcon
                    disabled={!filter.liberacion}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"850"} alt="Pto.Venta" />
                </label>
                <InputNumber
                  value={filter.puntoVentaDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, puntoVentaDesde: e.value });
                  }}
                  min={0}
                />
                <InputNumber
                  value={filter.puntoVentaHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, puntoVentaHasta: e.value });
                  }}
                  min={0}
                />
              </div>
              <div className={"RadioButtonFilter"}>
                <label>
                  <LanguageProvider id={"382"} alt="Despacho" />
                </label>
                <Checkbox
                  checked={filter.despacho}
                  onChange={() => {
                    setFilter({ ...filter, despacho: !filter.despacho });
                  }}
                />
              </div>
              <div className={"sipco-option"}>
                <Calendar
                  value={filter.despachoDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, despachoDesde: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                  disabled={!filter.despacho}
                />
                <Calendar
                  value={filter.despachoHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, despachoHasta: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                  disabled={!filter.despacho}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"851"} alt="Número" />
                </label>
                <InputNumber
                  value={filter.numeroDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, numeroDesde: e.value });
                  }}
                  min={0}
                />
                <InputNumber
                  value={filter.numerHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, numerHasta: e.value });
                  }}
                  min={0}
                />
              </div>
              <div className={"RadioButtonFilter"}>
                <label>
                  <LanguageProvider id={"16842"} alt="Carga retorno" />
                </label>
                <Checkbox
                  checked={filter.retorno}
                  onChange={() => {
                    setFilter({ ...filter, retorno: !filter.retorno });
                  }}
                />
              </div>
              <div className={"sipco-option"}>
                <Calendar
                  value={filter.retornoDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, retornoDesde: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                  disabled={!filter.retorno}
                />
                <Calendar
                  value={filter.retornoHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, retornoHasta: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                  disabled={!filter.retorno}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"21422"} alt="Fecha Emision" />
                </label>
                <Calendar
                  value={filter.fechaEmisionDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaEmisionDesde: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                />
                <Calendar
                  value={filter.fechaEmisionHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaEmisionHasta: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                />
              </div>
              <div className={"RadioButtonFilter"}>
                <label>
                  <LanguageProvider
                    id={"17638"}
                    alt="Incluir Operaciones Pendientes"
                  />
                </label>
                <Checkbox
                  checked={filter.incluirOperacionesPendientes}
                  onChange={() => {
                    setFilter({
                      ...filter,
                      incluirOperacionesPendientes:
                        !filter.incluirOperacionesPendientes,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1617"} alt="Tipo remito" />
                </label>
                <MultiSelect
                  value={filter.tipoRemito}
                  options={tiposRemito}
                  onChange={(e) => {
                    setFilter({ ...filter, tipoRemito: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingTiposRemito}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10774"} alt="Mercados" />
                </label>
                <MultiSelect
                  value={filter.mercados}
                  options={mercados}
                  onChange={(e) => {
                    setFilter({ ...filter, mercados: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingMercados}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1151"} alt="Estados" />
                </label>
                <MultiSelect
                  value={filter.estados}
                  options={estados}
                  onChange={(e) => {
                    setFilter({ ...filter, estados: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingEstadosVeiculos}
                  showClear
                />
              </div>
              {!clientIsTodos && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"266"} alt="Concesionarios" />
                  </label>
                  <MultiSelect
                    value={filter.concesionarios}
                    options={concesionarions}
                    onChange={(e) => {
                      setFilter({ ...filter, concesionarios: e.value });
                    }}
                    display="chip"
                    filter
                    optionLabel="label"
                    loading={loadingConcesionarions}
                    showClear
                  />
                </div>
              )}
              {filter.concesionarios.length === 1 && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"492"} alt="Dirección de Entrega" />
                  </label>
                  <MultiSelect
                    value={filter.dirEntrega}
                    options={direccionesEntrega}
                    onChange={(e) => {
                      setFilter({ ...filter, dirEntrega: e.value });
                    }}
                    display="chip"
                    filter
                    optionLabel="label"
                    loading={loadingDireccionesEntrega}
                    showClear
                  />
                </div>
              )}
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={"1417"}
                    alt="Listar vehiculos con daños"
                  />
                </label>
                <Dropdown
                  value={filter.listarVeiculosComDanos}
                  options={listarVeiculosComDanosOptions}
                  onChange={(e) => {
                    setFilter({ ...filter, listarVeiculosComDanos: e.value });
                  }}
                  filter
                  optionLabel="descripcionFull"
                  loading={loadingListarVeiculosComDanosOptions}
                  showClear
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="275" alt="Remitos" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={LanguageProvider({ id: "275", alt: "Remitos" })}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReport}
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="" header={columnsNames[1].label} sortable filter />
          <Column field="" header={columnsNames[2].label} sortable filter />
          <Column field="" header={columnsNames[3].label} sortable filter />
          <Column field="" header={columnsNames[4].label} sortable filter />
          <Column field="" header={columnsNames[5].label} sortable filter />
          <Column field="" header={columnsNames[6].label} sortable filter />
          <Column field="" header={columnsNames[7].label} sortable filter />
          <Column field="" header={columnsNames[8].label} sortable filter />
          <Column field="" header={columnsNames[9].label} sortable filter />
          <Column field="" header={columnsNames[10].label} sortable filter />
          <Column field="" header={columnsNames[11].label} sortable filter />
          <Column field="" header={columnsNames[12].label} sortable filter />
          <Column field="" header={columnsNames[13].label} sortable filter />
          <Column field="" header={columnsNames[14].label} sortable filter />
          <Column field="" header={columnsNames[15].label} sortable filter />
          <Column field="" header={columnsNames[16].label} sortable filter />
          <Column field="" header={columnsNames[17].label} sortable filter />
          <Column field="" header={columnsNames[18].label} sortable filter />
          <Column field="" header={columnsNames[19].label} sortable filter />
          <Column field="" header={columnsNames[20].label} sortable filter />
          <Column field="" header={columnsNames[21].label} sortable filter />
          <Column field="" header={columnsNames[22].label} sortable filter />
          <Column field="" header={columnsNames[23].label} sortable filter />
          <Column field="" header={columnsNames[24].label} sortable filter />
          <Column field="" header={columnsNames[25].label} sortable filter />
          <Column field="" header={columnsNames[26].label} sortable filter />
          <Column field="" header={columnsNames[27].label} sortable filter />
          <Column field="" header={columnsNames[28].label} sortable filter />
          <Column field="" header={columnsNames[29].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default Remitos;
