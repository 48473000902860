/**
 * useFormattedDate
 *
 * Este hook fornece duas funções:
 * - Uma que formata a data para o padrão "dd/MM/yyyy".
 * - Outra que formata a data e hora para o padrão "dd/MM/yyyy HH:mm:ss".
 * Se a data fornecida for inválida ou ausente, a função retorna uma string vazia.
 *
 * @returns {Object} - Um objeto com duas funções:
 * - `formatDate`: Formata apenas a data (dd/MM/yyyy).
 * - `formatDateTime`: Formata data e hora (dd/MM/yyyy HH:mm:ss).
 *
 * @example
 * const { formatDate, formatDateTime } = useFormattedDate();
 * console.log(formatDate("2023-08-20T12:34:56Z")); // "20/08/2023"
 * console.log(formatDateTime("2023-08-20T12:34:56Z")); // "20/08/2023 12:34:56"
 */

import { useCallback } from "react";
import { format } from "date-fns";

const useFormattedDate = () => {
  const formatDate = useCallback((data) => {
    if (!data) return "";
    const dataObjeto = new Date(data);
    return format(dataObjeto, "dd/MM/yyyy");
  }, []);

  const formatDateTime = useCallback((data) => {
    if (!data) return "";
    const dataObjeto = new Date(data);
    return format(dataObjeto, "dd/MM/yyyy HH:mm:ss");
  }, []);

  return { formatDate, formatDateTime };
};

//CAMINHO IMPORT
//import useFormattedDate from "../../../hooks/useFormattedDate";

export default useFormattedDate;
