import { CargaOperacionesForzadas } from "@pages/functions/CargaOpForzadas/Carga-operaciones-forzadas";
import VinView from "@pages/queries/consulta-vin/components/VinView";
import { ConsultaVin } from "@pages/queries/consulta-vin/consulta-vin";
import { LanguageProvider } from "@shared/components/language-provider";
import { Accordion, AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import "../components/modal-geral.scss";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { useFilterContext } from "../../../../protected-routes";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";

export function ModalGeral({ title, activeModal, setActiveModal }) {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const [contador, setContador] = useState(0);
  const [idRemoved, setIdRemoved] = useState(null);
  const [rolesRolesSelected, setRolesRolesSelected] = useState("");
  const [rolesPaisSelected, setRolesPaisSelected] = useState("");
  const [listagregarRole, setListAgregarRole] = useState([]);
  const [roleSelected, setRoleSelected] = useState({});
  const [listRolesRoles, setListRolesRoles] = useState([]);
  const [listRolesPais, setListRolesPais] = useState([]);

  const [ubicacionSelected, setUbicacionSelected] = useState("");
  const [ubicanioAreaSelected, setUbicanioAreaSelected] = useState("");
  const [listUbicaciones, setListUbicaciones] = useState([]);
  const [listUbicacionAreas, setListUbicacionAreas] = useState([]);

  const [segCheckedAdmUser, setSegCheckedAdmUser] = useState(false);
  const [segCheckedRespProycto, setSegCheckedRespProycto] = useState(false);
  const [segCheckedAqs, setSegCheckedAqs] = useState(false);
  const [segCheckedDanios, setSegCheckedDanios] = useState(false);
  const [segCheckedActivo, setSegCheckedActivo] = useState(false);

  const [listAgencias, setListAgencias] = useState([]);
  const [agenciaSelected, setAgenciaSelected] = useState({});
  const [checkedAsignado, setCheckedAsignado] = useState(false);



  const footerContent = (
    <div>
      <Button
        label="Aceptar"
        icon="pi pi-check"
        onClick={() => alert("YEEEEEEEEEEEEEEES")}
        autoFocus
      />

      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => setActiveModal(false)}
        className="p-button-text"
      />
      
    </div>
  );

  async function findAllRoles (){
    try {

      const {data, status} = await sipcoAxiosService.get("/mantenerUsuario/findAllRoles");

      if(status === 200){
        console.log(data);
        setListRolesRoles(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function findBySistema() {
    try {
      const {data, status} = await sipcoAxiosService.get("/mantenerUsuario/findBySistema", {
        params: {
          codSistema: "02"
        }
      });

      if(status === 200){
        setListRolesPais(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function findUbicacion() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/lugarFisicoService", {
          params: {
            codPais: Number(securityFilters.securityValues?.country?.value)
          }
        });
      if (status === 200) {
        setListUbicaciones(data);
        console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findAreasSistemas() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/areaSistemasService", {
          params: {
            codPais: Number(securityFilters.securityValues?.country?.value)
          }
        });
      if (status === 200) {
        setListUbicacionAreas(data);
        console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function aggregateInTabla(): void {
    setListAgregarRole([...listagregarRole, {rol: rolesRolesSelected, pais: rolesPaisSelected, id: contador}]);
    setContador(contador + 1)
  }

  console.log(listagregarRole)

  function disaggregate() {
   
    setListAgregarRole(listagregarRole.filter(({id})=>id !== idRemoved));
}

async function findAgencias() {
  try {
    const { status, data } = await sipcoAxiosService.get(
      "/mantenerUsuario/agenciaUsuarioService", {
        params: {
          codPais: Number(securityFilters.securityValues?.country?.value)
        }
      });
    if (status === 200) {
     setListAgencias(data)
      console.log(data);
    }
  } catch (error) {
    console.error(error);
  }
}

useEffect(()=>{
  findAgencias();
  findAllRoles();
}, [])

const agenciaLogicCheckeds = (e) => {
  console.log(e);

  setCheckedAsignado(e.value?.asignado);
  return (
    <div>
      <Checkbox
        checked={segCheckedRespProycto}
        onClick={() => {
          setCheckedAsignado(!checkedAsignado);
        }}
      ></Checkbox>
    </div>
  );
};

  return (
    <div className={"modal-geral"}>
      <Dialog
        header={title}
        visible={activeModal}
        maximizable
        style={{ width: "70vw" }}
        footer={footerContent}
        onHide={() => {
          if (!activeModal) return;
          setActiveModal(false);
        }}
      >
        <Panel>
          <TabView>
            {/* DATOS GENERALES */}
            <TabPanel header={"Datos Generales"}>
              
            </TabPanel>

            {/* SEGURIDADE */}
            <TabPanel header={"Seguridade"}>
            <div className="limit-Width">
                
                <div className={"org-option"}>
                  <label>
                    <LanguageProvider
                      id={"TODO"}
                      alt="Administrador de Usuarios"
                    />
                  </label>
                  <Checkbox
                    checked={segCheckedRespProycto}
                    onClick={() => {
                      setSegCheckedRespProycto(!segCheckedRespProycto);
                    }}
                  ></Checkbox>
                </div>

                <div className={"org-option"}>
                  <label>
                    <LanguageProvider
                      id={"TODO"}
                      alt="Resp. Proyecto"
                    />
                  </label>
                  <Checkbox
                    checked={segCheckedAqs}
                    onClick={() => {
                      setSegCheckedAqs(!segCheckedAqs);
                    }}
                  ></Checkbox>
                </div>

                <div className={"org-option"}>
                  <label>
                    <LanguageProvider
                      id={"TODO"}
                      alt="Aqs"
                    />
                  </label>
                  <Checkbox
                    checked={segCheckedDanios}
                    onClick={() => {
                      setSegCheckedDanios(!segCheckedDanios);
                    }}
                  ></Checkbox>
                </div>

                <div className={"org-option"}>
                  <label>
                    <LanguageProvider
                      id={"TODO"}
                      alt="Daños"
                    />
                  </label>
                  <Checkbox
                    checked={segCheckedActivo}
                    onClick={() => {
                      setSegCheckedActivo(!segCheckedActivo);
                    }}
                  ></Checkbox>
                </div>
                <div className={"org-option"}>
                  <label>
                    <LanguageProvider
                      id={"TODO"}
                      alt="Activo"
                    />
                  </label>
                  <Checkbox
                    checked={segCheckedAdmUser}
                    onClick={() => {
                      setSegCheckedAdmUser(!segCheckedAdmUser);
                    }}
                  ></Checkbox>
                </div>
              </div>
            </TabPanel>

            {/* AGENCIAS */}
            <TabPanel header={"Agencias"}>
            <div className="m-0" style={{ padding: "1rem", width: "100%" }}>
            <DataTable
            tableStyle={{ width: "100%" }}
            value={listAgencias}
            selectionMode="single" 
            selection={agenciaSelected}
              onSelectionChange={(e)=>{
                 setAgenciaSelected(e.value);
              }}
              
              //dataKey={"id"}
            >
              <Column
                field="asignado"
                header={<LanguageProvider id={"TODO"} alt="Asignado" />}
                body={agenciaLogicCheckeds}
              ></Column>
              <Column
                field="tets"
                header={<LanguageProvider id={"TODO"} alt="Agencia" />}
              ></Column>
            </DataTable>
          </div>
            </TabPanel>

            {/* UBICACION */}
            <TabPanel header={"Ubicacion"}>
              <div className="organizeHorizontally"> 

              
              <div className={"org-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Ubicacion" />
                </label>
                <AutoComplete
                  value={ubicacionSelected}
                  suggestions={listUbicaciones}
                  completeMethod={findUbicacion}
                  onChange={(e) => setUbicacionSelected(e.value?.descripcion)}
                  field="descripcion"
                  dropdown
                  forceSelection
                />
              </div>
              <div className={"org-option"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Area" />
                </label>
                <AutoComplete
                  value={ubicanioAreaSelected}
                  suggestions={listUbicacionAreas}
                  completeMethod={findAreasSistemas}
                  onChange={(e) =>
                    setUbicanioAreaSelected(e.value?.descripcion)
                  }
                  field="descripcion"
                  dropdown
                  forceSelection
                />
              </div>
              </div>
            </TabPanel>

            {/* ROLES */}
            <TabPanel header={"Roles"}>
              <div className={"organizeHorizontally"}>
                <div className={"org-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Roles" />
                  </label>
                  <Dropdown
                    value={rolesRolesSelected}
                    options={listRolesRoles}

                    onChange={(e) =>
                      setRolesRolesSelected(e.value?.descripcion)
                    }
                    optionLabel="descripcion"
                    filter
                  />
                </div>

                <div className={"org-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="País" />
                  </label>
                  <AutoComplete
                    value={rolesPaisSelected}
                    suggestions={listRolesPais}
                    completeMethod={findBySistema}
                    onChange={(e) => setRolesPaisSelected(e.value?.descripcion)}
                    field="descripcion"
                    dropdown
                    forceSelection
                    
                  />
                  <Button onClick={aggregateInTabla}>
                    <LanguageProvider id={"TODO"} alt="Agregar" />
                  </Button>
                  <Button onClick={disaggregate}>
                    <LanguageProvider id={"TODO"} alt="Quitar" />
                  </Button>
                </div>
                <div className="m-0" style={{ padding: "1rem", width: "100%" }}>
            <DataTable
            tableStyle={{ width: "100%" }}
            value={listagregarRole}
            selectionMode="single" 
            selection={roleSelected}
            metaKeySelection={false}
              onSelectionChange={(e)=>{
                setRoleSelected(e.value)
                setIdRemoved(e.value.id)}}
              dataKey={"id"}
            >
              <Column
                field="rol"
                header={<LanguageProvider id={"TODO"} alt="Rol" />}
              ></Column>
              <Column
                field="pais"
                header={<LanguageProvider id={"TODO"} alt="País" />}
              ></Column>
            </DataTable>
          </div>
              </div>
            </TabPanel>
          </TabView>
        </Panel>
      </Dialog>
    </div>
  );
}

export default ModalGeral;
