import "./IngresoManualVehiculos.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

import { AutoComplete } from "primereact/autocomplete";
import { Column } from "primereact/column";
import DamageHistory from "@pages/queries/consulta-vin/components/DamageHistory";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { Filter } from "@shared/components/filter/filter";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { LanguageProvider } from "@shared/components/language-provider";
import LocationHistory from "@pages/queries/consulta-vin/components/LocationHistory";
import Messages from "@pages/queries/consulta-vin/components/Messages";
import MoveHistory from "@pages/queries/consulta-vin/components/MoveHistory";
import OcurrenceHistory from "@pages/queries/consulta-vin/components/OccurrenceHistory";
import Operations from "@pages/queries/consulta-vin/components/Operations";
import { ScreenCodeValue } from "@shared/ScrennCode";
import Third from "@pages/queries/consulta-vin/components/Third";
import { Toast } from "primereact/toast";
import VinView from "@pages/queries/consulta-vin/components/VinView";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

export function IngresoManualVehiculos() {
  const SCREEN_CODE = "fun0123_ingreso_manual_de_vehiculos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);

  usePageViews();
  const [vinData, setVinData] = useState(null);
  const [codVin, setCodVin] = useState("");
  const [listVinObject, setListVinObject] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState([]);
  const [listClientes, setListClientes] = useState([]);
  const [modal, setModal] = useState(false);
  const [loadingVin, setLoadingVin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTabIndex] = useState(0);

  // States de ingreso manual de vehiculos - Funcionalidades
  const [dropTransportista, setDropTransportista] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [interfaz, setInterfaz] = useState(false);
  const [delVin, setDelVin] = useState(false);

  const handleOptionChange = (option) => {
    setSelectedOption(option);

    if (option === "manualDelVin") {
      setDelVin(false);
      setInterfaz(true);
    }

    if(option === "dadosInterfaz"){
      setDelVin(true);
      setInterfaz(false);
    }


    console.log("Del Vin " + delVin);
    console.log("Interfaz " + interfaz);
  };



  const filterLabelId = [
    662, 54, 1491, 1137, 254, 3701, 2693, 9573, 5854, 22700, 9955, 273,
  ];
  const filterNames = [];

  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }

  const showError = (id: string, alt: string) => {
    toast.current.show({
      severity: "error",
      summary: "Erro",
      detail: <LanguageProvider id={id} alt={alt} />,
      life: 3000,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setLoadingVin(true);
      findDatas();
    }
  };

  function findDatas() {
    if (codVin.length >= 8 && codVin.length <= 17) {
      findFirstBy();
    } else {
      setLoadingVin(false);
      return showError(
        "653",
        "Debe ingresar un minimo de 8 y un maximo de 17 dígitos"
      );
    }
  }

  async function findClienteByVin(vin: string) {
    setSelectedCliente([]);

    if (!vin) return showError("681", "Seleccione un vin");

    try {
      const { status, data } = await sipcoAxiosService.get(
        "report/findClientesVinBy",
        {
          params: {
            codPais: securityFilters.securityValues?.country?.id,
            codCuenta: securityFilters.securityValues?.account?.id,
            codVin: vin,
          },
        }
      );

      if (status === 200) {
        setListClientes(data);
        if (data.length > 0 || data.length === 1) {
          setSelectedCliente(data[0].descripcion);
          loadVinVinReportData(data[0]);
        }
        setListClientes(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findFirstBy() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "report/findFirstBy",
        {
          params: {
            codPais: securityFilters.securityValues?.country?.id,
            codCuenta: securityFilters.securityValues?.account?.id,
            codVin: codVin,
          },
        }
      );

      if (status === 200) {
        if (data.length === 0) {
          setLoadingVin(false);
          return showError("1480", "El VIN no existe");
        }

        if (codVin.length === 8) {
          if (data.length > 1) {
            setListVinObject(data);
            setModal(true);
          } else if (data.length === 1) {
            setCodVin(data[0].id);
            findClienteByVin(data[0].id);
          }
        } else if (data.length >= 1) {
          findClienteByVin(data[0].id);
        }

        setLoadingVin(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadVinVinReportData(cliente) {
    if (!selectedCliente) {
      return;
    }
    try {
      const reportResult = await sipcoAxiosService.get("/report/vinReport", {
        params: {
          codPais: securityFilters.securityValues?.country?.id,
          codCuenta: securityFilters.securityValues?.account?.id,
          codCliente: cliente.id,
          codVin: codVin,
        },
      });

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === 200 && data) {
          setVinData(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="ingreso-manual-vehiculos">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={findDatas}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
      </Filter>
      <Accordion multiple activeIndex={[0, 1]}>
        <AccordionTab header={filterNames[11].label}>


          <div className="vin-view">
            <Panel header={"Datos Generales"}>

              <div className="form-row">


                <div className={"colunmOne"}>

                  <RadioButton
                    name="manualDelVin"
                    value="manualDelVin"
                    onChange={() => handleOptionChange("manualDelVin")}
                    checked={selectedOption === "manualDelVin"}
                  />
                  <label>Usar datos de interfaz</label>

                  <RadioButton
                    name="dadosInterfaz"
                    value="dadosInterfaz"
                    onChange={() => handleOptionChange("dadosInterfaz")}
                    checked={selectedOption === "dadosInterfaz"}
                  />
                  <label>Carga Manual del Vin</label>



                </div>

                <div className={"form-field"}>
                  <label>Código de Barras Remito</label>
                  <InputText
                    readOnly={true}
                    value={vinData?.stockPlaya?.columna || ""}
                  />
                </div>

                <div className={"form-field"}>
                  <label>Códdigo de barras datos vin </label>
                  <InputText
                    readOnly={true}
                    value={vinData?.stockPlaya?.nivel || ""}
                  />
                </div>

                <div className={"colunmOne"}>

                  <Checkbox onChange={e => setDropTransportista(e.checked)} checked={dropTransportista}></Checkbox>


                  <label>Transportista</label>
                  {
                    dropTransportista ?
                      (<AutoComplete
                        dropdown />
                      ) : null
                  }

                </div>

              </div>



            </Panel>

            <Panel header={"Remitos"}>
              <div className="form-collumn">
                <div className="form-row">


                  <div className={"form-field"}>
                    <label>Concesionario</label>
                    <Dropdown
                      readOnly={true}
                      value={
                        vinData && vinData.vinResult.marca
                          ? vinData.vinResult.marca.descripcion
                          : ""
                      }
                    />
                  </div>


                  <div className={"form-field"}>
                    <label>Boca de Entrega</label>
                    <Dropdown
                      readOnly={true}
                      value={
                        vinData && vinData.vinResult.marca
                          ? vinData.vinResult.marca.descripcion
                          : ""
                      }
                    />
                  </div>


                  <div className={"form-field"}>
                    <label>Escala</label>
                    <Dropdown
                      readOnly={true}
                      value={
                        vinData && vinData.vinResult.marca
                          ? vinData.vinResult.marca.descripcion
                          : ""
                      }
                    />
                  </div>



                </div>
                <div className="form-row">


                  <div className={"form-field"}>
                    <label>Fecha Emisión</label>
                    <Calendar
                      showIcon
                      value={
                        vinData && vinData.vinResult.patente
                          ? vinData.vinResult.patente
                          : ""
                      }
                    />
                  </div>


                  <div className={"form-field"}>
                    <label>Letra</label>
                    <InputText
                      readOnly={true}
                      value={
                        vinData && vinData.vinResult.codigoPlataforma
                          ? vinData.vinResult.codigoPlataforma
                          : ""
                      }
                    />
                  </div>

                  <div className={"form-field"}>
                    <label>Pto.Venta</label>
                    <InputText
                      readOnly={true}
                      value={
                        vinData && vinData.vinResult.modelo
                          ? vinData.vinResult.modelo.descripcionFull
                          : ""
                      }
                    />
                  </div>

                  <div className={"form-field"}>
                    <label>Número</label>
                    <InputText
                      readOnly={true}
                      value={
                        vinData && vinData.vinResult.modelo
                          ? vinData.vinResult.modelo.descripcion
                          : ""
                      }
                    />
                  </div>

                </div>
              </div>
            </Panel>

            <Panel header={"Datos del Pedido"}>


              <div className="form-row">

                <div className={"colunmOne"}>
                  <label>Fecha Disponibilidad </label>
                  <Checkbox
                    checked
                  />
                  <Calendar
                    showIcon
                    value={vinData?.stockPlaya?.subcuenta?.descripcion || ""}
                  />
                </div>


                <div className={"form-field"}>
                  <label>Nro. Asignación</label>
                  <InputText
                    readOnly={true}
                    value={vinData?.stockPlaya?.subcuenta?.descripcion || ""}
                  />
                </div>



              </div>

            </Panel>

            <Panel header={"Ubicación"}>


              <div className="form-row">

                <div className={"form-field"}>
                  <label>Calle</label>
                  <AutoComplete
                    dropdown
                  />
                </div>

                <div className={"form-field"}>
                  <label>Columna</label>
                  <AutoComplete
                    dropdown
                  />
                </div>

                <div className={"form-field"}>
                  <label>Nível</label>
                  <AutoComplete
                    dropdown
                  />
                </div>

              </div>


            </Panel>

            <Panel header={"Datos del Vehículo"}>

              <div className="form-row">

                <div className={"form-field"}>
                  <label>Marca</label>
                  <AutoComplete
                    dropdown
                  />
                </div>

                <div className={"form-field"}>
                  <label>VIN</label>
                  <InputText
                    readOnly={true}
                    value={vinData?.stockPlaya?.calle || ""}
                  />
                </div>

                <div className={"form-field"}>
                  <label>Código Modelo</label>
                  {
                    interfaz ? 
                    (
                      <InputText/>
                    )
                    :
                    <AutoComplete
                    dropdown
                  />
                    
                  }
              
                </div>
              </div>


              <div className="form-row">

                <div className={"form-field"}>
                  <label>Mercado</label>
                  <Dropdown />
                </div>

                <div className={"form-field"}>
                  <label>Nro. Motor</label>
                  <InputText
                    readOnly={true}
                    value={vinData?.stockPlaya?.calle || ""}
                  />
                </div>

                <div className={"form-field"}>
                  <label>Modelo</label>
                  <InputText
                    readOnly={true}
                    value={vinData?.stockPlaya?.columna || ""}
                  />
                </div>

              </div>
              <div className="form-row">

                <div className={"form-field"}>
                  <label>Destino</label>
                  <Dropdown />
                </div>

                <div className={"form-field"}>
                  <label>Cód. Plataforma</label>
                  <InputText
                    readOnly={true}
                    value={vinData?.stockPlaya?.calle || ""}
                  />
                </div>

                <div className={"form-field"}>
                  <label>Color</label>
                  {
                    interfaz ? 
                    (
                      <InputText/>
                    )
                    :
                    <AutoComplete
                    dropdown
                  />
                    
                  }
                </div>

              </div>

              <div className="form-row">

                <div className={"form-field"}>
                  <label>Fecha Ingreso Playa</label>
                  <Calendar
                    showIcon
                    showTime />
                </div>


              </div>
            </Panel>

            <Divider />

            <div>
              <Button
                label={LanguageProvider({
                  id: "34",
                  alt: "Procesar o guardar cambios",
                })}
                text
              />
              <Button
                label={LanguageProvider({
                  id: "78",
                  alt: "Deshechar Cambios",
                })}
                text
              />
            </div>


          </div>
        </AccordionTab>

      </Accordion>
    </div>
  );
}
