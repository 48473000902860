import React, { useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { Tag } from "primereact/tag";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterColumnsIds } from "../hooks/useFilterColumnsIds";

const TransferenciaMasiva: React.FC<any> = () => {
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    capitalyHBA: true,
  });
  const table1 = [13, 1491, 19365, 19366, 19367, 19368];

  const columNames = useFilterColumnsIds(table1);

  return (
    <div>
      <div className="filter-options">
        <div className="sipco-options-line">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"927"} alt="Archivo" />
            </label>
            <InputText />
          </div>
          <div className={"sipco-option-button"}>
            <Button
              label={LanguageProvider({
                id: "4997",
                alt: "Agregar Archivo",
              })}
            />
          </div>
          <div className="RadioButtonFilter">
            <label>
              <LanguageProvider id={"21367"} alt="Pago?" />
            </label>
            <Checkbox checked={false}></Checkbox>
          </div>
        </div>
      </div>
      <DataTable
        size={"small"}
        showGridlines
        stripedRows
        value={data}
        paginator
        scrollHeight="flex"
        filterDisplay="menu"
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        style={{ maxWidth: "100%" }}
        loading={loading}
      >
        {columNames.map((colum, index) => (
          <Column
            key={index}
            field={colum.id}
            header={colum.label}
            sortable
            filter
          />
        ))}
      </DataTable>
      {/* tag */}
      <Tag
        severity="danger"
        value={LanguageProvider({
          id: "13444",
          alt: "Registros con errores.",
        })}
      />

      {/* buttons */}
      <div>
        <Button
          label={LanguageProvider({
            id: "16283",
            alt: "Procesar",
          })}
          text
        />
        <Button
          label={LanguageProvider({
            id: "26",
            alt: "Baja",
          })}
          text
        />
        <Button
          label={LanguageProvider({
            id: "3155",
            alt: "Cancelar",
          })}
          text
        />
      </div>
    </div>
  );
};
export default TransferenciaMasiva;
