import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import React from "react";

const CargaMasivaAba: React.FC<{ vinData: any }> = ({ vinData }) => {
  const filterColumnsId = [1491, 14288, 3625];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  return (
    <div className="vin-view">
      <Panel>
        <div className="form-row">
          <div className={"form-field"}>
            <div className="sipco-option">
              <label>CheckPoint</label>
              <AutoComplete dropdown />
            </div>
            <div className="sipco-option">
              <label>Parte dañada</label>
              <AutoComplete dropdown />
            </div>
            <div className="sipco-option">
              <label>Descripción Daño</label>
              <AutoComplete dropdown />
            </div>
            <div className="sipco-option">
              <label>Observaciones</label>
              <InputText
                readOnly={true}
                value={vinData?.stockPlaya?.nivel || ""}
              />
            </div>
            <div className="sipco-option">
              <label>Nro. Check List</label>
              <InputText
                readOnly={true}
                value={vinData?.stockPlaya?.nivel || ""}
              />
            </div>
            <div className="sipco-option">
              <label>Comentario Dossier</label>
              <InputText
                readOnly={true}
                value={vinData?.stockPlaya?.nivel || ""}
              />
            </div>
          </div>

          <div className={"form-field"}>
            <div className="sipco-option">
              <label>Imputaciones</label>
              <AutoComplete dropdown />
            </div>
            <div className="sipco-option">
              <label>Cuadrante LD</label>
              <AutoComplete dropdown />
            </div>
            <div className="sipco-option">
              <label>Unidad de Medida</label>
              <AutoComplete dropdown />
              <label>Valor</label>
              <InputText
                readOnly={true}
                value={vinData?.stockPlaya?.nivel || ""}
              />
            </div>
            <div className="sipco-option">
              <label>Fecha del daño</label>
              <Calendar
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
                value={new Date()}
              />
              <label>Importe</label>
              <InputText
                readOnly={true}
                value={vinData?.stockPlaya?.nivel || ""}
              />
            </div>
            <div className="sipco-option">
              <label>Fecha SAGAI</label>
              <Calendar
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
                value={new Date()}
              />
            </div>
          </div>
          <div className={"form-field"}>
            <div className="sipco-option">
              <label>Comentario Dossier</label>
              <AutoComplete dropdown />
            </div>
            <div className="sipco-option">
              <label>Gravedad del Daño</label>
              <AutoComplete dropdown />
            </div>
            <div className="sipco-option">
              <label>Cod. Inspector</label>
              <AutoComplete dropdown />
            </div>
            <div className="sipco-option">
              <label>Resultado Analisis</label>
              <AutoComplete dropdown />
            </div>
            <div className="sipco-option">
              <label>Nro. Dossier</label>
              <InputText
                readOnly={true}
                value={vinData?.stockPlaya?.nivel || ""}
              />
            </div>
          </div>
        </div>
      </Panel>
      <Fieldset
        legend={
          <label>
            Datos - Cantidad de Registros a Procesar.: 0 - Registros con
            errores.: 0
          </label>
        }
      >
        <div>
          <label style={{ marginRight: "10px" }}>
            <LanguageProvider id={1491} alt="VIN:" />
          </label>
          <AutoComplete dropdown />
        </div>

        <Divider />

        <DataTable
          size="small"
          showGridlines
          stripedRows
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          selectionMode="single"
        >
          <Column field="1" header={columnsNames[0].label} sortable filter />
          <Column field="2" header={columnsNames[1].label} sortable filter />
          <Column field="3" header={columnsNames[2].label} sortable filter />
        </DataTable>
        <div>
          <Button label={LanguageProvider({ id: "23824", alt: "Alta" })} text />
        </div>
      </Fieldset>
    </div>
  );
};

export default CargaMasivaAba;
