import "./MovimentoStock.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function MovimentoStock() {
  const SCREEN_CODE = "con0039_calculo_stock";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();

  const dt = useRef(null);

  const filterColumnsId = [2020, 2607, 2609, 2610, 2608, 2890];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 2612, 1184, 1182, 5044, 54, 1791, 4791, 327, 467, 468, 1140, 2020,
    2607, 2609, 2610, 2608, 2890, 16531,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [talleres, setTalleres] = useState([]);
  const [loadingTalleres, setLoadingTalleres] = useState(false);
  const [piezas, setPiezas] = useState([]);
  const [loadingPiezas, setLoadingPiezas] = useState(false);

  const [filter, setFilter] = useState({
    taller: null,
    fechaInicio: new Date(),
    fechaFinal: new Date(),
    pieza: null,
  });

  const [reporte, setReporte] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);

  async function loadPiezas(query: string) {
    setLoadingPiezas(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.client &&
        query.length >= 3
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/calculo-stock/combo/pieza",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codCuenta: securityFilters.securityValues.account.id,
              descripcion: query,
            },
          }
        );

        if (status === 200) {
          setPiezas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPiezas(false);
    }
    return [];
  }
  async function loadTalleres() {
    setLoadingTalleres(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/calculo-stock/combo/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
            },
          }
        );

        if (status === 200) {
          setTalleres(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTalleres(false);
    }
  }

  async function loadReportData() {
    try {
      setLoadingReport(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        codTaller: filter?.taller?.id || null,
        codPieza: filter?.pieza?.id || null,
        rangeFecha: [filter?.fechaInicio || null, filter?.fechaFinal || null],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/calculo-stock/report",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReport(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null
      ) {
        try {
          const talleres = await loadTalleres();
          setFilter({
            ...filter,
            taller: talleres[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  const data = convertDateObjects(reporte?.filas);

  return (
    <div className="movimento-stock">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <Dropdown
                  value={filter.taller}
                  options={talleres}
                  onChange={(e) => setFilter({ ...filter, taller: e.value })}
                  optionLabel="descripcion"
                  filter
                  showClear
                  loading={loadingTalleres}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <Calendar
                  value={filter.fechaInicio}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaInicio: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <Calendar
                  value={filter.fechaFinal}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaFinal: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2020"} alt="Pieza" />
                </label>
                <AutoComplete
                  value={filter.pieza}
                  suggestions={piezas}
                  completeMethod={(e) => {
                    return loadPiezas(e.query);
                  }}
                  onChange={(e) => setFilter({ ...filter, pieza: e.value })}
                  field="descripcion"
                  forceSelection
                  dropdown
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id={"2599"} alt="Movimiento Stock" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={LanguageProvider({
                    id: "2599",
                    alt: "Movimiento Stock",
                  })}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReport}
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default MovimentoStock;
