import "./ComprasTaller.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ComprasTaller() {
  const SCREEN_CODE = "con0038_compras_taller";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const filterLabelId = [327, 467, 468, 1151, 2521];
  const filterNames = [];
  const filterColumnsId = [
    5372, 16480, 932, 2519, 10829, 31, 1253, 1974, 4137, 4587, 2520, 2521,
    16481, 16482, 16483, 1974, 13706, 5271, 5788, 16476, 2263, 8818, 17041,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    1002, 65, 5583, 5044, 54, 1791, 1182, 4791, 327, 467, 468, 1151, 5372, 4137,
    16480, 932, 2519, 10829, 31, 1253, 1974, 4587, 2520, 2521, 16481, 16482,
    16483, 13706, 5271, 5788, 16476, 2263, 8818, 2521, 17041,
  ];

  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reportData, setReportData] = useState(null);

  const [loadingTalleres, setLoadingTalleres] = useState(false);
  const [talleres, setTalleres] = useState([]);
  const [loadingEstados, setLoadingEstados] = useState(false);
  const [estados, setEstados] = useState([]);
  const [loadingProvedores, setLoadingProvedores] = useState(false);
  const [provedores, setProvedores] = useState([]);

  const [filter, setFilter] = useState({
    taller: null,
    estado: null,
    provedor: null,
    fechaInicio: null,
    fechaFinal: null,
    aplicarFiltroFecha: false,
  });

  async function loadTaller() {
    setLoadingTalleres(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/compras-taller/combo/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
            },
          }
        );
        if (status === 200) {
          setTalleres(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTalleres(false);
    }
  }
  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/compras-taller/combo/estado-documento",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcion: "Todos" });
          setEstados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
  }
  async function loadProvedores() {
    setLoadingProvedores(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/compras-taller/combo/proveedor"
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcion: "Todos" });
          setProvedores(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProvedores(false);
    }
  }
  async function loadReportData() {
    setLoadingReportData(true);
    try {
      let filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        codTaller: filter?.taller?.id || null,
        estado: null,
        proveedor: null,
        rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
      };

      if (filter.estado != null && filter.estado.id != 0) {
        filters.estado = filter.estado.id;
      }
      if (filter.provedor != null && filter.provedor.id != 0) {
        filters.proveedor = filter.provedor.id;
      }
      const { status, data } = await sipcoAxiosService.post(
        "compras-taller/report",
        filters
      );
      if (status === 200) {
        setReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const talleres = await loadTaller();
        const estados = await loadEstados();
        setFilter({
          ...filter,
          taller: talleres[0],
          estado: estados[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);
  useEffect(() => {
    async function handleCountryChange() {
      try {
        const provedores = await loadProvedores();
        setFilter({
          ...filter,
          provedor: provedores[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    handleCountryChange();
  }, [securityFilters.securityValues.country]);

  const formattedReportData = convertDateObjects(reportData?.filas);

  return (
    <div className="compras-taller">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id="743" alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <Dropdown
                  value={filter.taller}
                  options={talleres}
                  onChange={(e) => {
                    setFilter({ ...filter, taller: e.value });
                  }}
                  filter
                  optionLabel="descripcion"
                  loading={loadingTalleres}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={"3233"}
                    alt="Aplicar Filtro por Fecha"
                  />
                </label>
                <Checkbox
                  checked={filter.aplicarFiltroFecha}
                  onChange={(e) => {
                    if (e.checked) {
                      setFilter({
                        ...filter,
                        aplicarFiltroFecha: e.checked,
                        fechaInicio: new Date(),
                        fechaFinal: new Date(),
                      });
                    } else {
                      setFilter({
                        ...filter,
                        aplicarFiltroFecha: e.checked,
                        fechaInicio: null,
                        fechaFinal: null,
                      });
                    }
                  }}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <Calendar
                  value={filter.fechaInicio}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaInicio: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                  disabled={!filter.aplicarFiltroFecha}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <Calendar
                  value={filter.fechaFinal}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaFinal: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                  disabled={!filter.aplicarFiltroFecha}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1151"} alt="Estados" />
                </label>
                <Dropdown
                  value={filter.estado}
                  options={estados}
                  onChange={(e) => {
                    setFilter({ ...filter, estado: e.value });
                  }}
                  filter
                  optionLabel="descripcion"
                  loading={loadingEstados}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2521"} alt="Proveedor" />
                </label>
                <Dropdown
                  value={filter.provedor}
                  options={provedores}
                  onChange={(e) => {
                    setFilter({ ...filter, provedor: e.value });
                  }}
                  filter
                  optionLabel="descripcion"
                  loading={loadingProvedores}
                  virtualScrollerOptions={{ itemSize: 38 }}
                  showClear
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="2355" alt="Compras Taller" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={formattedReportData}
                  columns={columnsNames}
                  screenName={"Compras Taller"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={formattedReportData}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          resizableColumns
          removableSort
          filterDisplay="menu"
          loading={loadingReportData}
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ComprasTaller;
