import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { RadioButton } from "primereact/radiobutton";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { format, sub } from "date-fns";
import useFilterColumnsIds from "../../../../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const columnsIdsHeaderColecta = [
  { header: 10758, field: "id" },
  { header: 5686, field: "fechaHojaRuta.date" },
  { header: 4285, field: "tractor.descripcion" },
  { header: 10902, field: "batea.descripcion" },
  { header: 4655, field: "chofer.nombre" },
  { header: 10893, field: "administradorTractor.descripcion" },
  { header: 3344, field: "estado.descripcion" },
  { header: 10767, field: "calle" },
  { header: 10767, field: "columna" },
];

const columnsIdsDetailsColecta = [
  { header: 1491, field: "vin.id" },
  { header: 1316, field: "remito.puntoDeVenta" },
  { header: 326, field: "remito.id" },
  { header: 740, field: "subcuenta.descripcion" },
  { header: 10903, field: "subcuentaOrigen.descripcion" },
  { header: 382, field: "tipoRegistro.id" },
  { header: 2941, field: "concesionarioP.descripcion" },
  { header: 10904, field: "direccionEntregaP.direccion" },
  { header: 3344, field: "tipoRegistro.descripcion" },
  {
    header: 22507,
    field: "subcuenta.subcuentaDetalle.algoritmoUbicacionPlaya",
  },
  { header: 10898, field: "fechaDespacho.date" },
];

const columnsIdsHeaderCTRC = [
  { header: 1316, field: "serie" },
  { header: 10794, field: "id" },
  { header: 1492, field: "fechaEmision.date" },
  { header: 10883, field: "cfop" },
  { header: 10884, field: "ciudadOrigen.descripcion" },
  { header: 10885, field: "ciudadDestino.descripcion" },
  { header: 10886, field: "concesionarioRe.descripcion" },
  { header: 10887, field: "direccionEntregaRe.direccion" },
  { header: 10888, field: "concesionarioDe.descripcion" },
  { header: 10889, field: "direccionEntregaDe.direccion" },
  { header: 10890, field: "concesionarioCo.descripcion" },
  { header: 10891, field: "direccionEntregaCo.direccion" },
  { header: 2805, field: "fleteaCargo" },
  { header: 10893, field: "proveedor.descripcion" },
  { header: 9215, field: "tipoImpuesto" },
  { header: 2898, field: "peso" },
  { header: 10894, field: "importeSeguro" },
  { header: 10895, field: "porcentajeAlicuota" },
  { header: 10896, field: "importeImpuesto" },
  { header: 10897, field: "importeBaseCalculo" },
  { header: 2724, field: "importeTotal" },
  { header: 3344, field: "estado" },
  { header: 3625, field: "observaciones" },
  { header: 10898, field: "fechaDespacho.date" },
  { header: 10899, field: "funcion" },
  { header: 10900, field: "@notaPedidoFlete.id" },
];

const columnsIdsDetailsCTRC = [
  { header: 1316, field: "serie" },
  { header: 10794, field: "id" },
  { header: 1491, field: "vin.id" },
  { header: 2898, field: "peso" },
  { header: 1316, field: "remito.puntoDeVenta" },
  { header: 10747, field: "remito.id" },
  { header: 32, field: "valor" },
  { header: 10776, field: "naturalezaFiscal.id" },
  { header: 10758, field: "hojaRuta.id" },
  { header: 10909, field: "centroCosto.id" },
  { header: 3344, field: "ordenInterna" },
  { header: 10772, field: "cuentaGasto.id" },
  { header: 11051, field: "funcion" },
  { header: 396, field: "vin.marca.id" },
  { header: 396, field: "vin.marca.descripcion" },
  { header: 3344, field: "estado.id" },
];

export function RecepcionPlayaBrForm(props) {
  const { SCREEN_CODE } = props;
  const toast = useRef<Toast>(null);

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const columnsNamesHeaderColecta = useFilterColumnsIds(
    columnsIdsHeaderColecta
  );
  const columnsNamesDetaisColecta = useFilterColumnsIds(
    columnsIdsDetailsColecta
  );
  const columnsNamesHeaderCTRC = useFilterColumnsIds(columnsIdsHeaderCTRC);
  const columnsNamesDetaisCTRC = useFilterColumnsIds(columnsIdsDetailsCTRC);

  const [isColetaOrCtrc, setIsColetaOrCtrc] = useState("CTRC");
  const [dataTableHeaderValue, setDataTableHeaderValue] = useState([]);
  const [initialDataTableHeader, setinitialDataTableHeader] = useState([]);
  const [dataTableDetailsValue, setDataTableDetailsValue] = useState([]);
  const [columnsNamesHeader, setColumnsNamesHeader] = useState([]);
  const [columnsNamesDetails, setColumnsNamesDetails] = useState([]);
  const [allCallesSuggestions, setAllCallesSuggestions] = useState([]);
  const [callesSuggestions, setCallesSuggestions] = useState([]);
  const [allColumnasSuggestions, setAllColumnasSuggestions] = useState([]);
  const [columnasSuggestions, setColumnasSuggestions] = useState([]);
  const [allNivelesSuggestions, setAllNivelesSuggestions] = useState([]);
  const [nivelesSuggestions, setNivelesSuggestions] = useState([]);
  const [colectaSuggestions, setColectaSuggestions] = useState([]);
  const [ctrcSuggestions, setCtrcSuggestions] = useState([]);
  const [loadingDataTableHeader, setLoadingDataTableHeader] = useState(false);
  const [loadingDataTableDetails, setLoadingDataTableDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAllCalles, setLoadingAllCalles] = useState(false);
  const [loadingAllColumnas, setLoadingAllColumnas] = useState(false);
  const [loadingAllNiveles, setLoadingAllNiveles] = useState(false);
  const [loadingCalles, setLoadingCalles] = useState(false);
  const [loadingColumnas, setLoadingColumnas] = useState(false);
  const [loadingNiveles, setLoadingNiveles] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedRowsHeader, setSelectedRowsHeader] = useState([]);
  const [selectedRowsDetails, setSelectedRowsDetails] = useState([]);
  const [InitialListCtrc, setInitialListCtrc] = useState([]);
  const [InitialListColeta, setInitialListColeta] = useState([]);

  const [formState, setFormState] = useState({
    allCalles: "",
    allColumnas: "",
    allNiveles: "",
    ctrc: "",
    colecta: "",
    fecha: new Date(),
    newCalles: "",
    newColumnas: "",
    newNiveles: "",
    ctrcFilter: "",
  });

  function loadingAllTrue() {
    setLoadingAllCalles(true);
    setLoadingAllColumnas(true);
    setLoadingAllNiveles(true);
    setLoading(true);
    setLoadingCalles(true);
    setLoadingColumnas(true);
    setLoadingNiveles(true);
    setLoadingDataTableHeader(true);
    setLoadingDataTableDetails(true);
  }
  function loadingAllFalse() {
    setLoadingAllCalles(false);
    setLoadingAllColumnas(false);
    setLoadingAllNiveles(false);
    setLoading(false);
    setLoadingCalles(false);
    setLoadingColumnas(false);
    setLoadingNiveles(false);
    setLoadingDataTableHeader(false);
    setLoadingDataTableDetails(false);
  }
  function checkSecurityFilters() {
    if (
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id &&
      securityFilters.securityValues.country?.id &&
      securityFilters.securityValues.subaccount?.id
    ) {
      return true;
    } else {
      toast.current?.show({
        severity: "warn",
        summary: "Error",
        detail: "País, Clientes e Cuentas Obrigatorio",
        life: 3000,
      });
      return false;
    }
  }

  async function loadDataTableHeaderCtrc() {
    try {
      setLoadingDataTableHeader(true);
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
      };

      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-de-playa/ctrc",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          setDataTableHeaderValue(data);
          setinitialDataTableHeader(data);
          const ids = data.map((item) => item.id);
          ids.unshift("TODOS");
          setInitialListCtrc(ids);
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
          setDataTableHeaderValue([]);
          setinitialDataTableHeader([]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTableHeader(false);
    }
  }

  async function loadDataTableHeaderColeta() {
    try {
      setLoadingDataTableHeader(true);
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
      };

      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-de-playa/hoja-ruta-br",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          setDataTableHeaderValue(data);
          setinitialDataTableHeader(data);
          const ids = data.map((item) => item.id);
          ids.unshift("TODOS");
          setInitialListColeta(ids);
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
          setDataTableHeaderValue([]);
          setinitialDataTableHeader([]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTableHeader(false);
    }
  }

  function loadReportData() {
    if (isColetaOrCtrc === "CTRC") {
      loadDataTableHeaderCtrc();
    } else {
      loadDataTableHeaderColeta();
    }
  }

  async function loadAllCalles(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-de-playa/all-calles",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          if (query) {
            setAllCallesSuggestions(
              data.filter((x: any) =>
                x.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setAllCallesSuggestions(data);
            return data;
          }
        } else {
          setAllCallesSuggestions(["N/A"]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchAllCalles(event: any) {
    await loadAllCalles(event.query);
  }

  async function loadCalles(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-de-playa/calles-br",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          if (query) {
            setCallesSuggestions(
              data.filter((x: any) =>
                x.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setCallesSuggestions(data);
            return data;
          }
        } else {
          setCallesSuggestions(["N/A"]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchCalles(event: any) {
    await loadCalles(event.query);
  }

  async function loadAllColumnas(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
        allCalle: formState.allCalles,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-de-playa/all-columnas",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          if (query) {
            setAllColumnasSuggestions(
              data.filter((x: any) =>
                x.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setAllColumnasSuggestions(data);
            return data;
          }
        } else {
          setAllColumnasSuggestions(["N/A"]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchAllColumnas(event: any) {
    await loadAllColumnas(event.query);
  }

  async function loadColumnas(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
        calle: formState.newCalles,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-de-playa/columnas-br",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          if (query) {
            setColumnasSuggestions(
              data.filter((x: any) =>
                x.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setColumnasSuggestions(data);
            return data;
          }
        } else {
          setColumnasSuggestions(["N/A"]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchColumnas(event: any) {
    await loadColumnas(event.query);
  }

  async function loadAllNiveles(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
        allCalle: formState.allCalles,
        allColumna: formState.allColumnas,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-de-playa/all-niveles",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          if (query) {
            setAllNivelesSuggestions(
              data.filter((x: any) =>
                x.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setAllNivelesSuggestions(data);
            return data;
          }
        } else {
          setAllNivelesSuggestions(["0"]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchAllNiveles(event: any) {
    await loadAllNiveles(event.query);
  }

  async function loadNiveles(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
        calle: formState.newCalles,
        columna: formState.newColumnas,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-de-playa/niveles-br",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          if (query) {
            setNivelesSuggestions(
              data.filter((x: any) =>
                x.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setNivelesSuggestions(data);
            return data;
          }
        } else {
          setNivelesSuggestions(["0"]);
          return ["0"];
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchNiveles(event: any) {
    await loadNiveles(event.query);
  }

  async function loadColecta(query = "") {
    try {
      if (query) {
        const dataFiltered = InitialListColeta.filter((x: any) =>
          x.toString().toLowerCase().includes(query.toLowerCase())
        ).filter(
          (value, index, self) => index === self.findIndex((t) => t === value)
        );
        setColectaSuggestions(dataFiltered);
      } else {
        const uniqueData = InitialListColeta.filter(
          (value, index, self) => index === self.findIndex((t) => t === value)
        );
        setColectaSuggestions(uniqueData);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchColecta(event: any) {
    await loadColecta(event.query);
  }

  async function loadCtrc(query = "") {
    try {
      if (query) {
        const dataFiltered = InitialListCtrc.filter((x: any) =>
          x.toString().toLowerCase().includes(query.toLowerCase())
        ).filter(
          (value, index, self) => index === self.findIndex((t) => t === value)
        );
        setCtrcSuggestions(dataFiltered);
      } else {
        const uniqueData = InitialListCtrc.filter(
          (value, index, self) => index === self.findIndex((t) => t === value)
        );
        setCtrcSuggestions(uniqueData);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchCtrc(event: any) {
    await loadCtrc(event.query);
  }

  function validateSecurityFilters(): boolean {
    return !!(
      securityFilters.securityValues.country?.id &&
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id &&
      securityFilters.securityValues.subaccount?.id
    );
  }

  function updateFormState(newState) {
    setFormState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }

  async function loadData(
    setLoading: (val: boolean) => void,
    loadFunction: () => Promise<any>,
    setData: (data: any) => void
  ) {
    try {
      setLoading(true);
      const result = await loadFunction();
      if (result) {
        setData(result[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function loadDataTableDetailsCtrc(crtcRow) {
    try {
      setLoadingDataTableDetails(true);
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
        listCabeceraCtrc: crtcRow,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-de-playa/detalle-ctrc",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          // setDataTableDetails(data);
          return data;
        } else {
          return null;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTableDetails(false);
    }
  }

  async function loadDataTableDetailsColeta(coletaRow) {
    try {
      setLoadingDataTableDetails(true);
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
        nroColecta: coletaRow[0].id,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-de-playa/linea-hoja-ruta-br",
        params
      );
      if (status === 200) {
        if (data.length > 0) {
          setDataTableDetailsValue(data);
          // return data;
        } else {
          return null;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTableDetails(false);
    }
  }

  async function selectItems(params) {
    try {
      // Atualiza os itens selecionados
      if (params.length > 0) {
        const lastSelectedItem = params[params.length - 1];

        // Verifica se o item foi desmarcado, comparando com os itens previamente selecionados
        const deselectedItems = selectedRowsHeader.filter(
          (item) => !params.some((selected) => selected.id === item.id)
        );

        if (deselectedItems.length > 0) {
          // Remove o item desmarcado do array `dataTableDetails`
          setDataTableDetailsValue((prevState) =>
            prevState.filter(
              (data) =>
                !deselectedItems.some((deselected) => deselected.id === data.id)
            )
          );
        }

        setSelectedRowsHeader(params);

        // Carrega os detalhes apenas para o último item selecionado
        let data;
        if (isColetaOrCtrc === "CTRC") {
          [data] = await loadDataTableDetailsCtrc([lastSelectedItem]);
          setDataTableDetailsValue((prevState) => {
            // Verifica se o item já existe no prevState com base no id
            const itemExists = prevState.some((item) => item.id === data.id);

            // Se o item não existir, adiciona; caso contrário, retorna o estado atual
            if (!itemExists) {
              return [...prevState, data];
            }
            return prevState; // Retorna o estado atual sem modificações
          });
        } else {
          await loadDataTableDetailsColeta([lastSelectedItem]);
        }
      } else {
        setSelectedRowsHeader([]);
      }

      if (isColetaOrCtrc === "CTRC") {
        // Sincroniza `dataTableDetails` com `dataTableHeader`
        setDataTableDetailsValue((prevState) => {
          // Filtra os itens de `dataTableDetails` que não estão em `params` (que são os itens selecionados no `dataTableHeader`)
          const filteredDetails = prevState.filter((detail) =>
            params.some((selected) => selected.id === detail.id)
          );

          // Atualiza `dataTableDetails`, removendo itens que não estão em `params`
          return filteredDetails.length !== prevState.length
            ? filteredDetails
            : prevState;
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function selectAllItems() {
    try {
      setLoadingDataTableHeader(true);
      const data = await loadDataTableDetailsCtrc(dataTableHeaderValue);
      if (data.length > 0) {
        setSelectedRowsHeader(dataTableHeaderValue);
        setDataTableDetailsValue(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTableHeader(false);
    }
  }

  async function selectAllItemsDetails() {
    setSelectedRowsDetails(dataTableDetailsValue);
  }

  function deselectAllItems() {
    setSelectedRowsHeader([]);
    setDataTableDetailsValue([]);
  }
  function deselectAllItemsDetails() {
    setSelectedRowsDetails([]);
  }

  async function validarCantidadSuperior(cantidad) {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
        },
        calle: formState.newCalles,
        columna: formState.newColumnas,
        nivel: formState.newNiveles,
        cantidad: cantidad,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-de-playa/validar-cantidades",
        params
      );
      if (status === 200) {
        return data;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  function askForConfirmationValidateModificar() {
    return new Promise((resolve) => {
      confirmDialog({
        message: (
          <LanguageProvider
            id={"660"}
            alt="Esta ultrapassando a Quantidade permitida - Deseja Continuar ?"
          />
        ),
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "reject",
        accept: () => resolve(true),
        reject: () => resolve(false),
      });
    });
  }

  async function validateModificar() {
    if (isColetaOrCtrc.toUpperCase() === "COLECTA") {
      if (selectedRowsDetails.length === 0) {
        toast.current?.show({
          severity: "warn",
          summary: "Error",
          detail: (
            <LanguageProvider
              id="753"
              alt="Deve selecionar ao menos um registro do quadro"
            />
          ),
          life: 3000,
        });
        return false;
      }
    } else {
      if (selectedRowsDetails.length !== selectedRowsHeader.length) {
        toast.current?.show({
          severity: "warn",
          summary: "Error",
          detail: (
            <LanguageProvider
              id="753"
              alt="Deve selecionar ao menos um registro do quadro"
            />
          ),
          life: 3000,
        });
        return false;
      }
    }
    // Lucas Silvestre - 18/11/2024
    // validação feita no codigo antigo do projeto, foi adaptado para ter o mesmo resultado.
    // if (!filterForm.subcuenta.subcuentaDetalle.algoritmoUbicacionPlaya.id != "1" &&
    //   !filterForm.subcuenta.subcuentaDetalle.algoritmoUbicacionPlaya.id != "2" &&
    //   !filterForm.subcuenta.subcuentaDetalle.algoritmoUbicacionPlaya.id != "3") {
    if (
      securityFilters.subAccountDetails?.algoritmoUbicacionPlaya?.id !== "1" &&
      securityFilters.subAccountDetails?.algoritmoUbicacionPlaya?.id !== "2" &&
      securityFilters.subAccountDetails?.algoritmoUbicacionPlaya?.id !== "3"
    ) {
      if (
        formState.newCalles === null ||
        formState.newColumnas === null ||
        formState.newNiveles === null
      ) {
        toast.current?.show({
          severity: "warn",
          summary: "Error",
          detail: <LanguageProvider id="39" alt="Há dados em branco." />,
          life: 3000,
        });
        return false;
      }

      const cantidad = selectedRowsDetails.length;

      const result = await validarCantidadSuperior(cantidad);
      if (result) {
        const userConfirmed = await askForConfirmationValidateModificar();
        return userConfirmed;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  async function recepcionPlayaColecta() {
    try {
      const params = {
        url: "",
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
          subcuentaDetalle: securityFilters.subAccountDetails,
        },
        lineasHojaRuta: selectedRowsDetails,
        fecha: { date: formState.fecha },
        stockPlayaOrigen: {
          calle: formState.allCalles,
          columna: formState.allColumnas,
          nivel: formState.allNiveles,
        },
        stockPlayaDestino: {
          calle: formState.newCalles,
          columna: formState.newColumnas,
          nivel: formState.newNiveles,
        },
        hojaRuta: selectedRowsHeader[0],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-de-playa/recepcion-br",
        params
      );
      if (status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function recepcionPlayaCtrcMasivo() {
    try {
      const params = {
        url: "",
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
          descripcion: securityFilters.securityValues.client.descripcion,
        },
        subcuenta: {
          id: securityFilters.securityValues.subaccount.id,
          subcuentaDetalle: securityFilters.subAccountDetails,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        detalleCtrcs: selectedRowsDetails,
        fecha: { date: formState.fecha },
        stockPlayaOrigen: {
          calle: formState.allCalles,
          columna: formState.allColumnas,
          nivel: formState.allNiveles,
        },
        stockPlayaDestino: {
          calle: formState.newCalles,
          columna: formState.newColumnas,
          nivel: formState.newNiveles,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-de-playa/recepcion-br-massivo",
        params
      );
      if (status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  function askForConfirmationModificar() {
    return new Promise((resolve) => {
      confirmDialog({
        message: (
          <LanguageProvider
            id={"13275"}
            alt="Deseja processar as alterações realizadas?"
          />
        ),
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "reject",
        accept: () => resolve(true),
        reject: () => resolve(false),
      });
    });
  }
  async function modificar() {
    loadingAllTrue();
    try {
      let result;
      const valid = await validateModificar();
      console.log(valid);
      if (valid) {
        const userConfirmed = await askForConfirmationModificar();
        if (userConfirmed) {
          if (isColetaOrCtrc.toUpperCase() === "COLECTA") {
            result = await recepcionPlayaColecta();
          } else {
            result = await recepcionPlayaCtrcMasivo();
          }

          //returnImprimir
          /*
          var result:int = super.printZebra(ViewUtil.getStringFromArray(ac.toArray()));
				if (result == -1) {
					super.alert(10650);
				}
        super.printZebra(ViewUtil.getStringFromArray(ac.toArray()));
				gridCTRC.dataProvider = null;
				gridCTRCDetalle.dataProvider = null;
				gridColectas.dataProvider = null;
				gridColectasDetalle.dataProvider = null;
				refresh(e);
				enableAll(false);
        */
          if (result) {
            setDataTableDetailsValue([]);
            setDataTableHeaderValue([]);
            setSelectedRowsDetails([]);
            setSelectedRowsHeader([]);
            toast.current?.show({
              severity: "success",
              detail: (
                <LanguageProvider
                  id="36"
                  alt="Sua transação se realizou com sucesso."
                />
              ),
              life: 3000,
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      loadingAllFalse();
    }
  }

  useEffect(() => {
    if (validateSecurityFilters()) {
      loadData(setLoadingAllCalles, loadAllCalles, (data) =>
        updateFormState({ allCalles: data })
      );
      loadData(setLoadingCalles, loadCalles, (data) =>
        updateFormState({ newCalles: data })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues]);

  useEffect(() => {
    setFormState((prev) => ({ ...prev, allColumnas: "", allNiveles: "" }));
    if (validateSecurityFilters()) {
      loadData(setLoadingAllColumnas, loadAllColumnas, (data) =>
        updateFormState({ allColumnas: data })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.allCalles]);

  useEffect(() => {
    setFormState((prev) => ({ ...prev, newColumnas: "", newNiveles: "" }));
    if (validateSecurityFilters()) {
      loadData(setLoadingColumnas, loadColumnas, (data) =>
        updateFormState({ newColumnas: data })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.newCalles]);

  useEffect(() => {
    if (validateSecurityFilters()) {
      loadData(setLoadingAllNiveles, loadAllNiveles, (data) =>
        updateFormState({ allNiveles: data })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.allColumnas]);

  useEffect(() => {
    if (validateSecurityFilters()) {
      loadData(setLoadingNiveles, loadNiveles, (data) =>
        updateFormState({ newNiveles: data })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.newColumnas]);

  useEffect(() => {
    setDataTableHeaderValue([]);
    setDataTableDetailsValue([]);
    setSelectedRowsDetails([]);
    setSelectedRowsHeader([]);
    if (isColetaOrCtrc === "CTRC") {
      setColumnsNamesHeader(columnsNamesHeaderCTRC);
      setColumnsNamesDetails(columnsNamesDetaisCTRC);
    } else {
      setColumnsNamesHeader(columnsNamesHeaderColecta);
      setColumnsNamesDetails(columnsNamesDetaisColecta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isColetaOrCtrc]);

  useEffect(() => {
    setDataTableDetailsValue([]);
    setSelectedRowsDetails([]);
    setSelectedRowsHeader([]);

    if (
      formState.ctrc &&
      formState.ctrc.toString().length === 9 &&
      formState.ctrc !== "TODOS"
    ) {
      const dataFiltered = initialDataTableHeader.filter((data) => {
        return data.id === formState.ctrc;
      });

      if (dataFiltered.length > 0) {
        setDataTableHeaderValue(dataFiltered);
      } else {
        setDataTableHeaderValue(initialDataTableHeader);
      }
    } else {
      setDataTableHeaderValue(initialDataTableHeader);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.ctrc]);

  useEffect(() => {
    setDataTableDetailsValue([]);
    setSelectedRowsDetails([]);
    setSelectedRowsHeader([]);

    if (
      formState.colecta &&
      formState.colecta.toString().length === 14 &&
      formState.colecta !== "TODOS"
    ) {
      const dataFiltered = initialDataTableHeader.filter((data) => {
        return data.id === formState.colecta;
      });

      if (dataFiltered.length > 0) {
        setDataTableHeaderValue(dataFiltered);
      } else {
        setDataTableHeaderValue(initialDataTableHeader);
      }
    } else {
      setDataTableHeaderValue(initialDataTableHeader);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.colecta]);

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };

  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  // console.log("securityFilters =>", securityFilters);

  return (
    <div>
      <Toast ref={toast} position="bottom-left" />
      <ConfirmDialog />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="RadioButtonFilter">
                <RadioButton
                  value="COLECTA"
                  onChange={(e) => setIsColetaOrCtrc(e.value)}
                  checked={isColetaOrCtrc === "COLECTA"}
                />
                <label>
                  <LanguageProvider id={"10961"} alt="Recepção por Coleta" />
                </label>
              </div>
              <div className="RadioButtonFilter">
                <RadioButton
                  value="CTRC"
                  onChange={(e) => setIsColetaOrCtrc(e.value)}
                  checked={isColetaOrCtrc === "CTRC"}
                />
                <label>
                  <LanguageProvider id={"10960"} alt="Recepção por CTRC" />
                </label>
              </div>
            </div>
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider
                    id={"739"}
                    alt="Localização Linha de Carga"
                  />
                </label>
                {loadingAllCalles ? (
                  <Skeleton width="100%" height="2.5rem" />
                ) : (
                  <AutoComplete
                    forceSelection
                    value={formState.allCalles}
                    suggestions={allCallesSuggestions}
                    completeMethod={searchAllCalles}
                    onChange={(e) => updateFormState({ allCalles: e.value })}
                    dropdown
                  />
                )}
              </div>
              <div className="sipco-option" style={{ maxWidth: "15%" }}>
                <label>
                  <LanguageProvider id={"991"} alt="Coluna" />
                </label>
                {loadingAllColumnas ? (
                  <Skeleton width="100%" height="2.5rem" />
                ) : (
                  <AutoComplete
                    forceSelection
                    value={formState.allColumnas}
                    suggestions={allColumnasSuggestions}
                    completeMethod={searchAllColumnas}
                    onChange={(e) => updateFormState({ allColumnas: e.value })}
                    dropdown
                  />
                )}
              </div>
              <div className="sipco-option" style={{ maxWidth: "15%" }}>
                <label>
                  <LanguageProvider id={"992"} alt="Nível" />
                </label>
                {loadingAllNiveles ? (
                  <Skeleton width="100%" height="2.5rem" />
                ) : (
                  <AutoComplete
                    forceSelection
                    value={formState.allNiveles}
                    suggestions={allNivelesSuggestions}
                    completeMethod={searchAllNiveles}
                    onChange={(e) => updateFormState({ allNiveles: e.value })}
                    dropdown
                  />
                )}
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={<LanguageProvider id={"816"} alt="Recepción de Playa" />}
      >
        <div className="fieldset-content">
          <div className="filter-options">
            <div className="sipco-options-line">
              {isColetaOrCtrc === "CTRC" ? (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"10794"} alt="Nro. CTRC" />
                  </label>
                  {loading ? (
                    <Skeleton width="100%" height="3rem"></Skeleton>
                  ) : (
                    <AutoComplete
                      forceSelection
                      value={formState.ctrc}
                      suggestions={ctrcSuggestions}
                      completeMethod={searchCtrc}
                      onChange={(e) => updateFormState({ ctrc: e.value })}
                      maxLength={9}
                      dropdown
                    />
                  )}
                </div>
              ) : (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"10758"} alt="Nro. Coleta" />
                  </label>
                  {loading ? (
                    <Skeleton width="100%" height="3rem"></Skeleton>
                  ) : (
                    <AutoComplete
                      forceSelection
                      value={formState.colecta}
                      suggestions={colectaSuggestions}
                      completeMethod={searchColecta}
                      onChange={(e) => updateFormState({ colecta: e.value })}
                      dropdown
                    />
                  )}
                </div>
              )}
              {isColetaOrCtrc === "CTRC" && (
                <>
                  <div className="sipco-option-button">
                    <Button
                      label={LanguageProvider({
                        id: "748",
                        alt: "Selecionar tudo",
                      })}
                      onClick={selectAllItems}
                    />
                  </div>
                  <div className="sipco-option-button">
                    <Button
                      label={LanguageProvider({
                        id: "749",
                        alt: "Deselecionar tudo",
                      })}
                      onClick={deselectAllItems}
                    />
                  </div>
                  <div className={"sipco-option-text-small"}>
                    <label>
                      <LanguageProvider id={"10270"} alt="Total Selecionados" />
                    </label>
                    <InputText
                      value={selectedRowsHeader.length.toString()}
                      readOnly
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <DataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={dataTableHeaderValue}
                  columns={columnsNamesHeader}
                  screenName={"RecepcionPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={dataTableHeaderValue}
            // value={teste}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loadingDataTableHeader}
            selectionMode={isColetaOrCtrc === "CTRC" ? "multiple" : "checkbox"}
            selection={selectedRowsHeader}
            onSelectionChange={(e) => selectItems(e.value)}
          >
            {columnsNamesHeader.map((column, index) => {
              if (
                column.field === "fechaEmision.date" ||
                column.field === "fechaDespacho.date" ||
                column.field === "fechaHojaRuta.date"
              ) {
                return (
                  <Column
                    key={index}
                    body={(e) => dateColumnTemplate(e, column.field)}
                    field={column.field}
                    header={column.label}
                    sortable
                  />
                );
              } else {
                return (
                  <Column
                    key={index}
                    field={column.field}
                    header={column.label}
                    sortable
                  />
                );
              }
            })}
          </DataTable>
          <DataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={dataTableDetailsValue}
                  columns={columnsNamesDetails}
                  screenName={"RecepcionPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={dataTableDetailsValue}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loadingDataTableDetails}
            selectionMode="multiple"
            selection={selectedRowsDetails}
            onSelectionChange={(e) => setSelectedRowsDetails(e.value)}
          >
            {columnsNamesDetails.map((column, index) => {
              if (column.field === "fechaDespacho.date") {
                return (
                  <Column
                    key={index}
                    body={(e) => dateColumnTemplate(e, column.field)}
                    field={column.field}
                    header={column.label}
                    sortable
                  />
                );
              } else {
                return (
                  <Column
                    key={index}
                    field={column.field}
                    header={column.label}
                    sortable
                  />
                );
              }
            })}
          </DataTable>
        </div>
        <Divider />
        <div className="fieldset-footer">
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"889"} alt="Data de Recepção" />
                </label>
                <Calendar
                  value={formState.fecha}
                  showButtonBar
                  showTime
                  showIcon
                  dateFormat="dd/mm/yy"
                  onChange={(e) => updateFormState({ fecha: e.value })}
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"21342"} alt="Localização" />
                </label>
                {loadingCalles ? (
                  <Skeleton width="100%" height="2.5rem" />
                ) : (
                  <AutoComplete
                    forceSelection
                    value={formState.newCalles}
                    suggestions={callesSuggestions}
                    completeMethod={searchCalles}
                    onChange={(e) => updateFormState({ newCalles: e.value })}
                    dropdown
                    disabled={isDisabled}
                  />
                )}
              </div>
              <div className="sipco-option" style={{ maxWidth: "15%" }}>
                <label>
                  <LanguageProvider id={"991"} alt="Coluna" />
                </label>
                {loadingColumnas ? (
                  <Skeleton width="100%" height="2.5rem" />
                ) : (
                  <AutoComplete
                    forceSelection
                    value={formState.newColumnas}
                    suggestions={columnasSuggestions}
                    completeMethod={searchColumnas}
                    onChange={(e) => updateFormState({ newColumnas: e.value })}
                    dropdown
                    disabled={isDisabled}
                  />
                )}
              </div>
              <div className="sipco-option" style={{ maxWidth: "15%" }}>
                <label>
                  <LanguageProvider id={"992"} alt="Nível" />
                </label>
                {loadingNiveles ? (
                  <Skeleton width="100%" height="2.5rem" />
                ) : (
                  <AutoComplete
                    forceSelection
                    value={formState.newNiveles}
                    suggestions={nivelesSuggestions}
                    completeMethod={searchNiveles}
                    onChange={(e) => updateFormState({ newNiveles: e.value })}
                    dropdown
                    disabled={isDisabled}
                  />
                )}
              </div>
            </div>
          </div>
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "748",
                alt: "Selecionar tudo",
              })}
              onClick={selectAllItemsDetails}
              text
            />
            <Button
              label={LanguageProvider({
                id: "749",
                alt: "Deselecionar tudo",
              })}
              onClick={deselectAllItemsDetails}
              text
            />
            <Button
              label={LanguageProvider({
                id: "16283",
                alt: "Processar",
              })}
              onClick={modificar}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default RecepcionPlayaBrForm;
