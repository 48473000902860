import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function RecepcionPlayaForm(props) {
  const { SCREEN_CODE } = props;
  const toast = useRef<Toast>(null);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const [loading, setLoading] = useState(false);

  const columnsIdsRecepcionPlaya = [
    54, 1491, 990, 991, 992, 396, 2139, 2140, 16846,
  ];

  const columnsNamesRecepcionPlaya = filterColumnsIds(columnsIdsRecepcionPlaya);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  return (
    <div>
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Fieldset
        legend={<LanguageProvider id={"816"} alt="Recepción de Playa" />}
      >
        <div className="fieldset-content">
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"901"} alt="ngrese el VIN" />
                </label>
                <AutoComplete field="id" dropdown />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"924"} alt="Hoja de Ruta" />
                </label>
                <AutoComplete field="id" dropdown />
              </div>
            </div>
          </div>
          <DataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsIdsRecepcionPlaya}
                  screenName={"RecepcionPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={listRemitos}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {/* aqui */}
            {columnsNamesRecepcionPlaya.map((colum, index) => (
              <Column
                key={index}
                field={colum.id}
                header={colum.label}
                sortable
                filter
              />
            ))}
          </DataTable>
        </div>
        <Divider />
        <div className="fieldset-footer">
          <Accordion>
            <AccordionTab
              header={
                <LanguageProvider
                  id={"883"}
                  alt="Ingreso de Datos para la Transferencia"
                />
              }
            >
              <div className="filter-options-column">
                <div className="sipco-options-column">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"921"} alt="Ubicaciones" />
                    </label>
                    <AutoComplete dropdown />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"889"} alt="Fecha de Recepción" />
                    </label>
                    <Calendar showButtonBar showIcon showTime />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16844"} alt="Proveedor daños" />
                    </label>
                    <AutoComplete dropdown />
                  </div>
                  <div className={"RadioButtonFilter"}>
                    <label>
                      <LanguageProvider id={"16875"} alt="Compra flete" />
                    </label>
                    <Checkbox checked={false} />
                  </div>
                </div>
                <div className="sipco-options-column">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"991"} alt="Columna" />
                    </label>
                    <AutoComplete dropdown />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"754"} alt="Transportista" />
                    </label>
                    <InputText />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"16874"}
                        alt="Proveedor de gancho"
                      />
                    </label>
                    <AutoComplete dropdown />
                  </div>
                  <div className={"RadioButtonFilter"}>
                    <label>
                      <LanguageProvider id={"16876"} alt="Venta flete" />
                    </label>
                    <Checkbox checked={false} />
                  </div>
                </div>

                <div className="sipco-options-column">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"992"} alt="Nivel" />
                    </label>
                    <AutoComplete dropdown />
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "34",
                alt: "Procesar o guardar cambios",
              })}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default RecepcionPlayaForm;
