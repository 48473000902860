import { LanguageProvider } from '@shared/components/language-provider';
import { useMemo } from 'react';


const useFilterColumnsIdsNew = (ids) => {
  const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i].header,
        field: ids[i].field,
        label: LanguageProvider({
          id: ids[i].header,
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
};

const useFilterColumnsIds = (ids) => {
  const result = useMemo(() => {
    return ids.map((id) => ({
      id,
      label: LanguageProvider({
        id,
        alt: 'Error Columns Labels',
      }),
    }));
  }, [ids]);

  return result;
};

export { useFilterColumnsIds, useFilterColumnsIdsNew };
