import "./FilterSecuritySection.scss";

import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import React, { ReactElement, useEffect, useState } from "react";

import { FilterSecurityOptions } from "../filter";
import { LanguageProvider } from "../../language-provider";
import useAuth from "@shared/AuthContext";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "../../../../services/axios/sipco-axios-service";

export interface FitlerSecuritySectionProps extends FilterSecurityOptions {}

export function FitlerSecuritySection(
  props: FitlerSecuritySectionProps
): ReactElement {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values, setValues } = useFilterContext();
  const { user } = useAuth();

  const [loadingSecurityFilters, setLoadingSecurityFilters] = useState(false);

  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [clients, setClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [subAccounts, setSubAccounts] = useState([]);
  const [loadingSubAccounts, setLoadingSubAccounts] = useState(false);

  const TODOS_OBJ = {
    id: 0,
    descripcion: LanguageProvider({ id: 113, alt: "TODOS" }),
  };

  async function loadCountries() {
    setLoadingCountries(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/screens/get-pais-options",
        {
          params: { screenCode: props.screenCode, level: user.nivel },
        }
      );
      if (status === 200) {
        const updatedElements = data.map((x) => {
          return { ...x, search: x.descripcion + x.id };
        });
        setCountries(updatedElements);
        return updatedElements;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCountries(false);
    }
  }
  async function loadClients(codPais: number) {
    setLoadingClients(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/screens/get-cliente-options",
        {
          params: {
            codPais: codPais,
            screenCode: props.screenCode,
            level: user.nivel,
          },
        }
      );
      if (status === 200) {
        if (props.allClientsOptions) {
          data.unshift(TODOS_OBJ);
        }
        setClients(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClients(false);
    }
  }
  async function loadAccounts(codClient: string) {
    setLoadingAccounts(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/screens/get-cuenta-options",
        {
          params: {
            screenCode: props.screenCode,
            level: user.nivel,
            codCliente: codClient,
            codIdioma: user.idioma.codIdioma,
          },
        }
      );
      if (status === 200) {
        const updatedElements = data.map((x) => {
          return { ...x, search: x.descripcion + x.id };
        });
        setAccounts(updatedElements);
        return updatedElements;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAccounts(false);
    }
  }
  async function loadSubAccounts(codClient: string, codAccount: number) {
    setLoadingSubAccounts(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/screens/get-subcuenta-options",
        {
          params: {
            screenCode: props.screenCode,
            level: user.nivel,
            codCliente: codClient,
            codIdioma: user.idioma.codIdioma,
            codCuenta: codAccount,
          },
        }
      );
      if (status === 200) {
        const updatedElements = data.map((x) => {
          return { ...x, search: x.descripcion + x.id };
        });
        if (props.allSubaccountsOptions) {
          updatedElements.unshift(TODOS_OBJ);
        }
        setSubAccounts(updatedElements);
        return updatedElements;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSubAccounts(false);
    }
  }
  async function handleCountryChange(event: DropdownChangeEvent) {
    try {
      const selectedCountry = event.value;
      let selectedClient, selectedAccount, selectedSubAccount;
      const clients = await loadClients(selectedCountry.id);
      if (Array.isArray(clients) && clients.length > 0) {
        selectedClient = clients[0];
        const accounts = await loadAccounts(selectedClient.id);
        if (Array.isArray(accounts) && accounts.length > 0) {
          selectedAccount = accounts[0];
          const subAccounts = await loadSubAccounts(
            selectedClient.id,
            selectedAccount.id
          );
          if (Array.isArray(subAccounts) && subAccounts.length > 0) {
            selectedSubAccount = subAccounts[0];
          }
        }
      }
      setValues({
        ...values,
        securityValues: {
          ...values.securityValues,
          country: selectedCountry,
          client: selectedClient,
          account: selectedAccount,
          subaccount: selectedSubAccount,
        },
      });
    } catch (error) {
      console.error();
    }
  }
  async function handleClientChange(event: DropdownChangeEvent) {
    try {
      const selectedClient = event.value;
      let filterClient = selectedClient;
      if (selectedClient.id === 0) {
        filterClient = clients[1];
      }
      let selectedAccount, selectedSubAccount;
      const accounts = await loadAccounts(filterClient.id);
      if (Array.isArray(accounts) && accounts.length > 0) {
        selectedAccount = accounts[0];
        const subAccounts = await loadSubAccounts(
          filterClient.id,
          selectedAccount.id
        );
        if (Array.isArray(subAccounts) && subAccounts.length > 0) {
          selectedSubAccount = subAccounts[0];
        }
      }
      setValues({
        ...values,
        securityValues: {
          ...values.securityValues,
          client: selectedClient,
          account: selectedAccount,
          subaccount: selectedSubAccount,
        },
      });
    } catch (error) {
      console.error();
    }
  }
  async function handleAccountChange(event: DropdownChangeEvent) {
    try {
      const selectedAccount = event.value;
      let selectedSubAccount;
      const subAccounts = await loadSubAccounts(
        values.securityValues.client.id,
        selectedAccount.id
      );
      if (Array.isArray(subAccounts) && subAccounts.length > 0) {
        selectedSubAccount = subAccounts[0];
      }

      setValues({
        ...values,
        securityValues: {
          ...values.securityValues,
          account: selectedAccount,
          subaccount: selectedSubAccount,
        },
      });
    } catch (error) {
      console.error();
    }
  }
  async function handleSubAccountChange(event: DropdownChangeEvent) {
    try {
      const selectedSubAccount = event.value;
      setValues({
        ...values,
        securityValues: {
          ...values.securityValues,
          subaccount: selectedSubAccount,
        },
      });
    } catch (error) {
      console.error();
    }
  }

  useEffect(() => {
    async function initialize() {
      setLoadingSecurityFilters(true);
      try {
        if (user && props.screenCode) {
          let selectedCountry,
            selectedClient,
            selectedAccount,
            selectedSubAccount;
          const countries = await loadCountries();
          if (Array.isArray(countries) && countries.length > 0) {
            selectedCountry = countries[0];
            const clients = await loadClients(selectedCountry.id);
            if (Array.isArray(clients) && clients.length > 0) {
              selectedClient = clients[0];
              let filterClient = selectedClient;
              if (selectedClient === TODOS_OBJ) {
                filterClient = clients[1];
              }
              const accounts = await loadAccounts(filterClient.id);
              if (Array.isArray(accounts) && accounts.length > 0) {
                selectedAccount = accounts[0];
                const subAccounts = await loadSubAccounts(
                  filterClient.id,
                  selectedAccount.id
                );
                if (Array.isArray(subAccounts) && subAccounts.length > 0) {
                  selectedSubAccount = subAccounts[0];
                }
              }
            }
          }
          setValues({
            ...values,
            securityValues: {
              ...values.securityValues,
              country: selectedCountry,
              client: selectedClient,
              account: selectedAccount,
              subaccount: selectedSubAccount,
            },
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingSecurityFilters(false);
      }
    }
    initialize();
  }, [user, props.screenCode]);
  useEffect(() => {
    async function handleSubAccountChange() {
      try {
        if (values.securityValues.subaccount && values.securityValues.account) {
          const { status, data } = await sipcoAxiosService.get(
            "/screens/get-subcuenta-detalles",
            {
              params: {
                codCuenta: values.securityValues.account.id,
                codSubcuenta: values.securityValues.subaccount.id,
              },
            }
          );
          if (status === 200) {
            setValues({
              ...values,
              subAccountDetails: data,
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    handleSubAccountChange();
  }, [values.securityValues]);

  useEffect(() => {
    if (
      loadingCountries ||
      loadingAccounts ||
      loadingClients ||
      loadingSubAccounts ||
      loadingSecurityFilters
    ) {
      setValues({
        ...values,
        loadingSecurityValues: true,
      });
    } else {
      setValues({
        ...values,
        loadingSecurityValues: false,
      });
    }
  }, [
    loadingCountries,
    loadingAccounts,
    loadingClients,
    loadingSubAccounts,
    loadingSecurityFilters,
  ]);

  return (
    <div className="filter-security-options">
      {props.country && (
        <div className="option">
          <label htmlFor="country">
            <LanguageProvider id="1499" alt="Pais" />
          </label>
          <Dropdown
            value={values.securityValues.country}
            onChange={handleCountryChange}
            options={countries}
            filter
            filterBy="search"
            loading={loadingCountries || loadingSecurityFilters}
            disabled={
              loadingCountries ||
              loadingSecurityFilters ||
              countries.length === 1
            }
            optionLabel="descripcion"
            showClear
            itemTemplate={DropdownOptionTemplate}
            valueTemplate={DropdownOptionTemplate}
            placeholder={LanguageProvider({ id: 1499, alt: "Pais" })}
          />
        </div>
      )}
      {props.client && (
        <div className="option">
          <label htmlFor="clients">
            <LanguageProvider id="488" alt="Cliente" />
          </label>
          <Dropdown
            value={values.securityValues.client}
            onChange={handleClientChange}
            options={clients}
            filter
            loading={loadingClients || loadingSecurityFilters}
            disabled={
              loadingClients || loadingSecurityFilters || clients.length === 1
            }
            optionLabel="descripcion"
            showClear
            itemTemplate={DropdownOptionTemplate}
            valueTemplate={DropdownOptionTemplate}
            filterBy="descripcionFull"
            placeholder={LanguageProvider({ id: 488, alt: "Cliente" })}
          />
        </div>
      )}
      {props.account && (
        <div className="option">
          <label htmlFor="accounts">
            <LanguageProvider id="1791" alt="Conta" />
          </label>
          <Dropdown
            value={values.securityValues.account}
            onChange={handleAccountChange}
            options={accounts}
            filter
            filterBy="search"
            loading={loadingAccounts || loadingSecurityFilters}
            disabled={
              loadingAccounts || loadingSecurityFilters || accounts.length === 1
            }
            optionLabel="descripcion"
            showClear
            itemTemplate={DropdownOptionTemplate}
            valueTemplate={DropdownOptionTemplate}
            placeholder={LanguageProvider({ id: 1791, alt: "Conta" })}
          />
        </div>
      )}
      {props.subaccount && (
        <div className="option">
          <label htmlFor="subaccounts">
            <LanguageProvider id="4791" alt="Subcuenta" />
          </label>
          <Dropdown
            value={values.securityValues.subaccount}
            onChange={handleSubAccountChange}
            options={subAccounts}
            filter
            filterBy="search"
            loading={loadingSubAccounts || loadingSecurityFilters}
            disabled={
              loadingSubAccounts ||
              loadingSecurityFilters ||
              subAccounts.length === 1
            }
            optionLabel="descripcion"
            showClear
            itemTemplate={DropdownOptionTemplate}
            valueTemplate={DropdownOptionTemplate}
            placeholder={LanguageProvider({ id: 4791, alt: "Subcuenta" })}
          />
        </div>
      )}
    </div>
  );
}

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
