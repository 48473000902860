import "./ImpresionChecklist.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { format } from "date-fns";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";

const TODOS_EstadoCheckLists = {
  label: "TODOS",
  id: "TODOS",
  descripcion: "TODOS",
};

const TODOS_EstadoRemitos = {
  label: "TODOS",
  id: 0,
  descripcion: 0,
};

export function ImpresionChecklist() {
  const SCREEN_CODE = "fun0229_impresion_checklist";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);

  const filterLabelId = [
    25109, 849, 13358, 2559, 25110, 467, 468, 2940, 1491, 4197, 1492, 7324,
    6763,
  ];
  const filterNames = [];
  const filterColumnsId = [
    3184, 1491, 25111, 25112, 1492, 1342, 924, 396, 2139, 2140, 25110, 19019,
    10871, 2941, 25113, 10904, 3591, 8299, 159, 3186,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    39, 42, 54, 65, 159, 396, 467, 468, 748, 749, 753, 849, 924, 1131, 1342,
    1491, 1492, 1791, 2139, 2140, 2559, 2940, 2941, 3184, 3186, 3591, 4197,
    5044, 5583, 6763, 7324, 8299, 10871, 10904, 13358, 14746, 17729, 19019,
    25109, 25110, 25111, 25112, 25113, 25114, 25106,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const formatDate = (data) => {
    if (!data) return "";
    const dataObjeto = new Date(data);
    return format(dataObjeto, "dd/MM/yyyy HH:mm:ss");
  };

  const tipoFechaMap = {
    fechaLiberacion: 0,
    fechaEmision: 1,
    fechaImpresion: 2,
    todas: -1,
  };

  const handleRadioChange = (value) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      tipoFecha: tipoFechaMap[value],
    }));
    setReporte([]);
  };

  const [filter, setFilter] = useState({
    pais: null,
    cliente: null,
    cuenta: null,
    subcuenta: null,
    usuario: null,

    estadoChecklists: TODOS_EstadoCheckLists,
    letra: null,
    puntoDeVenta: null,
    nroRemito: null,
    estadoRemitos: TODOS_EstadoRemitos,
    fechaDesde: new Date(new Date().setDate(new Date().getDate() - 1)),
    fechaHasta: new Date(),
    nroHojaRuta: null,
    vin: null,
    tipoFecha: 0,
  });

  const [loading, setLoading] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [estadoChecklists, setEstadoChecklists] = useState([]);
  const [letra, setLetra] = useState([]);
  const [puntoDeVenta, setPuntoDeVenta] = useState([]);
  const [nroRemito, setNroRemito] = useState([]);
  const [estadoRemitos, setEstadoRemitos] = useState([]);
  const [nroHojaRuta, setNroHojaRuta] = useState([]);
  const [vin, setVin] = useState([]);

  async function searchEstadoChecklists(event: any) {
    await loadEstadoChecklists(event.query);
  }
  async function loadEstadoChecklists(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/impresion-check-list/opcion-remito",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const estadoChecklist = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          estadoChecklist.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcion: "TODOS",
          });

          if (query) {
            const estadoChecklistFiltrados = estadoChecklist.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setEstadoChecklists(estadoChecklistFiltrados);
            return estadoChecklistFiltrados;
          } else {
            setEstadoChecklists(estadoChecklist);
            return estadoChecklist;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function searchEstadoRemito(event: any) {
    await loadEstadoRemito(event.query);
  }
  async function loadEstadoRemito(query = "") {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/impresion-check-list/estado-remito",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          const estadoRemito = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });

          estadoRemito.unshift({
            label: "TODOS",
            id: 0,
            descripcion: 0,
          });

          if (query) {
            const estadoRemitoFiltrados = estadoRemito.filter((x: any) =>
              x.label.toLowerCase().includes(query.toLowerCase())
            );
            setEstadoRemitos(estadoRemitoFiltrados);
            return estadoRemitoFiltrados;
          } else {
            setEstadoRemitos(estadoRemito);
            return estadoRemito;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "/impresion-check-list/find-checklists",
        {
          pais: { id: securityFilters?.securityValues?.country?.id || null },
          cliente: {
            id: securityFilters?.securityValues?.client?.id || null,
          },
          cuenta: {
            id: securityFilters?.securityValues?.account?.id || null,
          },

          estadoChecklists: {
            id: filter?.estadoChecklists.id || null,
          },
          letra: filter?.letra || "",
          puntoDeVenta: filter?.puntoDeVenta || "",
          nroRemito: filter?.nroRemito || "",
          estadoRemitos: { id: filter?.estadoRemitos.id || 0 },
          fechaDesde: { date: filter?.fechaDesde || [] },
          fechaHasta: { date: filter?.fechaHasta || [] },
          nroHojaRuta: filter?.nroHojaRuta || "",
          vin: { id: filter?.vin || "" },
          tipoFecha: filter.tipoFecha,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      {/*  <div className="impresion-checklist"> */}
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          allClientsOptions: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[0].label}
                  alt="Estado Checklist"
                />
              </label>
              <AutoComplete
                value={filter.estadoChecklists}
                suggestions={estadoChecklists}
                completeMethod={searchEstadoChecklists}
                onChange={(e) =>
                  setFilter({ ...filter, estadoChecklists: e.value })
                }
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"} style={{ maxWidth: "4%" }}>
              <label>
                <LanguageProvider id={filterNames[1].label} alt="Letra" />
              </label>
              <AutoComplete
                value={filter.letra}
                suggestions={letra}
                onChange={(e) => setFilter({ ...filter, letra: e.value })}
                field="label"
              />
            </div>

            <div className={"sipco-option"} style={{ maxWidth: "6%" }}>
              <label>
                <LanguageProvider id={filterNames[2].label} alt="Punto Venta" />
              </label>
              <AutoComplete
                value={filter.puntoDeVenta}
                suggestions={puntoDeVenta}
                onChange={(e) =>
                  setFilter({ ...filter, puntoDeVenta: e.value })
                }
                field="label"
              />
            </div>

            <div className={"sipco-option"} style={{ maxWidth: "8%" }}>
              <label>
                <LanguageProvider id={filterNames[3].label} alt="Nro. Remito" />
              </label>
              <AutoComplete
                value={filter.nroRemito}
                suggestions={nroRemito}
                onChange={(e) => setFilter({ ...filter, nroRemito: e.value })}
                field="label"
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[4].label}
                  alt="Estado Remito"
                />
              </label>
              <AutoComplete
                value={filter.estadoRemitos}
                suggestions={estadoRemitos}
                completeMethod={searchEstadoRemito}
                onChange={(e) =>
                  setFilter({ ...filter, estadoRemitos: e.value })
                }
                field="label"
                dropdown
                forceSelection
              />
            </div>

            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[5].label} alt="Desde" />
              </label>
              <Calendar
                value={filter.fechaDesde}
                onChange={(e) => {
                  setFilter({ ...filter, fechaDesde: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[6].label} alt="Hasta" />
              </label>
              <Calendar
                value={filter.fechaHasta}
                onChange={(e) => {
                  setFilter({ ...filter, fechaHasta: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider
                  id={filterNames[7].label}
                  alt="Nro. Hoja de Ruta"
                />
              </label>
              <AutoComplete
                value={filter.nroHojaRuta}
                suggestions={nroHojaRuta}
                onChange={(e) => setFilter({ ...filter, nroHojaRuta: e.value })}
                field="label"
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[8].label} alt="VIN" />
              </label>
              <AutoComplete
                value={filter.vin}
                suggestions={vin}
                onChange={(e) => setFilter({ ...filter, vin: e.value })}
                field="label"
              />
            </div>

            <div className="RadioButtonFilter">
              <RadioButton
                checked={filter.tipoFecha === tipoFechaMap.fechaLiberacion}
                onChange={() => handleRadioChange("fechaLiberacion")}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={filterNames[9].label} />
              </label>

              <RadioButton
                checked={filter.tipoFecha === tipoFechaMap.fechaEmision}
                onChange={() => handleRadioChange("fechaEmision")}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={filterNames[10].label} />
              </label>

              <RadioButton
                checked={filter.tipoFecha === tipoFechaMap.fechaImpresion}
                onChange={() => handleRadioChange("fechaImpresion")}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={filterNames[11].label} />
              </label>

              <RadioButton
                checked={filter.tipoFecha === tipoFechaMap.todas}
                onChange={() => handleRadioChange("todas")}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={filterNames[12].label} />
              </label>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[44].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={reporte}
                  columns={columnsNames}
                  screenName={"Impresión de Checklist"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loading}
          filterDisplay="menu"
        >
          <Column
            field="remito.formatRemito"
            header={columnsNames[0].label}
            sortable
            filter
          />
          <Column
            field="remito.vin.id"
            header={columnsNames[1].label}
            sortable
            filter
          />
          <Column
            field="impreso"
            header={columnsNames[2].label}
            sortable
            filter
          />
          <Column
            field="ultimaImpresion.date"
            header={columnsNames[3].label}
            sortable
            filter
            body={(rowData) => formatDate(rowData.ultimaImpresion.date)}
          />
          <Column
            field="remito.fechaEmision.date"
            header={columnsNames[4].label}
            sortable
            filter
            body={(rowData) => formatDate(rowData.remito.fechaEmision.date)}
          />
          <Column
            field="remito.fechaDespacho.date"
            header={columnsNames[5].label}
            sortable
            filter
            body={(rowData) => formatDate(rowData.remito.fechaDespacho.date)}
          />
          <Column
            field="remito.hojaRuta.id"
            header={columnsNames[6].label}
            sortable
            filter
          />
          <Column
            field="marcaFull"
            header={columnsNames[7].label}
            sortable
            filter
          />
          <Column
            field="modeloFull"
            header={columnsNames[8].label}
            sortable
            filter
          />
          <Column
            field="colorFull"
            header={columnsNames[9].label}
            sortable
            filter
          />
          <Column
            field="estadoRemitoFull"
            header={columnsNames[10].label}
            sortable
            filter
          />
          <Column
            field="remito.idPedidoCliente"
            header={columnsNames[11].label}
            sortable
            filter
          />
          <Column
            field="remito.concesionario.id"
            header={columnsNames[12].label}
            sortable
            filter
          />
          <Column
            field="remito.concesionario.descripcion"
            header={columnsNames[13].label}
            sortable
            filter
          />
          <Column
            field="remito.direccionEntrega.id"
            header={columnsNames[14].label}
            sortable
            filter
          />
          <Column
            field="remito.direccionEntrega.direccion"
            header={columnsNames[15].label}
            sortable
            filter
          />
          <Column
            field="remito.razonSocial"
            header={columnsNames[16].label}
            sortable
            filter
          />
          <Column
            field="remito.domicilioImpresion"
            header={columnsNames[17].label}
            sortable
            filter
          />
          <Column
            field="remito.provinciaImpresion"
            header={columnsNames[18].label}
            sortable
            filter
          />
          <Column
            field="remito.localidadImpresion"
            header={columnsNames[19].label}
            sortable
            filter
          />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ImpresionChecklist;
