import "./ReingresoStock.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ReingressoStock() {
  const SCREEN_CODE = "fun0082_reingreso_de_stock";
  usePageViews();
  ScreenCodeValue(SCREEN_CODE);

  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [14370, 467, 14368, 14369, 901, 18308, 18309, 1569];
  const filterNames = [];
  const filterColumnsId = [16476, 11351, 12419, 1253, 2890];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    32, 54, 65, 448, 467, 468, 653, 901, 1182, 1184, 1491, 1569, 1669, 2502,
    4791, 5044, 5583, 1480, 11035, 11833, 11986, 12642, 13324, 14368, 14369,
    14370, 14562, 18308, 18309, 396, 13514, 2139, 18305, 2140, 367, 2892, 6367,
    16580, 3410, 18308, 18307, 749, 748,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const { values: securityFilters } = useFilterContext();
  const sipcoAxiosService = useSipcoAxiosService();

  // Campos da requisição
  const [filter, setFilter] = useState({
    fechaGeracaoOt: false,
    fechaGeracaoOtInicio: null,
    fechaGeracaoOtFim: null,
    estadoOt: null,
    numeroOt: "",
    vin: "",
    orignesSolicitacao: [],
    grupoOpercacao: null,
    operacoes: [],
  });

  const [vinOptions, setVinOptions] = useState([]);
  const [estadoOT, setEstadoOT] = useState([]);
  const [origines, setOrigines] = useState([]);
  const [operaciones, setOperaciones] = useState([]);
  const [gruposOperacao, setGruposOperacao] = useState([]);
  const [loadingGruposOperacao, setLoadingGruposOperacao] = useState(false);
  const [loadingOperaciones, setLoadingOperaciones] = useState(false);
  const [loadingOrigines, setLoadingOrigines] = useState(false);
  const [loadingEstadosOt, setLoadingEstadosOt] = useState(true);

  const [loadingReport, setLoadingReport] = useState(false);

  // Requisição -> getEstado
  async function loadEstadoOt() {
    setLoadingEstadosOt(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/consulta-ot/estado",
          {
            params: { codPais: securityFilters.securityValues.country.value },
          }
        );

        if (status === 200) {
          data.unshift({
            id: "TODOS",
            descripcionFull: "TODOS",
          });
          setEstadoOT(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadosOt(false);
    }
    return [];
  }

  // Requisição -> getOrigen
  async function loadOrigines() {
    setLoadingOrigines(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client.value
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/consulta-ot/origen",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
            },
          }
        );

        if (status === 200) {
          setOrigines(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigines(false);
    }
    return [];
  }
  async function loadOperaciones() {
    setLoadingOperaciones(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/consulta-ot/operacion",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
            },
          }
        );
        if (status === 200) {
          setOperaciones(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOperaciones(false);
    }
    return [];
  }
  async function loadGruposOperaciones() {
    setLoadingGruposOperacao(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client.value
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/consulta-ot/grupo-operacion",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
            },
          }
        );

        if (status === 200) {
          data.unshift({
            id: 0,
            descripcion: "TODOS",
          });
          setGruposOperacao(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGruposOperacao(false);
    }
    return [];
  }

  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(query: any) {
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.account.value
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/consulta-ot/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.value,
              codCliente: securityFilters.securityValues.client.value,
              codCuenta: securityFilters.securityValues.account.value,
              vin: query,
            },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVinOptions(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadReport() {
    setLoadingReport(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/report/consulta-ot",
        {
          codPais: securityFilters?.securityValues?.country?.value || null,
          codCliente: securityFilters?.securityValues?.client?.value || "",
          codCuenta: securityFilters?.securityValues?.account?.value || null,
          codSubcuenta:
            securityFilters?.securityValues?.subaccount?.value || null,
          estadoOt: filter.estadoOt?.value || null,
          numeroOrden: filter?.numeroOt || null,
          rangeFecha: [
            filter?.fechaGeracaoOtInicio || null,
            filter?.fechaGeracaoOtFim || null,
          ],
          operacion: filter.operacoes?.map((x) => x.id) || [],
          origenSolicitud: filter.orignesSolicitacao?.map((x) => x.id) || [],
          grupoOperacion: filter.grupoOpercacao?.id || null,
        }
      );

      console.log();

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReport(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        if (securityFilters?.securityValues?.country != null) {
          const estadosOt = await loadEstadoOt();
          const origenes = await loadOrigines();
          const operaciones = await loadOperaciones();
          const gruposOperacao = await loadGruposOperaciones();
          setFilter({
            ...filter,
            estadoOt: estadosOt[0],
            orignesSolicitacao: origenes,
            operacoes: operaciones,
            grupoOpercacao: gruposOperacao[0],
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  return (
    <div className="consulta-ot">
      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={filterNames[0].label} alt="Taller" />
                </label>
                <AutoComplete
                  value={filter.vin}
                  suggestions={vinOptions}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="label"
                  dropdown
                  forceSelection
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={filterNames[0].label} alt="Rubro" />
                </label>
                <AutoComplete
                  value={filter.vin}
                  suggestions={vinOptions}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="label"
                  dropdown
                  forceSelection
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="6697" alt="Listado" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta Orden Trabajo"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div style={{ marginTop: "1.25em" }}>
                <Button
                  // className="buttons"
                  label={LanguageProvider({ id: "25", alt: "Alta" })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReport}
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ReingressoStock;

function setLoadingEstadosOt(arg0: boolean) {
  throw new Error("Function not implemented.");
}
