
import React, {useEffect, useState} from "react";
import { LanguageProvider } from "@shared/components/language-provider";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";

import useFilterColumnsIds from "../hooks/useFilterColumnsIds";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Card } from "primereact/card";

const DatosDelRemito: React.FC<{ data: any }> = ({ data }) => {
  const [reporte, setReporte] = useState({});
  useEffect(() => {
    setReporte(data);
  }, [data]);
//  const filterColumnsIdLectora = [1491, 1569, 5788, 8380, 2521, 1669];

 // const columNames = useFilterColumnsIds(filterColumnsIdLectora);

  return (
    <Card>
      <div className="filter-options-column">
        <div className="sipco-options-column">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"845"} alt="Despacho" />
            </label>
            <InputText value={data?.fechaDespacho?.date}/>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"16925"} alt="Fec. Ant. Retorno" />
            </label>
            <InputText value={data?.fechaAnticipoRetorno?.date} />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"295"} alt="Retorno" />
            </label>
            <InputText value={data?.fechaRetorno?.date}/>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"10868"} alt="Cod. Dir. Entrega" />
            </label>
            <InputText value={data?.direccionEntrega?.id}/>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"3069"} alt="Dirección" />
            </label>
            <InputText value={data?.direccionEntrega?.direccion}/>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"10871"} alt="Cod. Concesionario" />
            </label>
            <InputText value={data?.concesionario?.id}/>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"15818"} alt="Fecha Confirmación" />
            </label>
            <InputText />
          </div>
        </div>
        <div className="sipco-options-column">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"396"} alt="Marca" />
            </label>
            <InputText value={data?.vin?.marca?.descripcion}/>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"23864"} alt="Modelo" />
            </label>
            <InputText value={data?.vin?.modelo?.descripcion}/>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2496"} alt="Origen" />
            </label>
            <InputText value={data?.vin?.origen?.descripcion}/>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2140"} alt="Color" />
            </label>
            <InputText value={data?.vin?.color?.descripcion}/>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1491"} alt="VIN" />
            </label>
            <InputText value={data?.vin?.id}/>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2941"} alt="Concesionario" />
            </label>
            <InputText value={data?.concesionario?.descripcion}/>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"20804"} alt="Estado" />
            </label>
            <InputText value={data?.estadoConfirmacion?.descripcion}/>
          </div>
        </div>
      </div>
      <div className="tags">
        <Tag
          value={<LanguageProvider id={"17762"} alt={"Conforme"} />}
          style={{ background: "#66ff00", color: "black" }}
        />
        <Tag
          value={<LanguageProvider id={"17763"} alt={"No Conforme"} />}
          style={{ background: "#ffff00", color: "black" }}
        />
        <Tag
          value={<LanguageProvider id={"17764"} alt={"No Recibido"} />}
          style={{ background: "#ff0000" }}
        />
      </div>
    </Card>
  );
};
export default DatosDelRemito;
