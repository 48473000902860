import "./VehiculosPendientesTaller.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Tag } from "primereact/tag";
import useAuth from "@shared/AuthContext";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function VehiculosPendientesTaller() {
  const SCREEN_CODE = "con0049_vehiculos_pendiente_taller";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const { user } = useAuth();

  const dt = useRef(null);
  const filterLabelId = [396, 901];
  const filterNames = [];
  const filterColumnsId = [
    396, 17088, 321, 2139, 17091, 17092, 5701, 9857, 3232, 9904, 2520, 1043,
    10541, 16243, 17028, 336, 3625, 16480, 2519, 16950, 2520, 19689, 2496,
    19690, 14624, 11370, 16553, 16478,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    54, 65, 187, 321, 336, 396, 653, 654, 901, 1043, 1791, 2139, 2502, 2519,
    2520, 3232, 3625, 4791, 5044, 5583, 5701, 9857, 9904, 9905, 10541, 16243,
    16480, 16950, 17028, 17088, 17091, 17092, 2496, 14624, 11370, 16553, 16478,
    19689, 19690,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    vin: "",
    marca: null,
  });

  const [vins, setVins] = useState([]);
  const [loadingVins, setLoadingVins] = useState(false);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    setLoadingVins(true);
    try {
      if (
        securityFilters?.securityValues?.country?.id &&
        securityFilters?.securityValues?.client?.id &&
        securityFilters?.securityValues?.account?.id
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/operaciones-canceladas-web/combo/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codCliente: securityFilters.securityValues.client.id,
              vin: vin,
            },
          }
        );

        if (status === 200) {
          setVins(data);
          return data;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingVins(false);
    }
  }
  const [marcas, setMarcas] = useState([]);
  const [loadingMarcas, setLoadingMarcas] = useState(false);
  async function loadMarca() {
    setLoadingMarcas(true);
    try {
      if (
        securityFilters?.securityValues?.country == null ||
        securityFilters?.securityValues?.client == null
      )
        return;
      const { status, data } = await sipcoAxiosService.get(
        "/vehiculos-pendientes-taller/combo/marca",
        {
          params: {
            codPais: securityFilters?.securityValues?.country?.id,
            codCliente: securityFilters?.securityValues?.client?.id,
          },
        }
      );
      if (status === 200) {
        data.unshift({ id: 0, descripcionFull: "TODOS", descripcion: "TODOS" });
        setMarcas(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
  }
  const [clients, setClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);
  async function loadClients() {
    setLoadingClients(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.get(
          "/screens/get-cliente-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              screenCode: SCREEN_CODE,
              level: user.nivel,
            },
          }
        );
        if (status === 200) {
          setClients(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClients(false);
    }
  }

  const [reporte, setReporte] = useState(null);
  const [loadingReportData, setLoadingReportData] = useState(false);
  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        marca: filter.marca.id ?? null,
        marcaDescripcion: filter.marca.descripcion ?? null,
        vin: filter.vin,
        clientes: clients,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/vehiculos-pendientes-taller/report",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const marcas = await loadMarca();
        await loadClients();
        setFilter({
          ...filter,
          marca: marcas[0] ?? null,
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  return (
    <div className="vehiculos-pendientes-taller">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"396"} alt="Marca" />
                </label>
                <Dropdown
                  value={filter?.marca}
                  options={marcas}
                  onChange={(e) => setFilter({ ...filter, marca: e.value })}
                  optionLabel="descripcionFull"
                  loading={loadingMarcas}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN" />
                </label>
                <AutoComplete
                  value={filter.vin}
                  suggestions={vins}
                  completeMethod={searchVin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.value || null })
                  }
                  field="label"
                  dropdown
                  forceSelection
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="9854" alt="Vehiculos Pendiente Taller" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={reporte?.filas}
                  columns={columnsNames}
                  screenName={"Vehiculos Pendientes Taller"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReportData}
          filterDisplay="menu"
          rowClassName={getRowStyles}
        >
          <Column
            field="marca"
            header={columnsNames[0].label}
            sortable
            filter
          />
          <Column field="vin" header={columnsNames[1].label} sortable filter />
          <Column
            field="remitoDespacho"
            header={columnsNames[2].label}
            sortable
            filter
          />
          <Column
            field="modelo"
            header={columnsNames[3].label}
            sortable
            filter
          />
          <Column
            field="fechaPendienteIngreso"
            header={columnsNames[4].label}
            sortable
            filter
          />
          <Column
            field="descripcionPendienteIngreso"
            header={columnsNames[5].label}
            sortable
            filter
          />
          <Column
            field="codigoOrdenReparacion"
            header={columnsNames[6].label}
            sortable
            filter
          />
          <Column
            field="fechaOrden"
            header={columnsNames[7].label}
            sortable
            filter
          />
          <Column
            field="fechaIngreso"
            header={columnsNames[8].label}
            sortable
            filter
          />
          <Column
            field="fechaPromesaEntrega"
            header={columnsNames[9].label}
            sortable
            filter
          />
          <Column
            field="fechaCierreReal"
            header={columnsNames[10].label}
            sortable
            filter
          />
          <Column
            field="codigoDanio"
            header={columnsNames[11].label}
            sortable
            filter
          />
          <Column
            field="tipoDanio"
            header={columnsNames[12].label}
            sortable
            filter
          />
          <Column
            field="partesDanios"
            header={columnsNames[13].label}
            sortable
            filter
          />
          <Column
            field="fechareparado"
            header={columnsNames[14].label}
            sortable
            filter
          />
          <Column
            field="responsables"
            header={columnsNames[15].label}
            sortable
            filter
          />
          <Column
            field="ordenReparacionObs"
            header={columnsNames[16].label}
            sortable
            filter
          />
          <Column
            field="detalleNroPedido"
            header={columnsNames[17].label}
            sortable
            filter
          />
          <Column
            field="fechaCompra"
            header={columnsNames[18].label}
            sortable
            filter
          />
          <Column
            field="fechaPrevista"
            header={columnsNames[19].label}
            sortable
            filter
          />
          <Column
            field="fechaCierre"
            header={columnsNames[20].label}
            sortable
            filter
          />
          <Column
            field="usuarioPriorizacion"
            header={columnsNames[21].label}
            sortable
            filter
          />
          <Column
            field="origenAuto"
            header={columnsNames[22].label}
            sortable
            filter
          />
          <Column
            field="fechaPriorizacion"
            header={columnsNames[23].label}
            sortable
            filter
          />
          <Column
            field="fecEmisionRemito"
            header={columnsNames[24].label}
            sortable
            filter
          />
          <Column
            field="ordenOrigen"
            header={columnsNames[25].label}
            sortable
            filter
          />
          <Column
            field="fechaUltimoIngresoAPlaya"
            header={columnsNames[26].label}
            sortable
            filter
          />
          <Column
            field="numeroIngreso"
            header={columnsNames[27].label}
            sortable
            filter
          />
        </DataTable>
        <Tag
          style={{ background: "#ff0000", marginBottom: "10px" }}
          value={
            <LanguageProvider
              id={"9905"}
              alt="Vehiculos con Fecha de Promesa de la Orden de Reparación retrasada"
            />
          }
        />
      </Panel>
    </div>
  );
}
export default VehiculosPendientesTaller;

const getRowStyles = (data) => {
  let className = "";

  if (data.fechaPromesaEntrega != null) {
    const fechaHoy = new Date();
    const fechaPromesa = new Date(data.fechaPromesaEntrega);
    if (fechaPromesa < fechaHoy) {
      className = "vehiculo-con-fecha-promesa-retrasada";
    }
  }

  return className.trim();
};
