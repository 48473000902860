import "./CargaManualRemitos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SkeletonFilterOptions from "./components/SkeletonFilterOptions";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function CargaManualRemitosV2() {
  const SCREEN_CODE = "fun0069_carga_manual_remitos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();

  const [remito, setRemito] = useState<any>();

  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [ingreseElVin, setIngreseElVin] = useState([]);

  const [naturalezaFiscal, setNaturalezaFiscal] = useState([]);
  const [tipoRemito, setTipoRemito] = useState([]);
  const [leyendasRemitos, setLeyendasRemitos] = useState([]);
  const [concesionario, setConcesionario] = useState([]);
  const [direccion, setDireccion] = useState([]);
  const [pagaFlete, setPagaFlete] = useState([]);

  function checkSecurityFilters() {
    if (
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id
    ) {
      return true;
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "País, Clientes e Cuentas Obrigatorio",
        life: 3000,
      });
      return false;
    }
  }
  function resetStates() {
    setIsNew(false);
    setIsDisabled(true);
    setIngreseElVin([]);
    setRemito(undefined);
  }
  function newRemito() {
    if (!isNew) {
      //novo remito
      setIsNew(true);
      setIsDisabled(false);
    } else {
      //cancelar
      setIsNew(false);
      setIsDisabled(true);
      resetStates();
    }
  }
  async function loadTipoRemito(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/tipo-remito",
        params
      );
      if (status === 200) {
        if (query) {
          setTipoRemito(
            data.filter((x: any) =>
              x.descripcionFull.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setTipoRemito(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchTipoRemito(event: any) {
    await loadTipoRemito(event.query);
  }
  async function loadLeyendasRemitos(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/leyendas-remitos",
        params
      );
      if (status === 200) {
        if (query) {
          setLeyendasRemitos(
            data.filter((x: any) =>
              x.descripcionFull.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setLeyendasRemitos(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchLeyendasRemitos(event: any) {
    await loadLeyendasRemitos(event.query);
  }
  async function loadPagaFlete(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/paga-flete",
        params
      );
      if (status === 200) {
        if (query) {
          setPagaFlete(
            data.filter((x: any) =>
              x.descripcionFull.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setPagaFlete(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchPagaFlete(event: any) {
    await loadPagaFlete(event.query);
  }
  async function loadNaturalezaFiscal(vin: string, query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
        },
        cuenta: {
          id: securityFilters.securityValues.account.id,
        },
        vin: vin,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/natureza-fiscal",
        params
      );
      if (status === 200) {
        if (query) {
          setNaturalezaFiscal(
            data.filter((x: any) =>
              x.descripcionFull.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setNaturalezaFiscal(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchNaturalezaFiscal(event: any) {
    await loadNaturalezaFiscal(remito?.vin, event.query);
  }
  async function loadConcesionario(query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/concesionario",
        params
      );
      if (status === 200) {
        if (query) {
          setConcesionario(
            data.filter((x: any) =>
              x.descripcionFull.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setConcesionario(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchConcesionario(event: any) {
    await loadConcesionario(event.query);
  }
  async function loadDireccion(concesionario: any, query = "") {
    try {
      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: {
          id: securityFilters.securityValues.client.id,
        },
        concesionario,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/direccion-entrega",
        params
      );
      if (status === 200) {
        if (query) {
          setDireccion(
            data.filter((x: any) =>
              x.descripcionFull.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setDireccion(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function searchDireccion(event: any) {
    await loadDireccion(remito?.concesionario, event.query);
  }
  async function searchIngreseElVin(event: any) {
    await loadIngreseElVin(event.query);
  }
  async function loadIngreseElVin(query = "") {
    try {
      const check = checkSecurityFilters();
      if (typeof query !== "string" || query.length < 8 || query.length > 17) {
        setIngreseElVin([]);
        return;
      }

      if (!check) {
        return;
      }

      const params = {
        pais: {
          id: securityFilters.securityValues.country.id,
        },
        cliente: { id: securityFilters.securityValues.client.id },
        cuenta: { id: securityFilters.securityValues.account.id },
        vin: { id: query },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/vin",
        params
      );
      if (status === 200) {
        if (query) {
          setIngreseElVin(
            data.filter((x: any) =>
              x.id.toLowerCase().includes(query.toLowerCase())
            )
          );
        } else {
          setIngreseElVin(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  function showToast(params) {
    const { severity, detail } = params;
    toast.current?.show({
      severity: severity,
      detail: detail,
      life: 3000,
    });
  }
  async function searchRemito() {
    setLoading(true);
    if (
      remito?.letra === "" ||
      remito?.puntoDeVenta === "" ||
      remito?.numero === ""
    ) {
      showToast({
        severity: "warn",
        detail: <LanguageProvider id={"39"} alt="Existen datos en Blanco." />,
      });
      setLoading(false);
      return;
    }
    const check = checkSecurityFilters();
    if (!check) {
      setLoading(false);
      return;
    }

    try {
      const params = {
        remito: {
          pais: {
            id: securityFilters.securityValues.country.id,
          },
          cliente: {
            id: securityFilters.securityValues.client.id,
          },
          cuenta: {
            id: securityFilters.securityValues.account.id,
          },
          letra: remito?.letra?.toUpperCase(),
          puntoDeVenta: remito?.puntoDeVenta,
          id: remito?.numero,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/carga-manual-remitos/remito",
        params
      );
      if (status === 200) {
        setRemito(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (
      securityFilters.securityValues.country?.id &&
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id
    ) {
      loadTipoRemito();
      loadLeyendasRemitos();
      loadPagaFlete();
      loadConcesionario();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues.country]);

  useEffect(() => {
    if (remito?.vin && typeof remito?.vin === "object") {
      loadNaturalezaFiscal(remito?.vin);
    }
  }, [remito?.vin]);
  useEffect(() => {
    if (remito?.concesionario && typeof remito?.concesionario === "object") {
      loadDireccion(remito?.concesionario);
    }
  }, [remito?.concesionario]);

  return (
    <div className="carga-manual-remitos">
      <Toast ref={toast} position="bottom-left" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Fieldset
        legend={<LanguageProvider id={"1478"} alt="Carga Manual de Remitos" />}
      >
        <div className="fieldset-content">
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option-text-small"}>
                <label>
                  <LanguageProvider id={"849"} alt="Letra" />
                </label>
                <InputText
                  value={remito?.letra}
                  onChange={(e) =>
                    setRemito({ ...remito, letra: e.target.value })
                  }
                  invalid={remito?.remito?.letra === ""}
                />
              </div>
              <div className={"sipco-option-text-small"}>
                <label>
                  <LanguageProvider id={"850"} alt="Pto.Venta" />
                </label>
                <InputText
                  value={remito?.puntoDeVenta}
                  onChange={(e) =>
                    setRemito({ ...remito, puntoDeVenta: e.target.value })
                  }
                  invalid={remito?.puntoDeVenta === ""}
                />
              </div>
              <div className={"sipco-option-text-medium"}>
                <label>
                  <LanguageProvider id={"851"} alt="Número" />
                </label>
                <InputText
                  value={remito?.numero}
                  onChange={(e) =>
                    setRemito({ ...remito, numero: e.target.value })
                  }
                  invalid={remito?.numero === ""}
                />
              </div>
              <div className={"sipco-button-search"}>
                <Button
                  onClick={searchRemito}
                  loading={loading}
                  icon="pi pi-search"
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                </label>
                <AutoComplete
                  value={remito?.vin}
                  suggestions={ingreseElVin}
                  completeMethod={searchIngreseElVin}
                  onChange={(e) => setRemito({ ...remito, vin: e.value })}
                  field="id"
                  dropdown
                  forceSelection
                  disabled={isDisabled}
                  invalid={
                    !!(
                      isNew &&
                      (remito?.vin === "" || remito?.vin === undefined)
                    )
                  }
                />
              </div>
            </div>
          </div>

          {loading ? (
            <SkeletonFilterOptions />
          ) : (
            //FORM
            <div>
              {/* 2 colunas  */}
              <div className="filter-options-column">
                <div className="sipco-options-column">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"2941"} alt="Concesionario" />
                    </label>

                    <AutoComplete
                      forceSelection
                      value={remito?.concesionario}
                      suggestions={concesionario}
                      completeMethod={searchConcesionario}
                      onChange={(e) =>
                        setRemito({ ...remito, concesionario: e.value })
                      }
                      field="descripcion"
                      disabled={isDisabled}
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"1617"} alt="Tipo remito" />
                    </label>
                    <AutoComplete
                      forceSelection
                      value={remito?.tipoRemito}
                      suggestions={tipoRemito}
                      completeMethod={searchTipoRemito}
                      onChange={(e) =>
                        setRemito({ ...remito, tipoRemito: e.value })
                      }
                      field="descripcionFull"
                      disabled={isDisabled}
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option-text"}>
                    <label>
                      <LanguageProvider id={"16923"} alt="Cód.Pedido Cliente" />
                    </label>
                    <InputText
                      value={remito?.idPedidoCliente}
                      onChange={(e) =>
                        setRemito({
                          ...remito,
                          codigoPedidoDonde: e.target.value,
                        })
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="sipco-options-column">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4216"} alt="Boca de Entrega" />
                    </label>

                    <AutoComplete
                      forceSelection
                      value={remito?.direccionEntrega}
                      suggestions={direccion}
                      completeMethod={searchDireccion}
                      onChange={(e) =>
                        setRemito({ ...remito, direccionEntrega: e.value })
                      }
                      field="direccion"
                      disabled={isDisabled}
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16757"} alt="Leyenda" />
                    </label>
                    <AutoComplete
                      forceSelection
                      value={remito?.leyendaRemito}
                      suggestions={leyendasRemitos}
                      completeMethod={searchLeyendasRemitos}
                      onChange={(e) =>
                        setRemito({ ...remito, leyendaRemito: e.value })
                      }
                      field="descripcionFull"
                      disabled={isDisabled}
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option-text"}>
                    <label>
                      <LanguageProvider id={"7888"} alt="Nro. CAI" />
                    </label>
                    <InputText
                      value={remito?.caiImpresion}
                      onChange={(e) =>
                        setRemito({ ...remito, caiImpresion: e.target.value })
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </div>
              {/* 3 colunas  */}
              <div className="filter-options-column">
                <div className="sipco-options-column">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"1492"} alt="Fecha Emisión" />
                    </label>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      value={new Date(remito?.fechaEmision?.date)}
                      onChange={(e) =>
                        setRemito({ ...remito, fechaEmision: e.value })
                      }
                      showButtonBar
                      showIcon
                      disabled={isDisabled}
                    />
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4422"} alt="Llegado" />
                    </label>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      value={
                        remito?.fechaLlegada?.date &&
                        new Date(remito?.fechaLlegada.date)
                      }
                      onChange={(e) =>
                        setRemito({ ...remito, fechaLlegada: e.value })
                      }
                      showButtonBar
                      showIcon
                      disabled
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"14683"} alt="Fecha Anulación" />
                    </label>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      value={
                        remito?.fechaAnulacion?.date &&
                        new Date(remito?.fechaAnulacion.date)
                      }
                      onChange={(e) =>
                        setRemito({ ...remito, fechaAnulacion: e.value })
                      }
                      showButtonBar
                      showIcon
                      disabled
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"21503"} alt="Liberación" />
                    </label>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      value={
                        remito?.fechaLiberacion?.date &&
                        new Date(remito?.fechaLiberacion.date)
                      }
                      onChange={(e) =>
                        setRemito({ ...remito, fechaLiberacion: e.value })
                      }
                      showButtonBar
                      showIcon
                      disabled
                    />
                  </div>
                  <div className={"sipco-option-text"}>
                    <label>
                      <LanguageProvider id={"3591"} alt="Razón Social" />
                    </label>
                    <InputText
                      value={remito?.razonSocial}
                      onChange={(e) =>
                        setRemito({ ...remito, razonSocial: e.target.value })
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className={"sipco-option-text"}>
                    <label>
                      <LanguageProvider id={"8299"} alt="Domicilio" />
                    </label>
                    <InputText
                      value={remito?.domicilioImpresion}
                      onChange={(e) =>
                        setRemito({
                          ...remito,
                          domicilioImpresion: e.target.value,
                        })
                      }
                      disabled
                    />
                  </div>
                  <div className={"sipco-option-text"}>
                    <label>
                      <LanguageProvider id={"16928"} alt="Modelo a imprimir" />
                    </label>
                    <InputText
                      value={remito?.modeloImpresion}
                      onChange={(e) =>
                        setRemito({
                          ...remito,
                          modeloImpresion: e.target.value,
                        })
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="sipco-options-column">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"382"} alt="Despacho" />
                    </label>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      value={
                        remito?.fechaDespacho?.date &&
                        new Date(remito?.fechaDespacho.date)
                      }
                      onChange={(e) =>
                        setRemito({ ...remito, fechaDespacho: e.value })
                      }
                      showButtonBar
                      showIcon
                      disabled
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"13710"} alt="Reingreso" />
                    </label>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      value={
                        remito?.fechaReingreso?.date &&
                        new Date(remito?.fechaReingreso.date)
                      }
                      onChange={(e) =>
                        setRemito({ ...remito, fechaReingreso: e.value })
                      }
                      showButtonBar
                      showIcon
                      disabled
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16925"} alt="Fec. Ant. Retorno" />
                    </label>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      value={
                        remito?.fechaAnticipoRetorno?.date &&
                        new Date(remito?.fechaAnticipoRetorno.date)
                      }
                      onChange={(e) =>
                        setRemito({ ...remito, fechaAnticipoRetorno: e.value })
                      }
                      showButtonBar
                      showIcon
                      disabled
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"16926"}
                        alt="Fecha carga retorno"
                      />
                    </label>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      value={
                        remito?.fechaCargaRetorno?.date &&
                        new Date(remito?.fechaCargaRetorno.date)
                      }
                      onChange={(e) =>
                        setRemito({ ...remito, fechaCargaRetorno: e.value })
                      }
                      showButtonBar
                      showIcon
                      disabled
                    />
                  </div>
                  <div className={"sipco-option-text"}>
                    <label>
                      <LanguageProvider id={"11178"} alt="CUIT" />
                    </label>
                    <InputText
                      value={remito?.cuitImpresion}
                      onChange={(e) =>
                        setRemito({ ...remito, cuitImpresion: e.target.value })
                      }
                      disabled
                    />
                  </div>
                  <div className={"sipco-option-text"}>
                    <label>
                      <LanguageProvider id={"159"} alt="Provincia" />
                    </label>
                    <InputText
                      value={remito?.provinciaImpresion}
                      onChange={(e) =>
                        setRemito({
                          ...remito,
                          provinciaImpresion: e.target.value,
                        })
                      }
                      disabled
                    />
                  </div>
                  <div className={"sipco-option-text"}>
                    <label>
                      <LanguageProvider id={"3186"} alt="Localidad" />
                    </label>
                    <InputText
                      value={remito?.localidadImpresion}
                      onChange={(e) =>
                        setRemito({
                          ...remito,
                          localidadImpresion: e.target.value,
                        })
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="sipco-options-column">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"295"} alt="Retorno" />
                    </label>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      value={
                        remito?.fechaRetorno?.date &&
                        new Date(remito?.fechaRetorno?.date)
                      }
                      onChange={(e) =>
                        setRemito({ ...remito, fechaReingreso: e.value })
                      }
                      showButtonBar
                      showIcon
                      disabled
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"16924"} alt="Vence CAI" />
                    </label>
                    <InputText
                      value={remito?.fechaVencimientoCaiImpresion}
                      onChange={(e) =>
                        setRemito({
                          ...remito,
                          fechaVencimientoCaiImpresion: e.target.value,
                        })
                      }
                      disabled
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"9712"} alt="Fecha de Control" />
                    </label>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      value={
                        remito?.fechaControl?.date &&
                        new Date(remito?.fechaControl?.date)
                      }
                      onChange={(e) =>
                        setRemito({ ...remito, fechaControl: e.value })
                      }
                      showButtonBar
                      showIcon
                      disabled
                    />
                  </div>
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id="16927" alt="Actualizó fecha" />
                    </label>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      value={
                        remito?.fechaUltimaModificacion?.date &&
                        new Date(remito?.fechaUltimaModificacion?.date)
                      }
                      onChange={(e) =>
                        setRemito({
                          ...remito,
                          fechaUltimaModificacion: e.value,
                        })
                      }
                      showButtonBar
                      showIcon
                      readOnlyInput
                      disabled
                    />
                  </div>
                  <div className="sipco-option-text">
                    <label>
                      <LanguageProvider id="22216" alt="IVA" />
                    </label>
                    <InputText
                      value={remito?.ivaImpresion}
                      onChange={(e) =>
                        setRemito({ ...remito, iva: e.target.value })
                      }
                      readOnly
                      disabled
                    />
                  </div>
                  <div className="sipco-option-text">
                    <label>
                      <LanguageProvider id="11176" alt="Código Postal" />
                    </label>
                    <InputText
                      value={remito?.codigoPostalImpresion}
                      readOnly
                      onChange={(e) =>
                        setRemito({
                          ...remito,
                          codigoPostalImpresion: e.target.value,
                        })
                      }
                      disabled
                    />
                  </div>
                  <div className={"sipco-option-text"}>
                    <label>
                      <LanguageProvider id={"9387"} alt="Estado del remito" />
                    </label>
                    <InputText
                      value={remito?.estadoRemito?.id}
                      onChange={(e) =>
                        setRemito({
                          ...remito,
                          estadoRemito: {
                            ...remito.estadoRemito,
                            id: e.target.value,
                          },
                        })
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
              {/* linha final  */}
              <div className="filter-options">
                <div
                  className="sipco-options-line"
                  style={{ alignItems: "flex-end" }}
                >
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"10855"} alt="Paga Flete" />
                    </label>
                    <AutoComplete
                      forceSelection
                      value={remito?.pagaFlete}
                      suggestions={pagaFlete}
                      completeMethod={searchPagaFlete}
                      onChange={(e) =>
                        setRemito({ ...remito, pagaFlete: e.value })
                      }
                      field="descripcionFull"
                      disabled={isDisabled}
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"10769"} alt="Naturaleza Fiscal" />
                    </label>

                    <AutoComplete
                      forceSelection
                      value={remito?.naturalezaFiscal}
                      suggestions={naturalezaFiscal}
                      completeMethod={searchNaturalezaFiscal}
                      onChange={(e) =>
                        setRemito({ ...remito, naturalezaFiscal: e.value })
                      }
                      field="descripcionFull"
                      disabled={isDisabled}
                      dropdown
                    />
                  </div>
                  <div className={"sipco-option-text-small"}>
                    <label>
                      <LanguageProvider id={"32"} alt="Valor" />
                    </label>
                    <InputText
                      value={remito?.valor}
                      onChange={(e) =>
                        setRemito({ ...remito, valor: e.target.value })
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4974"} alt="Comentarios" />
                    </label>
                    <InputTextarea
                      autoResize
                      rows={5}
                      cols={30}
                      value={remito?.comentarioImpresion}
                      onChange={(e) =>
                        setRemito({
                          ...remito,
                          comentarioImpresion: e.target.value,
                        })
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className={"RadioButtonFilter"}>
                    <label>
                      <LanguageProvider id={"22446"} alt="Ficticio" />
                    </label>
                    <Checkbox
                      checked={remito?.ficticio}
                      onChange={(e) =>
                        setRemito({ ...remito, ficticio: e.checked })
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="footer-table">
          {/* butons */}
          {isNew ? (
            <div>
              <Button
                label={LanguageProvider({
                  id: "9491",
                  alt: "Aceptar",
                })}
                text
              />
              <Button
                label={LanguageProvider({
                  id: "3155",
                  alt: "Cancelar",
                })}
                onClick={newRemito}
                text
              />
            </div>
          ) : (
            <div>
              <Button
                label={LanguageProvider({
                  id: "25",
                  alt: "Alta",
                })}
                onClick={newRemito}
                text
              />
              <Button
                label={LanguageProvider({
                  id: "27",
                  alt: "Modificar",
                })}
                disabled={true}
                text
              />
              <Button
                label={LanguageProvider({
                  id: "21880",
                  alt: "Borrar",
                })}
                text
              />
              <Button
                label={LanguageProvider({
                  id: "13334",
                  alt: "Cargar Vines de PEUGEOT CHILE con archivo Excel",
                })}
                disabled={true}
                text
              />
              <Button
                label={LanguageProvider({
                  id: "15076",
                  alt: "Cargar Remitos MACO CHILE desde archivo excel.",
                })}
                disabled={true}
                text
              />
              <Button
                label={LanguageProvider({
                  id: "17526",
                  alt: "Genérico",
                })}
                text
              />
            </div>
          )}
        </div>
      </Fieldset>
    </div>
  );
}
export default CargaManualRemitosV2;
