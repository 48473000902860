import "./PedidosPlaya.scss";

import React, { useEffect, useRef, useState } from "react";
import { AccordionTab } from "primereact/accordion";

import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import useFormattedDate from "../../../hooks/useFormattedDate";
import { Dropdown } from "primereact/dropdown";

export function PedidosPlaya() {
  const SCREEN_CODE = "fun0221_pedidos_playa";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);

  const filterLabelId = [];
  const filterNames = [];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }

  const getRowStyles = (data) => {
    let className = "";

    if (
      selectedReportData &&
      JSON.stringify(selectedReportData) === JSON.stringify(data)
    ) {
      className = "highlight-row";
    }

    if (data.vencido === 1) {
      className += " color-pedido-vencido ";
    }

    if (data.estado.id === "4") {
      className += " color-pedido-cancelado ";
    }

    if (data.disponibilizado === 0) {
      className += " color-pedido-sin-disponibilizar ";
    }

    return className.trim();
  };

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [selectedReportData, setSelectedReportData] = useState(null);

  const [nroAsignacion, setNroAsignacion] = useState([]);
  const [codigoReferencia, setCodigoReferencia] = useState([]);

  const { formatDate, formatDateTime } = useFormattedDate();

  const [filter, setFilter] = useState({
    fechaPedido: true,
    fechaPedidoDesde: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000), // -30 day
    fechaPedidoHasta: new Date(),
    estado: TODOS_OBJ,
    numeroAsignacion: "",
    referenciaCliente: "",
  });

  const [estado, setEstado] = useState([]);
  const [loadingEstado, setLoadingEstado] = useState(false);
  async function loadEstado() {
    setLoadingEstado(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/pedidos-playa/codigo-playa",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          data.unshift({ id: "TODOS", descripcionFull: "TODOS" });
          setEstado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstado(false);
    }
  }

  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const filters = {
        pais: {
          id: securityFilters?.securityValues?.country?.id || null,
        },
        cliente: {
          id: securityFilters?.securityValues?.client?.id || null,
        },
        cuenta: {
          id: securityFilters?.securityValues?.account?.id || null,
        },
        subcuenta: {
          id: securityFilters?.securityValues?.subaccount?.id || null,
        },

        fechaPedidoDesde: filter.fechaPedido
          ? filter.fechaPedidoDesde.getTime()
          : null,
        fechaPedidoHasta: filter.fechaPedido
          ? filter.fechaPedidoHasta.getTime()
          : null,
        estado: { id: filter?.estado.id || "" },
        numeroAsignacion: filter?.numeroAsignacion || "",
        referenciaCliente: filter?.referenciaCliente || "",
      };
      const { status, data } = await sipcoAxiosService.post(
        "/pedidos-playa/pedido-playa",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters.securityValues.country) {
        try {
          const estados = await loadEstado();
          setFilter({
            ...filter,
            estado: estados[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues.country]);

  return (
    <div className="pedidos-playa">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="RadioButtonFilter">
              <Checkbox
                checked={filter.fechaPedido}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    fechaPedido: !filter.fechaPedido,
                  });
                }}
              />
              <label>
                <LanguageProvider id="11372" alt="Fecha Pedido" />
              </label>
            </div>

            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id="2621" alt="Desde" />
              </label>{" "}
              <Calendar
                value={filter.fechaPedidoDesde}
                onChange={(e) => {
                  const date = new Date(e.value);
                  setFilter({ ...filter, fechaPedidoDesde: date });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
                disabled={!filter.fechaPedido}
              />
            </div>
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id="2622" alt="Hasta" />
              </label>
              <Calendar
                value={filter.fechaPedidoHasta}
                onChange={(e) => {
                  const date = new Date(e.value);
                  setFilter({ ...filter, fechaPedidoHasta: date });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
                disabled={!filter.fechaPedido}
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"3344"} alt="Estado" />
              </label>
              <Dropdown
                value={filter.estado}
                options={estado}
                onChange={(e) => setFilter({ ...filter, estado: e.value })}
                optionLabel="descripcionFull"
                loading={loadingEstado}
                filter
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id="22137" alt="Nro. Asignación" />
              </label>
              <AutoComplete
                value={filter.numeroAsignacion}
                suggestions={nroAsignacion}
                onChange={(e) =>
                  setFilter({ ...filter, numeroAsignacion: e.value })
                }
                field="label"
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id="23097" alt="Código referencia" />
              </label>
              <AutoComplete
                value={filter.referenciaCliente}
                suggestions={codigoReferencia}
                onChange={(e) =>
                  setFilter({ ...filter, referenciaCliente: e.value })
                }
                field="label"
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {
                  <LanguageProvider
                    id="22138"
                    alt="Pedidos Playa"
                  ></LanguageProvider>
                }
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={reporte}
                  columns={columnsNames}
                  screenName={"Pedidos Playa"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={getRowStyles}
          loading={loadingReportData}
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
          selectionMode="multiple"
          filterDisplay="menu"
        >
          <Column
            field="nroAsignacion"
            header={<LanguageProvider id="22137" alt="Nro. Asignación" />}
            sortable
            filter
          />
          <Column
            field="referencia_cliente"
            header={<LanguageProvider id="23097" alt="Código referencia" />}
            sortable
            filter
          />
          <Column
            field="fechaPedido.date"
            header={<LanguageProvider id="11372" alt="Fecha Pedido" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaPedido?.date
                ? formatDateTime(rowData.fechaPedido.date)
                : rowData.fechaPedido || ""
            }
          />
          <Column
            field="vencido"
            header={<LanguageProvider id="17203" alt="Vencido" />}
            sortable
            filter
            body={(rowData) =>
              rowData.vencido === 1 ? (
                <LanguageProvider id="10043" alt="Si"></LanguageProvider>
              ) : rowData.vencido === 0 ? (
                <LanguageProvider id="10044" alt="No"></LanguageProvider>
              ) : (
                ""
              )
            }
          />
          <Column
            field="diasVencido"
            header={<LanguageProvider id="2830" alt="Dias Vencidos" />}
            sortable
            filter
            body={(rowData) =>
              rowData.diasVencido ? rowData.diasVencido.toFixed(2) : "0,00"
            }
          />
          <Column
            field="tiempoTransporte"
            header={<LanguageProvider id="22406" alt="Tiempo Transporte" />}
            sortable
            filter
            body={(rowData) =>
              rowData.tiempoTransporte
                ? rowData.tiempoTransporte.toFixed(2)
                : "0,00"
            }
          />
          <Column
            field="fechaVencimiento.date"
            header={<LanguageProvider id="8139" alt="Fecha Vencimiento" />}
            sortable
            filter
            body={(rowData) => formatDate(rowData.fechaVencimiento.date)}
          />
          <Column
            field="concesionario.id"
            header={<LanguageProvider id="10871" alt="Cod. Concesionario" />}
            sortable
            filter
          />
          <Column
            field="concesionario.descripcion"
            header={<LanguageProvider id="2941" alt="Concesionario" />}
            sortable
            filter
          />
          <Column
            field="direccionEntrega.id"
            header={<LanguageProvider id="10868" alt="Cod. Dir. Entrega" />}
            sortable
            filter
          />
          <Column
            field="direccionEntrega.direccion"
            header={<LanguageProvider id="10904" alt="Dir. Entrega" />}
            sortable
            filter
          />
          <Column
            field=""
            header={<LanguageProvider id="23369" alt="Cód. Escala" />}
            sortable
            filter
          />
          <Column
            field=""
            header={<LanguageProvider id="23370" alt="Escala" />}
            sortable
            filter
          />
          <Column
            field="kmPlayaOrigenEscala"
            header={
              <LanguageProvider id="23371" alt="Km. Playa Origen - Escala" />
            }
            sortable
            filter
          />
          <Column
            field="kmEscalaBocaDeEntrega"
            header={
              <LanguageProvider id="23372" alt="Km. Escala - Dir. Entrega" />
            }
            sortable
            filter
          />
          <Column
            field="kmPlayaOrigenBocaDeEntrega"
            header={
              <LanguageProvider
                id="23373"
                alt="Km. Playa Origen - Dir. Entrega"
              />
            }
            sortable
            filter
          />
          <Column
            field=""
            header={<LanguageProvider id="23374" alt="Cód. Corredor Escala" />}
            sortable
            filter
          />
          <Column
            field=""
            header={<LanguageProvider id="23375" alt="Corredor Escala" />}
            sortable
            filter
          />
          <Column
            field=""
            header={<LanguageProvider id="23376" alt="Localidad Escala" />}
            sortable
            filter
          />
          <Column
            field=""
            header={<LanguageProvider id="23377" alt="Ciudad Escala" />}
            sortable
            filter
          />
          <Column
            field="direccionEntrega.corredor.id"
            header={<LanguageProvider id="22404" alt="Cód. Corredor" />}
            sortable
            filter
          />
          <Column
            field="direccionEntrega.corredor.descripcion"
            header={<LanguageProvider id="10502" alt="Corredor" />}
            sortable
            filter
          />
          <Column
            field="direccionEntrega.localidad.descripcion"
            header={<LanguageProvider id="164" alt="Corredor" />}
            sortable
            filter
          />
          <Column
            field="direccionEntrega.localidad.descripcion"
            header={<LanguageProvider id="24026" alt="Localidad" />}
            sortable
            filter
          />
          <Column
            field="familia.descripcion"
            header={<LanguageProvider id="10034" alt="Familia" />}
            sortable
            filter
          />
          <Column
            field="modelo.descripcion"
            header={<LanguageProvider id="10746" alt="Modelo" />}
            sortable
            filter
          />
          <Column
            field="preHojaRuta"
            header={<LanguageProvider id="22139" alt="Pre Hoja de Ruta" />}
            sortable
            filter
          />
          <Column
            field="estado.descripcion"
            header={<LanguageProvider id="3344" alt="Estado" />}
            sortable
            filter
          />
          <Column
            field="prioridad"
            header={<LanguageProvider id="4976" alt="Prioridad" />}
            sortable
            filter
            body={(rowData) =>
              rowData.prioridad === 1 ? (
                <LanguageProvider id="10043" alt="Si"></LanguageProvider>
              ) : rowData.prioridad === 0 ? (
                <LanguageProvider id="10044" alt="No"></LanguageProvider>
              ) : (
                ""
              )
            }
          />
          <Column
            field="observacion"
            header={<LanguageProvider id="1669" alt="Observación" />}
            sortable
            filter
          />
          <Column
            field="usuarioUltimaModificacion.id"
            header={
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            }
            sortable
            filter
          />
          <Column
            field="fechaUltimaModificacion.date"
            header={<LanguageProvider id="5271" alt="Fec. Últ. Modificación" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaUltimaModificacion?.date
                ? formatDateTime(rowData.fechaUltimaModificacion.date)
                : rowData.fechaUltimaModificacion || ""
            }
          />
          <Column
            field="remito.letra"
            header={<LanguageProvider id="6781" alt="Letra" />}
            sortable
            filter
          />
          <Column
            field="remito.puntoDeVenta"
            header={<LanguageProvider id="7868" alt="Pto. Vta" />}
            sortable
            filter
          />
          <Column
            field="remito.id"
            header={<LanguageProvider id="932" alt="Código Remito" />}
            sortable
            filter
          />
        </DataTable>

        <div className="management-register">
          <div className="register-item">
            <label>
              <LanguageProvider id="841" alt="Total de Registros:" />
            </label>
            <label className="value">
              {reporte && Array.isArray(reporte) ? reporte.length : 0}
            </label>
          </div>

          <div className="register-item">
            <label>
              <LanguageProvider id="864" alt="Registros Seleccionados:" />
            </label>
            <label className="value">
              {selectedReportData && selectedReportData.length > 0
                ? selectedReportData.length
                : 0}
            </label>
          </div>
        </div>

        <div className="tags">
          <Tag
            value={
              <LanguageProvider
                id="22407"
                alt="Pedido vencido"
              ></LanguageProvider>
            }
            className="tag-pedido-vencido"
          />
          <Tag
            value={
              <LanguageProvider
                id="22408"
                alt="Pedido cancelado"
              ></LanguageProvider>
            }
            className="tag-pedido-cancelado"
          />
          <Tag
            value={
              <LanguageProvider
                id="23450"
                alt="Pedido sin disponibilizar"
              ></LanguageProvider>
            }
            className="tag-pedido-sin-disponibilizar"
          />
        </div>

        <div className="button-container">
          <Button
            label={LanguageProvider({ id: "22140", alt: "Agrupar" })}
            icon="pi pi-plus"
            className="custom-button green-button"
          />
          <Button
            label={LanguageProvider({ id: "22141", alt: "Desagrupar" })}
            icon="pi pi-minus"
            className="custom-button yellow-button"
          />
          <Button
            label={LanguageProvider({ id: "3155", alt: "Cancelar" })}
            icon="pi pi-times"
            className="custom-button red-button"
          />
          <Button
            label={LanguageProvider({
              id: "187",
              alt: "Imprimir ou gerar arquivo Excel",
            })}
            icon="pi pi-print"
            className="custom-button"
          />
          <Button
            label={LanguageProvider({ id: "2366", alt: "Importar Arquivo" })}
            icon="pi pi-upload"
            className="custom-button"
          />
          <Button
            label={LanguageProvider({ id: "25019", alt: "Importar Interfaz" })}
            icon="pi pi-upload"
            className="custom-button"
          />
        </div>
      </Panel>
    </div>
  );
}
export default PedidosPlaya;

const TODOS_OBJ = {
  label: "TODOS",
  id: "TODOS",
  descripcion: "TODOS",
};
