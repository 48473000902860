import "./LiberacionAduana.scss";

import LiberacionAduanaBrForm from "./components/LiberacionAduanaBrForm";
import LiberacionAduanaForm from "./components/LiberacionAduanaForm";
import React from "react";
import useAuth from "@shared/AuthContext";
import usePageViews from "../../../hooks/usePageViews";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function LiberacionAduana() {
  const SCREEN_CODE = "fun0060_liberacion_aduana";
  ScreenCodeValue(SCREEN_CODE);
  const { user } = useAuth();
  usePageViews();

  function CountryForm() {
    if (user.pais.codPais === 2) {
      return <LiberacionAduanaBrForm SCREEN_CODE={SCREEN_CODE} />;
    } else {
      return <LiberacionAduanaForm SCREEN_CODE={SCREEN_CODE} />;
    }
  }

  return (
    <div className="liberacion-aduana">
      <CountryForm />
    </div>
  );
}
export default LiberacionAduana;
