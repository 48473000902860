import "./mantener-hojas-ruta.scss";

import React, { useEffect, useRef, useState } from "react";

import { PedidosPlaya } from "../../functions/pedidos-playa/PedidosPlaya";
import { ConsultaVin } from "../../queries/consulta-vin/consulta-vin";

import { AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Button } from "primereact/button";
import useRowStyles from "../../../hooks/useRowStyles";
import { AutoComplete } from "primereact/autocomplete";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { TabPanel, TabView } from "primereact/tabview";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import useFormattedDate from "../../../hooks/useFormattedDate";
import { Dropdown } from "primereact/dropdown";

export function MentenerHojasRuta() {
  const SCREEN_CODE = "fun0090_modificacion_manual_hr";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const dt = useRef(null);
  const { values: securityFilters } = useFilterContext();
  usePageViews();

  //#region --methods and functions
  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRadioChange = (value) => {
    try {
      setModel({ ...model, radioButton: value });
    } catch (error) {
      console.error(error);
    }
  };

  const getRowStyles = (data) => {
    let className = "";

    if (
      selectedReportData &&
      JSON.stringify(selectedReportData) === JSON.stringify(data)
    ) {
      className = "highlight-row";
    }

    if (data.length > 0) {
      className += " color-font-bold ";
    }

    return className.trim();
  };

  // const clearFieldsBtnAlta = () => {
  //   try {
  //     setModel({
  //       //Alta-Cabecera
  //       fechaDespacho: new Date(),
  //       estado: estado[0],
  //       nroHojaRutaProv: null,
  //       totalKm: null,
  //       chkCargaCombinada: false,
  //       chkVenta: true,
  //       chkCompra: true,
  //       tipoServicio: tipoServicio[0],
  //       tipoViajeRetorno: tipoViajeRetorno[0],
  //       aforoVenta: null,
  //       transportista: transportista[0],
  //       tractor: tractor[0],
  //       bateaTransportin: bateaTransportin[0],
  //       chofer: chofer[0],
  //       obs: null,

  //       //btnAlta-Detalle
  //       quantAddData: null,
  //       quantRemData: null,
  //       radioButton: "Despacho desde Stock",
  //       vinDetalle: vinDetalle[0],
  //       estadoDetalle: estadoDetalle[0],
  //       patente: null,
  //       ubicacion: null,
  //       chkTrabajarConLectoraCodBarras: false,
  //       clienteDetalle: clienteDetalle[0],
  //       nroRemito: nroRemito[0],
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  //#endregion

  //#region --form
  const [model, setModel] = useState({
    //btnAlta -Cabecera
    fechaDespacho: new Date(),
    estado: null,
    nroHojaRutaProv: null,
    totalKm: null,
    chkCargaCombinada: false,
    chkVenta: true,
    chkCompra: true,
    tipoServicio: null,
    tipoViajeRetorno: null,
    aforoVenta: null,
    transportista: null,
    tractor: null,
    bateaTransportin: null,
    chofer: null,
    obs: null,

    //btnAlta -Detalle
    quantAddData: null,
    quantRemData: null,
    radioButton: "Despacho desde Stock",
    vinDetalle: null,
    estadoDetalle: null,
    patente: null,
    ubicacion: null,
    chkTrabajarConLectoraCodBarras: false,
    clienteDetalle: null,
    nroRemito: null,
  });

  //#region --Filters
  const [selectedReportData, setSelectedReportData] = useState(null);
  const [selectedChildReportData, setSelectedChildReportData] = useState(null);

  const rowStyles = useRowStyles([selectedReportData, selectedChildReportData]);
  const { formatDate, formatDateTime } = useFormattedDate();

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reportData, setReportData] = useState(null);
  const formattedReportData = convertDateObjects(reportData?.filas);

  const [loadingChildReportData, setLoadingChildReportData] = useState(false);
  const [childReportData, setChildReportData] = useState([]);

  const [hr, setHR] = useState([]);

  const [filterForm, setFilterForm] = useState({
    vin: null,
    hr: null,
    fechaDespachoDesde: new Date(),
    fechaHasta: new Date(),
  });

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters.securityValues.account) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/find-vin",
          {
            codPais: securityFilters?.securityValues?.country?.id,
            codCuenta: securityFilters?.securityValues?.account?.id,
            vin: {
              id: vin,
            },
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError(
              "900",
              "No existe VIN para el Cliente, Cuenta y Subcuenta seleccionado"
            );
          }

          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          setVinDetalle(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadReportData() {
    setLoadingReportData(true);
    try {
      let filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,

        vin: filterForm?.vin || "",
        numeroHojaRuta: filterForm?.hr || null,
        rangeFecha: [filterForm?.fechaDespachoDesde, filterForm?.fechaHasta],
      };

      const { status, data } = await sipcoAxiosService.post(
        "/modificacion-manual-hoja-ruta/report",
        filters
      );
      if (status === 200) {
        setReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  async function loadReportDataDetails() {
    setLoadingChildReportData(true);
    try {
      let filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        idHojaRuta: selectedReportData[0] || null,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/modificacion-manual-hoja-ruta/linea-hoja-ruta",
        filters
      );

      if (status === 200) {
        setChildReportData(Array.isArray(data) ? data : []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChildReportData(false);
    }
  }

  useEffect(() => {
    if (selectedReportData) {
      loadReportDataDetails();
    }
  }, [selectedReportData]);

  //trazer primeira linha do listado selecionada
  useEffect(() => {
    if (childReportData && childReportData.length > 0) {
      setSelectedChildReportData(childReportData[0]);
    }
  }, [childReportData]);

  //#endregion

  const [validaDataDialog, setValidaDataDialog] = useState(false);

  //#region --btnAlta
  const [loadingBtnAlta, setLoadingBtnAlta] = useState(false);
  const [btnAltaDialog, setBtnAltaDialog] = useState(false);

  const handleBtnAlta = (isConfirmed) => {
    try {
      setBtnAltaDialog(false);
      if (isConfirmed) {
        // btnAceptarAlta();
      } else {
        // clearFieldsBtnAlta();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // async function btnAceptarAlta() {
  //   // setLoadingBtnAlta(true);
  //   try {
  //     const filters = { ""
  //
  //     };

  //     const { status } = await sipcoAxiosService.post("", filters);
  //     if (status === 200) {
  //       // setConfirmAltaDialog(true);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     // setLoadingBtnAlta(false);
  //   }
  // }

  //#region --panelCabecera
  const [nroHojaRutaProv, setNroHojaRutaProv] = useState([]);
  const [totalKm, setTotalKm] = useState([]);
  const [aforoVenta, setAforoVenta] = useState([]);
  const [obs, setObs] = useState([]);

  const [loadingEstado, setLoadingEstado] = useState(false);
  const [estado, setEstado] = useState([]);
  async function loadEstado() {
    setLoadingEstado(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/estado-hoja-ruta",
          {
            codPais: securityFilters.securityValues.country.id,
          }
        );

        if (status === 200) {
          const formattedData = data.map((item) => ({
            ...item,
            descripcion: `${item.descripcion} (${item.id})`,
          }));

          setEstado(formattedData);
          return formattedData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstado(false);
    }
  }

  const [loadingTipoServicio, setLoadingTipoServicio] = useState(false);
  const [tipoServicio, setTipoServicio] = useState([]);
  async function loadTipoServicio() {
    setLoadingTipoServicio(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/",
          {
            // codPais: securityFilters.securityValues.country.id,
          }
        );

        if (status === 200) {
          const formattedData = data.map((item) => ({
            ...item,
            descripcion: `${item.descripcion} (${item.id})`,
          }));

          setTipoServicio(formattedData);
          return formattedData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoServicio(false);
    }
  }

  const [loadingTipoViajeRetorno, setLoadingTipoViajeRetorno] = useState(false);
  const [tipoViajeRetorno, setTipoViajeRetorno] = useState([]);
  async function loadTipoViajeRetorno() {
    setLoadingTipoViajeRetorno(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/tipo-viaje-retorno",
          {
            codPais: securityFilters.securityValues.country.id,
          }
        );

        if (status === 200) {
          const formattedData = data.map((item) => ({
            ...item,
            descripcion: `${item.descripcion} (${item.id})`,
          }));

          setTipoViajeRetorno(formattedData);
          return formattedData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoViajeRetorno(false);
    }
  }

  const [loadingTransportista, setLoadingTransportista] = useState(false);
  const [transportista, setTransportista] = useState([]);
  async function loadTransportista() {
    setLoadingTransportista(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/proveedor",
          {
            codPais: securityFilters.securityValues.country.id,
            codCuenta: securityFilters.securityValues.account.id,
            codSubcuenta: securityFilters.securityValues.subaccount.id,
          }
        );

        if (status === 200) {
          const formattedData = data.map((item) => ({
            ...item,
            descripcion: `${item.descripcion} (${item.id})`,
          }));

          setTransportista(formattedData);
          return formattedData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransportista(false);
    }
  }

  const [loadingTractor, setLoadingTractor] = useState(false);
  const [tractor, setTractor] = useState([]);
  async function loadTractor() {
    setLoadingTractor(true);
    try {
      if (securityFilters?.securityValues?.country && transportista !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/tractores",
          {
            codPais: securityFilters.securityValues.country.id,
            transportista: {
              id: transportista[0].id,
            },
          }
        );

        if (status === 200) {
          const formattedData = data.map((item) => ({
            ...item,
            descripcion: `${item.descripcion} (${item.id})`,
          }));

          setTractor(formattedData);
          return formattedData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTractor(false);
    }
  }

  const [loadingBateaTransportin, setLoadingBateaTransportin] = useState(false);
  const [bateaTransportin, setBateaTransportin] = useState([]);
  async function loadBateaTransportin() {
    setLoadingBateaTransportin(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/",
          {
            // codPais: securityFilters.securityValues.country.id,
          }
        );

        if (status === 200) {
          const formattedData = data.map((item) => ({
            ...item,
            descripcion: `${item.descripcion} (${item.id})`,
          }));

          setBateaTransportin(formattedData);
          return formattedData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBateaTransportin(false);
    }
  }

  const [loadingChofer, setLoadingChofer] = useState(false);
  const [chofer, setChofer] = useState([]);
  async function loadChofer() {
    setLoadingChofer(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/chofer",
          {
            // codPais: securityFilters.securityValues.country.id,
            // administrador: {
            //   id: tractor[0].id,
            // },
            // fecha: model.fechaDespacho.getTime(),
          }
        );

        if (status === 200) {
          const formattedData = data.map((item) => ({
            ...item,
            descripcion: `${item.nombre} (${item.id})`,
          }));

          setChofer(formattedData);
          return formattedData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChofer(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country !== null) {
        try {
          const estados = await loadEstado();
          // const tipoServicios = await loadTipoServicio();
          const tipoViajeRetornos = await loadTipoViajeRetorno();
          const transportistas = await loadTransportista();
          const tractors = await loadTractor();
          // const bateaTransportins = await loadBateaTransportin();
          //const chofers = await loadChofer();

          const estadoDefault = estados.find((estado) => estado.id === "1");

          setModel({
            ...model,
            estado: estadoDefault || estados[0],
            // tipoServicio: tipoServicios[0],
            tipoViajeRetorno: tipoViajeRetornos[0],
            transportista: transportistas[0],
            tractor: tractors[0],
            // bateaTransportin: bateaTransportins[0],
            //chofer: chofers[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);
  //#endregion

  //#region --panelDetalle
  const [loadingBtnPedidosPlayaDialog, setLoadingBtnPedidosPlayaDialog] =
    useState(false);
  const [btnPedidosPlayaDialog, setBtnPedidosPlayaDialog] = useState(false);

  const [loadingBtnConsultaVin, setLoadingBtnConsultaVin] = useState(false);
  const [btnConsultaVinDialog, setBtnConsultaVinDialog] = useState(false);

  const [quantAddData, setQuantAddData] = useState([]);
  const [quantRemData, setQuantRemData] = useState([]);
  const [patente, setPatente] = useState([]);
  const [ubicacion, setUbicacion] = useState([]);

  const [vinDetalle, setVinDetalle] = useState([]);

  const [loadingClienteDetalle, setLoadingClienteDetalle] = useState(false);
  const [clienteDetalle, setClienteDetalle] = useState([]);
  async function loadClienteDetalle() {
    setLoadingClienteDetalle(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/cliente",
          {
            codPais: securityFilters.securityValues.country.id,
          }
        );

        if (status === 200) {
          const formattedData = data.map((item) => ({
            ...item,
            descripcion: `${item.descripcion} (${item.id})`,
          }));

          setClienteDetalle(formattedData);
          return formattedData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClienteDetalle(false);
    }
  }

  const [loadingEstadoDetalle, setLoadingEstadoDetalle] = useState(false);
  const [estadoDetalle, setEstadoDetalle] = useState([]);
  async function loadEstadoDetalle() {
    setLoadingEstadoDetalle(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/estado-hoja-ruta",
          {
            codPais: securityFilters.securityValues.country.id,
          }
        );

        if (status === 200) {
          const formattedData = data.map((item) => ({
            ...item,
            descripcion: `${item.descripcion} (${item.id})`,
          }));

          setEstadoDetalle(formattedData);
          return formattedData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadoDetalle(false);
    }
  }

  const [loadingNroRemito, setLoadingNroRemito] = useState(false);
  const [nroRemito, setNroRemito] = useState([]);
  async function loadNroRemito() {
    setLoadingNroRemito(true);
    try {
      if (securityFilters?.securityValues?.country && vinDetalle !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/remito",
          {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
            codCuenta: securityFilters.securityValues.account.id,
            vin: {
              id: vinDetalle[0].id,
            },
          }
        );

        if (status === 200) {
          const formattedData = data.map((item) => ({
            ...item,
            descripcion: `${item.formatRemito} (${item.id})`,
          }));

          setNroRemito(formattedData);
          return formattedData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNroRemito(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const estadosDetalles = await loadEstadoDetalle();
          const clientesDetalles = await loadClienteDetalle();
          const nroRemitos = await loadNroRemito();

          const estadoDefault = estadosDetalles.find(
            (estado) => estado.id === "1"
          );

          setModel({
            ...model,
            estado: estadoDefault || estadosDetalles[0],
            clienteDetalle: clientesDetalles[0],
            nroRemito: nroRemitos[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);
  //#endregion

  //#endregion

  //#region --btnModificar
  const [loadingBtnModificar, setLoadingBtnModificar] = useState(false);
  const [btnModificarDialog, setBtnModificarDialog] = useState(false);

  const [validaDespachadaDialog, setValidaDespachadaDialog] = useState(false);

  const handleBtnModificar = (isConfirmed: boolean) => {
    try {
      setBtnModificarDialog(false);
      if (isConfirmed) {
        // btnAceptarModificar();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // async function btnAceptarModificar() {
  //   // setLoadingBtnModificar(true);
  //   try {
  //     const filters = { ""
  //
  //     };

  //     const { status } = await sipcoAxiosService.post("", filters);
  //     if (status === 200) {
  //       // setConfirmModificarDialog(true);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     // setLoadingBtnModificar(false);
  //   }
  // }
  //#endregion

  //#region --btnBorrar
  const [loadingBtnBorrar, setLoadingBtnBorrar] = useState(false);
  const [btnBorrarDialog, setBtnBorrarDialog] = useState(false);

  const handleBtnBorrar = (isConfirmed) => {
    try {
      setBtnBorrarDialog(false);
      if (isConfirmed) {
        // btnAceptarBorrar();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // async function btnAceptarMorrar() {
  //   // setLoadingBtBorrar(true);
  //   try {
  //     const filters = { ""
  //
  //     };

  //     const { status } = await sipcoAxiosService.post("", filters);
  //     if (status === 200) {
  //       // setConfirmBorrarDialog(true);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     // setLoadingBtBorrar(false);
  //   }
  // }
  //#endregion

  //#region --btnPosicionBatea
  const [loadingBtnPosicionBatea, setLoadingBtnPosicionBatea] = useState(false);
  const [btnPosicionBateaDialog, setBtnPosicionBateaDialog] = useState(false);

  const handleBtnPosicionBatea = (isConfirmed) => {
    try {
      setBtnPosicionBateaDialog(false);
      if (isConfirmed) {
        // btnAceptarPosicionBatea();
      }
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion

  //#region --btnCambiarFecha
  const [loadingBtnCambiarFecha, setLoadingBtnCambiarFecha] = useState(false);
  const [btnCambiarFechaDialog, setBtnCambiarFechaDialog] = useState(false);

  const [validHojaRutaExtDialog, setValidHojaRutaExtDialog] = useState(false);

  const handleBtnCambiarFecha = (isConfirmed) => {
    try {
      setBtnCambiarFechaDialog(false);
      if (isConfirmed) {
        // btnAceptarCambiarFecha();
      }
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion

  //#region --btnCancelarContable
  const [loadingBtnCancContable, setLoadingBtnCancContable] = useState(false);
  const [btnCancContableDialog, setBtnCancContableDialog] = useState(false);

  const handleBtnCancelarContable = (isConfirmed) => {
    try {
      setBtnCancContableDialog(false);
      if (isConfirmed) {
        // btnAceptarCancelarContable();
      }
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion

  //#region --btnContabilizar
  const [loadingBtnContabilizar, setLoadingBtnContabilizar] = useState(false);
  const [btnContabilizarDialog, setBtnContabilizarDialog] = useState(false);

  const handleBtnContabilizar = (isConfirmed) => {
    try {
      setBtnContabilizarDialog(false);
      if (isConfirmed) {
        // btnAceptarContabilizar();
      }
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion

  //#region --btnRecalcularKM
  const [loadingBtnRecalcKM, setLoadingBtnRecalcKM] = useState(false);
  const [btnRecalcKMDialog, setBtnRecalcKMDialog] = useState(false);

  const [validaDespachKMDialog, setValidaDespachKMDialog] = useState(false);

  const handleBtnRecalcKM = (isConfirmed) => {
    try {
      setBtnRecalcKMDialog(false);
      if (isConfirmed) {
        // btnAceptarRecalcKM();
      }
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion

  //#region --btnGenerarGuiaAlSPI
  const [loadingBtnGenGuiaSPI, setLoadingBtnGenGuiaSPI] = useState(false);
  const [btnGenGuiaSPIDialog, setBtnGenGuiaSPIDialog] = useState(false);

  const handleBtnGenGuiaSPI = (isConfirmed) => {
    try {
      setBtnGenGuiaSPIDialog(false);
      if (isConfirmed) {
        // btnAceptarGenGuiaSPI();
      }
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion

  //#endregion

  return (
    <div className="mantener-hojas-ruta">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"901"} alt="Ingrese el VIN" />
              </label>
              <AutoComplete
                value={filterForm.vin}
                suggestions={vin}
                completeMethod={searchVin}
                onChange={(e) => setFilterForm({ ...filterForm, vin: e.value })}
                field="label"
                dropdown
                forceSelection
                maxLength={17}
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"5431"} alt="HR" />
              </label>
              <AutoComplete
                value={filterForm.hr}
                suggestions={hr}
                onChange={(e) => setFilterForm({ ...filterForm, hr: e.value })}
              />
            </div>

            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={"25192"} alt="Fecha Despacho Desde" />
              </label>
              <Calendar
                value={filterForm.fechaDespachoDesde}
                onChange={(e) => {
                  setFilterForm({ ...filterForm, fechaDespachoDesde: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={"2622"} alt="Hasta" />
              </label>
              <Calendar
                value={filterForm.fechaHasta}
                onChange={(e) => {
                  setFilterForm({ ...filterForm, fechaHasta: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider
                  id={"4301"}
                  alt="Mantener Hojas de Ruta"
                ></LanguageProvider>
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={formattedReportData}
                  columns={null}
                  screenName={"Mantener Hojas de Ruta"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        {
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={formattedReportData}
            paginator
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            resizableColumns
            removableSort
            rowClassName={getRowStyles}
            filterDisplay="menu"
            loading={loadingReportData}
            selectionMode="single"
            onSelectionChange={(e) => setSelectedReportData(e.value)}
            selection={selectedReportData}
          >
            <Column
              field="0"
              header={<LanguageProvider id="924" alt="Hoja de Ruta" />}
              sortable
              filter
            />
            <Column
              field="1"
              header={<LanguageProvider id="16453" alt="Fecha Hoja de Ruta" />}
              sortable
              filter
            />
            <Column
              field="4"
              header={<LanguageProvider id="3344" alt="Estado" />}
              sortable
              filter
            />
            <Column
              field="18"
              header={<LanguageProvider id="10136" alt="Km." />}
              sortable
              filter
              body={(rowData) =>
                rowData[18] ? rowData[18].toFixed(2) : "0,00"
              }
            />
            <Column
              field="8"
              header={<LanguageProvider id="4285" alt="Tractor" />}
              sortable
              filter
            />
            <Column
              field="5"
              header={<LanguageProvider id="10902" alt="Batea" />}
              sortable
              filter
            />
            <Column
              field="13"
              header={<LanguageProvider id="4655" alt="Chofer" />}
              sortable
              filter
            />
            <Column
              field="12"
              header={<LanguageProvider id="4655" alt="Chofer" />}
              sortable
              filter
            />
            <Column
              field="20"
              header={<LanguageProvider id="3206" alt="Tipo Servicio" />}
              sortable
              filter
            />
            <Column
              field="14"
              header={<LanguageProvider id="11787" alt="Proveedor Tractor" />}
              sortable
              filter
            />
            <Column
              field="15"
              header={<LanguageProvider id="11787" alt="Proveedor Tractor" />}
              sortable
              filter
            />
            <Column
              field="16"
              header={<LanguageProvider id="16454" alt="Proveedor Remolque" />}
              sortable
              filter
            />
            <Column
              field="17"
              header={<LanguageProvider id="16454" alt="Proveedor Remolque" />}
              sortable
              filter
            />
            <Column
              field="19"
              header={<LanguageProvider id="16773" alt="Aforo compra" />}
              sortable
              filter
              body={(rowData) =>
                rowData[19] ? rowData[19].toFixed(2) : "0,00"
              }
            />
            <Column
              field="24"
              header={<LanguageProvider id="16455" alt="Aforo Venta" />}
              filter
              body={(rowData) =>
                rowData[24] ? rowData[24].toFixed(2) : "0,00"
              }
            />
            <Column
              field="21"
              header={<LanguageProvider id="16456" alt="Modificó" />}
              sortable
              filter
            />
            <Column
              field="23"
              header={<LanguageProvider id="16774" alt="Hoha ruta anterior" />}
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="1849" alt="Compra" />}
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="1850" alt="Venta" />}
              sortable
              filter
              body={(rowData) =>
                rowData[29] === 1 ? (
                  <LanguageProvider id="10043" alt="Si"></LanguageProvider>
                ) : rowData[29] === 0 ? (
                  <LanguageProvider id="10044" alt="No"></LanguageProvider>
                ) : (
                  ""
                )
              }
            />
            <Column
              field=""
              header={<LanguageProvider id="5592" alt="Carga Combinada" />}
              sortable
              filter
              body={(rowData) =>
                rowData[28] === 1 ? (
                  <LanguageProvider id="10043" alt="Si"></LanguageProvider>
                ) : rowData[28] === 0 ? (
                  <LanguageProvider id="10044" alt="No"></LanguageProvider>
                ) : (
                  ""
                )
              }
            />
            <Column
              field="30"
              header={<LanguageProvider id="23020" alt="Tipo Viaje Retorno" />}
              sortable
              filter
            />
            <Column
              field="25"
              header={<LanguageProvider id="3625" alt="Observaciones" />}
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="5371" alt="Externa" />}
              sortable
              filter
              body={(rowData) =>
                rowData[36] === 1 ? (
                  <LanguageProvider id="10043" alt="Si"></LanguageProvider>
                ) : rowData[36] === 0 ? (
                  <LanguageProvider id="10044" alt="No"></LanguageProvider>
                ) : (
                  ""
                )
              }
            />
            <Column
              field="6"
              header={<LanguageProvider id="23097" alt="Código referencia" />}
              sortable
              filter
            />
            <Column
              field="37"
              header={<LanguageProvider id="25193" alt="COEF" />}
              sortable
              filter
            />
            <Column
              field="39"
              header={<LanguageProvider id="24991" alt="Limite inferior" />}
              sortable
              filter
              body={(rowData) =>
                rowData[39] ? rowData[39].toFixed(2) : "0,00"
              }
            />
            <Column
              field="26"
              header={<LanguageProvider id="24992" alt="Limite Superior" />}
              sortable
              filter
            />
          </DataTable>
        }
        {/* N/A */}
        {/* <div className="button-container">
          <Button
            label={LanguageProvider({ id: "9475", alt: "Transportes en Uso" })}
            icon="pi pi-truck"
            className="custom-button"
            onClick={() => window.open("/transporte-en-uso", "_blank")}
          />
        </div> */}
      </Panel>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id={"16226"} alt="Listado"></LanguageProvider>
              </span>
            </div>
          );
        }}
      >
        {
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={Array.isArray(childReportData) ? childReportData : []}
            paginator
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            resizableColumns
            removableSort
            rowClassName={rowStyles}
            filterDisplay="menu"
            loading={loadingChildReportData}
            selectionMode="single"
            onSelectionChange={(e) => setSelectedChildReportData(e.value)}
            selection={selectedChildReportData}
          >
            <Column
              field="cliente.descripcion"
              header={<LanguageProvider id="0" alt="Cliente" />}
              sortable
              filter
            />
            <Column
              field="vin.id"
              header={<LanguageProvider id="1491" alt="VIN" />}
              sortable
              filter
            />
            <Column
              field="modelo.patente"
              header={<LanguageProvider id="4724" alt="Patente" />}
              sortable
              filter
            />
            <Column
              field="remito"
              header={<LanguageProvider id="3184" alt="Remito" />}
              sortable
              filter
            />
            <Column
              field="subcuentaOrigen.descripcion"
              header={<LanguageProvider id="16457" alt="Playa de la Unidad" />}
              sortable
              filter
            />
            <Column
              field="direccionEntregaP.descripcion"
              header={<LanguageProvider id="740" alt="Playa Destino" />}
              sortable
              filter
            />
            <Column
              field="concesionarioP.descripcion"
              header={<LanguageProvider id="2941" alt="Concesionario" />}
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="22927" alt="Playa/Punto Origen" />}
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="22928" alt="Playa/Punto destino" />}
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="4399" alt="Posición" />}
              sortable
              filter
            />
            <Column
              field="ordenEntrega"
              header={<LanguageProvider id="16459" alt="Orden entrega" />}
              sortable
              filter
            />
            <Column
              field="usuarioUltimaModificacion.id"
              header={<LanguageProvider id="id" alt="Modificó" />}
              sortable
              filter
            />
            <Column
              field="fechaUltimaModificacion.date"
              header={
                <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />
              }
              sortable
              filter
              body={(rowData) =>
                rowData.fechaUltimaModificacion?.date
                  ? formatDateTime(rowData.fechaUltimaModificacion.date)
                  : rowData.fechaUltimaModificacion || ""
              }
            />
            <Column
              field=""
              header={<LanguageProvider id="164" alt="Ciudad" />}
              sortable
              filter
            />
            <Column
              field="tipoRegistro.descripcion"
              header={<LanguageProvider id="504" alt="Tipo Despacho" />}
              sortable
              filter
            />
            <Column
              field="estado.descripcion"
              header={<LanguageProvider id="3344" alt="Estado" />}
              sortable
              filter
            />
            <Column
              field="vin.marca.descripcion"
              header={<LanguageProvider id="396" alt="Marca" />}
              sortable
              filter
            />
            <Column
              field="vin.modelo.descripcion"
              header={<LanguageProvider id="2139" alt="Modelo" />}
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="16460" alt="Pto Retiro" />}
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="16460" alt="Pto Retiro" />}
              sortable
              filter
            />
            <Column
              field="vin.marca.id"
              header={<LanguageProvider id="396" alt="Marca" />}
              sortable
              filter
            />
            <Column
              field="coeficienteVin"
              header={<LanguageProvider id="25194" alt="Coef Vin" />}
              sortable
              filter
            />
          </DataTable>
        }
        <div className="button-container">
          {/* Dialog de aviso para selecionar ao menos um registro */}
          <Dialog
            header={getLabel("16239", "Atención")}
            visible={validaDataDialog}
            style={{ width: "20vw", textAlign: "center" }}
            footer={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  label={getLabel("5599", "OK")}
                  onClick={() => setValidaDataDialog(false)}
                  autoFocus
                />
              </div>
            }
            onHide={() => setValidaDataDialog(false)}
          >
            <p>
              {getLabel(
                "753",
                "Debe seleccionar al menos un registro de la Grilla."
              )}
            </p>
          </Dialog>

          <Button
            label={LanguageProvider({ id: "23824", alt: "Alta" })}
            icon="pi pi-plus-circle"
            className="custom-button green-button"
            onClick={() => setBtnAltaDialog(true)}
          />
          <Dialog
            header={`${getLabel("4301", "Mantener Hojas de Ruta")} - ${getLabel("23824", "Alta")}`}
            visible={btnAltaDialog}
            style={{ width: "60vw", textAlign: "center" }}
            footer={
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Button
                  label={getLabel("9491", "Aceptar")}
                  icon="pi pi-check"
                  onClick={() => handleBtnAlta(true)}
                  autoFocus
                  disabled={loadingBtnAlta}
                />
                <Button
                  label={getLabel("3155", "Cancelar")}
                  icon="pi pi-times"
                  onClick={() => handleBtnAlta(false)}
                  className="p-button-text"
                  disabled={loadingBtnAlta}
                />
              </div>
            }
            onHide={() => setBtnAltaDialog(false)}
          >
            {loadingBtnAlta && (
              <ProgressSpinner style={{ width: "50px", height: "50px" }} />
            )}

            <TabView>
              {/* Cabecera */}
              <TabPanel
                header={
                  <LanguageProvider id="6693" alt="Cabecera"></LanguageProvider>
                }
              >
                <div className="mantener-hojas-ruta">
                  <div className="form-container">
                    <div className="form">
                      <div className="form-left">
                        <div className="sipco-option-row">
                          <div className="sipco-option-left-label">
                            <label>
                              <LanguageProvider
                                id="1342"
                                alt="Fecha Despacho"
                              />
                            </label>
                            <Calendar
                              value={model.fechaDespacho}
                              onChange={(e) =>
                                setModel({
                                  ...model,
                                  fechaDespacho: e.value,
                                })
                              }
                              showButtonBar
                              showIcon
                              dateFormat="dd/mm/yy"
                            />
                          </div>
                        </div>

                        <div className="sipco-option-left-label">
                          <label>
                            <LanguageProvider id={"3344"} alt="Estado" />
                          </label>
                          <Dropdown
                            value={model.estado}
                            options={estado}
                            onChange={(e) =>
                              setModel({ ...model, estado: e.value })
                            }
                            optionLabel="descripcion"
                            loading={loadingEstado}
                            filter
                            disabled={true}
                          />
                        </div>

                        <div className="sipco-option-left-label">
                          <label>
                            <LanguageProvider
                              id="12305"
                              alt="Nro. Hoja de Ruta Prov."
                            />
                          </label>
                          <AutoComplete
                            value={model.nroHojaRutaProv}
                            suggestions={nroHojaRutaProv}
                            onChange={(e) =>
                              setModel({
                                ...model,
                                nroHojaRutaProv: e.value,
                              })
                            }
                            field="label"
                          />
                        </div>

                        <div className="sipco-option-left-label">
                          <label>
                            <LanguageProvider id="3150" alt="Total KM:" />
                          </label>
                          <AutoComplete
                            value={model.totalKm}
                            suggestions={totalKm}
                            onChange={(e) =>
                              setModel({
                                ...model,
                                totalKm: e.value,
                              })
                            }
                            field="label"
                          />
                        </div>

                        <div className="form-row RadioButtonFilter">
                          <Checkbox
                            checked={model.chkCargaCombinada}
                            onChange={(e) =>
                              setModel({
                                ...model,
                                chkCargaCombinada: !model.chkCargaCombinada,
                              })
                            }
                          />
                          <label>
                            <LanguageProvider id="5592" alt="Carga Combinada" />
                          </label>
                          <Checkbox
                            checked={model.chkVenta}
                            onChange={(e) =>
                              setModel({
                                ...model,
                                chkVenta: !model.chkVenta,
                              })
                            }
                          />
                          <label>
                            <LanguageProvider id="1850" alt="Venta" />
                          </label>
                          <Checkbox
                            checked={model.chkCompra}
                            onChange={(e) =>
                              setModel({
                                ...model,
                                chkCompra: !model.chkCompra,
                              })
                            }
                          />
                          <label>
                            <LanguageProvider id="1849" alt="Compra" />
                          </label>
                        </div>

                        <div className="sipco-option-left-label">
                          <label>
                            <LanguageProvider id={"3206"} alt="Tipo Servicio" />
                          </label>
                          <Dropdown
                            value={model.tipoServicio}
                            options={tipoServicio}
                            onChange={(e) =>
                              setModel({ ...model, tipoServicio: e.value })
                            }
                            optionLabel="descripcion"
                            loading={loadingTipoServicio}
                            filter
                            disabled={true}
                          />
                        </div>

                        <div className="sipco-option-left-label">
                          <label>
                            <LanguageProvider
                              id={"23020"}
                              alt="Tipo Viaje Retorno"
                            />
                          </label>
                          <Dropdown
                            value={model.tipoViajeRetorno}
                            options={tipoViajeRetorno}
                            onChange={(e) =>
                              setModel({ ...model, tipoViajeRetorno: e.value })
                            }
                            optionLabel="descripcion"
                            loading={loadingTipoViajeRetorno}
                            filter
                          />
                        </div>

                        <div className="sipco-option-left-label">
                          <label>
                            <LanguageProvider id="16455" alt="Aforo Venta" />
                          </label>
                          <AutoComplete
                            value={model.aforoVenta}
                            suggestions={aforoVenta}
                            onChange={(e) =>
                              setModel({
                                ...model,
                                aforoVenta: e.value,
                              })
                            }
                            field="label"
                          />
                        </div>
                      </div>

                      <div className="form-right">
                        <div className="sipco-option-left-label">
                          <label>
                            <LanguageProvider id={"754"} alt="Transportista" />
                          </label>
                          <Dropdown
                            value={model.transportista}
                            options={transportista}
                            onChange={(e) =>
                              setModel({ ...model, transportista: e.value })
                            }
                            optionLabel="descripcion"
                            loading={loadingTransportista}
                            filter
                          />
                        </div>

                        <div className="sipco-option-left-label">
                          <label>
                            <LanguageProvider id={"4285"} alt="Tractor" />
                          </label>
                          <Dropdown
                            value={model.tractor}
                            options={tractor}
                            onChange={(e) =>
                              setModel({ ...model, tractor: e.value })
                            }
                            optionLabel="descripcion"
                            loading={loadingTractor}
                            filter
                          />
                        </div>

                        <div className="sipco-option-left-label">
                          <label>
                            <LanguageProvider
                              id={"4284"}
                              alt="Batea / Transportín"
                            />
                          </label>
                          <Dropdown
                            value={model.bateaTransportin}
                            options={bateaTransportin}
                            onChange={(e) =>
                              setModel({ ...model, bateaTransportin: e.value })
                            }
                            optionLabel="descripcion"
                            loading={loadingBateaTransportin}
                            filter
                          />
                        </div>

                        <div className="sipco-option-left-label">
                          <label>
                            <LanguageProvider id={"4655"} alt="Chofer" />
                          </label>
                          <Dropdown
                            value={model.chofer}
                            options={chofer}
                            onChange={(e) =>
                              setModel({ ...model, chofer: e.value })
                            }
                            optionLabel="descripcion"
                            loading={loadingChofer}
                            filter
                          />
                        </div>

                        <div className="sipco-option-left-label">
                          <label>
                            <LanguageProvider id="4145" alt="Obs." />
                          </label>
                          <AutoComplete
                            value={model.obs}
                            suggestions={obs}
                            onChange={(e) =>
                              setModel({
                                ...model,
                                obs: e.value,
                              })
                            }
                            field="label"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

              {/* Detalle */}
              <TabPanel
                header={
                  <LanguageProvider id="6694" alt="Detalle"></LanguageProvider>
                }
              >
                <div className="mantener-hojas-ruta">
                  <div className="button-container">
                    <Button
                      label={LanguageProvider({
                        id: "22170",
                        alt: "Pedidos Playa (Despacho desde Stock)",
                      })}
                      icon="pi pi-search"
                      className="custom-button"
                      onClick={() => setBtnPedidosPlayaDialog(true)}
                    />
                    <Dialog
                      visible={btnPedidosPlayaDialog}
                      onHide={() => setBtnPedidosPlayaDialog(false)}
                      style={{
                        width: "100vw",
                        maxWidth: "1020px",
                        padding: "1rem",
                      }}
                    >
                      <PedidosPlaya />
                    </Dialog>
                  </div>

                  <div className="table-container">
                    {/* <DataTable
                      size={"small"}
                      showGridlines
                      stripedRows
                      ref={dt}
                      value={childReportData}
                      paginator
                      scrollHeight="flex"
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"
                      resizableColumns
                      removableSort
                      rowClassName={rowStyles}
                      filterDisplay="menu"
                      loading={loadingChildReportData}
                      selectionMode="single"
                      onSelectionChange={(e) =>
                        setSelectedChildReportData(e.value)
                      }
                      selection={selectedChildReportData}
                    >
                      <Column
                        field="cliente.descripcion"
                        header={<LanguageProvider id="0" alt="Cliente" />}
                        sortable
                        filter
                      />
                      <Column
                        field="vin.id"
                        header={<LanguageProvider id="1491" alt="VIN" />}
                        sortable
                        filter
                      />
                      <Column
                        field="modelo.patente"
                        header={<LanguageProvider id="4724" alt="Patente" />}
                        sortable
                        filter
                      />
                      <Column
                        field="3"
                        header={<LanguageProvider id="3184" alt="Remito" />}
                        sortable
                        filter
                      />
                      <Column
                        field="subcuentaOrigen.descripcion"
                        header={
                          <LanguageProvider
                            id="16457"
                            alt="Playa de la Unidad"
                          />
                        }
                        sortable
                        filter
                      />
                      <Column
                        field="5"
                        header={
                          <LanguageProvider id="740" alt="Playa Destino" />
                        }
                        sortable
                        filter
                      />
                      <Column
                        field="concesionarioP.descripcion"
                        header={
                          <LanguageProvider id="2941" alt="Concesionario" />
                        }
                        sortable
                        filter
                      />
                      <Column
                        field="7"
                        header={
                          <LanguageProvider
                            id="22927"
                            alt="Playa/Punto Origen"
                          />
                        }
                        sortable
                        filter
                      />
                      <Column
                        field="8"
                        header={
                          <LanguageProvider
                            id="22928"
                            alt="Playa/Punto destino"
                          />
                        }
                        sortable
                        filter
                      />
                      <Column
                        field="9"
                        header={<LanguageProvider id="4399" alt="Posición" />}
                        sortable
                        filter
                      />
                      <Column
                        field="ordenEntrega"
                        header={
                          <LanguageProvider id="16459" alt="Orden entrega" />
                        }
                        sortable
                        filter
                      />
                      <Column
                        field="usuarioUltimaModificacion.id"
                        header={<LanguageProvider id="id" alt="Modificó" />}
                        sortable
                        filter
                      />
                      <Column
                        field="fechaUltimaModificacion.date"
                        header={
                          <LanguageProvider
                            id="5271"
                            alt="Fec. Últ. Modificación"
                          />
                        }
                        sortable
                        filter
                      />
                      <Column
                        field="13"
                        header={<LanguageProvider id="164" alt="Ciudad" />}
                        sortable
                        filter
                      />
                      <Column
                        field="tipoRegistro.descripcion"
                        header={
                          <LanguageProvider id="504" alt="Tipo Despacho" />
                        }
                        sortable
                        filter
                      />
                      <Column
                        field="estado.descripcion"
                        header={<LanguageProvider id="3344" alt="Estado" />}
                        sortable
                        filter
                      />
                      <Column
                        field="vin.marca.descripcion"
                        header={<LanguageProvider id="396" alt="Marca" />}
                        sortable
                        filter
                      />
                      <Column
                        field="vin.modelo.descripcion"
                        header={<LanguageProvider id="2139" alt="Modelo" />}
                        sortable
                        filter
                      />
                      <Column
                        field="18"
                        header={
                          <LanguageProvider id="16460" alt="Pto Retiro" />
                        }
                        sortable
                        filter
                      />
                      <Column
                        field="19"
                        header={
                          <LanguageProvider id="16460" alt="Pto Retiro" />
                        }
                        sortable
                        filter
                      />
                      <Column
                        field="vin.marca.id"
                        header={<LanguageProvider id="396" alt="Marca" />}
                        sortable
                        filter
                      />
                      <Column
                        field="21"
                        header={<LanguageProvider id="25194" alt="Coef Vin" />}
                        sortable
                        filter
                      />
                    </DataTable> */}
                  </div>

                  <div className="form-conteiner">
                    <div className="form">
                      <div className="form-left">
                        <div className="sipco-option-row">
                          <div className="sipco-option">
                            <AutoComplete
                              value={model.quantAddData}
                              suggestions={quantAddData}
                              onChange={(e) =>
                                setModel({ ...model, quantAddData: e.value })
                              }
                            />
                          </div>

                          <div className="sipco-option">
                            <AutoComplete
                              value={model.quantRemData}
                              suggestions={quantRemData}
                              onChange={(e) =>
                                setModel({ ...model, quantRemData: e.value })
                              }
                            />
                          </div>

                          <Button
                            icon="pi pi-chevron-up"
                            className="custom-button green-button"
                            // onClick={() => handleClick("up")}
                          />
                          <Button
                            icon="pi pi-chevron-down"
                            className="custom-button red-button"
                            // onClick={() => handleClick("down")}
                          />
                          <Button
                            label={LanguageProvider({
                              id: "273",
                              alt: "Consulta Vin",
                            })}
                            icon="pi pi-search"
                            className="custom-button"
                            onClick={() => setBtnConsultaVinDialog(true)}
                          />
                          <Dialog
                            visible={btnConsultaVinDialog}
                            onHide={() => setBtnConsultaVinDialog(false)}
                            style={{
                              width: "100vw",
                              maxWidth: "1020px",
                              padding: "1rem",
                            }}
                          >
                            <ConsultaVin />
                          </Dialog>
                        </div>

                        <div className="sipco-option-row">
                          <div className="RadioButtonFilter">
                            <RadioButton
                              checked={
                                model.radioButton === "Despacho desde Stock"
                              }
                              onChange={() =>
                                handleRadioChange("Despacho desde Stock")
                              }
                            />
                            <label>
                              <LanguageProvider
                                id="5394"
                                alt="Despacho desde Stock"
                              />
                            </label>

                            <RadioButton
                              checked={
                                model.radioButton === "Despacho Patentado"
                              }
                              onChange={() =>
                                handleRadioChange("Despacho Patentado")
                              }
                            />
                            <label>
                              <LanguageProvider
                                id="5396"
                                alt="Despacho Patentado"
                              />
                            </label>

                            <RadioButton
                              checked={model.radioButton === "Devolución"}
                              onChange={() => handleRadioChange("Devolución")}
                            />
                            <label>
                              <LanguageProvider id="846" alt="Devolución" />
                            </label>

                            <RadioButton
                              checked={model.radioButton === "Viaje Tercero"}
                              onChange={() =>
                                handleRadioChange("Viaje Tercero")
                              }
                            />
                            <label>
                              <LanguageProvider
                                id="22913"
                                alt="Viaje Tercero"
                              />
                            </label>
                          </div>
                        </div>

                        <div className="sipco-option-row">
                          <div className="RadioButtonFilter">
                            <RadioButton
                              checked={
                                model.radioButton ===
                                "Importado (Llegada Buque Camión)"
                              }
                              onChange={() =>
                                handleRadioChange(
                                  "Importado (Llegada Buque Camión)"
                                )
                              }
                            />
                            <label>
                              <LanguageProvider
                                id="5537"
                                alt="Importado (Llegada Buque Camión)"
                              />
                            </label>

                            <RadioButton
                              checked={
                                model.radioButton ===
                                "Transferencia. e/playas - Disp. Importación"
                              }
                              onChange={() =>
                                handleRadioChange(
                                  "Transferencia. e/playas - Disp. Importación"
                                )
                              }
                            />
                            <label>
                              <LanguageProvider
                                id="5395"
                                alt="Transferencia. e/playas - Disp. Importación"
                              />
                            </label>
                          </div>
                        </div>

                        <div className={"sipco-option-row"}>
                          <label>
                            <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                          </label>
                          <AutoComplete
                            value={model.vinDetalle}
                            suggestions={vinDetalle}
                            completeMethod={searchVin} //mesmo do filter
                            onChange={(e) =>
                              setModel({ ...model, vinDetalle: e.value })
                            }
                            field="label"
                            dropdown
                            forceSelection
                            maxLength={17}
                          />

                          <div className="move-right">
                            <label>
                              <LanguageProvider id="54" alt="Cliente" />
                            </label>
                            <Dropdown
                              value={model.clienteDetalle}
                              options={clienteDetalle}
                              onChange={(e) =>
                                setModel({ ...model, clienteDetalle: e.value })
                              }
                              optionLabel="descripcion"
                              loading={loadingClienteDetalle}
                              filter
                            />
                          </div>
                        </div>

                        <div className={"sipco-option-row"}>
                          <label>
                            <LanguageProvider id="3344" alt="Estado" />
                          </label>
                          <Dropdown
                            value={model.estadoDetalle}
                            options={estadoDetalle}
                            onChange={(e) =>
                              setModel({ ...model, estadoDetalle: e.value })
                            }
                            optionLabel="descripcion"
                            loading={loadingEstadoDetalle}
                            filter
                            disabled={true}
                          />

                          <div className="move-right">
                            <label>
                              <LanguageProvider id="2559" alt="Nro. Remito" />
                            </label>
                            <Dropdown
                              value={model.nroRemito}
                              options={nroRemito}
                              onChange={(e) =>
                                setModel({ ...model, nroRemito: e.value })
                              }
                              optionLabel="descripcion"
                              loading={loadingNroRemito}
                              filter
                            />
                          </div>
                        </div>

                        <div className={"sipco-option-row"}>
                          <label>
                            <LanguageProvider id="4724" alt="Patente" />
                          </label>
                          <AutoComplete
                            value={model.patente}
                            suggestions={patente}
                            onChange={(e) =>
                              setModel({ ...model, patente: e.value })
                            }
                          />
                        </div>

                        <div className={"sipco-option-row"}>
                          <label>
                            <LanguageProvider id="10748" alt="Ubicación" />
                          </label>
                          <AutoComplete
                            value={model.ubicacion}
                            suggestions={ubicacion}
                            onChange={(e) =>
                              setModel({ ...model, ubicacion: e.value })
                            }
                          />
                        </div>

                        <div className="form-row RadioButtonFilter">
                          <Checkbox
                            checked={model.chkTrabajarConLectoraCodBarras}
                            onChange={(e) =>
                              setModel({
                                ...model,
                                chkTrabajarConLectoraCodBarras:
                                  !model.chkTrabajarConLectoraCodBarras,
                              })
                            }
                          />
                          <label>
                            <LanguageProvider
                              id="1500"
                              alt="Trabajar con Lectora Cod.Barras"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </Dialog>

          <Button
            label={LanguageProvider({ id: "19267", alt: "Modificar" })}
            icon="pi pi-pencil"
            className="custom-button yellow-button"
            onClick={() => {
              const hasData = selectedReportData?.length > 0;
              const isDespachada = selectedReportData?.["2"] === "4";

              if (hasData) {
                isDespachada
                  ? setValidaDespachadaDialog(true)
                  : setBtnModificarDialog(true);
              } else {
                setValidaDataDialog(true);
              }
            }}
          />
          <Dialog
            header={getLabel("16239", "Atención")}
            visible={validaDespachadaDialog}
            style={{ width: "20vw", textAlign: "center" }}
            footer={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  label={getLabel("5599", "OK")}
                  onClick={() => setValidaDespachadaDialog(false)}
                  autoFocus
                />
              </div>
            }
            onHide={() => setValidaDespachadaDialog(false)}
          >
            <p>
              {getLabel(
                "5451",
                "La hoja de ruta esta despachada, debe cancelar contablemente el despacho antes de modificar"
              )}
            </p>
          </Dialog>
          <Dialog
            header={`${getLabel("4301", "Mantener Hojas de Ruta")} - ${getLabel("19267", "Modificar")}`}
            visible={btnModificarDialog}
            style={{ width: "40vw", textAlign: "center" }}
            footer={
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Button
                  label={getLabel("9491", "Aceptar")}
                  icon="pi pi-check"
                  onClick={() => handleBtnModificar(true)}
                  autoFocus
                  disabled={loadingBtnModificar}
                />
                <Button
                  label={getLabel("3155", "Cancelar")}
                  icon="pi pi-times"
                  onClick={() => handleBtnModificar(false)}
                  className="p-button-text"
                  disabled={loadingBtnModificar}
                />
              </div>
            }
            onHide={() => setBtnModificarDialog(false)}
          >
            {loadingBtnModificar && (
              <ProgressSpinner style={{ width: "50px", height: "50px" }} />
            )}
          </Dialog>

          <Button
            label={LanguageProvider({ id: "2016", alt: "Borrar" })}
            icon="pi pi-times"
            className="custom-button red-button"
            onClick={() => {
              const hasData = selectedReportData?.length > 0;

              if (hasData) {
                setBtnBorrarDialog(true);
              } else {
                setValidaDataDialog(true);
              }
            }}
          />
          <Dialog
            header={`${getLabel("16239", "Atención")}`}
            visible={btnBorrarDialog}
            style={{ width: "10vw", textAlign: "center" }}
            footer={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  label={getLabel("9491", "Aceptar")}
                  icon="pi pi-check"
                  onClick={() => handleBtnBorrar(true)}
                  autoFocus
                  disabled={loadingBtnBorrar}
                />
                <Button
                  label={getLabel("3155", "Cancelar")}
                  icon="pi pi-times"
                  onClick={() => handleBtnBorrar(false)}
                  className="p-button-text"
                  disabled={loadingBtnBorrar}
                />
              </div>
            }
            onHide={() => setBtnBorrarDialog(false)}
          >
            <p>{getLabel("829", "¿Elimina el o los registros?")}</p>
            {loadingBtnBorrar && (
              <ProgressSpinner style={{ width: "50px", height: "50px" }} />
            )}
          </Dialog>

          <Button
            label={LanguageProvider({ id: "5522", alt: "Posición Batea" })}
            icon="pi pi-box"
            className="custom-button"
            onClick={() => {
              const hasData = selectedReportData?.length > 0;

              if (hasData) {
                setBtnPosicionBateaDialog(true);
              } else {
                setValidaDataDialog(true);
              }
            }}
          />
          {/* trazer Dialog com o modal no panel de Detalles */}

          <Button
            label={LanguageProvider({ id: "16785", alt: "Cambiar fecha" })}
            icon="pi pi-calendar"
            className="custom-button"
            onClick={() => {
              const hasData = selectedReportData?.length > 0;
              const isHojaRutaExterna = ""; // Validar aqui se 'origenWs' exbibir Dialog com  mensagem '24521'

              if (hasData) {
                isHojaRutaExterna
                  ? setValidHojaRutaExtDialog(true)
                  : setBtnCambiarFechaDialog(true);
              } else {
                setValidaDataDialog(true);
              }
            }}
          />
          {/* Dialog origenWs */}

          <Button
            label={LanguageProvider({
              id: "11683",
              alt: "Cancelar Contable",
            })}
            icon="pi pi-times-circle"
            className="custom-button red-button"
            onClick={() => {
              const hasData = selectedReportData?.length > 0;

              if (hasData) {
                setBtnCancContableDialog(true);
              } else {
                setValidaDataDialog(true);
              }
            }}
          />
          <Dialog
            header={`${getLabel("16239", "Atención")}`}
            visible={btnCancContableDialog}
            style={{ width: "10vw", textAlign: "center" }}
            footer={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  label={getLabel("9491", "Aceptar")}
                  icon="pi pi-check"
                  onClick={() => handleBtnCancelarContable(true)}
                  autoFocus
                  disabled={loadingBtnCancContable}
                />
                <Button
                  label={getLabel("3155", "Cancelar")}
                  icon="pi pi-times"
                  onClick={() => handleBtnCancelarContable(false)}
                  className="p-button-text"
                  disabled={loadingBtnCancContable}
                />
              </div>
            }
            onHide={() => setBtnCancContableDialog(false)}
          >
            <p>{getLabel("28", "¿Confirma las modificaciones?")}</p>
            {loadingBtnCancContable && (
              <ProgressSpinner style={{ width: "50px", height: "50px" }} />
            )}
          </Dialog>

          <Button
            label={LanguageProvider({ id: "1309", alt: "Contabilizar" })}
            icon="pi pi-check-circle"
            className="custom-button green-button"
            onClick={() => {
              const hasData = selectedReportData?.length > 0;

              if (hasData) {
                setBtnContabilizarDialog(true);
              } else {
                setValidaDataDialog(true);
              }
            }}
          />

          <Button
            label={LanguageProvider({
              id: "5513",
              alt: "Listado de hojas de ruta",
            })}
            icon="pi pi-file"
            className="custom-button"
            onClick={() => window.open("/listado-de-hojas-de-rutas", "_blank")}
          />

          <Button
            label={LanguageProvider({
              id: "25067",
              alt: "Recalcular KM",
            })}
            icon="pi pi-calculator"
            className="custom-button"
            onClick={() => {
              const hasData = selectedReportData?.length > 0;
              const isDespachada = selectedReportData?.["2"] === "4";

              if (hasData) {
                isDespachada
                  ? setValidaDespachKMDialog(true)
                  : setBtnRecalcKMDialog(true);
              } else {
                setValidaDataDialog(true);
              }
            }}
          />
          <Dialog
            header={getLabel("16239", "Atención")}
            visible={validaDespachKMDialog}
            style={{ width: "20vw", textAlign: "center" }}
            footer={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  label={getLabel("5599", "OK")}
                  onClick={() => setValidaDespachKMDialog(false)}
                  autoFocus
                />
              </div>
            }
            onHide={() => setValidaDespachKMDialog(false)}
          >
            <p>
              {getLabel(
                "25202",
                `La HR se encuentra en estado DESPACHADA por lo que no puede Recalcular los KM`
              )}
            </p>
          </Dialog>
          <Dialog
            header={`${getLabel("16239", "Atención")}`}
            visible={btnRecalcKMDialog}
            style={{ width: "10vw", textAlign: "center" }}
            footer={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  label={getLabel("9491", "Aceptar")}
                  icon="pi pi-check"
                  onClick={() => handleBtnRecalcKM(true)}
                  autoFocus
                  disabled={loadingBtnRecalcKM}
                />
                <Button
                  label={getLabel("3155", "Cancelar")}
                  icon="pi pi-times"
                  onClick={() => handleBtnRecalcKM(false)}
                  className="p-button-text"
                  disabled={loadingBtnRecalcKM}
                />
              </div>
            }
            onHide={() => setBtnRecalcKMDialog(false)}
          >
            <p>{getLabel("25069", "¿Desea recalcular los KM de la HR?")}</p>
            {loadingBtnRecalcKM && (
              <ProgressSpinner style={{ width: "50px", height: "50px" }} />
            )}
          </Dialog>

          <Button
            label={LanguageProvider({
              id: "25103",
              alt: "Generar Guía al SPI",
            })}
            className="custom-button"
            onClick={() => {
              const hasData = selectedReportData?.length > 0;

              if (hasData) {
                setBtnContabilizarDialog(true);
              } else {
                setValidaDataDialog(true);
              }
            }}
          />
        </div>
      </Panel>
    </div>
  );
}

export default MentenerHojasRuta;

const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
